import { showFieldValue } from 'utils'

import { renderCurrency } from 'utils/stringFormatting'

import * as T from 'types'

export const getDiscountAmount = (discount?: T.IOfferCode) => {
  let discountAmount: string = '---'
  if (!discount) return discountAmount

  switch (discount.units) {
    case 'percent':
      discountAmount = `${discount.discount}%`
      break
    case 'fixed':
      discountAmount = '(fixed price)'
      break
    default:
      discountAmount = `${renderCurrency(discount.discount)}`
  }

  return discountAmount
}

// group fields by listingFlowSubstep names
export const groupListingFieldsByFlowSubstep = (listingContentFields: T.IMlsField[]) => {
  const groupedFields: T.IMlsField[][] = []
  const fieldLabels: string[] = []
  listingContentFields.forEach(field => {
    const substepLabel = field.listingFlowSubStep || ''
    let labelIndex = fieldLabels.indexOf(substepLabel)

    if (labelIndex === -1) {
      fieldLabels.push(substepLabel)
      groupedFields.push([])
      labelIndex = fieldLabels.length - 1
    }

    groupedFields[labelIndex].push(field)
  })
  return groupedFields
}

// filter mls fiels with values
export const filterMlsFieldsValues = (mlsFields: T.IMlsField[]) => {
  const fieldsWithValues = mlsFields.filter(field => field.value)
  const fieldsWithoutValues = mlsFields.filter(field => !field.value)
  return { fieldsWithValues, fieldsWithoutValues }
}

// group fields in an alphabetical order
export const groupListingFieldsAlphabetically = (mlsFields: T.IMlsField[]) =>
  mlsFields.sort((a, b) => {
    if (
      a.fieldId?.name
      && b.fieldId?.name
      && a.fieldId.name.toLowerCase() < b.fieldId.name.toLowerCase()
    ) {
      return -1
    }

    if (
      a.fieldId?.name
      && b.fieldId?.name
      && a.fieldId.name.toLowerCase() > b.fieldId.name.toLowerCase()
    ) {
      return 1
    }

    return 0
  })

export const formatFieldToArray = (fieldType: string, field: T.IMlsField) => {
  let fieldValue: string | string[] = showFieldValue(field) || ''
  if (fieldType === 'list' || fieldType === 'link') fieldValue = field.value || ''

  if (!Array.isArray(fieldValue)) fieldValue = [fieldValue]

  return fieldValue
}

export const formatFieldArray = (fieldType: string, fieldValue: string[]) =>
  fieldType === 'checklist' ? fieldValue.sort().join(', ') : fieldValue.join('')
