import { Table } from 'antd'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { themeFontSize } from 'utils/styles'

const CustomTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
  }
  .ant-table table {
    ${themeFontSize}
  }
  .ant-table-cell {
    white-space: nowrap;
  }
`

const TableView = (tableData: any[], tableColumns?: any[], expandable?: object) => {
  const theme = useContext(ThemeContext)

  if (tableData.length === 0) return null

  const dataSource = tableData.map((value: any, index: number) => ({ ...value, key: index }))

  const columns
    = tableColumns
    || Object.keys(tableData[0])
      .map(key => ({ title: key, dataIndex: key, key }))
      .filter(col => col.title !== 'key')

  return (
    <CustomTable
      columns={columns}
      dataSource={dataSource}
      expandable={expandable}
      pagination={false}
      scroll={{ x: theme.metrics.phone }}
    />
  )
}

export default TableView
