import { red } from '@ant-design/colors'
import { PhoneOutlined } from '@ant-design/icons'
import { Badge, Button, Modal, message } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'

import { bringUserToIdle, bringUserToOffline } from 'services/twilioWorker'

import * as T from 'types'
import * as E from 'types/enums'

const StyledButton = styled(Button)`
  font-size: 13px;
  border: none;
  padding: 0 10px;
  box-shadow: none;
`

interface IProps {
  twilioWorker: T.ITwilioWorker
}
const TwilioStatusButton: FunctionComponent<IProps> = ({ twilioWorker }) => {
  const [loading, setLoading] = useState(false)
  const [saveError, setSaveError] = useState('')

  const displayErrorMessage = (errorMsg: string) => {
    message.error({
      style: { marginTop: '50px' },
      duration: 5,
      content: errorMsg,
    })
  }

  useEffect(() => {
    if (saveError) {
      displayErrorMessage(saveError)
      setSaveError('')
    }
  }, [saveError])

  const handleStatusChange = async () => {
    if (twilioWorker?.activityName === E.TwilioWorkerActivity.reserved) {
      message.warning({
        style: { marginTop: '50px' },
        duration: 5,
        content: 'Can not change status when phone number is in use',
      })
      return
    }

    if (twilioWorker?.activityName === E.TwilioWorkerActivity.busy) {
      const confirmation = new Promise(resolve => {
        Modal.confirm({
          title: 'You have an active call. Are you sure you want to go offline?',
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        })
      })

      if (!(await confirmation)) {
        return
      }
    }

    setLoading(true)

    const [data, error]
      = twilioWorker?.activityName === E.TwilioWorkerActivity.busy
      || twilioWorker?.activityName === E.TwilioWorkerActivity.idle
        ? await bringUserToOffline(twilioWorker.friendlyName)
        : await bringUserToIdle(twilioWorker.friendlyName)

    if (!data && error?.message) setSaveError(error?.message)

    setLoading(false)
  }

  const isWorkerOnCall = () => twilioWorker?.activityName === E.TwilioWorkerActivity.busy

  return (
    <Badge count={isWorkerOnCall() ? <PhoneOutlined style={{ color: red[4] }} /> : 0}>
      <StyledButton
        onClick={handleStatusChange}
        loading={loading}
        size="small"
      >
        {twilioWorker?.activityName !== E.TwilioWorkerActivity.offline
          ? 'Turn OFF phone'
          : 'Turn ON phone'}
      </StyledButton>
    </Badge>
  )
}

export default TwilioStatusButton
