/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRouter } from 'next/router'
import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import * as T from 'types'

import { api } from '../api'
import { fetch, fetchWithToken } from '../fetchers'
import { patchListing } from '../listings'
import { getDataFromResponse } from './utils/dataHelpers'
import { getFetchErrorMessage, getUpdateErrorMessage } from './utils/errorMessageHelpers'
import mutateCacheKeysByRegex from './utils/mutateCacheKeysByRegex'

let lastRequest = 0
let updatingFields = false

const noListingError: [undefined, T.IServerError] = [
  undefined,
  { message: 'Listing not available' },
]

interface IOptions {
  customLinkUrl: boolean
  withToken: boolean
  noFetch: boolean
}

const defaultOptions: IOptions = {
  customLinkUrl: false,
  withToken: true,
  noFetch: false,
}

const mutateHistoryRoutes = () => mutateCacheKeysByRegex(/history/)

const getSwrKey = (customLinkUrl: boolean, listingId: string) =>
  customLinkUrl ? `/view/${listingId}` : `/listings/${listingId}/ownerDetails`

const useListing = ({ customLinkUrl, withToken, noFetch } = defaultOptions) => {
  const router = useRouter()
  let apiEndpoint: string | null = getSwrKey(customLinkUrl, String(router.query.id))

  // router is `undefined` on first render. use `null` key in `useSWR` to avoid calling API with an
  // `undefined` param that will result in flashing user an error message
  if (noFetch || !router.query.id) apiEndpoint = null

  const response = useSWR<T.IListing | T.IServerError>(
    apiEndpoint,
    withToken ? fetchWithToken : fetch,
    { isPaused: () => updatingFields },
  )

  let listing: T.IListing | undefined
  if (response.data && !response.data.message) listing = response.data as T.IListing

  const fetchError = getFetchErrorMessage(response, 'listing')
  const [errors, setErrors] = useState<{ [key: string]: T.IServerError }>({})

  const [updateError, setUpdateError] = useState('')

  const update: T.IUpdateListing = async (updates, options) => {
    if (!listing) return 'No Listing'

    // edge case: swr would refetch after we tried to update conditional parent fields
    // this tells swr to temporarily pause revalidation, which solves that issue
    if (Object.keys(updates).includes('fields')) updatingFields = true

    // race condition fix
    const requestId = Math.random()
    lastRequest = requestId
    let errMsg = ''

    // we POST the API and update the UI with the data that comes back on success
    const updateResult = await patchListing(listing._id, updates, options)

    if (updateResult) {
      if (lastRequest !== requestId) {
        errMsg = 'version mismatch'
        setUpdateError(errMsg)
        return errMsg
      }

      // clear the error, if there are no errors
      if (updateError) setUpdateError('')

      await mutate(apiEndpoint, updateResult, false)
    } else {
      errMsg = 'failed to update'
      setUpdateError(errMsg)
    }

    if (updatingFields) updatingFields = false

    return errMsg
  }

  // new data service style - attempt to write new services this way as we migrate existing ones
  const addSellerNote: T.IAddSellerNote = async ({ sellerNote: newSellerNote, options }) => {
    if (!listing) return noListingError

    const addNoteResponse = await api.addAgentSellerNote(listing._id, newSellerNote, options)
    const updatedListing = getDataFromResponse<T.IListing>(addNoteResponse)

    const userReadableError = 'Failed to add the seller note'
    const addSellerNoteError = getUpdateErrorMessage(addNoteResponse, { userReadableError })
    if (addSellerNoteError) setErrors({ ...errors, addSellerNoteError })

    if (updatedListing) {
      await mutate(apiEndpoint, updatedListing, false)
      await mutateHistoryRoutes()
    }

    return [updatedListing, addSellerNoteError]
  }

  // new data service style - attempt to write new services this way as we migrate existing ones
  const updateAdminGoLiveDate: T.IUpdateAdminGoLiveDate = async ({ goLiveDate }) => {
    if (!listing) return noListingError

    const goLiveResponse = await api.updateAdminGoLiveDate(listing._id, { goLiveDate })
    const updatedListing = getDataFromResponse<T.IListing>(goLiveResponse)

    const userReadableError = 'failed to update the admin-selected go live date'
    const updateAdminGoLiveDateError = getUpdateErrorMessage(goLiveResponse, { userReadableError })
    if (updateAdminGoLiveDateError) setErrors({ ...errors, updateAdminGoLiveDateError })

    if (updatedListing) {
      await mutate(apiEndpoint, updatedListing, false)
      await mutateHistoryRoutes()
    }

    return [updatedListing, updateAdminGoLiveDateError]
  }

  // new data service style - attempt to write new services this way as we migrate existing ones
  const updateListingClaim: T.IUpdateListingClaim = async ({ userId, listing: passedListing }) => {
    const listingToUpdate = passedListing || listing
    if (!listingToUpdate) return noListingError

    const data = userId ? { userId } : {}

    const listingClaimResponse = await api.updateListingClaim(listingToUpdate._id, data)
    const updatedListing = getDataFromResponse<T.IListing>(listingClaimResponse)

    const userReadableError = 'failed to update the broker claim'

    const updateListingClaimError = getUpdateErrorMessage(listingClaimResponse, {
      userReadableError,
    })
    if (updateListingClaimError) setErrors({ ...errors, updateListingClaimError })

    if (updatedListing) {
      await mutate(apiEndpoint, updatedListing, false)
      await mutateHistoryRoutes()
      if (passedListing?.searchMutateKey) await mutate(passedListing.searchMutateKey)
    }

    return [updatedListing, updateListingClaimError]
  }

  // new data service style - attempt to write new services this way as we migrate existing ones
  const deleteListing = async (): Promise<[boolean | undefined, T.IServerError | undefined]> => {
    if (!listing) return noListingError

    const deleteListingResponse = await api.deleteListing(listing._id)

    const userReadableError = 'failed to delete the listing'
    const deleteListingError = getUpdateErrorMessage(deleteListingResponse, { userReadableError })
    if (deleteListingError?.message) setErrors({ ...errors, deleteListingError })

    return [deleteListingError?.message ? undefined : true, deleteListingError]
  }

  return {
    ...response,
    listing,
    error: fetchError, // swr fetch error
    update, // generic update function
    updateError, // updateError @TODO: move into named errors
    // specialized update functions
    addSellerNote,
    updateAdminGoLiveDate,
    updateListingClaim,
    deleteListing,
    errors, // errors from specialized update routes
  }
}

const refreshListing = (listingId: string, customLinkUrl: boolean = false) =>
  mutate(getSwrKey(customLinkUrl, listingId))

export { useListing, refreshListing }
