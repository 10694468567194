import styled from 'styled-components'

import { themeFontSize } from 'utils/styles'

interface IStyledTextProps {
  lineThrough?: boolean
  textColor?: string
}
const StyledText = styled.div<IStyledTextProps>`
  ${props => props.lineThrough && 'text-decoration: line-through;'}
  ${props => props.textColor && `color: ${props.textColor};`}
  ${themeFontSize}
`

export { StyledText }
