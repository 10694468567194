import styled from 'styled-components'

const PageContainer = styled.div`
  padding: 10px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 0 25px;
  }
`

export default PageContainer
