import { blue } from '@ant-design/colors'
import { Anchor, Spacer } from 'components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useWindowSize } from 'services/hooks'

import * as E from 'types/enums'

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Menu = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  overflow: inherit;
`

interface IIsCurrentPageProp {
  isCurrentPage?: boolean
}
const MenuItem = styled.div<IIsCurrentPageProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${props =>
    props.isCurrentPage
    && `
    color: ${blue[4]};
    font-weight: 800;
    height: calc(100% - 4px);
    margin-top: 4px;
    & > a {
      border-bottom: 4px solid ${blue[4]};
    }
  `}
`

const OverflowMenuContainer = styled.div`
  position: relative;
  height: 100%;
`

const OverflowMenuButton = styled.button`
  height: 100%;
  align-items: center;
  font-size: 15px;
  outline: none;
  background: none;
  border: none;
  :hover,
  :active,
  :visited,
  :focus {
    cursor: pointer;
    color: ${props => props.theme.colors.blue};
    border-bottom: 1px solid ${props => props.theme.colors.blue};
  }
`

interface ILinkLabel {
  underline?: boolean
  padding?: string
}
const LinkLabel = styled.div<ILinkLabel>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: start;
  white-space: nowrap;
  padding: ${props => props.padding ?? '0'};
  :hover,
  :active,
  :visited,
  :focus {
    cursor: pointer;
    color: ${props => props.theme.colors.blue};
    ${props => (props.underline ? `border-bottom: 1px solid ${props.theme.colors.blue};` : '')}
  }
`

interface IShow {
  show: boolean
}
const OverflowMenu = styled.div<IShow>`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 50px;
  right: 0;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d; /* uses ant-design box shadow */
  z-index: 1;
`

interface INavLinks {
  name: string
  link: string
}

interface IMenuItems extends INavLinks {
  width: number
}

const ListingSearchNavLinks = () => {
  const { width } = useWindowSize()

  const router = useRouter()

  const [openOverflowMenu, setOpenOverflowMenu] = useState(false)
  const [navLinks, setNavLinks] = useState<INavLinks[]>([])
  const [overflowLinks, setOverflowLinks] = useState<INavLinks[]>([])
  const [menuItems, setMenuItems] = useState<IMenuItems[]>([])

  const overflowContainerRef = useRef<HTMLDivElement>(null)
  const containerDiv = useRef<HTMLDivElement>(null)
  const menuDiv = useRef<HTMLDivElement>(null)

  const links = [
    { name: 'All', link: `/listings?mode=${E.ListingStatusGroupings.all}` },
    { name: 'Active', link: `/listings?mode=${E.ListingStatusGroupings.active}` },
    { name: 'Open Tasks', link: `/listings?mode=${E.ListingStatusGroupings.openTasks}` },
    { name: 'New Listings', link: `/listings?mode=${E.ListingStatusGroupings.newListings}` },
    { name: 'Broker Review', link: `/listings?mode=${E.ListingStatusGroupings.brokerReview}` },
    { name: 'In Contract', link: `/listings?mode=${E.ListingStatusGroupings.inContract}` },
    { name: 'Closed', link: `/listings?mode=${E.ListingStatusGroupings.closed}` },
    { name: 'Expiring Soon', link: `/listings?mode=${E.ListingStatusGroupings.expiringSoon}` },
    { name: 'Collections', link: `/listings?mode=${E.ListingStatusGroupings.collections}` },
    {
      name: 'Offers Outstanding',
      link: `/listings?mode=${E.ListingStatusGroupings.offersOutstanding}`,
    },
    { name: 'Escrows', link: `/listings?mode=${E.ListingStatusGroupings.escrow}` },
  ]

  const handleOverflowButton = () => setOpenOverflowMenu(!openOverflowMenu)
  const handleCloseOverflowMenu = () => setOpenOverflowMenu(false)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      overflowContainerRef.current
      && !overflowContainerRef.current.contains(event.target as Node | null)
    ) {
      setOpenOverflowMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    setNavLinks([...links])

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!!menuItems.length || !containerDiv?.current || !menuDiv?.current?.children.length) return

    const newLinks = links.map((link, index) => ({
      ...link,
      width: (menuDiv.current?.children[index] as HTMLDivElement).offsetWidth,
    }))

    setMenuItems([...newLinks])
  }, [containerDiv.current, menuDiv.current])

  useEffect(() => {
    if (!menuItems.length || !containerDiv?.current) return

    const containerWidth = containerDiv.current.offsetWidth

    let sliceIndex = 0
    let widthSum = 0

    // Calculate the widths of the menu items to remove
    for (let i = 0; i < menuItems.length; i++) {
      if (widthSum + menuItems[i].width < containerWidth) {
        widthSum += menuItems[i].width
        sliceIndex++
      } else {
        break
      }
    }

    setOverflowLinks([...menuItems.slice(sliceIndex)])
    setNavLinks([...menuItems.slice(0, sliceIndex)])
  }, [width, menuItems, containerDiv?.current?.offsetWidth])

  useEffect(() => {
    if (!overflowLinks.length) setOpenOverflowMenu(false)
  }, [overflowLinks])

  return (
    <>
      <MenuContainer ref={containerDiv}>
        <Menu ref={menuDiv}>
          {navLinks.map((navLink, index) => (
            <MenuItem
              key={navLink.name}
              isCurrentPage={router.asPath === navLink.link}
              data-testid={navLink.name}
            >
              <Link
                href={navLink.link}
                passHref
              >
                <Anchor height="100%">
                  <LinkLabel underline>{navLink.name}</LinkLabel>
                </Anchor>
              </Link>
              {index !== navLinks.length - 1 && <Spacer />}
            </MenuItem>
          ))}
        </Menu>
      </MenuContainer>
      <OverflowMenuContainer>
        {!!overflowLinks.length && (
          <OverflowMenuButton onClick={handleOverflowButton}>...</OverflowMenuButton>
        )}
        <OverflowMenu
          show={openOverflowMenu}
          ref={overflowContainerRef}
        >
          {overflowLinks.map(overflowLink => (
            <MenuItem
              key={overflowLink.name}
              onClick={handleCloseOverflowMenu}
              data-testid={overflowLink.name}
            >
              <Link href={overflowLink.link}>
                <LinkLabel padding="10px">{overflowLink.name}</LinkLabel>
              </Link>
            </MenuItem>
          ))}
        </OverflowMenu>
      </OverflowMenuContainer>
    </>
  )
}

export default ListingSearchNavLinks
