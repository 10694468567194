import dayjs from 'dayjs'
import { mutate } from 'swr'
import { downloadData, formatAddress } from 'utils'

import { ITruthyAgreementFromListing } from 'utils/agreementHelpers'

import * as T from 'types'
import * as E from 'types/enums'

import { api } from './api'

const prepareParamsForPreviewSignatureRequest = (
  listing: T.IListing,
  agreement: ITruthyAgreementFromListing,
) => {
  const parameters = {
    owners: listing.owners,
    street: listing.street,
    aptNum: listing.aptNum,
    city: listing.city,
    state: listing.state,
    zip: listing.zip,
    goLiveDate: listing.goLiveDate,
    price: listing.price,
    commission: listing.commission,
    ownerType: listing.ownerType,
    companyOrTrustName: listing.companyOrTrustName,
    signedDate: agreement.signedDate,
    listPriceIncludes: listing.listPriceIncludes,
    listPriceExcludes: listing.listPriceExcludes,
    services: `${listing.pricingPackage.name} package`,
    canUsePhotos: listing.canUsePhotos,
  }
  return parameters
}

interface ISignatureRequestOptions {
  isAmendment?: boolean
  setting?: E.AgreementSetting
}

export const signatureRequest = async (
  listingId: string,
  options: ISignatureRequestOptions = {},
) => {
  const isListingExtension = options.setting === E.AgreementSetting.SendExtension || false
  const isTransactionManagement = options.setting === E.AgreementSetting.TransactionManagement

  const response = await api.signatureRequest(listingId, {
    isAmendment: !!options.isAmendment,
    isListingExtension,
    isTransactionManagement,
  })
  if (!response.ok) throw new Error(`bad response: ${response.data}`)

  await mutate(`/listings/${listingId}/ownerDetails`)
  return response
}

export const previewAmendment = async (
  listing: T.IListing,
  agreement: ITruthyAgreementFromListing,
  user: T.IUser,
) => {
  const isAmendment = true
  const isListingExtension = false
  const parameters = prepareParamsForPreviewSignatureRequest(listing, agreement)

  try {
    const response = await api.previewAmendment(listing._id, {
      update: parameters,
      isAmendment,
      isListingExtension,
      isCancelRequest: false,
      requestor: user,
    })

    if (!response.ok) throw new Error(`bad response: ${response.data}`)

    const filename
      = response.headers?.filename
      || `${formatAddress(listing)?.fullAddress} Amendment ${dayjs(listing.updatedAt).format(
        'MM_DD_YYYY',
      )}.pdf`
    downloadData(response.data, filename)
  } catch (error) {
    throw new Error('Retrieving the amendment file link failed.')
  }
}

export const getDocumentByToken = async (token: string) => {
  try {
    const response = await api.getDocumentByToken(token)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    const data = response.data
    const filename = response.headers?.filename || 'attachment.pdf'
    downloadData(data, filename)
  } catch (error) {
    return error
  }
}

export const previewInvoice = async (
  listing: T.IListing,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.previewInvoice(listing._id)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    const data = response.data

    const filename
      = response.headers?.filename
      || `${formatAddress(listing)?.fullAddress} Invoice ${dayjs(listing.updatedAt).format(
        'MM_DD_YYYY',
      )}.pdf`
    downloadData(data, filename)
    return [true, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('Unknown error occured.')
    return [undefined, err]
  }
}

export const sendInvoice = async (
  listing: T.IListing,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.sendInvoice(listing._id)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    return [true, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('Unknown error occured.')
    return [undefined, err]
  }
}

export const sendSignatureReminderEmail = async (
  listingId: string,
  opts: { isAgreement: boolean },
) => {
  const response = await (opts.isAgreement
    ? api.sendEmailToNextAgreementSigner(listingId)
    : api.sendEmailToNextAmendmentSigner(listingId))

  if (!response.ok) throw new Error(`bad response: ${response.data}`)

  return response
}
