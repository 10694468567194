import styled from 'styled-components'

import { themeFontSize } from 'utils/styles'

interface ISubsectionTitleProps {
  backgroundColor?: string
}
export const SubsectionTitle = styled.div<ISubsectionTitleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.grey6};
  padding: 2px 5px 0;
  font-weight: 600;
  letter-spacing: 0.4px;
  border-radius: 4px;
  margin-bottom: 8px;
  top: 2px;
  ${themeFontSize};
`
interface IFieldContainerProps {
  backgroundColor: boolean
}

export const InfoContainer = styled.div<IFieldContainerProps>`
  width: 100%;
  padding: 2px 5px;
  display: flex;
  justify-content: flex-start;
  ${props => props.backgroundColor && `background-color: ${props.theme.colors.grey};`}
`
