import { useMemo } from 'react'
import useSWR from 'swr'

import * as T from 'types'

import { fetch } from '../fetchers'
import { useInitialized } from './utils/useInitialized'

const usePricingPackages = () => {
  const response = useSWR<T.IPricingPackage[]>('/pricingpackages?includeInactive=true', fetch)

  const uniquePricingPackageNames = new Set(response?.data?.map(pkg => pkg.name))
  const pricingPackageNames = Array.from(uniquePricingPackageNames)

  const sortedPricingPackages
    = response?.data?.sort((packageA, packageB) => packageA.price - packageB.price) ?? []

  const pricingPackagesInitialized = useInitialized(response)

  const pricingPackagesByName = useMemo(
    () =>
      sortedPricingPackages.reduce((res, pkg) => {
        if (res[pkg.name]) {
          res[pkg.name] = [...res[pkg.name], pkg]
        } else {
          res[pkg.name] = [pkg]
        }

        return res
      }, {} as { [key: string]: T.IPricingPackage[] }),
    [sortedPricingPackages],
  )

  return {
    ...response,
    pricingPackageNames,
    pricingPackagesByName,
    pricingPackagesInitialized,
  }
}

export default usePricingPackages
