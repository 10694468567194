import { Row } from 'antd'

import LoadingSpinner from './LoadingSpinner'

const PageLoader = () => (
  <Row
    style={{ height: '95vh' }}
    align="middle"
    justify="center"
  >
    <LoadingSpinner />
  </Row>
)

export default PageLoader
