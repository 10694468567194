import styled from 'styled-components'

interface IDebugProps {
  debug?: boolean
}
interface ISizeProps {
  size?: number
  desktopLargeSize?: number
  tabletSize?: number
  responsiveSize?: number
  hideMobile?: boolean
  hideTablet?: boolean
  hideDesktop?: boolean
  flexShrink?: number
  flexGrow?: number
  display?: string
}
const Spacer = styled.div<ISizeProps & IDebugProps>`
  width: ${props => props.responsiveSize || props.size || 20}px;
  height: ${props => props.responsiveSize || props.size || 20}px;
  flex-basis: ${props => props.responsiveSize || props.size || 20}px;
  flex-shrink: 0;
  flex-grow: 0;
  ${props => props.hideMobile && 'display: none;'}
  ${props => props.debug && 'background: green;'}
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: block;
    ${props => props.hideTablet && 'display: none;'}
    width: ${props => props.tabletSize || props.size || 20}px;
    height: ${props => props.tabletSize || props.size || 20}px;
    flex-basis: ${props => props.tabletSize || props.size || 20}px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: block;
    ${props => props.hideDesktop && 'display: none;'}
    width: ${props => props.size || 20}px;
    height: ${props => props.size || 20}px;
    flex-basis: ${props => props.size || 20}px;
  }
  @media (min-width: ${props => props.theme.metrics.desktopLarge}px) {
    display: block;
    ${props => props.hideDesktop && 'display: none;'}
    width: ${props => props.desktopLargeSize || props.size || 20}px;
    height: ${props => props.desktopLargeSize || props.size || 20}px;
    flex-basis: ${props => props.desktopLargeSize || props.size || 20}px;
  }
`

export default (props: ISizeProps & IDebugProps) => (
  <Spacer
    data-testid="spacer"
    {...props}
  />
)
