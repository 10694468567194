import Cookies from 'js-cookie'
import Router from 'next/router'
import queryString from 'query-string'
import { useMemo } from 'react'
import useSWR, { mutate, responseInterface } from 'swr'

import { stringifyParams } from 'utils/stringFormatting'

import * as T from 'types'
import { IBlockedCallerMap, IHistoryRequiringAck, IListing, IListingChangeTask } from 'types'

import { fetch, fetchWithToken } from '../fetchers'
import { useInitialized } from './utils/useInitialized'
import useIsFetching, { IIsFetching } from './utils/useIsFetching'

export { useListing } from './useListing'
export { useRecording } from './useRecording'
export { useMls } from './useMls'

// all calls that use authenticated `fetchWithToken` with no unique param in the key (like
// listing id) need to be cleared on logout so if user logs into different account they won't see
// stale data from the previously logged in account
const resetCache = () => {
  mutate('/listings/user', null, true)
  mutate('/orders/user/history', null, true)
  mutate('/orders/user/open', null, true)
  mutate('/stripe/customer/cards', null, true)
  mutate('/users/me', null, true)
}

export const useChecklists = (listingId: string | undefined) => {
  const response = useSWR<T.IChecklist[]>(`/listings/${listingId}/checklists`, fetchWithToken)
  return { ...response, checklists: response.data }
}

export const useConditionalDocument = (documentId?: string) => {
  const { data, error } = useSWR<T.IConditionalDocument>(
    documentId ? `/conditional-documents/${documentId}` : null,
    fetchWithToken,
  )
  return { document: data, error }
}

export const useConditionalDocuments = () => {
  const response = useSWR<T.IConditionalDocument[]>('/conditional-documents', fetchWithToken)
  return { ...response, documents: response.data }
}

export const useDocuments = (listingId?: string) => {
  const response = useSWR<T.IListingDocument[]>(
    listingId ? `/documents/listing/${listingId}` : null,
    fetchWithToken,
  )
  return { ...response, listingDocuments: response.data }
}

export const useOrdersByListingId = (listingId?: string, includeDeleted?: boolean) => {
  let key = listingId ? `/orders/listing/${listingId}` : null
  if (key && includeDeleted) key += `?includeDeleted=${includeDeleted}`

  const response = useSWR<T.IOrder[]>(key, fetchWithToken)
  return { ...response, orders: response.data }
}

export const useOrderByOrderId = (orderId?: string) => {
  const key = orderId ? `/orders/${orderId}` : null

  const response = useSWR<T.IOrder>(key, fetchWithToken)
  return { ...response, order: response.data }
}

export const useOrdersByFulfilledStatus = (
  fulfilled: boolean,
  sortFn?: (left: T.IOrder, right: T.IOrder) => number,
) => {
  const key = `/orders/details?fulfilled=${fulfilled}`

  const response = useSWR<T.IOrder[]>(key, fetchWithToken)

  const orders = useMemo(() => {
    const result = response.data
    return sortFn && result ? [...result].sort(sortFn) : result
  }, [sortFn, response.data])

  return { ...response, orders }
}

export const usePricingDetails = (listingId: string) => {
  const key = `/orders/pricingDetails/${listingId}`
  const response = useSWR<T.IPricingDetails>(key, fetchWithToken)
  return { ...response, pricingDetails: response.data }
}

export const usePricingPackageItems = () => {
  const response = useSWR<T.IPricingPackageItem[]>('/pricingpackageitems', fetch)
  return { ...response, pricingPackageItems: response.data }
}

// services
export const useService = (serviceId?: string) => {
  const { data, error } = useSWR<T.IService>(serviceId ? `/services/${serviceId}` : null, fetch)
  return { service: data, error }
}

export const useServices = () => {
  const response = useSWR<T.IService[]>('/services', fetch)

  const services = response?.data?.filter(
    (service: T.IService) =>
      service.forSale && Object.prototype.hasOwnProperty.call(service, 'price'),
  )
  return { ...response, services, allServices: response?.data }
}

export const usePricingPackageServices = () => {
  const response = useSWR<T.IService[]>('/services/packages', fetch)
  return { ...response, pricingPackageServices: response?.data }
}

export const useUser = (initialData?: T.IUser | undefined) => {
  const response = useSWR<T.IUser>('/users/me', fetchWithToken, { initialData })

  const logout = () => {
    Cookies.remove('token')
    resetCache()
    Router.push('/')
  }

  return { ...response, user: response.data, logout }
}

// Admin API

export const useMlsSearch = (queryParams: T.IGetQueryParams) => {
  let key = '/mlss/search'

  if (Object.keys(queryParams).length) {
    queryParams = stringifyParams(['filter', 'populate'], queryParams) as T.IGetQueryParams
    key += `?${queryString.stringify(queryParams)}`
  }

  const response = useSWR<T.ISearchResultsWithCount<T.IMls>>(key, fetchWithToken)

  return {
    ...response,
    mlsSearchResults: response.data?.results,
    mlsSearchResultsCount: response.data?.count,
  }
}

export const useMlsList = () => {
  const key = '/mlss'
  let mlsList: T.IMls[] = []
  const response = useSWR<T.IMls[]>(key, fetchWithToken)
  if (response.data) mlsList = response.data

  return { ...response, mlsList }
}

export const useMlsListForZip = (zip: string) => {
  const key = `/mlss/zip/${zip}`
  let mlsListForZip: T.IMls[] = []
  const response = useSWR<T.IMls[]>(key, fetchWithToken)
  if (response.data) mlsListForZip = response.data

  return { ...response, mlsListForZip }
}

// NB: be careful with this one. it's very slow, usually you'll want `useMlsList` instead
export const useMlsWithActiveListings = () => {
  const response = useSWR<T.IMls[]>('/mlss/withActiveListings', fetchWithToken)
  const mlsList = response?.data

  if (mlsList && mlsList.length) {
    mlsList.sort((a: T.IMls, b: T.IMls) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) return -1

      if (nameA > nameB) return 1

      return 0
    })
  }

  return { ...response, mlsList }
}

export const useUserById = (userId?: string | null) => {
  const key = userId ? `/users/admin/${userId}` : null

  const response = useSWR<T.IUser>(key, fetchWithToken)
  return { ...response, user: response.data }
}

export const useUsers = () => {
  const response = useSWR<T.IUser[]>('/users', fetchWithToken)
  return { ...response, usersList: response.data }
}

export const useZips = () => {
  const response = useSWR<T.IZip[]>('/zips', fetchWithToken)
  const zips = response?.data
  return { ...response, zips }
}

export const useZipsSearch = (queryParams: T.IGetQueryParams) => {
  let key = '/zips/search'

  if (Object.keys(queryParams).length) {
    queryParams = stringifyParams(['filter', 'populate'], queryParams) as T.IGetQueryParams
    key += `?${queryString.stringify(queryParams)}`
  }

  const response = useSWR<T.IZipResponse>(key, fetchWithToken)

  return {
    ...response,
    zipSearchResults: response.data?.data || [],
    zipSearchResultsCount: response.data?.count,
  }
}

export const useListingsByUsers = () => {
  const key = '/users/userListings/'
  const response = useSWR<T.IUserListings>(key, fetchWithToken)
  return { ...response, listingsByUsers: response.data }
}

export const useSellerListings = (listing?: T.IListing) => {
  const sellerId = listing?.userId._id

  const key = sellerId ? `/listings/user/${sellerId}` : null

  const response = useSWR<T.ISparseListing[] | T.IServerError>(key, fetchWithToken)

  let listings: T.ISparseListing[] = []

  if (response.data && Array.isArray(response.data)) {
    listings = response.data
  }

  return { ...response, listings }
}

export const useUserSearchResults = (queryParams: T.IGetQueryParams) => {
  let key = '/users/search/'

  if (Object.keys(queryParams).length) {
    queryParams = stringifyParams(['filter', 'populate'], queryParams) as T.IGetQueryParams
    key += `?${queryString.stringify(queryParams)}`
  }

  const response = useSWR<T.ISearchResultsWithCount<T.IUser>>(key, fetchWithToken)

  return {
    ...response,
    userSearchResults: response.data?.results,
    userSearchResultsCount: response.data?.count,
  }
}

export const useRecordingsSearch = (queryParams: T.IGetQueryParams) => {
  let key = '/recordings/search'

  if (Object.keys(queryParams).length) {
    queryParams = stringifyParams(['filter', 'populate'], queryParams) as T.IGetQueryParams
    key += `?${queryString.stringify(queryParams)}`
  }

  const response = useSWR<T.ISearchResultsWithCount<T.IRecording>>(key, fetchWithToken)
  return {
    ...response,
    recordingSearchResults: response.data?.results,
    recordingSearchResultsCount: response.data?.count,
  }
}

interface IUseListingSearchResultsData
  extends Partial<IIsFetching>,
    responseInterface<T.IServerError | T.IListingsWithCount, any> {
  listingSearchResults: T.IListing[]
  listingSearchResultsCount: number
  listingSearchResultsKey: string
}

interface IUseListingSearchResults {
  (argsObj: {
    statusIds?: string
    states?: string
    services?: string
    resultsNo?: number
    pageNo?: number
    tab?: string
    showFutureTasks?: boolean
    // search values
    street?: string
    mlsId?: string
    mlsLiveIn?: string | string[]
    phone?: string
    escrow?: string
    seller?: string
    listingManagerName?: string
    includeBasicTm?: boolean
    sellerUsingHomeListerTitleService?: boolean
    shouldFetch?: boolean
    closingDateFrom?: string
    closingDateTo?: string
    goLiveDateFrom?: string
    goLiveDateTo?: string
    sort?: string
    collections?: boolean | undefined
    includeTotalDueAtClosing?: boolean
    sellerId?: string
    andQueryExpression?: object[]
    hasOutstandingOffers?: boolean
    escrowStatuses?: string
  }): IUseListingSearchResultsData
}

export const useListingSearchResults: IUseListingSearchResults = ({
  statusIds = 'all',
  states = 'all',
  services = '',
  resultsNo = 25,
  pageNo = 0,
  tab = 'search',
  showFutureTasks = false,
  // search values
  street = '',
  mlsId = '',
  mlsLiveIn = '',
  phone = '',
  escrow = '',
  seller = '',
  listingManagerName = '',
  includeBasicTm = true,
  sellerUsingHomeListerTitleService = false,
  shouldFetch = true,
  closingDateFrom = '',
  closingDateTo = '',
  goLiveDateFrom = '',
  goLiveDateTo = '',
  sort = '',
  sellerId,
  collections,
  includeTotalDueAtClosing = false,
  andQueryExpression,
  hasOutstandingOffers,
  escrowStatuses = 'all',
}) => {
  let key
    = '/listings/broker-search'
    + `?statusIds=${statusIds}`
    + `&states=${states}`
    + `&resultsNo=${resultsNo}`
    + `&pageNo=${pageNo}`
    + `&tab=${tab}`
    + `&showFutureTasks=${showFutureTasks}`
    + `&includeBasicTm=${includeBasicTm}`
    + `&sellerUsingHomeListerTitleService=${sellerUsingHomeListerTitleService}`
    + `&escrowStatuses=${escrowStatuses}`

  if (services) key += `&services=${services}`

  if (street) key += `&street=${street}`

  if (mlsId) key += `&mlsId=${mlsId}`

  if (mlsLiveIn) key += `&mlsLiveIn=${mlsLiveIn}`

  if (phone) key += `&phone=${phone}`

  if (escrow) key += `&escrowQuery=${escrow}`

  if (seller) key += `&user=${seller}`

  if (listingManagerName) key += `&listingManagerName=${listingManagerName}`

  if (closingDateFrom) key += `&closingDateFrom=${closingDateFrom}`

  if (closingDateTo) key += `&closingDateTo=${closingDateTo}`

  if (goLiveDateFrom) key += `&goLiveDateFrom=${goLiveDateFrom}`

  if (goLiveDateTo) key += `&goLiveDateTo=${goLiveDateTo}`

  if (sort) key += `&sort=${sort}`

  if (collections !== undefined) key += `&collections=${collections}`

  if (includeTotalDueAtClosing) key += '&includeTotalDueAtClosing=true'

  if (sellerId) key += `&userId=${sellerId}`

  if (andQueryExpression?.length) {
    key += `&$and=${encodeURIComponent(JSON.stringify(andQueryExpression))}`
  }

  if (hasOutstandingOffers !== undefined) key += `&hasOutstandingOffers=${hasOutstandingOffers}`

  const response = useIsFetching<T.IListingsWithCount | T.IServerError>(
    shouldFetch ? key : null,
    fetchWithToken,
  )

  return {
    ...response,
    listingSearchResults: (response.data as T.IListingsWithCount)?.listings,
    listingSearchResultsCount: (response.data as T.IListingsWithCount)?.count,
    listingSearchResultsKey: key,
  }
}

export const useListingHistory = (listingId: string, queryParams: T.IGetQueryParams = {}) => {
  let key = `/listings/${listingId}/history`

  if (Object.keys(queryParams).length) {
    queryParams = stringifyParams(['filter', 'populate'], queryParams)
    key += `?${queryString.stringify(queryParams)}`
  }

  const response = useSWR<T.IHistory[]>(key, fetchWithToken)
  return { ...response, history: response.data }
}

export const useListingHistoryWithCreator = (
  listingId: string,
  queryParams: T.IGetQueryParams = {},
) => {
  queryParams.populate = { path: 'creator', select: 'name email roles' }

  return useListingHistory(listingId, queryParams)
}

export const useListingStatus = () => {
  const response = useSWR<T.IListingStatus[]>('/status/listing', fetchWithToken)
  return { ...response, statuses: response.data }
}

export const useMlsStates = () => {
  const key = '/states'
  const response = useSWR<T.IState[]>(key, fetchWithToken)

  const mlsStates = response.data || []
  const mlsStateCodes = mlsStates.map(state => state.abbreviation)
  const mlsStatesInitialized = useInitialized(response)

  return { ...response, mlsStates, mlsStateCodes, mlsStatesInitialized }
}

export const useStatuses = () => {
  const key = '/status'
  const response = useSWR<T.IListingStatus[]>(key, fetchWithToken)

  const statuses = response.data || []

  return { ...response, statuses }
}

export const useListingManagers = () => {
  const key
    = '/users/search?filter={"roles":{"$in":["listingManager","agent"]}}&limit=100&pageNo=0&sort=name'

  const response = useSWR(key, fetchWithToken)
  let managers: T.IManager[] = []
  if (response?.data?.results) managers = response.data.results

  return { ...response, managers }
}

export const useListingEditors = () => {
  const key
    = '/users/search?filter={"roles":{"$in":["listingCoordinator","listingManager","agent"]}}&limit=100&pageNo=0&sort=name'

  const response = useSWR(key, fetchWithToken)
  let editors: T.IEditor[] = []
  if (response?.data?.results) editors = response.data.results

  return { ...response, editors }
}

export const useBlockedWords = () => {
  const key = '/blockedWords'
  let blockedWordList: T.IBlockedWord[] = []
  const response = useSWR<T.IBlockedWord[]>(key, fetchWithToken)
  if (response.data) blockedWordList = response.data

  return { ...response, blockedWordList }
}

export const useBlockedPhoneNumbers = (phoneNumbers: string[]) => {
  const key = `/blockedCaller/phone?phones=${phoneNumbers.join(',')}`
  let blockedPhoneNumbers: IBlockedCallerMap = {}
  const response = useSWR<IBlockedCallerMap>([key], fetchWithToken)
  if (response.data) blockedPhoneNumbers = response.data

  return { ...response, blockedPhoneNumbers }
}

export const useListingPhotos = (listingId?: string) => {
  const key = `/listings/${listingId}/photos?includeDeleted=true&returnAllPhotosForSoldListings=true`

  const response = useSWR<T.IPhoto[]>(key, fetch)
  const photos = response.data

  return { ...response, photos }
}

export const useBrokerSignatureNeededListings = () => {
  const response = useSWR<T.ISparseListing[]>('/listings/brokerSignatureNeeded', fetchWithToken)
  let brokerSigListings: T.ISparseListing[] = []
  if (response.data) brokerSigListings = response.data

  return { ...response, brokerSigListings }
}

export const useTwilioPhoneNumber = () => {
  const key = '/twilios/setup'
  const response = useSWR<{ number: string }>(key, fetchWithToken)

  return { ...response, twilioPhoneNumber: response?.data?.number }
}

export const useTwilioWorkerByEmail = (email?: string) => {
  const key = email ? `/twilios/worker/email/${email}` : null

  const response = useSWR<T.ITwilioWorker>(key, fetchWithToken, { refreshInterval: 10 * 1000 })

  return { ...response, twilioWorker: response.data }
}

export const useTwilioWorkers = () => {
  const key = '/twilios/workers'
  const response = useSWR<T.ITwilioWorker[]>(key, fetchWithToken)

  return { ...response, twilioWorkers: response.data }
}

export const useListingChangeTask = (listing: IListing) => {
  const key = listing ? `/listingChangeTask/listing/${listing._id}` : null

  const response = useSWR<IListingChangeTask[]>(key, fetchWithToken)

  return { ...response, listingChangeTasks: response.data }
}

export const useHistoryRequiringAck = (listing: IListing) => {
  const key = listing ? `/listings/${listing._id}/historyRequiringAck` : null

  const response = useSWR<IHistoryRequiringAck[]>(key, fetchWithToken)

  return { ...response, historyRequiringAck: response.data }
}

export const useExistingSkillNames = () => {
  const response = useSWR<string[]>('/twilios/workers/skills', fetchWithToken)

  return { ...response, existingSkillNames: response.data }
}

export const useTwilioWorkerDeviceType = (email: string | undefined) => {
  const key = email ? `/twilios/worker/${email}/device` : null

  const response = useSWR<string>(key, fetchWithToken)

  return { ...response, deviceType: response.data }
}
