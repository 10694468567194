import { Radio, RadioGroupProps } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

import { themeFontSize } from 'utils/styles'

interface IStyledRadioGroup {
  fontWeight?: number
}
const StyledRadioGroup = styled(Radio.Group)<IStyledRadioGroup>`
  .ant-radio-button-wrapper {
    ${themeFontSize}
    ${props => props.fontWeight && `font-weight: ${props.fontWeight}`};
    height: 22px;
    white-space: nowrap;
  }
  white-space: nowrap;
`

const CustomRadioGroup: FC<RadioGroupProps & IStyledRadioGroup> = ({ ...radioGroupProps }) => (
  <StyledRadioGroup
    {...radioGroupProps}
    size="small"
    optionType="button"
  />
)

export default CustomRadioGroup
