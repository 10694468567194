const includesAny = <T>(arr: T[], itemsPossiblyIncluded: T[]): boolean => {
  const setOfItems = new Set(itemsPossiblyIncluded)
  return arr.some(el => setOfItems.has(el))
}

// behaves like _.intersection
const intersection = <T>(...arrays: T[][]) => {
  if (!arrays.length) return []

  let result = arrays[0]

  // Array.prototype doesn't expose a method which allows for breaking out of an iteration in the way we
  // should - since there's no point in iterating further once we've filtered out all the possible results.
  // eslint-disable-next-line no-restricted-syntax
  for (const arr of arrays.slice(1)) {
    const setOfElements = new Set(arr)
    result = result.filter(el => setOfElements.has(el))

    if (!result.length) break
  }

  return result
}

// behaves like _.orderBy
const orderBy = <T>(
  array: T[],
  iteratee: (item: T) => number,
  order: 'asc' | 'desc' = 'asc',
): T[] => {
  const result = [...array]
  return result.sort((left: T, right: T) =>
    order === 'asc' ? iteratee(left) - iteratee(right) : iteratee(right) - iteratee(left),
  )
}

// behaves like _.without
const without = <T>(arr: T[], removeTheseVals: T[]): T[] => {
  const setOfVals = new Set(removeTheseVals)

  return arr.reduce((res, val) => {
    if (!setOfVals.has(val)) res.push(val)

    return res
  }, [] as T[])
}

export { includesAny, intersection, orderBy, without }
