import useSWR from 'swr'

import fetchWithToken from 'services/fetchers/fetchWithToken'

import * as T from 'types'

export const useDocuments = (listingId?: string) => {
  const key = listingId ? `/documents/listing/${listingId}` : null
  const response = useSWR<T.IListingDocument[]>(key, fetchWithToken)
  return { ...response, listingDocuments: response.data }
}

export const useContractDetailsDocuments = (contractDetailsId?: number) => {
  const key = contractDetailsId ? `/documents/contract-details/${contractDetailsId}` : null
  // @FIXME: this type name is now a misnomer
  const response = useSWR<T.IListingDocument[]>(key, fetchWithToken)
  return { ...response, documents: response.data }
}
