/* eslint-disable camelcase */
import apisauce, { ApiResponse, ApisauceInstance } from 'apisauce'
import Cookies from 'js-cookie'
import { NextPageContext } from 'next'

import { IBlockedCaller, IListingChangeTask } from '../types'
import * as T from '../types'
import { getToken } from '../utils'
import { ICreateListingData, ISignupParams, IToken } from './types'

interface IGenericSauceCall {
  func(...args: any[]): Promise<ApiResponse<{}>>
}

interface IIdString {
  id: string
}

type IFindListingHistoryResp = (Partial<T.IHistory> & { _id: string })[]

export interface IApi {
  [key: string]: IGenericSauceCall
}

export default class ApiCreator {
  public api: ApisauceInstance

  constructor(ctx?: NextPageContext) {
    let token = ''
    if (typeof window !== 'undefined') token = Cookies.get('token') || ''

    if (ctx && !token) token = getToken(ctx) || ''

    this.api = apisauce.create({
      baseURL: process.env.API_URL,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      timeout: 30000,
    })
  }

  // NOTE: before you add a new API route - all GETs for data should go through `swr` and be
  // written in their own file in the `swr/` directory with name in the format `use[DataType].ts`

  // generic ping for use with establishing if client can connect to server
  public ping = (path?: string, params = {}) => this.api.get(`/${path || ''}`, params)

  // auth
  public login = (email: string, password: string) =>
    this.api.post<IToken>('auth/local', { email, password })

  public changePassword = (userId: string, oldPassword: string, newPassword: string) =>
    this.api.put(`users/${userId}/password`, { oldPassword, newPassword })

  public updateLastLogin = (userId: string) => this.api.put(`users/updateUser/${userId}/lastLogin`)

  // user
  public createUser = (createUserParams: ISignupParams) =>
    this.api.post<IToken>('users', createUserParams)

  public getMe = () => this.api.get<T.IUser>('users/me')

  public updateUser = (userId: string, update: {}) =>
    this.api.put<T.IUser>(`users/${userId}`, update)

  public updateUserRoles = (userId: string, roles: string[]) =>
    this.api.put<T.IUser>(`users/${userId}/roles`, { roles })

  public deleteUser = (userId: string) => this.api.delete<T.IUser>(`users/${userId}`)

  public syncIntercomUser = (userId: string) => this.api.post(`users/${userId}/sync-intercom`)

  // listing
  public createListing = (data: ICreateListingData) => this.api.post<T.IListing>('listings', data)

  public findListingHistory = (listingId: string, payload: object = {}) =>
    this.api.post<IFindListingHistoryResp>(`listings/${listingId}/findHistory`, payload)

  public getMyListings = () => this.api.get<T.IListing[]>('/listings/user')

  // aka property details from third party services: melissa data, zillow
  public getPropertyDetails = (listingId: string) =>
    this.api.get<T.IListing>(`listings/${listingId}/propertyDetails`)

  public updateListing = (
    listingId: string,
    update: { [key: string]: T.ValueOf<T.IListing> },
    options: T.IListingApiOptions = {
      disableStatusUpdate: false,
      disableListingChangesRequired: false,
    },
  ) =>
    this.api.put<T.IListing>(
      `listings/${listingId}`,
      { update },
      {
        params: {
          disable_status_update: options.disableStatusUpdate,
          disableListingChangesRequired: options.disableListingChangesRequired,
        },
      },
    )

  public patchListing = (
    listingId: string,
    patch: Partial<T.IListing>,
    options: T.IListingApiOptions = {
      disableStatusUpdate: false,
      disableListingChangesRequired: false,
    },
  ) =>
    this.api.patch<T.IListing>(`listings/${listingId}`, patch, {
      params: {
        disable_status_update: options.disableStatusUpdate,
        disableListingChangesRequired: options.disableListingChangesRequired,
      },
    })

  public updateAdminGoLiveDate = (
    listingId: string,
    update: { [key: string]: T.ValueOf<T.IListing> },
  ) => this.api.put<T.IListing>(`listings/${listingId}/updateAdminGoLiveDate`, { update })

  public addAgentSellerNote = (
    listingId: string,
    note: T.IAgentSellerNote,
    options: T.IListingApiOptions = {
      disableStatusUpdate: false,
      disableListingChangesRequired: false,
    },
  ) =>
    this.api.post<T.IListing | T.IHttpServerError>(
      `listings/${listingId}/addSellerNote`,
      { note },
      {
        params: {
          disable_status_update: options.disableStatusUpdate,
          disableListingChangesRequired: options.disableListingChangesRequired,
        },
      },
    )

  public updateListingPhotos = (listingId: string, update: T.IListingPhotosUpdate) =>
    this.api.put(`listings/${listingId}/updatePhotos?includesDeleted=true`, update)

  public updateRoom = (listingId: string, room: T.IRoom) =>
    this.api.post(`listings/${listingId}/saveRoomDimensions`, { room })

  public resetEscrow = (contractDetailsId: number, data?: Partial<T.IContractDetails>) =>
    this.api.post<T.IContractDetails>(`contract-details/${contractDetailsId}/reset`, data || {})

  public offerWasReceived = (listingId: string) =>
    this.api.post<T.IListing>(`listings/${listingId}/offerWasReceived`)

  public updateListingClaim = (listingId: string, update: { userId?: string }) =>
    this.api.put<T.IListing>(`listings/${listingId}/claim`, update)

  public deleteListing = (listingId: string) => this.api.delete(`listings/${listingId}`)

  // agreement
  public resetAgreement = (listingId: string) =>
    this.api.post<T.IListing>(`listings/${listingId}/resetAgreement`)

  public previewAmendment = (listingId: string, params: any) =>
    this.api.post(`hellosigns/previewAmendment/${listingId}`, params, {
      responseType: 'arraybuffer',
    })

  public signatureRequest = (listingId: string, params: any) =>
    this.api.post<T.ISignatureRequestResponse>(
      `hellosigns/signatureRequest/agreement/${listingId}`,
      params,
    )

  // conditional documents
  public createConditionalDocument = (data: Partial<T.IConditionalDocument>) =>
    this.api.post<T.IConditionalDocument>('conditional-documents', data)

  public updateConditionalDocument = (data: T.IConditionalDocument) =>
    this.api.put<T.IConditionalDocument>(`conditional-documents/${data._id}`, data)

  public removeConditionalDocument = (documentId: string) =>
    this.api.delete(`conditional-documents/${documentId}`)

  public sendEmailToNextAgreementSigner = (listingId: string) =>
    this.api.post(`hellosigns/send-signature-reminder/listing/${listingId}`)

  public sendEmailToNextAmendmentSigner = (listingId: string) =>
    this.api.post(`hellosigns/send-signature-reminder/listing/${listingId}`, { isAmendment: true })

  // documents
  public createDocument = (data: Partial<T.IAdminUploadedDocument | T.IAttachedDocument>) =>
    this.api.post<T.IListingDocument>('documents', data)

  public removeDocument = (documentId: string) => this.api.delete(`documents/${documentId}`)

  public updateDocument = (data: T.IListingDocument | T.IDashboardDocument) =>
    this.api.put<T.IListingDocument>(`documents/${data._id}`, data)

  public getDocumentByToken = (token: string) =>
    this.api.post('hellosigns/view/token', { token }, { responseType: 'arraybuffer' })

  public getDocumentByUrl = (url: string) => this.api.get(url, {}, { responseType: 'arraybuffer' })

  // services
  public createService = (data: Partial<T.IService>) =>
    this.api.post<Partial<T.IService>>('services', data)

  public updateService = (data: Partial<T.IService>) =>
    this.api.put<Partial<T.IService>>(`services/${data._id}`, data)

  public createNewServiceVersion = (originalServiceId: string, price: number) =>
    this.api.post<T.IService>(`services/${originalServiceId}/newVersion`, { price })

  // offer codes
  public createOfferCode = (data: Partial<T.IOfferCode>) =>
    this.api.post<Partial<T.IOfferCode>>('offerCodes', data)

  public updateOfferCode = (data: Partial<T.IOfferCode>) =>
    this.api.put<Partial<T.IOfferCode>>(`offerCodes/${data._id}`, data)

  public deleteOfferCode = (id: string) => this.api.delete(`offerCodes/${id}`)

  // offerCodes
  public checkOfferCode = (params: T.ICheckOfferCodeParams) =>
    this.api.get<T.IOfferCode | null>('offerCodes/checkCode', params)

  // orders
  public toggleFulfilled = (orderId: string, service: string, listing: string, fulfill: boolean) =>
    this.api.put<T.IOrder>(`orders/${orderId}/fulfill`, { service, listing, fulfill })

  public removeOrderService = (orderId: string, service: string, listing: string) =>
    this.api.delete(`orders/${orderId}/${listing}/${service}`)

  public removeOrder = (orderId: string) => this.api.delete(`orders/${orderId}`)

  // s3
  public getS3UploadUrl = (
    listingId: string,
    name: string,
    type: string,
    filetype: 'photos' | 'agreements',
  ) =>
    this.api.get<{ signed_request: string; [key: string]: any }>(
      `amazons/${listingId}/s3/sign/${filetype}?name=${encodeURIComponent(name)}&type=${type}`,
    )

  public addAttachedDocument = (listingId: string, url: string, name: string, type: string) =>
    this.api.post<{ [key: string]: any }>(`amazons/${listingId}/s3/addAttachedDocument`, {
      type,
      url,
      name,
    })

  public getGenericS3UploadUrl = (name: string, type: string, s3_public: boolean = false) =>
    this.api.get<{ signed_request: string; [key: string]: any }>(
      `amazons/document/s3/sign/?name=${encodeURIComponent(
        name,
      )}&type=${type}&s3_public=${s3_public}`,
    )

  public downloadZipLinks = (listingId: string, links: T.IPhoto[], name: string) =>
    this.api.post<string>(
      `/amazons/${listingId}/links`,
      { links, name },
      { responseType: 'arraybuffer', timeout: 90000 },
    )

  // zip
  public validateZip = (zip: string) => this.api.get<[]>(`zips/validate/${zip}`)

  public saveZips = (zips: T.IZip[]) => this.api.put<[]>('zips/many', { zipCodes: zips })

  // history
  public updateHistory = ({ id }: IIdString, data: T.IUpdateHistory) =>
    this.api.put<T.IHistory[]>(`/listings/${id}/history`, data)

  // checklists
  public addChecklist = (checklist: T.IChecklistCreateObject) =>
    this.api.post<T.IChecklist>('checklists', checklist)

  //  ORIGINAL
  // public updateChecklist = (
  //   checklistId: string,
  //   update: T.IChecklistUpdateObject,
  // ) => this.api.put<T.IChecklist>(`checklists/${checklistId}`, update)

  // @FIXME: per original app's api calls, 'update' is always in practice an entire checklist object
  // using T.IChecklist is hard right now because { tasks: string[] | T.ITask[] } causes issues
  public updateChecklist = (checklistId: string, update: any) =>
    this.api.put<T.IChecklist>(`checklists/${checklistId}`, update)

  public addTask = (task: string) => this.api.post<T.ITask>('tasks', { task })

  public deleteTask = (taskId: string) => this.api.delete(`tasks/${taskId}`)

  public updateTask = (taskId: string, update: T.ITaskUpdateObject) =>
    this.api.put<T.ITask>(`tasks/${taskId}`, update)

  // field schema
  public updateFieldSchema = (
    mlsId: string,
    update: { [key: string]: T.ValueOf<T.IFieldSchema> | undefined | null },
  ) => this.api.put<T.IFieldSchema>(`fieldSchema/${mlsId}`, { update })

  public initializeFieldSchema = (mlsId: string) =>
    this.api.post<T.IFieldSchema>(`fieldSchema/${mlsId}`)

  public initFromSchema = (toMlsId: string, mlsId: string) =>
    this.api.post<T.IFieldSchema>(`fieldSchema/${mlsId}/initFromSchema`, { toMlsId })

  public copyFieldsToMls = (fields: string[], fromMlsId: string, toMlsIds: string[]) =>
    this.api.post<T.IFieldSchema[]>(`fieldSchema/${fromMlsId}/copyFieldsToMls`, {
      fields,
      toMlsIds,
    })

  public checkFieldsToCopy = (fields: string[], fromMlsId: string, toMlsIds: string[]) =>
    this.api.post<T.ICopyFieldsConflict[]>(`fieldSchema/${fromMlsId}/checkFieldsToCopy`, {
      fields,
      toMlsIds,
    })

  // listing status
  public updateListingStatus = (
    listingId: string,
    update: { [key: string]: T.ValueOf<T.IListing> },
  ) => this.api.put<T.IListing>(`listings/${listingId}/status`, { update })

  // mls
  public updateMlsStatus = (listingId: string, update: T.IListingMls) =>
    this.api.put<T.IListing>(`listings/${listingId}/mls`, { update })

  public addNewMls = (listingId: string, mls: T.IListingMls) =>
    this.api.post<T.IListing>(`listings/${listingId}/mls`, { mls })

  public createMls = (mls: T.IMls) => this.api.post<T.IMls>('mlss', { mls })

  public deleteMls = (listingId: string, mlsId: string) =>
    this.api.delete<T.IListing>(`listings/${listingId}/mls/${mlsId}`)

  public updateMls = (mlsId: string, update: Partial<T.IMls>) =>
    this.api.put<T.IMls>(`mlss/${mlsId}`, { mls: update })

  public updateListingManager = (
    listingId: string,
    update: { [key: string]: T.ValueOf<T.IListing> },
  ) => this.api.put<T.IListing>(`listings/${listingId}/manager`, { update })

  public updateListingEditor = (
    listingId: string,
    update: { [key: string]: T.ValueOf<T.IListing> },
  ) => this.api.put<T.IListing>(`listings/${listingId}/editor`, { update })

  // blocked word
  public createBlockedWord = (word: string, usedIn: string[]) =>
    this.api.post<T.IBlockedWord | string>('blockedWords', { word, usedIn })

  public deleteBlockedWord = (id: number) => this.api.delete<T.IBlockedWord>(`blockedWords/${id}`)

  public previewInvoice = (listingId: string) =>
    this.api.post(`hellosigns/invoice/${listingId}/preview`, {}, { responseType: 'arraybuffer' })

  public sendInvoice = (listingId: string) => this.api.post(`hellosigns/invoice/${listingId}`)

  // twilio
  public callSeller = (listingId: string, phone: string) =>
    this.api.post<{ success: boolean; error: string }>(`twilios/call/listing/${listingId}`, {
      phone,
    })

  public blockCaller = (phone: string, userId?: string) =>
    this.api.post<IBlockedCaller>('blockedCaller', { phone, userId })

  public unBlockCaller = (id: string) => this.api.put<IBlockedCaller>(`blockedCaller/unblock/${id}`)

  public createTwilioWorker = (newWorker: T.ITwilioWorkerParams) =>
    this.api.post<T.ITwilioWorker>('twilios/worker', newWorker)

  public updateTwilioWorker = (email: string, changes: Partial<T.ITwilioWorkerParams>) =>
    this.api.put<T.ITwilioWorker>(`twilios/worker/email/${email}`, changes)

  public removeTwilioWorker = (email: string) => this.api.delete(`twilios/worker/email/${email}`)

  public updateRecording = (
    voicemailTaskId: string,
    update: { [key: string]: T.ValueOf<T.IRecording> },
  ) => this.api.put<T.IRecording>(`recordings/${voicemailTaskId}`, { ...update })

  public toggleListingChangeTaskAck = (id: string) =>
    this.api.post<IListingChangeTask>(`/listingChangeTask/${id}/toggle`)

  public toggleHistoryRequiringAck = (historyRequiringAck: T.IHistoryRequiringAck) =>
    this.api.post<void>(`/listings/${historyRequiringAck.listingId}/history/ack`, {
      category: historyRequiringAck.name,
      ack: !historyRequiringAck.ack,
    })

  public addListingChangeTask(task: T.INewTask, listingId: string) {
    return this.api.post<T.IListingChangeTask>(`listingChangeTask/listing/${listingId}`, {
      ...task,
    })
  }

  public bringUserToIdle = (email: string) =>
    this.api.post<{ success: boolean }>('twilios/bringUserToIdle', { email })

  public bringUserToOffline = (email: string) =>
    this.api.post<{ success: boolean }>('twilios/bringUserToOffline', { email })

  public setCellphoneAsPrimaryDevice = (email: string) =>
    this.api.post<{ success: boolean }>(`twilios/worker/${email}/device`, {
      deviceType: 'cellphone',
    })

  public createFaq = (createArgs: T.ICreateFaqOptions) =>
    this.api.post<T.IFaqAnchor>('faq', createArgs)

  public deleteFaq = (deleteArgs: T.IDeleteFaqOptions) =>
    this.api.post<undefined>('faq/delete', deleteArgs)

  // we only want to send this right before agreement send - so we don't want to handle this in swr like we
  // normally do
  public canSendAgreement = (listingId: string) =>
    this.api.get<boolean>(`completionPercentages/${listingId}/canSendAgreement`)

  // Checklist Template Items
  public createChecklistTemplateItem = (item: T.ICreatableChecklistItem) =>
    this.api.post<T.IChecklistTemplateItem>('v2/checklist/template-item', item)

  public updateChecklistTemplateItem = (id: number, update: Partial<T.IChecklistTemplateItem>) =>
    this.api.patch<T.IChecklistTemplateItem>(`v2/checklist/template-item/${id}`, update)

  public updateChecklistTemplateItems = (
    checklistTemplateItems: T.WithAtLeast<'id', T.IChecklistTemplateItem>[],
  ) =>
    this.api.patch<T.IChecklistTemplateItem[]>('v2/checklist/template-item', {
      checklistTemplateItems,
    })

  public deleteChecklistTemplateItem = (id: number) =>
    this.api.delete<T.IChecklistTemplateItem>(`v2/checklist/template-item/${id}`)

  // Listing Checklist Items
  public updateListingChecklistItem = (id: number, update: T.IChecklistListingItem) =>
    this.api.put<T.IChecklistListingItem>(`/v2/checklist/listing-item/${id}`, update)

  // Contract Details
  public patchContractDetails = (
    id: number,
    patch: Partial<T.IContractDetails>,
    options: T.IListingApiOptions = {
      disableStatusUpdate: false,
      disableListingChangesRequired: false,
    },
  ) =>
    this.api.patch<T.IContractDetails>(`contract-details/${id}`, patch, {
      params: {
        disable_status_update: options.disableStatusUpdate,
        disableListingChangesRequired: options.disableListingChangesRequired,
      },
    })
}

export const api = new ApiCreator()
