import { green, grey, red } from '@ant-design/colors'
import { Typography } from 'antd'
import { Spacer } from 'components'
import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { addCommas } from 'utils'

import * as T from 'types'

const { Text } = Typography

enum notShowNames {
  photos = 'photos',
  openHouseDates = 'openHouseDates',
  owners = 'owners',
  contactInfo = 'contactInfo',
  secondaryContactInfo = 'secondaryContactInfo',
}

const hasNoOldValue = (changeEntry: T.IHistoryRequiringAckItem) =>
  (changeEntry.oldVal == null
    || (typeof changeEntry.oldVal !== 'number' && !changeEntry.oldVal.length))
  && !Object.values<string>(notShowNames).includes(changeEntry.name as string)
  && changeEntry.name !== 'contractDetails'

const isDateString = (val: string | object | number) => {
  if (typeof val !== 'string' || (typeof val === 'string' && !Number.isNaN(+val))) return false

  return dayjs(val).isValid()
}

const formatToShortDate = (date: string | object | number) => {
  if (!date || typeof date !== 'string') return ''

  return dayjs(date).format('MM/DD/YY, h:mm A')
}

const formatValue = (value: string | object | number, category: string) => {
  if (typeof value === 'object') return <pre>{JSON.stringify(value, undefined, 2)}</pre>

  if (category === 'price') return `$${addCommas(Number(value).toFixed(2))}`

  if (typeof value === 'number') return `${value}`

  try {
    const parsed = JSON.parse(value)
    return <pre>{JSON.stringify(parsed, undefined, 2)}</pre>
  } catch {
    return `${value}`
  }
}

interface IProps {
  change: T.IHistoryRequiringAckItem
}

const ListingChangeView: FunctionComponent<IProps> = ({ change }) => (
  <>
    <div>
      {change.name === 'photos' && <Text>Photos field was modified</Text>}
      {change.name === 'openHouseDates' && <Text>Open House Dates field was modified</Text>}
      {change.name === 'owners' && <Text>Owners field was modified</Text>}
    </div>
    {hasNoOldValue(change) && (
      <div>
        <Text>Set to: </Text>
        <Spacer size={5} />
        <div style={{ backgroundColor: grey[0] }}>
          {isDateString(change.newVal)
            ? formatToShortDate(change.newVal)
            : formatValue(change.newVal, change.name)}
        </div>
      </div>
    )}
    {!hasNoOldValue(change) && change.name !== 'photos' && (
      <div>
        <Text>Previous: </Text>
        <div style={{ backgroundColor: red[2] }}>
          {!change.isArrayDiff && !isDateString(change.oldVal)
            ? formatValue(change.oldVal, change.name)
            : formatToShortDate(change.oldVal)}
        </div>
        {change.isArrayDiff && Array.isArray(change.oldVal) && (
          <div style={{ backgroundColor: grey[2] }}>
            <Text>[</Text>
            {change.oldVal.map((val: string) => val)}
            <Text>]</Text>
          </div>
        )}
        <Spacer size={5} />
        {change.name !== 'contractDetails' && (
          <div>
            <Text>New: </Text>
            <div style={{ backgroundColor: green[2] }}>
              {!change.isArrayDiff && !isDateString(change.newVal)
                ? formatValue(change.newVal, change.name)
                : formatToShortDate(change.newVal)}
            </div>
            {change.isArrayDiff && Array.isArray(change.newVal) && (
              <div style={{ backgroundColor: grey[2] }}>
                <Text>[</Text>
                {change.newVal.map((val: string) => val)}
                <Text>]</Text>
              </div>
            )}
            <Spacer size={5} />
          </div>
        )}
      </div>
    )}
    <Spacer size={10} />
  </>
)

export default ListingChangeView
