import { addCommas, removeCommas } from 'utils'

export const formatInputNumber = (value: number | undefined) => {
  if (typeof value === 'undefined') return ''

  return `$${addCommas(value)}`
}

export const parseInputNumber = (value: string | undefined) => {
  if (value?.substring(1) === '' || typeof value === 'undefined') return 0

  return Number(removeCommas(value.substring(1)))
}
