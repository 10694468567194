/* eslint-disable import/prefer-default-export */
import queryString from 'query-string'
import useSWR, { mutate } from 'swr'

import { fetchWithToken } from 'services/fetchers'

import { stringifyParams } from 'utils/stringFormatting'

import * as T from 'types'

import { api } from './api'
import { useInitialized } from './swr/utils/useInitialized'

export const updateFieldSchema = async (
  mlsId: string,
  update: { [key: string]: T.ValueOf<T.IFieldSchema> | null | undefined },
): Promise<T.IFieldSchema | undefined> => {
  try {
    const response = await api.updateFieldSchema(mlsId, update)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    console.error({ error })
  }
}

export const useFieldSchema = (mlsId?: string) => {
  const ENDPOINT = `/fieldSchema/${mlsId}`

  const response = useSWR(mlsId ? ENDPOINT : null, fetchWithToken)

  const fieldSchema = response.data
  const fieldSchemaInitialized = useInitialized(response)

  const update = async (key: string, value: T.ValueOf<T.IFieldSchema>) => {
    if (!fieldSchema || !mlsId) return

    const updateResult = await updateFieldSchema(mlsId, { [key]: value })
    if (updateResult) await mutate(ENDPOINT, updateResult, false)
  }

  return { ...response, fieldSchema, fieldSchemaInitialized, update }
}

export const useSearchFieldSchemas = (queryParams: T.IGetQueryParams) => {
  let key = '/fieldSchema/search'

  if (Object.keys(queryParams).length) {
    queryParams = stringifyParams(['filter', 'populate'], queryParams) as T.IGetQueryParams
    key += `?${queryString.stringify(queryParams)}`
  }

  const response = useSWR<T.ISearchResultsWithCount<T.IFieldSchema>>(key, fetchWithToken)
  return {
    ...response,
    searchFieldSchemas: response.data?.results,
    searchFieldSchemasCount: response.data?.count,
  }
}

export const useFieldSchemaForListing = (pageListing?: T.IListing) => {
  const mlsId = pageListing ? pageListing.mlsList[0].mls._id : undefined
  return useFieldSchema(mlsId)
}

export const initializeFieldSchema = async (mlsId: string): Promise<T.IFieldSchema | undefined> => {
  try {
    const response = await api.initializeFieldSchema(mlsId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    console.error({ error })
  }
}

export const copyFieldsToMls = async (
  fields: string[],
  fromMlsId: string,
  toMlsIds: string[],
): Promise<T.IFieldSchema[] | undefined> => {
  try {
    const response = await api.copyFieldsToMls(fields, fromMlsId, toMlsIds)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    console.error({ error })
  }
}

export const checkFieldsToCopy = async (
  fields: string[],
  fromMlsId: string,
  toMlsIds: string[],
): Promise<T.ICopyFieldsConflict[] | undefined> => {
  try {
    const response = await api.checkFieldsToCopy(fields, fromMlsId, toMlsIds)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    console.error({ error })
  }
}

export const initFromSchema = async (
  toMlsId: string,
  mlsId: string,
): Promise<T.IFieldSchema | undefined> => {
  try {
    const response = await api.initFromSchema(toMlsId, mlsId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    console.error({ error })
  }
}
