import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import * as T from 'types'

import Spacer from '../components/Spacer'

export const searchRegex = (value: string) => {
  // remove special characters from input value
  value = value.replace(/[^a-zA-Z0-9 ]/g, '')
  // case insensitive word match
  return new RegExp(`\\b${value}`, 'i')
}

export const showTotal = (total: number) => (
  <div>
    total results: {total}
    <Spacer
      display="inline-block"
      size={20}
    />
  </div>
)

// Table Drag and Drop helpers
const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableBody = SortableContainer((props: any) => <tbody {...props} />)

interface IMongoId {
  _id: string
}
interface IPrismaId {
  id: number
}

export const makeDraggableBodyRow
  = <Type extends IMongoId | IPrismaId>(tableData: Type[]) =>
    ({ ...props }) => {
      const index = tableData.findIndex(item =>
        (item as IPrismaId).id
          ? (item as IPrismaId).id === props['data-row-key']
          : (item as IMongoId)._id === props['data-row-key'],
      )
      return (
        <SortableItem
          index={index}
          {...props}
        />
      )
    }

export const makeDraggableContainer = (onSortEnd: T.IOnSortEnd, helperClass: string) => {
  const DraggableContainer = ({ ...props }) => (
    <SortableBody
      useDragHandle
      helperClass={helperClass}
      onSortEnd={onSortEnd}
      lockAxis="y"
      {...props}
    />
  )
  return DraggableContainer
}
