import { NextPage, NextPageContext } from 'next'
import { ApiCreator } from 'services'

import * as T from 'types'

import HomePage from './listings'
import Signin from './signin'

interface IProps {
  initialData: T.IUser | undefined
}

const IndexPage: NextPage<IProps> = ({ initialData }) =>
  initialData ? <HomePage /> : <Signin initialData={initialData} />

export default IndexPage

IndexPage.getInitialProps = async (ctx: NextPageContext) => {
  try {
    const api = new ApiCreator(ctx)
    const response = await api.getMe()
    if (!response.ok) throw new Error(response.problem)

    return { initialData: response.data }
  } catch (err) {
    return { initialData: undefined }
  }
}
