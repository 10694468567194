/* eslint-disable import/prefer-default-export */

/* eslint-disable camelcase */
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import apisauce from 'apisauce'
import produce from 'immer'
import { mutate } from 'swr'
import { downloadData } from 'utils'
import { v4 as uuid } from 'uuid'

import { isProduction } from 'utils/nodeEnv'

import * as T from 'types'

import { api } from './api'

interface IResult {
  name: string
  url: string
}

const encodeURIFilename = (url: string) => {
  const path = url.substring(0, url.lastIndexOf('/'))
  const filename = encodeURIComponent(url.substring(url.lastIndexOf('/') + 1))
  return `${path}/${filename}`
}

export const addDocumentToS3 = async (
  file: UploadFile,
  publicDoc: boolean = false,
  generateUniqueName: boolean = false,
) => {
  try {
    if (!file) throw new Error('missing necessary data')

    if (typeof window === 'undefined') throw new Error('window not defined')

    // 1. Split the filename to get the name and type, needed to get signed S3 url
    const type = file.name?.trim().split('.').pop() || ''

    const name = generateUniqueName ? `${uuid()}.${type}` : file.name?.trim()

    // 2. Get the URL
    const response = await api.getGenericS3UploadUrl(name, type, publicDoc)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    // 3. Upload the photo(s), return photo URL(s)
    const awsApi = apisauce.create({
      baseURL: response.data?.signed_request,
      headers: { 'Content-Type': type },
    })

    const uploadResponse = await awsApi.put('', file)
    if (!uploadResponse.ok) throw new Error(`bad response: ${response.problem}`)

    return {
      url: response.data.url,
      name,
    }
  } catch (error) {
    if (!isProduction()) console.error({ error })
  }
}

export const createConditionalDocument = async (
  data: Partial<T.IConditionalDocument>,
): Promise<[T.IConditionalDocument | undefined, Error | undefined]> => {
  try {
    const response = await api.createConditionalDocument(data)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    mutate(
      '/conditional-documents',
      (cachedDocuments: T.IConditionalDocument[]) =>
        cachedDocuments?.length ? [...cachedDocuments, response.data] : [response.data],
      false,
    )
    return [response.data, undefined]
  } catch (error) {
    if (!isProduction()) console.error(error)

    const err = error instanceof Error ? error : new Error('unknown error')
    return [undefined, err]
  }
}

export const updateConditionalDocument = async (
  data: T.IConditionalDocument,
): Promise<[T.IConditionalDocument | undefined, Error | undefined]> => {
  try {
    const response = await api.updateConditionalDocument(data)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
    // argument of mutate is a function, it passes in the cached current value stored for the key
    mutate(
      '/conditional-documents',
      produce((draftDocuments: T.IConditionalDocument[]) => {
        const newDocument = response.data as T.IConditionalDocument

        if (draftDocuments && draftDocuments.length) {
          const foundIndex = draftDocuments.findIndex(doc => doc._id === response?.data?._id)
          draftDocuments[foundIndex] = newDocument
        }
      }),
      false,
    )
    return [response.data, undefined]
  } catch (error) {
    console.error(error)
    const err = error instanceof Error ? error : new Error('unknown error')
    return [undefined, err]
  }
}

export const removeConditionalDocument = async (documentId: string) => {
  try {
    const response = await api.removeConditionalDocument(documentId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    mutate('/conditional-documents', null)
    return response
  } catch (error) {
    return error
  }
}

export const displayDocumentByUrl = async (url: string, displayFilename?: string) => {
  try {
    const response = await api.getDocumentByUrl(url)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    // Filename is not applicable while a document opens in new window
    const filename = displayFilename || response.headers?.filename || 'attachment.pdf'
    downloadData(response.data, filename, true, response.headers?.['content-type'])
    return [true, undefined]
  } catch (err) {
    return [false, err]
  }
}

export const addDocumentToListing = async (
  files: T.IDropzoneFile[] | RcFile[],
  listingId: string,
  singleUpload?: boolean,
  newFileType?: string,
) => {
  const filesArr = singleUpload ? [files[0]] : files

  const promiseArr = filesArr.map(async file => {
    try {
      if (!file || !listingId) throw new Error('missing necessary data')

      if (typeof window === 'undefined') throw new Error('window not defined')

      // 1. Get the name and type, needed to get signed S3 url
      const name = file.name?.trim()
      const type = file.type
      if (!type) throw new Error('error reading file type')

      // 2. Get the URL
      const response = await api.getS3UploadUrl(listingId, name, type, 'agreements')
      if (!response.ok || !response.data) throw new Error(`upload url error: ${response.problem}`)

      const url = encodeURIFilename(response?.data?.url)

      // 3. Upload the agreement(s), return agreement URL(s)
      const awsApi = apisauce.create({
        baseURL: response.data?.signed_request,
        headers: { 'Content-Type': type },
      })

      const uploadResponse = await awsApi.put('', file)
      if (!uploadResponse.ok) throw new Error(`upload failed: ${response.problem}`)

      const addDocResponse = await api.createDocument({
        name,
        url,
        type: newFileType,
        listing: listingId,
      })

      if (!addDocResponse.ok || !addDocResponse.data) {
        throw new Error(`bad response: ${addDocResponse.problem}`)
      }

      mutate(`/documents/listing/${listingId}`)
      mutate(`/listings/${listingId}/history`)

      return {
        ...addDocResponse.data,
        url,
        name,
      } as T.IListingDocument
    } catch (error) {
      if (!isProduction()) console.error({ error })
    }
  })

  const uploadResults: T.IListingDocument[] = await Promise.all(promiseArr).then(
    (uploadResult: (T.IListingDocument | undefined)[]) =>
      uploadResult.filter(result => !!result) as T.IListingDocument[],
  )
  return uploadResults
}

// legacy part will be deprecated after Owners/Contacts documents transition happens
export const addAgreementToS3 = async (
  files: T.IDropzoneFile[] | RcFile[],
  listingId: string,
  singleUpload?: boolean,
  addToAttachedDocs?: boolean,
) => {
  const filesArr = singleUpload ? [files[0]] : files

  const promiseArr = filesArr.map(async file => {
    try {
      if (!file || !listingId) throw new Error('missing necessary data')

      if (typeof window === 'undefined') throw new Error('window not defined')

      // 1. Get the name and type, needed to get signed S3 url
      const name = file.name?.trim()
      const type = file.type
      if (!type) throw new Error('error reading file type')

      // 2. Get the URL
      const response = await api.getS3UploadUrl(listingId, name, type, 'agreements')
      if (!response.ok || !response.data) throw new Error(`upload url error: ${response.problem}`)

      const url = encodeURIFilename(response?.data?.url)

      // 3. Upload the agreement(s), return agreement URL(s)
      const awsApi = apisauce.create({
        baseURL: response.data?.signed_request,
        headers: { 'Content-Type': type },
      })

      const uploadResponse = await awsApi.put('', file)
      if (!uploadResponse.ok) throw new Error(`upload failed: ${response.problem}`)

      if (addToAttachedDocs) {
        const addDocResponse = await api.addAttachedDocument(listingId, url, name, type)

        if (!addDocResponse.ok || !addDocResponse.data) {
          throw new Error(`bad response: ${addDocResponse.problem}`)
        }
      }

      return { url, name }
    } catch (error) {
      if (!isProduction()) console.error({ error })
    }
  })

  const uploadResults: IResult[] = await Promise.all(promiseArr).then(
    (uploadResult: (IResult | undefined)[]) => uploadResult.filter(result => !!result) as IResult[],
  )
  return uploadResults
}

export const removeDocument = async (documentId: string, listingId: string) => {
  try {
    const response = await api.removeDocument(documentId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    mutate(`/documents/listing/${listingId}`)
    mutate(`/listings/${listingId}/history`)
    return response
  } catch (error) {
    return error
  }
}

export const updateDocument = async (
  data: T.IListingDocument | T.IDashboardDocument,
  listingId: string,
): Promise<[T.IListingDocument | undefined, Error | undefined]> => {
  try {
    const response = await api.updateDocument({ ...data, listing: listingId })
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
    // argument of mutate is a function, it passes in the cached current value stored for the key
    mutate(
      `/documents/listing/${listingId}`,
      produce((draftDocuments: T.IListingDocument[]) => {
        const newDocument = response.data as T.IListingDocument

        if (draftDocuments && draftDocuments.length) {
          const foundIndex = draftDocuments.findIndex(doc => doc._id === response?.data?._id)
          draftDocuments[foundIndex] = newDocument
        }
      }),
      false,
    )
    mutate(`/listings/${listingId}/history`)
    return [response.data, undefined]
  } catch (error) {
    console.error(error)
    const err = error instanceof Error ? error : new Error('unknown error')
    return [undefined, err]
  }
}
