import useSWR, { mutate } from 'swr'

import { api } from 'services/api'
import { fetchWithToken } from 'services/fetchers'

import * as T from 'types'

import { getDataFromResponse } from './utils/dataHelpers'
import { getUpdateErrorMessage } from './utils/errorMessageHelpers'

const getSwrKey = (listingId: string) => `/v2/checklist/listing-item/listing/${listingId}`

const refreshChecklistListingItems = (listingId: string) => mutate(getSwrKey(listingId))

const useChecklistListingItems = (listingId?: string) => {
  const key = listingId ? getSwrKey(listingId) : null
  const response = useSWR<T.IChecklistListingItem[]>(key, fetchWithToken)

  const update: T.IUpdateChecklistListingItem = async item => {
    if (!listingId) return [undefined, { message: 'Missing listingId' }]

    const updateResponse = await api.updateListingChecklistItem(item.id, item)
    const data = getDataFromResponse(updateResponse)
    const userReadableError = 'Failed to update a listing checklist item'
    const errorMessage = getUpdateErrorMessage(updateResponse, { userReadableError })

    if (data) refreshChecklistListingItems(listingId)

    return [item, errorMessage]
  }

  return {
    ...response,
    listingChecklistItems: response.data,
    update,
  }
}

export { refreshChecklistListingItems, useChecklistListingItems }
