import { FC } from 'react'

import { useStatusById } from 'services/hooks'
import { useListingHistory } from 'services/swr'

import * as T from 'types'

import HistoryPopover, { IGetHistoryValue } from './HistoryPopover'

interface IProps {
  listing: T.IListing
}

const ListingStatusName: FC<IProps> = ({ listing }) => {
  const { error, history } = useListingHistory(listing._id, {
    filter: { category: 'Updated Listing Status' },
    select: 'changes createdAt',
    sort: '-createdAt',
  })

  const statusById = useStatusById()
  const getHistoryValue: IGetHistoryValue = statusId => statusById[statusId as string]?.name || ''

  return (
    <HistoryPopover
      history={history}
      getHistoryValue={getHistoryValue}
      swrError={error}
    >
      {listing.listingStatus.name}
    </HistoryPopover>
  )
}

export default ListingStatusName
