import { Dispatch, useEffect, useState } from 'react'

import * as T from 'types'
import * as E from 'types/enums'

interface IBadgeData {
  text: string
  status: 'processing' | 'success' | 'warning' | 'default' | 'error'
}

const useTwilioWorkerBadge = (
  user: T.IUser,
  twilioWorker: T.ITwilioWorker | null,
  twilioWorkerError: T.IServerError,
): [IBadgeData, Dispatch<IBadgeData>] => {
  const [badgeData, setBadgeData] = useState<IBadgeData>({
    text: 'Loading...',
    status: 'processing',
  })

  useEffect(() => {
    if (!user.twilioWorkerSid) {
      return setBadgeData({ text: 'N/A', status: 'error' })
    }

    if (user.twilioWorkerSid && !twilioWorker) {
      return setBadgeData({ text: 'Loading...', status: 'processing' })
    }

    if (twilioWorkerError) {
      return setBadgeData({ text: 'Failed to get status', status: 'error' })
    }

    if (twilioWorker?.activityName === E.TwilioWorkerActivity.idle) {
      return setBadgeData({ text: 'Online', status: 'success' })
    }

    if (twilioWorker?.activityName === E.TwilioWorkerActivity.offline) {
      return setBadgeData({ text: 'Offline', status: 'error' })
    }

    if (twilioWorker?.activityName === E.TwilioWorkerActivity.busy) {
      return setBadgeData({ text: 'Busy', status: 'error' })
    }

    if (twilioWorker?.activityName === E.TwilioWorkerActivity.reserved) {
      return setBadgeData({ text: 'Reserved', status: 'error' })
    }
  }, [twilioWorker, twilioWorkerError?.message, user])

  return [badgeData, setBadgeData]
}

export default useTwilioWorkerBadge
