import { Button } from 'antd'
import styled from 'styled-components'

/*
  listing details page custom button colors
  action: yellow[4], onHover: yellow[5]
  preview: cyan[2], onHover: cyan[3]
  signagure: green[5], onHover: green[6]
  danger: red[5], onHover: red[6]
*/
interface ICustomButton {
  active?: string
  width?: string
  color?: string
  backgroundColor?: string
  backgroundColorOnHover?: string
  custom?: boolean
}

/*
  withConfig shouldForwardProp fixes the issue of sending unrecognized props to the DOM elements.
  It possibly happened due to conflicting prop handling of styled-component props and antd button props
  Reference: https://styled-components.com/docs/api#shouldforwardprop
*/
const CustomButton = styled(Button).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<ICustomButton>`
  font-size: 12px;
  font-weight: 800;
  height: 24px;
  margin-top: 1px;
  width: ${props => (props.width ? props.width : 'auto')};
  ${props => props.color && `color: ${props.color};`}
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${props =>
    props.custom
    && `
    border: none;
    border-radius: 32px;
  `}

  span {
    padding-top: 1px; /* vertical font fix */
  }

  :hover,
  :active,
  :focus {
    ${props =>
    props.custom
      && `
      border: none;
      color: ${props.color ? props.color : 'black'};
    `}
    ${props => props.backgroundColorOnHover && `background-color: ${props.backgroundColorOnHover};`}
  }
`

export default CustomButton
