export interface fontObject {
  [key: string]: string
}

const fonts = {
  base: '"Avenir LT W01_55 Roman1475520",Lato,Montserrat,Raleway,"Helvetica Neue",Helvetica,Arial,sans-serif',
  defaultFontSize: 12,
}

export default fonts
