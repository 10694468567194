import { blue, red } from '@ant-design/colors'
import { CloseCircleOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons'
import { Alert, Col, Input, Modal, Row, Space, message } from 'antd'
import { Anchor, FontSizeButton, IconButton, Spacer } from 'components'
import dayjs from 'dayjs'
import { FC, Fragment, useEffect, useState } from 'react'
import { useListing } from 'services'
import styled from 'styled-components'
import { copyToClipboard } from 'utils'

import { useUser } from 'services/swr'
import mutateCacheKeysByRegex from 'services/swr/utils/mutateCacheKeysByRegex'

import * as T from 'types'

const { TextArea } = Input

const mutateHistoryRoutes = () => mutateCacheKeysByRegex(/history/)

interface IWidthProp {
  width?: string
}
interface ITextProps extends T.IFontSize {
  bold?: Boolean
}

type IHandleTextAreaChange = React.ChangeEventHandler<HTMLTextAreaElement>

const StyledButton = styled(FontSizeButton)`
  width: 100px;
`

const Text = styled.div<ITextProps & IWidthProp>`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => (props.bold ? '600' : '400')};
  white-space: pre-wrap;
  ${props => props.width && `width: ${props.width}`};
`

const AgentSellerNotesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`

const NoteContentContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  background-color: ${props => props.theme.colors.grey};
  border-radius: 5px;
`

const RightAlignContainer = styled.div<IWidthProp>`
  display: flex;
  justify-content: flex-end;
  width: ${props => (props.width ? props.width : '100%')};
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
`

const DeleteButton = styled(IconButton)`
  color: ${red[5]};
`

const EditButton = styled(IconButton)`
  color: ${blue[5]};
`

const CopyOutlineButton = styled(IconButton)``

interface IProps extends T.IFontSize {
  listing: T.IListing
}

const AgentSellerNotes: FC<IProps> = ({ fontSize, listing }) => {
  const { user } = useUser()
  const { addSellerNote, update, updateError } = useListing()

  const [agentSellerNotes, setAgentSellerNotes] = useState<T.IAgentSellerNote[]>(
    listing.agentSellerNotes || [],
  )

  const [newNote, setNewNote] = useState('')
  const [editDeleteMode, setEditDeleteMode] = useState(false)
  const [selectedEditingNote, setSelectedEditingNote] = useState<T.IAgentSellerNote | null>(null)
  const [addedNoteWithChangesRequired, setAddedNoteWithChangesRequired] = useState(false)

  const [error, setError] = useState('')

  const [confirmingSellerNote, setConfirmingSellerNote] = useState(false)

  const addNote = (changesRequired = false) => {
    if (!user?.name) return setError('Admin user does not exist')

    if (!newNote) return setError('Note cannot be empty')

    setConfirmingSellerNote(true)
    setAddedNoteWithChangesRequired(changesRequired)
  }

  const addNoteWithChangesRequired = () => addNote(true)
  const addNoteWithoutChangesRequired = () => addNote()

  const handleConfirmAddNote = async () => {
    setConfirmingSellerNote(false)

    if (!user?.name) return setError('Admin user does not exist')

    if (!newNote) return setError('Note cannot be empty')

    const sellerNote = {
      note: newNote,
      user: user.name,
      date: new Date().toISOString(),
      hasBeenSeen: true,
    }

    const options = {
      disableStatusUpdate: true,
      disableListingChangesRequired: true,
      mutateHistoryRoutes,
    }

    const [, noteError] = addedNoteWithChangesRequired
      ? await addSellerNote({ sellerNote })
      : await addSellerNote({ sellerNote, options })

    const { message: noteMessage = '' } = noteError || {}
    if (noteMessage) return setError(noteMessage)

    setNewNote('')
  }

  const handleCancelAddNote = () => {
    setConfirmingSellerNote(false)
  }

  const handleDelete = (selectedNoteId?: string) => {
    if (!selectedNoteId) return

    const filteredNotes = agentSellerNotes.filter(note => note._id !== selectedNoteId)
    setAgentSellerNotes(filteredNotes)
  }

  const reset = () => {
    setAgentSellerNotes(listing.agentSellerNotes || [])
    setNewNote('')
    setEditDeleteMode(false)
    setSelectedEditingNote(null)
  }

  const handleEditDeleteMode = () => {
    if (editDeleteMode) {
      update({ agentSellerNotes })
      reset()
    } else {
      setEditDeleteMode(mode => !mode)
    }
  }

  const handleEditContent = (agentSellerNote: T.IAgentSellerNote) => {
    setNewNote(agentSellerNote.note)
    setSelectedEditingNote(agentSellerNote)
  }

  const handleModalOk = () => {
    if (!selectedEditingNote) return

    const updatedNotes = agentSellerNotes.map(agentSellerNote => {
      if (agentSellerNote._id === selectedEditingNote._id) {
        const updatedNote = selectedEditingNote
        selectedEditingNote.note = newNote // insert updated note text
        return updatedNote
      }

      return agentSellerNote
    })
    setAgentSellerNotes(updatedNotes)
    setSelectedEditingNote(null)
  }

  const handleModalCancel = () => {
    setNewNote('')
    setSelectedEditingNote(null)
  }

  const handleCopyToClipboard = (
    data: object | string,
    successMessage: string = 'Value Copied!',
  ) => {
    message.success({
      style: { marginTop: '50px' },
      content: successMessage,
      duration: 5,
    })
    copyToClipboard(data)
  }

  const textParsedWithClickableLink = (input: string) => {
    // reference: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
    const urlRegex
      = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

    const textArr = input.split(urlRegex)
    return textArr.map(text => {
      if (text.match(urlRegex)) {
        let href = text
        if (text[text.length - 1] === '.') href = text.slice(0, -1)

        if (text.startsWith('www.')) href = `https://${text}`

        return (
          <Anchor
            href={href}
            target="_blank"
            style={{ color: blue[5] }}
            key={text}
          >
            {text}
          </Anchor>
        )
      }

      return <span key={text}>{text}</span>
    })
  }

  useEffect(() => {
    setAgentSellerNotes(listing.agentSellerNotes || [])
  }, [listing])

  useEffect(() => {
    setError(updateError)
  }, [updateError])

  const handleTextAreaChange: IHandleTextAreaChange = evt => setNewNote(evt.target.value)

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row justify="end">
            {editDeleteMode
              ? (
                <Col span={24}>
                  <Row justify="end">
                    <StyledButton
                      type={editDeleteMode ? 'primary' : 'default'}
                      onClick={handleEditDeleteMode}
                    >
                      {editDeleteMode ? 'Confirm' : 'Edit Mode'}
                    </StyledButton>
                    {editDeleteMode && <StyledButton onClick={reset}>Cancel</StyledButton>}
                  </Row>
                </Col>
              )
              : (
                <InputContainer>
                  <Row gutter={[5, 5]}>
                    <Col
                      xs={24}
                      md={16}
                    >
                      <TextArea
                        rows={3}
                        style={{ color: 'red' }}
                        value={newNote}
                        onChange={handleTextAreaChange}
                      />
                    </Col>
                    <Col
                      xs={24}
                      md={8}
                    >
                      <Row justify="space-between">
                        <Col span={8}>
                          <Space
                            direction="vertical"
                            size={10}
                          >
                            <FontSizeButton
                              onClick={addNoteWithoutChangesRequired}
                              type="primary"
                              disabled={!newNote}
                            >
                            Add Note
                            </FontSizeButton>
                            <FontSizeButton
                              onClick={addNoteWithChangesRequired}
                              type="primary"
                              disabled={!newNote}
                            >
                            Add Note + Create Change Required
                            </FontSizeButton>
                          </Space>
                        </Col>
                        {agentSellerNotes.length > 0 && (
                          <StyledButton
                            style={{ marginRight: 5 }}
                            type={editDeleteMode ? 'primary' : 'default'}
                            onClick={handleEditDeleteMode}
                          >
                            {editDeleteMode ? 'Confirm' : 'Edit Mode'}
                          </StyledButton>
                        )}
                      </Row>
                    </Col>
                    {error && (
                      <Col span={24}>
                        <Alert
                          style={{ height: '32px', width: '100%' }}
                          message={error}
                          type="error"
                          showIcon
                          closable
                        />
                      </Col>
                    )}
                  </Row>
                </InputContainer>
              )}
          </Row>
        </Col>
        <Col span={24}>
          <AgentSellerNotesContainer>
            {agentSellerNotes.map((note, index) => {
              const handleEditNoteContent = () => handleEditContent(note)
              const handleDeleteNote = () => handleDelete(note._id)

              const handleCopyNote = () =>
                handleCopyToClipboard(note.note, 'Copied seller note value')

              return (
                <Fragment key={dayjs(note.date).unix()}>
                  {index > 0 && <Spacer size={10} />}
                  <RightAlignContainer>
                    <Text
                      fontSize={fontSize}
                      bold
                    >
                      {note.user}
                    </Text>
                    <Spacer size={5} />
                    <Text fontSize={fontSize}>{dayjs(note.date).format('MM/DD/YY, h:mm A')}</Text>
                  </RightAlignContainer>
                  <NoteContentContainer>
                    <Text
                      fontSize={fontSize}
                      width="100%"
                    >
                      {textParsedWithClickableLink(note.note)}
                    </Text>
                    <RightAlignContainer width="50px">
                      {editDeleteMode && !selectedEditingNote && (
                        <>
                          <EditButton
                            Icon={EditOutlined}
                            onClick={handleEditNoteContent}
                          />
                          <Spacer size={5} />
                          <DeleteButton
                            Icon={CloseCircleOutlined}
                            onClick={handleDeleteNote}
                          />
                          <Spacer size={5} />
                        </>
                      )}
                      <CopyOutlineButton
                        Icon={CopyOutlined}
                        onClick={handleCopyNote}
                      />
                    </RightAlignContainer>
                  </NoteContentContainer>
                </Fragment>
              )
            })}
          </AgentSellerNotesContainer>
        </Col>
      </Row>
      <Modal
        title="Edit Note"
        visible={!!selectedEditingNote}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        centered
      >
        <TextArea
          value={newNote}
          onChange={handleTextAreaChange}
          autoSize={{ minRows: 5, maxRows: 10 }}
        />
      </Modal>
      <Modal
        title="Confirm Seller Note"
        visible={confirmingSellerNote}
        onOk={handleConfirmAddNote}
        onCancel={handleCancelAddNote}
        centered
      >
        <p>Are you sure you want to send this note to the seller?</p>
      </Modal>
    </>
  )
}

export default AgentSellerNotes
