import moment from 'moment'
import { useMemo } from 'react'
import { useMlsSearch } from 'services'
import { v4 as uuid } from 'uuid'

import { getDiscountAmount } from 'modules/ListingContentSection/utils'

import { useOrdersByListingId, usePricingDetails } from 'services/swr'

import { getPackageLabel } from 'utils/listingHelpers'
import {
  addPercent,
  parseBooleanValue,
  parseTzDate,
  renderCurrency,
  renderPhoneNumber,
  renderValueOrNothing,
} from 'utils/stringFormatting'

import * as T from 'types'
import * as E from 'types/enums'

export const useServiceFulfillmentFields = (listing: T.IListing) => {
  const serviceSetupFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.mondayMorning',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Monday Morning',
        label: 'Monday Morning',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.mondayMorning),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.mondayAfternoon',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Monday Afternoon',
        label: 'Monday Afternoon',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.mondayAfternoon),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.tuesdayMorning',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Tuesday Morning',
        label: 'Tuesday Morning',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.tuesdayMorning),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.tuesdayAfternoon',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Tuesday Afternoon',
        label: 'Tuesday Afternoon',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.tuesdayAfternoon),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.wednesdayMorning',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Wednesday Morning',
        label: 'Wednesday Morning',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.wednesdayMorning),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.wednesdayAfternoon',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Wednesday Afternoon',
        label: 'Wednesday Afternoon',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.wednesdayAfternoon),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.thursdayMorning',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Thursday Morning',
        label: 'Thursday Morning',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.thursdayMorning),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.thursdayAfternoon',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Thursday Afternoon',
        label: 'Thursday Afternoon',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.thursdayAfternoon),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.fridayMorning',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Friday Morning',
        label: 'Friday Morning',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.fridayMorning),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographyAvailabilitySchedule.fridayAfternoon',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Friday Afternoon',
        label: 'Friday Afternoon',
        value: parseBooleanValue(listing?.photographyAvailabilitySchedule?.fridayAfternoon),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lockboxType',
          type: 'select',
        },
        isCoreField: true,
        name: 'Lockbox Type',
        label: 'Lockbox Type',
        value: renderValueOrNothing(listing?.lockboxType || ''),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
        fieldOptions: Object.keys(E.LockboxType),
        fieldLabels: Object.keys(E.LockboxType),
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'stagingReport.requested',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Staging Report',
        label: 'Staging Report',
        value: parseBooleanValue(listing?.stagingReport?.requested),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'additionalListingInfo',
          type: 'text',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Additional Listing Info',
        label: 'Additional Listing Info',
        value: renderValueOrNothing(listing?.additionalListingInfo),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Service Setup',
        defaultValue: '',
      },
    ],
    [listing],
  )

  const photographerFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographer.name',
          type: 'text',
        },
        isCoreField: true,
        name: 'Photographer Name',
        label: 'Photographer Name',
        value: renderValueOrNothing(listing?.photographer?.name),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Photographer Information',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographer.email',
          type: 'text',
        },
        isCoreField: true,
        name: 'Photographer Email',
        label: 'Photographer Email',
        value: renderValueOrNothing(listing?.photographer?.email),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Photographer Information',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographer.phoneNumber',
          type: 'phonenumber',
        },
        isCoreField: true,
        name: 'Photographer Phone Number',
        label: 'Photographer Phone Number',
        value: renderPhoneNumber(listing?.photographer?.phoneNumber),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Photographer Information',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'photographer.scheduledDate',
          type: 'date',
        },
        isCoreField: true,
        isTimepicker: true,
        name: 'Photographer Scheduled Date',
        label: 'Photographer Scheduled Date',
        value: renderValueOrNothing(
          parseTzDate(listing?.photographer?.scheduledDate, listing?.timeZone, true),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Photographer Information',
        defaultValue: '',
      },
    ],
    [listing],
  )

  const yardSignFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.service',
          type: 'text',
        },
        isCoreField: true,
        readOnly: true,
        name: 'Selected Sign Service',
        label: 'Selected Sign Service',
        value: renderValueOrNothing(listing?.sign?.service?.name),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.installationOptions',
          type: 'checklist',
        },
        isCoreField: true,
        name: 'Installation Options',
        label: 'Installation Options',
        value: renderValueOrNothing(listing?.sign?.installationOptions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
        fieldOptions: ['In-Ground Sprinkler', 'In-Ground Wiring', 'Other'],
        type: 'checklist',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.installationDetails',
          type: 'text',
        },
        isCoreField: true,
        name: 'Installation Details',
        label: 'Installation Details',
        value: renderValueOrNothing(listing?.sign?.installationDetails),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.hasGateCode',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Do you have a Gate Code?',
        label: 'Do you have a Gate Code?',
        value: parseBooleanValue(listing?.sign?.hasGateCode),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.gateCode',
          type: 'text',
        },
        isCoreField: true,
        name: 'Gate Code',
        label: 'Gate Code',
        value: renderValueOrNothing(listing?.sign?.gateCode),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.hasRestrictions',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Association/HOA/City Restrictions',
        label: 'Association/HOA/City Restrictions',
        value: parseBooleanValue(listing?.sign?.hasRestrictions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.restrictions',
          type: 'checklist',
        },
        isCoreField: true,
        name: 'Yard Sign Restrictions',
        label: 'Yard Sign Restrictions',
        value: renderValueOrNothing(listing?.sign?.restrictions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
        fieldOptions: [
          'Placement Restrictions',
          'Sign Size and/or Color Restrictions',
          'Height of Post Restrictions',
          'Other',
        ],
        type: 'checklist',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.cautions',
          type: 'text',
        },
        isCoreField: true,
        name: 'Restriction Details',
        label: 'Restriction Details',
        value: renderValueOrNothing(listing?.sign?.cautions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.notes',
          type: 'text',
        },
        isCoreField: true,
        name: 'Yard Sign Notes',
        label: 'Yard Sign Notes',
        value: renderValueOrNothing(listing?.sign?.notes),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.installationCompany',
          type: 'text',
        },
        isCoreField: true,
        name: 'Sign Installation Company',
        label: 'Sign Installation Company',
        value: renderValueOrNothing(listing?.sign?.installationCompany),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.installationScheduledDate',
          type: 'date',
        },
        isCoreField: true,
        name: 'Sign Installation Scheduled Date',
        label: 'Sign Ordered Date',
        value: renderValueOrNothing(
          parseTzDate(listing?.sign?.installationScheduledDate, listing?.timeZone),
        ),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.shippingTrackingInformation',
          type: 'text',
        },
        isCoreField: true,
        name: 'Sign Shipping Tracking Information',
        label: 'Sign Shipping Tracking Information',
        value: renderValueOrNothing(listing?.sign?.shippingTrackingInformation),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.postShippingTrackingInformation',
          type: 'text',
        },
        isCoreField: true,
        name: 'Sign Post Shipping Tracking Information',
        label: 'Sign Post Shipping Tracking Information',
        value: renderValueOrNothing(listing?.sign?.postShippingTrackingInformation),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.postRemovalOrdered',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Sign Post Removal Ordered',
        label: 'Sign Post Removal Ordered',
        value: parseBooleanValue(listing?.sign?.postRemovalOrdered),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.postRemovalDate',
          type: 'date',
        },
        isCoreField: true,
        name: 'Sign Post Removal Date',
        label: 'Sign Post Removal Date',
        value: parseTzDate(listing?.sign?.postRemovalDate, listing?.timeZone),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Yard Sign',
        defaultValue: '',
      },
    ],
    [listing],
  )

  const otherServicesFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'openHouseSignsOrdered',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Open House Signs Ordered?',
        label: 'Open House Signs Ordered?',
        value: parseBooleanValue(listing?.openHouseSignsOrdered),
        listingFlowStep: 'Other Services',
        listingFlowSubStep: 'Other Services',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'openHouseSignsTrackingInformation',
          type: 'text',
        },
        isCoreField: true,
        name: 'Open House Signs Tracking Information',
        label: 'Open House Signs Tracking Information',
        value: renderValueOrNothing(listing?.openHouseSignsTrackingInformation),
        listingFlowStep: 'Other Services',
        listingFlowSubStep: 'Other Services',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'flyerProvided',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Flyer Provided?',
        label: 'Flyer Provided?',
        value: parseBooleanValue(listing?.flyerProvided),
        listingFlowStep: 'Other Services',
        listingFlowSubStep: 'Other Services',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lockboxOrdered',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Lockbox Ordered?',
        label: 'Lockbox Ordered?',
        value: parseBooleanValue(listing?.lockboxOrdered),
        listingFlowStep: 'Other Services',
        listingFlowSubStep: 'Other Services',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lockboxTrackingInformation',
          type: 'text',
        },
        isCoreField: true,
        name: 'Lockbox Tracking Information',
        label: 'Lockbox Tracking Information',
        value: renderValueOrNothing(listing?.lockboxTrackingInformation),
        listingFlowStep: 'Other Services',
        listingFlowSubStep: 'Other Services',
        defaultValue: '',
      },
    ],
    [listing],
  )

  const oldYardSignFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.wantsWindowSign',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Wants Window Sign',
        label: 'Wants Window Sign',
        value: parseBooleanValue(listing?.sign?.wantsWindowSign),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Old Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.hasRestrictions',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Has Restrictions',
        label: 'Has Restrictions',
        value: parseBooleanValue(listing?.sign?.hasRestrictions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Old Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.requests',
          type: 'text',
        },
        isCoreField: true,
        name: 'Sign placement request',
        label: 'Sign placement request',
        value: renderValueOrNothing(listing?.sign?.requests),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Old Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.hasCautions',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Sprinkler or Wiring in Ground',
        label: 'Sprinkler or Wiring in Ground',
        value: parseBooleanValue(listing?.sign?.hasCautions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Old Yard Sign',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sign.cautions',
          type: 'text',
        },
        isCoreField: true,
        name: 'Sprinkler or Wiring Details',
        label: 'Sprinkler or Wiring Details',
        value: renderValueOrNothing(listing?.sign?.cautions),
        listingFlowStep: 'Yard Sign',
        listingFlowSubStep: 'Old Yard Sign',
        defaultValue: '',
      },
    ],
    [listing],
  )

  const measurementAppraisalCompany = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.name',
          type: 'text',
        },
        isCoreField: true,
        name: 'Company',
        label: 'Company',
        value: renderValueOrNothing(listing?.measurementAppraisalCompany?.name),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.performedBy',
          type: 'text',
        },
        isCoreField: true,
        name: 'Performed By',
        label: 'Performed By',
        value: renderValueOrNothing(listing?.measurementAppraisalCompany?.performedBy),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.phone',
          type: 'phonenumber',
        },
        isCoreField: true,
        name: 'Phone Number',
        label: 'Phone Number',
        value: renderPhoneNumber(listing?.measurementAppraisalCompany?.phone),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.email',
          type: 'text',
        },
        isCoreField: true,
        name: 'Email',
        label: 'Email',
        value: renderValueOrNothing(listing?.measurementAppraisalCompany?.email),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.dateOrdered',
          type: 'date',
        },
        isCoreField: true,
        name: 'Date Ordered',
        label: 'Date Ordered',
        value: renderValueOrNothing(
          parseTzDate(listing?.measurementAppraisalCompany?.dateOrdered, listing?.timeZone),
        ),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.dateScheduled',
          type: 'date',
        },
        isCoreField: true,
        name: 'Date Scheduled',
        label: 'Date Scheduled',
        value: renderValueOrNothing(
          parseTzDate(listing?.measurementAppraisalCompany?.dateScheduled, listing?.timeZone),
        ),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.materialDefectsFound',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Material Defects Found',
        label: 'Material Defects Found',
        value: parseBooleanValue(listing?.measurementAppraisalCompany?.materialDefectsFound),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'measurementAppraisalCompany.materialDefectsDetails',
          type: 'text',
        },
        isCoreField: true,
        name: 'Material Defects Details',
        label: 'Material Defects Details',
        value: renderValueOrNothing(listing?.measurementAppraisalCompany?.materialDefectsDetails),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'Measurement/Appraisal/Inspections',
        defaultValue: '',
      },
    ],
    [listing],
  )

  const mlsRulesFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'mlsRulesAgreed',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'MLS Rules Agreed',
        label: 'MLS Rules Agreed',
        value: parseBooleanValue(listing?.mlsRulesAgreed),
        listingFlowStep: 'Service Fulfillment',
        listingFlowSubStep: 'MLS Rules Agreed',
        defaultValue: '',
      },
    ],
    [listing],
  )

  return {
    photographerFields,
    serviceSetupFields,
    yardSignFields,
    otherServicesFields,
    oldYardSignFields,
    measurementAppraisalCompany,
    mlsRulesFields,
  }
}

export const useServiceFields = (listing: T.IListing, contractDetails: T.IContractDetails) => {
  const { pricingDetails } = usePricingDetails(listing._id)
  const { orders } = useOrdersByListingId(listing._id)

  const additionalOrderServices = useMemo(
    () =>
      orders
        ?.map(order => {
          const services = order.items.map(item => item.services).flat()
          return services
            .map(service => {
              const serviceWithDiscountPrice = pricingDetails?.services.find(
                serviceItem => service.type._id === serviceItem._id,
              )
              service.type.discountedPrice = serviceWithDiscountPrice?.price ?? 0
              service.type.discount = serviceWithDiscountPrice?.discount
              return { service, orderId: order._id, paid: order.paid }
            })
            .filter(
              additionalOrderService =>
                additionalOrderService.paid
                && additionalOrderService.service.type.status !== 'PricingPackage',
            )
        })
        .flat(),
    [orders, pricingDetails],
  )

  const packageSelectedFields = useMemo(
    () => [
      {
        _id: uuid(),
        name: 'Package Label',
        label: 'Package Label',
        value: renderValueOrNothing(getPackageLabel(listing)),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Package Name',
        label: 'Package Name',
        value: renderValueOrNothing(pricingDetails?.pricingPackage.name),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Package Price',
        label: 'Package Price',
        value: renderCurrency(pricingDetails?.pricingPackage.price),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Discount Code Used',
        label: 'Discount Code Used',
        value: parseBooleanValue(pricingDetails?.discountApplied),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Discount Amount',
        label: 'Discount Amount',
        value: getDiscountAmount(pricingDetails?.discount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Paid To Date',
        label: 'Paid To Date',
        value: renderCurrency(pricingDetails?.paidToDate),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Due At Closing',
        label: 'Due At Closing',
        value: renderCurrency(pricingDetails?.packageDueAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Package Selected',
        defaultValue: '',
        readOnly: true,
      },
    ],
    [pricingDetails],
  )

  const totalForAccountFields = useMemo(
    () => [
      {
        _id: uuid(),
        name: 'Paid To Date Of Package',
        label: 'Paid To Date Of Package',
        value: renderCurrency(pricingDetails?.paidToDate),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Paid To Date Of Services',
        label: 'Paid To Date Of Services',
        value: renderCurrency(pricingDetails?.totalPaidOfOrders),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Total Paid',
        label: 'Total Paid',
        value: renderCurrency(pricingDetails?.totalPaid),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Due At Closing Of Package',
        label: 'Due At Closing Of Package',
        value: renderCurrency(pricingDetails?.packageDueAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Total Services Due At Closing',
        label: 'Total Services Due At Closing',
        value: renderCurrency(pricingDetails?.totalUnpaidOfOrders),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'additionalPricing.discountToAmountDueAtClosing',
          type: 'currency',
        },
        isCoreField: true,
        name: 'Additional Discount to Amount Due at Closing',
        label: 'Additional Discount to Amount Due at Closing',
        value: renderCurrency(listing.additionalPricing?.discountToAmountDueAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        readOnly: false,
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'additionalPricing.discountToAmountDueAtClosingDesc',
          type: 'text',
        },
        isCoreField: true,
        name: 'Additional Discount to Amount Due at Closing Description',
        label: 'Additional Discount to Amount Due at Closing Description',
        value: renderValueOrNothing(listing.additionalPricing?.discountToAmountDueAtClosingDesc),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        readOnly: false,
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'additionalPricing.additionalAmountDueAtClosing',
          type: 'currency',
        },
        isCoreField: true,
        name: 'Additional Amount Due at Closing',
        label: 'Additional Amount Due at Closing',
        value: renderCurrency(listing.additionalPricing?.additionalAmountDueAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        readOnly: false,
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'additionalPricing.additionalAmountDueAtClosingDesc',
          type: 'text',
        },
        isCoreField: true,
        name: 'Additional Amount Due at Closing Description',
        label: 'Additional Amount Due at Closing Description',
        value: renderValueOrNothing(listing.additionalPricing?.additionalAmountDueAtClosingDesc),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        readOnly: false,
      },
      {
        _id: uuid(),
        name: 'Refundable Amount Due at Closing',
        label: 'Refundable Amount Due at Closing',
        value: renderCurrency(pricingDetails?.totalRefundableAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Refundable Amount Due at Closing Description',
        label: 'Refundable Amount Due at Closing Description',
        value: renderValueOrNothing(
          pricingDetails?.refundableOrders.map(order => order.name).join(','),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Total Due At Closing',
        label: 'Total Due At Closing',
        value: renderCurrency(pricingDetails?.totalDueAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Total Account',
        label: 'Total Account',
        value: renderCurrency(pricingDetails?.totalAccount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Total For Account',
        defaultValue: '',
        readOnly: true,
      },
    ],
    [listing, pricingDetails],
  )

  const manualCheckPaymentsFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'manualPayment.checkIssuedBy',
          type: 'text',
        },
        isCoreField: true,
        name: 'Check Issued By',
        label: 'Check Issued By',
        value: renderValueOrNothing(listing.manualPayment?.checkIssuedBy),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'manualPayment.checkNumber',
          type: 'number',
        },
        isCoreField: true,
        name: 'Check #',
        label: 'Check #',
        value: renderValueOrNothing(listing.manualPayment?.checkNumber),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'manualPayment.checkAmount',
          type: 'currency',
        },
        isCoreField: true,
        name: 'Check Amount',
        label: 'Check Amount',
        value: renderCurrency(listing.manualPayment?.checkAmount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'manualPayment.checkDescription',
          type: 'text',
        },
        isCoreField: true,
        name: 'Check Description',
        label: 'Check Description',
        value: renderValueOrNothing(listing.manualPayment?.checkDescription),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'manualPayment.checkDepositDate',
          type: 'date',
        },
        isCoreField: true,
        name: 'Check Deposit Date',
        label: 'Check Deposit Date',
        value: renderValueOrNothing(
          parseTzDate(listing.manualPayment?.checkDepositDate, listing?.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'fullyPaid',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Fully Paid?',
        label: 'Fully Paid?',
        value: parseBooleanValue(contractDetails?.fullyPaid),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'inCollections',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Collections?',
        label: 'Collections?',
        value: parseBooleanValue(contractDetails?.inCollections),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Payments',
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  return {
    packageSelectedFields,
    additionalOrderServiceFields: additionalOrderServices ?? ([] as T.IAdditionalOrderService[]),
    totalForAccountFields,
    manualCheckPaymentsFields,
  }
}

interface IEscrowFields {
  generalInfoFields: T.IMlsField[]
  contractStatusFields: T.IMlsField[]
  buyerInfoFields: T.IMlsField[]
  buyerAgentInfoFields: T.IMlsField[]
  sellersTitleCompanyInfoFields: T.IMlsField[]
  buyersTitleCompanyInfoFields: T.IMlsField[]
  sellersAttorneyInfoFields: T.IMlsField[]
  buyersAttorneyInfoFields: T.IMlsField[]
  lenderInfoFields: T.IMlsField[]
  [key: string]: T.IMlsField[]
}

export const useEscrowFields = (
  contractDetails: T.IContractDetails,
  listing: T.IListing,
): IEscrowFields => {
  const { mlsSearchResults, error: mlsSearchError } = useMlsSearch({
    filter: { name: { $in: listing?.mlsList.map(mls => mls.mlsLiveIn) } },
  })

  let closingDateMlsValue = renderValueOrNothing(
    parseTzDate(contractDetails?.closingDateMls, listing.timeZone),
  )

  let isClosingDateMlsValueReadOnly = false

  if (closingDateMlsValue === '---') {
    if (mlsSearchError) {
      closingDateMlsValue = 'Error occurred'
      isClosingDateMlsValueReadOnly = true
    } else {
      const isClosingDateRequired = mlsSearchResults?.some(mls => !!mls?.closeDateRequired)
      if (isClosingDateRequired) closingDateMlsValue = ''

      if (!isClosingDateRequired) {
        closingDateMlsValue = 'N/A'
        isClosingDateMlsValueReadOnly = true
      }
    }
  }

  const generalInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'contractDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Contract Date',
        label: 'Contract Date',
        value: renderValueOrNothing(parseTzDate(contractDetails?.contractDate, listing.timeZone)),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'closingDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Closing Date',
        label: 'Closing Date',
        value: renderValueOrNothing(parseTzDate(contractDetails?.closingDate, listing.timeZone)),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'closingDateMls',
          type: 'date',
        },
        isContractDetailsField: true,
        readOnly: isClosingDateMlsValueReadOnly,
        name: 'Closing Date In MLS',
        label: 'Closing Date In MLS',
        value: closingDateMlsValue,
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'salePrice',
          type: 'currency',
        },
        isContractDetailsField: true,
        name: 'Sale Price',
        label: 'Sale Price',
        value: renderCurrency(contractDetails?.salePrice),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'depositAmount',
          type: 'currency',
        },
        isContractDetailsField: true,
        name: 'Deposit Amount',
        label: 'Deposit Amount',
        value: renderCurrency(contractDetails?.depositAmount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerCreditsToBuyerAtClosing',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Seller credits to the buyer at closing',
        label: 'Seller credits to the buyer at closing',
        value: renderValueOrNothing(contractDetails?.sellerCreditsToBuyerAtClosing),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerFinancingType',
          type: 'select',
        },
        isContractDetailsField: true,
        name: 'Buyer Financing Type',
        label: 'Buyer Financing Type',
        value: renderValueOrNothing(
          contractDetails?.buyerFinancingType
            ? E.BuyerFinancingType[contractDetails.buyerFinancingType as E.BuyerFinancingTypeKey]
            : '',
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
        fieldOptions: Object.keys(E.BuyerFinancingType),
        fieldLabels: Object.values(E.BuyerFinancingType),
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerFinancingDescription',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Other Buyer Financing Type',
        label: 'Other Buyer Financing Type',
        value: renderValueOrNothing(contractDetails?.buyerFinancingDescription),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'earnestMoneyDepositReceived',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Earnest Money Deposit Received',
        label: 'Earnest Money Deposit Received',
        value: parseBooleanValue(contractDetails?.earnestMoneyDepositReceived),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'earnestMoneyDepositAmount',
          type: 'currency',
        },
        isContractDetailsField: true,
        name: 'Earnest Money Deposit Amount',
        label: 'Earnest Money Deposit Amount',
        value: renderCurrency(contractDetails?.earnestMoneyDepositAmount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'earnestMoneyDepositDeadline',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Earnest Money Deposit Deadline',
        label: 'Earnest Money Deposit Deadline',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.earnestMoneyDepositDeadline, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerConcessions',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Concessions",
        label: "Seller's Concessions",
        value: renderValueOrNothing(contractDetails?.sellerConcessions),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'homeWarrantyPayment',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Paying For Home Warranty',
        label: 'Paying For Home Warranty',
        value: parseBooleanValue(contractDetails?.homeWarrantyPayment),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'homeWarrantyPaymentAmount',
          type: 'currency',
        },
        isContractDetailsField: true,
        name: 'Home Warranty Payment Amount',
        label: 'Home Warranty Payment Amount',
        value: renderCurrency(contractDetails?.homeWarrantyPaymentAmount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'acceptsBackupOffers',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Backup offers',
        label: 'Continue Showing For Backup Offers',
        value: parseBooleanValue(contractDetails?.acceptsBackupOffers),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'notes',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Other Notes or Terms',
        label: 'Other Notes or Terms',
        value: renderValueOrNothing(contractDetails?.notes),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'escrowDetails',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Escrow Details',
        label: 'Escrow Details',
        value: renderValueOrNothing(contractDetails?.escrowDetails),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'fullyPaid',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Fully Paid',
        label: 'Fully Paid',
        value: parseBooleanValue(contractDetails?.fullyPaid),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Basics',
        defaultValue: '',
      },
    ],
    [contractDetails, listing, closingDateMlsValue],
  )

  const contractStatusFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'contractStatus',
          type: 'select',
        },
        isContractDetailsField: true,
        name: 'Contract Status',
        label: 'Contract Status',
        value: renderValueOrNothing(
          contractDetails?.contractStatus
            ? E.ContractStatus[contractDetails?.contractStatus as E.ContractStatusEnumKey]
            : '',
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        fieldOptions: Object.keys(E.ContractStatus),
        fieldLabels: Object.values(E.ContractStatus),
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'optionExpirationDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Option Expiration Date',
        label: 'Option Expiration Date',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.optionExpirationDate, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'optionPaymentAmount',
          type: 'currency',
        },
        isContractDetailsField: true,
        name: 'Option Payment Amount',
        label: 'Option Payment Amount',
        value: renderCurrency(contractDetails?.optionPaymentAmount),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'attorneyReviewExpirationDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Attorney Review Expiration Date',
        label: 'Attorney Review Expiration Date',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.attorneyReviewExpirationDate, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'mortgageContingency',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Mortgage Contingency',
        label: 'Mortgage Contingency',
        value: parseBooleanValue(contractDetails?.mortgageContingency),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'mortgageContingencyExpirationDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Mortgage Contingency Expiration Date',
        label: 'Mortgage Contingency Expiration Date',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.mortgageContingencyExpirationDate, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'inspectionContingency',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Inspection Contingency',
        label: 'Inspection Contingency',
        value: parseBooleanValue(contractDetails?.inspectionContingency),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'inspectionContingencyExpirationDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Inspection Contingency Expiration Date',
        label: 'Inspection Contingency Expiration Date',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.inspectionContingencyExpirationDate, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'otherContingency',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Other Contingency',
        label: 'Other Contingency',
        value: parseBooleanValue(contractDetails?.otherContingency),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'otherContingencyDescription',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Other Contingency Description',
        label: 'Other Contingency Description',
        value: renderValueOrNothing(contractDetails?.otherContingencyDescription),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'otherContingencyExpirationDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Other Contingency Expiration Date',
        label: 'Other Contingency Expiration Date',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.otherContingencyExpirationDate, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'dueDiligencePeriod',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Due Diligence Period',
        label: 'Due Diligence Period',
        value: parseBooleanValue(contractDetails?.dueDiligencePeriod),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'dueDiligencePeriodExpirationDate',
          type: 'date',
        },
        isContractDetailsField: true,
        name: 'Due Diligence Period Expiration Date',
        label: 'Due Diligence Period Expiration Date',
        value: renderValueOrNothing(
          parseTzDate(contractDetails?.dueDiligencePeriodExpirationDate, listing.timeZone),
        ),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Contract Status',
        defaultValue: moment().format('DD/MM/YYYY'),
      },
    ],
    [contractDetails, listing],
  )

  const buyerInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Buyer Name',
        label: 'Buyer Name',
        value: renderValueOrNothing(contractDetails?.buyerName),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Buyer Phone',
        label: 'Buyer Phone',
        value: renderPhoneNumber(contractDetails?.buyerPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Buyer Email',
        label: 'Buyer Email',
        value: renderValueOrNothing(contractDetails?.buyerEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Info',
        defaultValue: '',
      },
    ],
    [contractDetails, listing],
  )

  const buyerAgentInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerUsingAgent',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: "Buyer Represented By Buyer's Agent",
        label: "Buyer Represented By Buyer's Agent",
        value: parseBooleanValue(contractDetails?.buyerUsingAgent),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAgentName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Buyer Agent Name',
        label: 'Buyer Agent Name',
        value: renderValueOrNothing(contractDetails?.buyerAgentName),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAgentPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Buyer Agent Cell Phone',
        label: 'Buyer Agent Cell Phone',
        value: renderPhoneNumber(contractDetails?.buyerAgentPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAgentOfficePhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Buyer Agent Office Phone',
        label: 'Buyer Agent Office Phone',
        value: renderPhoneNumber(contractDetails?.buyerAgentOfficePhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAgentEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Buyer Agent Email',
        label: 'Buyer Agent Email',
        value: renderValueOrNothing(contractDetails?.buyerAgentEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAgentBrokerageName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Buyer Agent Brokerage',
        label: 'Buyer Agent Brokerage',
        value: renderValueOrNothing(contractDetails?.buyerAgentBrokerageName),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAgentCommission',
          type: 'number',
        },
        isContractDetailsField: true,
        name: 'Buyer Agent Commission',
        label: 'Buyer Agent Commission',
        value: addPercent(contractDetails?.buyerAgentCommission),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
        max: 999.99,
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'commissionModifications',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Commission Modifications (Additional commission or other changes)',
        label: 'Commission Modifications (Additional commission or other changes)',
        value: renderValueOrNothing(contractDetails?.commissionModifications),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Buyer Agent Info',
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  // all Seller's Title Company group will not reset except escrow number
  const sellersTitleCompanyInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'escrowNumber',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Escrow Number',
        label: 'Escrow Number',
        value: renderValueOrNothing(contractDetails?.escrowNumber),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Title Company",
        label: "Seller's Title Company",
        value: renderValueOrNothing(contractDetails?.sellerTitleAgencyName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyEscrowAgentName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Title / Escrow Agent",
        label: "Seller's Title / Escrow Agent",
        value: renderValueOrNothing(contractDetails?.sellerTitleAgencyEscrowAgentName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyEscrowAgentPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Seller's Title / Escrow Phone",
        label: "Seller's Title / Escrow Phone",
        value: renderPhoneNumber(contractDetails?.sellerTitleAgencyEscrowAgentPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyEscrowAgentEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Title / Escrow Email",
        label: "Seller's Title / Escrow Email",
        value: renderValueOrNothing(contractDetails?.sellerTitleAgencyEscrowAgentEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyClosingAgentName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Closing Agent or Assistant Name',
        label: 'Closing Agent or Assistant Name',
        value: renderValueOrNothing(contractDetails?.sellerTitleAgencyClosingAgentName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyClosingAgentPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Closing Agent or Assistant Phone',
        label: 'Closing Agent or Assistant Phone',
        value: renderPhoneNumber(contractDetails?.sellerTitleAgencyClosingAgentPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerTitleAgencyClosingAgentEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Closing Agent or Assistant Email',
        label: 'Closing Agent or Assistant Email',
        value: renderValueOrNothing(contractDetails?.sellerTitleAgencyClosingAgentEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Title Company",
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  const buyersTitleCompanyInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerUsingTitleAgency',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Buyer Is Using Different Title Company',
        label: 'Buyer Is Using Different Title Company',
        value: parseBooleanValue(contractDetails?.buyerUsingTitleAgency),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Title Agency',
        label: 'Title Agency',
        value: renderValueOrNothing(contractDetails?.buyerTitleAgencyName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyContactName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Title Agency Contact Name',
        label: 'Title Agency Contact Name',
        value: renderValueOrNothing(contractDetails?.buyerTitleAgencyContactName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyContactPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Title Agency Contact Phone',
        label: 'Title Agency Contact Phone',
        value: renderPhoneNumber(contractDetails?.buyerTitleAgencyContactPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyContactEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Title Agency Contact Email',
        label: 'Title Agency Contact Email',
        value: renderValueOrNothing(contractDetails?.buyerTitleAgencyContactEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyContactType',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Title Agency Contact Type',
        label: 'Title Agency Contact Type',
        value: renderValueOrNothing(contractDetails?.buyerTitleAgencyContactType),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyClosingAgentName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Title Closing Agent or Assistant Name',
        label: 'Title Closing Agent or Assistant Name',
        value: renderValueOrNothing(contractDetails?.buyerTitleAgencyClosingAgentName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyClosingAgentPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Title Closing Agent or Assistant Phone',
        label: 'Title Closing Agent or Assistant Phone',
        value: renderPhoneNumber(contractDetails?.buyerTitleAgencyClosingAgentPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerTitleAgencyClosingAgentEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Title Closing Agent or Assistant Email',
        label: 'Title Closing Agent or Assistant Email',
        value: renderValueOrNothing(contractDetails?.buyerTitleAgencyClosingAgentEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Title Company",
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  const sellersAttorneyInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerUsingAttorney',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Seller is Using Attorney',
        label: 'Seller is Using Attorney',
        value: parseBooleanValue(contractDetails?.sellerUsingAttorney),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyFirmName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Firm",
        label: "Seller's Attorney Firm",
        value: renderValueOrNothing(contractDetails?.sellerAttorneyFirmName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Name",
        label: "Seller's Attorney Name",
        value: renderValueOrNothing(contractDetails?.sellerAttorneyName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Email",
        label: "Seller's Attorney Email",
        value: renderValueOrNothing(contractDetails?.sellerAttorneyEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Phone Number",
        label: "Seller's Attorney Phone Number",
        value: renderPhoneNumber(contractDetails?.sellerAttorneyPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyFirmPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Firm Phone Number",
        label: "Seller's Attorney Firm Phone Number",
        value: renderPhoneNumber(contractDetails?.sellerAttorneyFirmPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyClosingAssistantName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Closing Assistant Name",
        label: "Seller's Attorney Closing Assistant Name",
        value: renderValueOrNothing(contractDetails?.sellerAttorneyClosingAssistantName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyClosingAssistantPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Closing Assistant Phone Number",
        label: "Seller's Attorney Closing Assistant Phone Number",
        value: renderPhoneNumber(contractDetails?.sellerAttorneyClosingAssistantPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'sellerAttorneyClosingAssistantEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Seller's Attorney Closing Assistant Email",
        label: "Seller's Attorney Closing Assistant Email",
        value: renderValueOrNothing(contractDetails?.sellerAttorneyClosingAssistantEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Seller's Attorney Info",
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  const buyersAttorneyInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerUsingAttorney',
          type: 'switch',
        },
        isContractDetailsField: true,
        name: 'Buyer is Using Attorney',
        label: 'Buyer is Using Attorney',
        value: parseBooleanValue(contractDetails?.buyerUsingAttorney),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyFirmName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Firm",
        label: "Buyer's Attorney Firm",
        value: renderValueOrNothing(contractDetails?.buyerAttorneyFirmName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Name",
        label: "Buyer's Attorney Name",
        value: renderValueOrNothing(contractDetails?.buyerAttorneyName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Email",
        label: "Buyer's Attorney Email",
        value: renderValueOrNothing(contractDetails?.buyerAttorneyEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Phone Number",
        label: "Buyer's Attorney Phone Number",
        value: renderPhoneNumber(contractDetails?.buyerAttorneyPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyFirmPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Firm Phone Number",
        label: "Buyer's Attorney Firm Phone Number",
        value: renderPhoneNumber(contractDetails?.buyerAttorneyFirmPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyClosingAssistantName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Closing Assistant Name",
        label: "Buyer's Attorney Closing Assistant Name",
        value: renderValueOrNothing(contractDetails?.buyerAttorneyClosingAssistantName),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyClosingAssistantPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Closing Assistant Phone Number",
        label: "Buyer's Attorney Closing Assistant Phone Number",
        value: renderPhoneNumber(contractDetails?.buyerAttorneyClosingAssistantPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'buyerAttorneyClosingAssistantEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: "Buyer's Attorney Closing Assistant Email",
        label: "Buyer's Attorney Closing Assistant Email",
        value: renderValueOrNothing(contractDetails?.buyerAttorneyClosingAssistantEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: "Buyer's Attorney or Closing Attorney Info",
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  const lenderInfoFields = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Lender Name',
        label: 'Lender Name',
        value: renderValueOrNothing(contractDetails?.lenderName),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Lender Phone',
        label: 'Lender Phone',
        value: renderPhoneNumber(contractDetails?.lenderPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Lender Email',
        label: 'Lender Email',
        value: renderValueOrNothing(contractDetails?.lenderEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderCompanyName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Lender Company Name',
        label: 'Lender Company Name',
        value: renderValueOrNothing(contractDetails?.lenderCompanyName),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderCompanyPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Lender Company Phone',
        label: 'Lender Company Phone',
        value: renderPhoneNumber(contractDetails?.lenderCompanyPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderCompanyEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Lender Company Email',
        label: 'Lender Company Email',
        value: renderValueOrNothing(contractDetails?.lenderCompanyEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderCompanyAssistantName',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Lender Company Assistant Name',
        label: 'Lender Company Assistant Name',
        value: renderValueOrNothing(contractDetails?.lenderCompanyAssistantName),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderCompanyAssistantPhone',
          type: 'phonenumber',
        },
        isContractDetailsField: true,
        name: 'Lender Company Assistant Phone',
        label: 'Lender Company Assistant Phone',
        value: renderPhoneNumber(contractDetails?.lenderCompanyAssistantPhone),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'lenderCompanyAssistantEmail',
          type: 'text',
        },
        isContractDetailsField: true,
        name: 'Lender Company Assistant Email',
        label: 'Lender Company Assistant Email',
        value: renderValueOrNothing(contractDetails?.lenderCompanyAssistantEmail),
        listingFlowStep: 'Services',
        listingFlowSubStep: 'Lender Info',
        defaultValue: '',
      },
    ],
    [contractDetails],
  )

  const escrowFields: IEscrowFields = {
    generalInfoFields,
    contractStatusFields,
    buyerInfoFields,
    buyerAgentInfoFields,
    sellersTitleCompanyInfoFields,
    buyersTitleCompanyInfoFields,
    sellersAttorneyInfoFields,
    buyersAttorneyInfoFields,
    lenderInfoFields,
  }

  return escrowFields
}
