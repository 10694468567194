import { useMemo } from 'react'

import { useStatusByName } from 'services/hooks'
import usePricingPackages from 'services/swr/usePricingPackages'

import { includesAny, intersection, without } from 'utils/array'

import * as T from 'types'
import * as E from 'types/enums'

export const useSpecialCaseStatuses = () => {
  const { isInitialized: statusByNameInitialized, statusByName } = useStatusByName()

  const statuses = useMemo(() => {
    const result = {
      amendmentNeeded: statusByName['Amendment Needed'],
      draft: statusByName.Draft,
      listingDetailsUpdated: statusByName['Listing Details Updated'],
      publishedOnMls: statusByName['Published on MLS'],
      temporaryHold: statusByName['Temporary Hold'],
    }

    if (statusByNameInitialized && !Object.values(result).every(Boolean)) {
      throw new Error(
        "at least one of the following statuses don't exist: Listing Details Updated, Published on MLS, Temporary Hold",
      )
    }

    return result
  }, [statusByName, statusByNameInitialized])

  return { isInitialized: statusByNameInitialized, ...statuses }
}

type IUseAndQueryExpression = ({
  currentMode,
  selectedServiceNames,
  selectedStatusIds,
}: {
  currentMode: E.ListingStatusGroupings | T.Nullish
  selectedServiceNames: string[]
  selectedStatusIds: string[]
}) => object[]

export const useAndQueryExpression: IUseAndQueryExpression = arg => {
  const { currentMode, selectedServiceNames, selectedStatusIds } = arg

  const {
    amendmentNeeded,
    draft,
    isInitialized: statusesInitialized,
    listingDetailsUpdated,
    publishedOnMls,
    temporaryHold,
  } = useSpecialCaseStatuses()

  const { pricingPackagesInitialized, pricingPackagesByName, pricingPackageNames }
    = usePricingPackages()

  const { Platinum, Premium, Basic } = pricingPackagesByName

  const isInitialized = statusesInitialized && pricingPackagesInitialized

  const result = useMemo(() => {
    const andQueryExpression: object[] = []

    if (!isInitialized) return andQueryExpression

    const publishedAndTemporaryHold = [publishedOnMls._id, temporaryHold._id]

    const platinumIds = Platinum.map(pkg => pkg._id)
    const premiumIds = Premium.map(pkg => pkg._id)
    const basicIds = Basic.map(pkg => pkg._id)

    const selectedServiceIds = selectedServiceNames
      .map(name => {
        if (name === 'Premium') return premiumIds

        if (name === 'Platinum') return platinumIds

        return basicIds
      })
      .flat()

    if (
      currentMode === E.ListingStatusGroupings.newListings
      && includesAny(selectedStatusIds, [listingDetailsUpdated._id, amendmentNeeded._id])
    ) {
      andQueryExpression.push({
        $or: [
          {
            listingStatus: {
              $in: without(selectedStatusIds, [listingDetailsUpdated._id, amendmentNeeded._id]),
            },
          },
          {
            'listingStatus': listingDetailsUpdated._id,
            'mlsList.status': { $nin: publishedAndTemporaryHold },
          },
          {
            'listingStatus': amendmentNeeded._id,
            'mlsList.status': { $nin: publishedAndTemporaryHold },
          },
        ],
      })
    } else if (
      currentMode === E.ListingStatusGroupings.openTasks
      && includesAny(selectedStatusIds, [listingDetailsUpdated._id, amendmentNeeded._id])
    ) {
      andQueryExpression.push({
        listingStatus: { $in: selectedStatusIds },
        firstPublishedAt: { $exists: true, $ne: null },
      })
    }

    if (
      currentMode === E.ListingStatusGroupings.newListings
      && selectedStatusIds.includes(draft._id)
    ) {
      andQueryExpression.push({
        $or: [
          {
            listingStatus: { $in: without(selectedStatusIds, [draft._id]) },
          },
          {
            listingStatus: draft._id,
            pricingPackage: {
              $in: intersection(selectedServiceIds, [...platinumIds, ...premiumIds]),
            },
          },
        ],
      })
    }

    return andQueryExpression
  }, [currentMode, isInitialized, selectedStatusIds, selectedServiceNames, pricingPackageNames])

  return result
}
