import { useEffect, useState } from 'react'
import { responseInterface } from 'swr'

// simple helper to be used to indicate that the swr hook has returned data for the first time
// - data flow only moves from false -> true, will be set to true only once when response.data comes in
// - `any` typings are purposeful: this fn can work with every type
//
// NB: you typically don't need this kind of logic, and can just rely on whether return data exists
//     or not. use this if you need to do some setup work only 1 time the first time data is available
//     to your component
export const useInitialized = (response: responseInterface<any, any>) => {
  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (!initialized && !!response?.data) setInitialized(true)
  }, [response])

  return initialized
}
