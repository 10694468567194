/* eslint-disable no-alert */
import { red, yellow } from '@ant-design/colors'
import { UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Col, Input, Modal, Row, Typography, Upload } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CopyCheckboxes, CustomButton, LoadingSpinner, Spacer } from 'components'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

import { addDocumentToListing } from 'services/documents'
import { previewInvoice, sendInvoice } from 'services/hellosign'
import { useEscrowFields } from 'services/hooks/listingDetails'
import { useOrdersByListingId } from 'services/swr'
import { useContractDetails } from 'services/swr/useContractDetails'
import { useDocuments } from 'services/swr/useDocuments'
import { useListing } from 'services/swr/useListing'

import { validateEmail } from 'utils/stringValidation'

import * as T from 'types'

import FieldListView from './FieldListView'

const { Text } = Typography
const { Dragger } = Upload

const FILE_UPLOAD = 'fileUpload'
const RESET_ESCROW = 'resetEscrow'
const SEND_INVOICE = 'sendInvoice'

enum EscrowFieldTypes {
  generalInfoFields = 'Contract Basics',
  contractStatusFields = 'Contract Status',
  sellersTitleCompanyInfoFields = "Seller's Title Company",
  buyersTitleCompanyInfoFields = "Buyer's Title Company",
  buyerInfoFields = 'Buyer Info',
  buyerAgentInfoFields = 'Buyer Agent Info',
  sellersAttorneyInfoFields = "Seller's Attorney Info",
  buyersAttorneyInfoFields = "Buyer's Attorney or Closing Attorney Info",
  lenderInfoFields = 'Lender Info',
}

const UploadContainer = styled.div`
  width: 100%;
`

const UploadDragAndDrop = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    height: unset;
  }
`
interface IProps {
  listing: T.IListing
  contractDetails: T.IContractDetails
  listView: boolean
}

const Escrow: FunctionComponent<IProps> = ({ listing, contractDetails, listView }) => {
  const { mutate } = useListing()
  const { update, resetEscrow } = useContractDetails(listing._id)
  const { orders } = useOrdersByListingId(listing._id)
  const { listingDocuments } = useDocuments(listing._id)

  const [alert, setAlert] = useState<T.IAlert | null>(null)
  const handleOnAlertClose = () => setAlert(null)

  const [error, setError] = useState('')

  const [updating, setUpdating] = useState('')

  const [resetEscrowModalVisible, setResetEscrowModalVisible] = useState(false)
  const closeResetEscrowModal = () => setResetEscrowModalVisible(false)
  const openResetEscrowModal = () => setResetEscrowModalVisible(true)

  const [sendInvoiceModalVisible, setSendInvoiceModalVisible] = useState(false)
  const closeInvoiceModal = () => setSendInvoiceModalVisible(false)
  const openInvoiceModal = () => setSendInvoiceModalVisible(true)

  // usually resetting/closing escrow happens when a buyer falls through, and typically the seller's
  // info won't change between escrows, so preserve this information by default
  const [escrowSectionsToPreserve, setEscrowSectionsToPreserve] = useState<string[]>([
    'sellersTitleCompanyInfoFields',
    'sellersAttorneyInfoFields',
  ])

  const handleFileUpload = async (
    options: any /* Antd's RcFile typings seem to be incorrect */,
  ) => {
    const { file, onSuccess } = options

    setUpdating(FILE_UPLOAD)
    const uploadedDoc: T.IListingDocument[] = await addDocumentToListing(
      [file],
      listing._id,
      true,
      'Contract',
    )
    if (!uploadedDoc || !uploadedDoc.length) return setError('Failed uploading the document')

    mutate()
    setUpdating('')
    onSuccess('OK')
  }

  const handleEmailInputOnChange = (evt: React.ChangeEvent) => {
    const input = evt.target as HTMLInputElement
    if (alert?.type === 'error') setAlert(null)

    const emailFieldUpdate = { [input.name]: input.value } as Partial<T.IContractDetails>

    const validEmail = validateEmail(input.value)
    if (validEmail) update({ id: contractDetails.id, updates: emailFieldUpdate })
  }

  const handleEmailInputOnBlur = (evt: React.FocusEvent) => {
    const input = evt.target as HTMLInputElement
    const validEmail = validateEmail(input.value)

    if (!validEmail) {
      setAlert({ type: 'error', message: 'Not a valid email address form.' })
    }
  }

  const handlePreviewInvoice = async () => {
    if (!orders) return

    const [, invoiceError] = await previewInvoice(listing)

    if (invoiceError) {
      setAlert({ type: 'error', message: 'There was an error while downloading the invoice data.' })
    }
  }

  const handleSendInvoice = async () => {
    setUpdating(SEND_INVOICE)
    const validateAndSendInvoice = async () => {
      if (!orders) {
        setAlert({ type: 'error', message: 'Not enough information to send an invoice.' })
        return
      }

      if (
        !contractDetails?.sellerTitleAgencyEscrowAgentEmail
        && !contractDetails?.sellerTitleAgencyClosingAgentEmail
      ) {
        setAlert({ type: 'error', message: 'Email address is missing.' })
        return
      }

      const [invoiceData, sendInvoiceError] = await sendInvoice(listing)

      if (sendInvoiceError) {
        setAlert({ type: 'error', message: 'There was an error while sending the invoice.' })
        return
      }

      if (invoiceData) {
        setAlert({ type: 'success', message: 'Invoice was successfully sent!' })
        setSendInvoiceModalVisible(false)
      }
    }

    await validateAndSendInvoice()
    setUpdating('')
  }

  const escrowFields = useEscrowFields(contractDetails, listing)

  const {
    generalInfoFields,
    contractStatusFields,
    buyerInfoFields,
    buyerAgentInfoFields,
    sellersTitleCompanyInfoFields,
    buyersTitleCompanyInfoFields,
    sellersAttorneyInfoFields,
    buyersAttorneyInfoFields,
    lenderInfoFields,
  } = escrowFields

  const handleResetEscrow = async () => {
    if (!contractDetails || !listing) return

    const escrowInfoToPreserve: Partial<T.IContractDetails> = escrowSectionsToPreserve.reduce(
      (acc, curr) => {
        const sectionData: Partial<T.IContractDetails> = {}

        const sectionKeys = escrowFields[curr]
          .map(field => field.fieldId?.name)
          .filter(Boolean) as string[]
        sectionKeys.forEach(key => {
          const value = contractDetails[key]
          if (value) sectionData[key] = value
        })
        return { ...acc, ...sectionData }
      },
      {},
    )

    setUpdating(RESET_ESCROW)
    const contractDetailsId = contractDetails.id
    const [data, err] = await resetEscrow({ contractDetailsId, escrowInfoToPreserve })

    if (data) {
      setAlert({ type: 'success', message: 'Escrow successfully reset.' })
      closeResetEscrowModal()
    }

    if (err.message) setAlert({ type: 'error', message: 'Failed to cancel escrow.' })

    setUpdating('')
  }

  const contractDocument = listingDocuments?.find(
    (document: T.IListingDocument) => document.type === 'Contract' && !document.archived,
  )

  const buyerField = buyerInfoFields.find(buyerInfoField => buyerInfoField.name === 'Buyer Email')

  const isBuyerAgentField = ({ name }: T.IMlsField) => name === 'Buyer Agent Email'
  const buyerAgentField = buyerAgentInfoFields.find(isBuyerAgentField)

  const isAttorneyEscrowAgentField = ({ name }: T.IMlsField) =>
    name === 'Attorney or Escrow Agent Email'

  const attorneyOrEscrowAgentEmail = sellersTitleCompanyInfoFields.find(isAttorneyEscrowAgentField)

  const isClosingAgentField = ({ name }: T.IMlsField) => name === 'Closing Agent or Assistant Email'
  const closingAgentOrAssistantEmail = sellersTitleCompanyInfoFields.find(isClosingAgentField)

  const isTitleAgencyField = ({ name }: T.IMlsField) => name === 'Title Agency Contact Email'
  const titleAgencyContactEmail = sellersTitleCompanyInfoFields.find(isTitleAgencyField)

  const isTitleClosingAgentField = ({ name }: T.IMlsField) => name === 'Title Closing Agent Email'
  const titleClosingAgentEmail = sellersTitleCompanyInfoFields.find(isTitleClosingAgentField)

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {generalInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {contractStatusFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field as T.IMlsField}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {sellersTitleCompanyInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {buyersTitleCompanyInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {buyerInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {buyerAgentInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {sellersAttorneyInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {buyersAttorneyInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={12}
          lg={8}
        >
          {lenderInfoFields.map((field, index) => (
            <FieldListView
              key={field.name}
              field={field}
              index={index}
              listView={listView}
            />
          ))}
        </Col>
        <Row
          align="middle"
          gutter={[10, 10]}
        >
          <Col>
            <CustomButton
              backgroundColor={red[5]}
              backgroundColorOnHover={red[6]}
              loading={updating === RESET_ESCROW}
              onClick={openResetEscrowModal}
              disabled={!contractDetails && !contractDocument}
              custom
            >
              Cancel Escrow
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              backgroundColor={yellow[4]}
              backgroundColorOnHover={yellow[5]}
              loading={updating === SEND_INVOICE}
              onClick={openInvoiceModal}
              custom
            >
              Send Invoice
            </CustomButton>
          </Col>
          <Col
            xs={24}
            md={24}
            lg={24}
          >
            <Row>
              <UploadContainer>
                <Spacer size={5} />
                <UploadDragAndDrop
                  name="file"
                  customRequest={handleFileUpload}
                  disabled={updating === 'fileUpload'}
                  multiple
                >
                  {updating
                    ? (
                      <LoadingSpinner />
                    )
                    : (
                      <Button
                        icon={<UploadOutlined />}
                        disabled={updating === 'fileUpload'}
                      >
                      Upload Documents
                      </Button>
                    )}
                </UploadDragAndDrop>
              </UploadContainer>
              {error && (
                <Alert
                  message={error}
                  type="error"
                />
              )}
            </Row>
          </Col>
          <Col>
            <CopyCheckboxes
              copyOptions={[
                { label: 'Buyer', value: buyerField?.value ?? '' },
                { label: 'Buyer Agent', value: buyerAgentField?.value ?? '' },
                {
                  label: 'Attorney or Escrow Agent',
                  value: attorneyOrEscrowAgentEmail?.value ?? '',
                },
                {
                  label: 'Closing Agent or Assistant',
                  value: closingAgentOrAssistantEmail?.value ?? '',
                },
                { label: 'Title Agency Contact', value: titleAgencyContactEmail?.value ?? '' },
                { label: 'Title Closing Agent', value: titleClosingAgentEmail?.value ?? '' },
              ]}
              copyBtnText="Copy Emails"
              copyCompletedMsg="Copied Emails!"
            />
          </Col>
        </Row>
        <Spacer size={10} />
        {alert && (
          <Alert
            style={{ height: '32px' }}
            message={alert.message}
            type={alert.type}
            showIcon
            closable
            onClose={handleOnAlertClose}
          />
        )}
      </Row>
      <Modal
        title="Cancel Escrow"
        visible={resetEscrowModalVisible}
        onCancel={closeResetEscrowModal}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            onClick={handleResetEscrow}
            loading={updating === RESET_ESCROW}
          >
            Cancel Escrow
          </Button>,
        ]}
      >
        <div>
          Select which sections should be preserved in the new escrow after this one is closed:
        </div>
        <Spacer />
        {Object.entries(EscrowFieldTypes).map(([objectName, displayName]) => {
          const selectCheckbox = (event: CheckboxChangeEvent) => {
            const { checked } = event.target

            if (checked) {
              return setEscrowSectionsToPreserve([...escrowSectionsToPreserve, objectName])
            }

            setEscrowSectionsToPreserve(
              escrowSectionsToPreserve.filter(section => section !== objectName),
            )
          }

          return (
            <Row key={objectName}>
              <Checkbox
                onChange={selectCheckbox}
                checked={escrowSectionsToPreserve.includes(objectName)}
              >
                {displayName}
              </Checkbox>
            </Row>
          )
        })}
        {alert?.type === 'error' && (
          <>
            <Spacer size={10} />
            <Alert
              message={alert.message}
              type={alert.type}
              showIcon
            />
          </>
        )}
      </Modal>
      <Modal
        title="Send Invoice"
        visible={sendInvoiceModalVisible}
        onCancel={closeInvoiceModal}
        destroyOnClose
        footer={[
          <Button onClick={handlePreviewInvoice}>Preview Invoice</Button>,
          <Button
            type="primary"
            disabled={
              !contractDetails?.sellerTitleAgencyEscrowAgentEmail
              && !contractDetails?.sellerTitleAgencyClosingAgentEmail
            }
            onClick={handleSendInvoice}
            loading={updating === SEND_INVOICE}
          >
            Send Invoice
          </Button>,
        ]}
      >
        <Text>Attorney or Escrow Agent Email:</Text>
        <Input
          name="sellerTitleAgencyEscrowAgentEmail"
          placeholder="Enter attorney or escrow agent email"
          defaultValue={contractDetails?.sellerTitleAgencyEscrowAgentEmail || ''}
          onChange={handleEmailInputOnChange}
          onBlur={handleEmailInputOnBlur}
        />
        <Spacer size={10} />
        <Text>Closing Agent or Assistant Email:</Text>
        <Input
          name="sellerTitleAgencyClosingAgentEmail"
          placeholder="Enter closing agent or assistant email"
          defaultValue={contractDetails?.sellerTitleAgencyClosingAgentEmail || ''}
          onChange={handleEmailInputOnChange}
          onBlur={handleEmailInputOnBlur}
        />
        {alert?.type === 'error' && (
          <>
            <Spacer size={10} />
            <Alert
              message={alert.message}
              type={alert.type}
              showIcon
            />
          </>
        )}
      </Modal>
    </>
  )
}

export default Escrow
