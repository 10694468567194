import { blue, red } from '@ant-design/colors'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { Alert, Button, DatePicker, Modal, Row, Switch, Upload } from 'antd'
import { Spacer } from 'components'
import Cookies from 'js-cookie'
import moment, { Moment } from 'moment'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'

import Anchor from 'components/Anchor'

import { trackBasicPackagePurchase } from 'services/analytics'
import { addDocumentToListing, removeDocument, updateDocument } from 'services/documents'
import { resetAgreement } from 'services/listings'
import { useListing, useUser } from 'services/swr'
import { useDocuments } from 'services/swr/useDocuments'

import { getAgreementOverride } from 'utils/listingHelpers'
import { setDefaultMomentTimezone } from 'utils/timezone'

import * as T from 'types'
import * as E from 'types/enums'

const AgreementDatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const UploadedAgreementLink = styled.div`
  display: flex;
  align-items: center;

  a {
    text-overflow: ellipsis;
  }
  a:hover {
    color: ${blue.primary};
  }
  svg {
    color: ${red.primary};
  }
`

const UploadContainer = styled.div`
  margin: 10px 0;
`

const OverrideInfoGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 900;
`

const Bold = styled.span`
  font-weight: 800;
`
interface IOverrideAgreementModalProps {
  listing: T.IListing
  setModalType: Dispatch<SetStateAction<E.AgreementSetting | undefined>>
  onCompletion?: (message: T.IAlert) => void
}

export const OverrideAgreementModal: FC<IOverrideAgreementModalProps> = ({
  listing,
  setModalType,
  onCompletion,
}) => {
  const { user } = useUser()
  const { update } = useListing()
  const { listingDocuments } = useDocuments(listing._id)
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)
  // Agreement Dates and Override Document
  const [signatureDate, setSignatureDate] = useState<Moment>(moment())
  const [goLiveDate, setGoLiveDate] = useState<Moment>(moment().add(2, 'days'))
  const [expirationDate, setExpirationDate] = useState<Moment>(moment().add(6, 'months'))

  const [agreementOverrideDocUploaded, setAgreementOverrideDocUploaded] = useState<
    T.IListingDocument | undefined
  >()

  const [signedByBroker, setSignedByBroker] = useState(false)

  const handleFileUpload = async (options: any) => {
    const { onSuccess, onError, file } = options

    if (!options || !listing._id || !user) {
      return onError('Missing a required data to upload the agreement.')
    }

    const uploadedDoc = await addDocumentToListing([file], listing._id, true, 'Agreement Override')
    const name = uploadedDoc[0].name
    const url = uploadedDoc[0].url
    if (!name || !url) return onError('Failed uploading the agreement.')

    const uploadedAgreement: T.IListingDocument = {
      ...uploadedDoc,
      name,
      url,
      uploadedAt: moment().format(),
      signedDate: signatureDate.format(),
      dateOfExpiration: expirationDate.format(),
      brokerSigned: signedByBroker,
    }
    setAgreementOverrideDocUploaded(uploadedAgreement)
    setError('')
    return onSuccess('Successfully uploaded the agreement.')
  }

  const onChangeSignatureDate = (date: Moment | null) => {
    if (!date) return

    setSignatureDate(date)
  }

  const onChangeGoLiveDate = (date: Moment | null) => {
    if (!date) return

    setGoLiveDate(date)
  }

  const onChangeExpirationDate = (date: Moment | null) => {
    if (!date) return

    setExpirationDate(date)
  }

  const onChangeSignedByBroker = (value: boolean) => setSignedByBroker(value)

  const handleSave = async () => {
    if (!agreementOverrideDocUploaded) return setError('Agreement Override Document is required')

    if (!signedByBroker) {
      return setError('Please confirm that override agreement has been signed by the broker')
    }

    setSaving(true)

    const agreementFields = {
      goLiveDate: goLiveDate.format(),
    }
    await updateDocument(
      {
        ...agreementOverrideDocUploaded,
        signedDate: signatureDate.format(),
        dateOfExpiration: expirationDate.format(),
        brokerSigned: signedByBroker,
      },
      listing._id,
    )
    await update(agreementFields)
    if (onCompletion) onCompletion({ message: 'Agreement Override Saved', type: 'success' })

    if (listing.pricingPackage?.name === E.PackageType.basic && !listing.analyticsTrackWasCalled) {
      trackBasicPackagePurchase(listing, listing.userId?.referrerParams)
      await update({ analyticsTrackWasCalled: true })
    }

    setSaving(false)
    setError('')
    setModalType(undefined)
  }

  const removeAgreementOverride = () => {
    if (agreementOverrideDocUploaded && agreementOverrideDocUploaded._id) {
      removeDocument(agreementOverrideDocUploaded._id, listing._id)
      setAgreementOverrideDocUploaded(undefined)
      setSignedByBroker(false)
    }
  }

  const handleCancel = () => setModalType(undefined)

  useEffect(() => {
    if (!listing) return

    setDefaultMomentTimezone(listing.timeZone)
  }, [listing])

  useEffect(() => {
    if (!listingDocuments) return

    const agreementOverrideDocument = getAgreementOverride(listingDocuments)

    if (agreementOverrideDocument?.url) {
      // when the file is uploaded, don't tick back the signedByBroker switch if it was set to true
      if (!signedByBroker) setSignedByBroker(!!agreementOverrideDocument.brokerSigned)

      setAgreementOverrideDocUploaded(agreementOverrideDocument)
      if (
        agreementOverrideDocument?.signedDate
        && moment(agreementOverrideDocument?.signedDate).isValid()
      ) {
        setSignatureDate(moment(agreementOverrideDocument?.signedDate))
      }

      if (
        agreementOverrideDocument?.dateOfExpiration
        && moment(agreementOverrideDocument?.dateOfExpiration).isValid()
      ) {
        setExpirationDate(moment(agreementOverrideDocument?.dateOfExpiration))
      } else {
        setExpirationDate(moment().add(6, 'months'))
      }

      if (listing.goLiveDate && moment(listing.goLiveDate).isValid()) {
        setGoLiveDate(moment(listing.goLiveDate))
      } else {
        setGoLiveDate(moment().add(2, 'days'))
      }
    }
  }, [listing, listingDocuments])

  const agreementLink = `
    ${process.env.API_URL}/amazons/agreementDocument/${
  listing._id
}?inline=true&access_token=${Cookies.get('token')}
  `

  return (
    <Modal
      title="Override Agreement"
      okText="Confirm"
      onOk={handleSave}
      onCancel={handleCancel}
      confirmLoading={saving}
      destroyOnClose
      visible
    >
      <OverrideInfoGridContainer>
        <div>
          <Subtitle>Address</Subtitle>
          <div>
            <Bold>Street Info:&nbsp;</Bold>
            {listing.street}
          </div>
          <div>
            <Bold>Unit Number:&nbsp;</Bold>
            {listing.aptNum || ''}
          </div>
          <div>
            <Bold>City:&nbsp;</Bold>
            {listing.city || ''}
          </div>
          <div>
            <Bold>State:&nbsp;</Bold>
            {listing.state || ''}
          </div>
          <div>
            <Bold>Zip:&nbsp;</Bold>
            {listing.zip || ''}
          </div>
        </div>
        <div>
          <Subtitle>Pricing</Subtitle>
          <div>
            <Bold>Listing Price:&nbsp;</Bold>
            {listing.price}
          </div>
          <div>
            <Bold>List Price Includes:&nbsp;</Bold>
            {listing.listPriceIncludes || ''}
          </div>
          <div>
            <Bold>List Price Excludes:&nbsp;</Bold>
            {listing.listPriceExcludes || ''}
          </div>
          <div>
            <Bold>Cooperating Commission:&nbsp;</Bold>
            {`${listing.commission}%` || ''}
          </div>
          <div>
            <Bold>Level Of Service:&nbsp;</Bold>
            {listing.pricingPackage.name || ''}
          </div>
        </div>
        <div>
          <Subtitle>
            Owners:&nbsp;
            {!listing.owners || !listing.owners.length ? 'not completed' : ''}
          </Subtitle>
          <div>
            <Bold>Owner Type:&nbsp;</Bold>
            {listing.ownerType || ''}
          </div>
          <div>
            <Bold>Company / Trust Name:&nbsp;</Bold>
            {listing.companyOrTrustName || ''}
          </div>
        </div>
      </OverrideInfoGridContainer>
      <Spacer />
      <AgreementDatesContainer>
        <div>
          <div>Signature Date</div>
          <DatePicker
            defaultValue={signatureDate}
            value={signatureDate}
            onChange={onChangeSignatureDate}
            format="MM/DD/YY"
          />
        </div>
        <div>
          <div>Go Live Date</div>
          <DatePicker
            defaultValue={goLiveDate}
            value={goLiveDate}
            onChange={onChangeGoLiveDate}
            format="MM/DD/YY"
          />
        </div>
        <div>
          <div>Expiration Date</div>
          <DatePicker
            defaultValue={expirationDate}
            value={expirationDate}
            onChange={onChangeExpirationDate}
            format="MM/DD/YY"
          />
        </div>
      </AgreementDatesContainer>
      <Spacer />
      <Row>
        <div>Has the broker signed the agreement?</div>
        <Spacer />
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={signedByBroker}
          onChange={onChangeSignedByBroker}
        />
      </Row>
      <UploadContainer>
        <Upload customRequest={handleFileUpload}>
          <Button
            icon={<UploadOutlined />}
            disabled={!!agreementOverrideDocUploaded}
          >
            Upload Agreement
          </Button>
        </Upload>
        <Spacer />
        {agreementOverrideDocUploaded?.url && (
          <UploadedAgreementLink>
            <Anchor
              href={agreementLink}
              target="_blank"
            >
              {agreementOverrideDocUploaded?.name}
            </Anchor>
            <DeleteOutlined onClick={removeAgreementOverride} />
          </UploadedAgreementLink>
        )}
      </UploadContainer>
      {error && (
        <Alert
          message={error}
          type="error"
        />
      )}
    </Modal>
  )
}

interface IResetAgreementModalProps {
  listing: T.IListing
  setModalType: Dispatch<SetStateAction<E.AgreementSetting | undefined>>
  onCompletion?: (message: T.IAlert) => void
}

export const ResetAgreementModal: FC<IResetAgreementModalProps> = ({
  listing,
  setModalType,
  onCompletion,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleSave = async () => {
    setConfirmLoading(true)
    const [, error] = await resetAgreement(listing._id)

    if (onCompletion) {
      if (error.message) onCompletion({ message: error.message, type: 'error' })
      else onCompletion({ message: 'Agreement Was Reset', type: 'success' })
    }

    setConfirmLoading(false)
    setModalType(undefined)
  }

  const handleCancel = () => setModalType(undefined)

  return (
    <Modal
      title="Override Agreement"
      okText="Confirm"
      onOk={handleSave}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      visible
    >
      <div>Are you sure you want to reset this agreement?</div>
    </Modal>
  )
}
