export interface colorsObject {
  [key: string]: string
}

const colors: colorsObject = {
  // transparent
  transparent: 'rgba(0,0,0,0)',

  // blacks
  black: '#000000',

  // blues
  blue: '#37a4e6',
  blue2: 'rgba(7, 191, 241, 0.25)',
  blue3: '#0046BE',
  blue4: '#1299EC',
  blue5: '#11B0E2',
  blue7: '#00ABDA',
  blue8: '#293189',

  // green

  // greys
  grey: '#F0F0F0',
  grey2: '#FAFAFA',
  grey3: '#E3E3E3',
  grey4: '#E2E2E2',
  grey5: '#F2F2F2',
  grey6: '#343434',
  grey7: '#141414',
  grey15: '#EEEEEE',

  // purple
  purple: '#293189',
  purple2: '#202879',
  purple3: '#202774',

  // red
  red: '#CC0033',
  red3: '#AB002B',

  // transparent blacks
  transparentBlackAlpha05: 'rgba(0, 0, 0, 0.05)',
  transparentBlackAlpha10: 'rgba(0, 0, 0, 0.1)',
  transparentBlackAlpha20: 'rgba(0, 0, 0, 0.2)',
  transparentBlackAlpha25: 'rgba(0, 0, 0, 0.25)',
  transparentBlackAlpha50: 'rgba(0, 0, 0, 0.5)',
  transparentBlackAlpha60: 'rgba(0, 0, 0, 0.6)',
  transparentBlackAlpha85: 'rgba(0, 0, 0, 0.85)',

  // transparent blues
  transparentBlueAlpha10: 'rgba(7, 191, 241, 0.1)',
  transparentBlueAlpha20: 'rgba(7, 191, 241, 0.2)',

  // transparent greys
  transparentBlueAlpha08: 'rgba(23, 43, 77, 0.08)',
  transparentBlueAlpha04: 'rgba(23, 43, 77, 0.04)',

  // transparent purple
  transparentPurpleAlpha20: 'rgba(41, 49, 137, 0.2)',
  transparentPurpleAlpha25: 'rgba(41, 49, 137, 0.25)',

  // transparent reds
  transparentRedAlpha10: 'rgba(204, 0, 51, 0.1)',

  // transparent whites
  transparentWhite: 'rgba(255,255,255,0)',
  transparentWhiteAlpha50: 'rgba(255, 255, 255, 0.5)',

  // whites
  white: '#FFFFFF',

  // yellows
  yellow: '#FFE400',
  yellow3: '#E1C900',
}

export default colors
