/* eslint-disable no-alert */
import { Col, Row } from 'antd'
import { FunctionComponent } from 'react'

import Spacer from 'components/Spacer'

import { useServiceFields } from 'services/hooks/listingDetails'

import * as T from 'types'

import AdditionalService from './AdditionalService'
import FieldListView from './FieldListView'
import { SubsectionTitle } from './Styles'

interface IProps {
  listing: T.IListing
  contractDetails: T.IContractDetails
  listView: boolean
}

const Services: FunctionComponent<IProps> = ({ listing, contractDetails, listView }) => {
  const {
    packageSelectedFields,
    additionalOrderServiceFields,
    totalForAccountFields,
    manualCheckPaymentsFields,
  } = useServiceFields(listing, contractDetails)

  return (
    <Row gutter={[15, 0]}>
      <Col
        xs={24}
        md={12}
        lg={8}
      >
        {packageSelectedFields.map((field, index) => (
          <FieldListView
            key={field.name}
            field={field}
            index={index}
            listView={listView}
          />
        ))}
        <Spacer />
        {manualCheckPaymentsFields.map((field, index) => (
          <FieldListView
            key={field.name}
            field={field}
            index={index}
            listView={listView}
            fieldSet={manualCheckPaymentsFields}
          />
        ))}
      </Col>
      <Col
        xs={24}
        md={12}
        lg={8}
      >
        <SubsectionTitle>
          <div>Additional Services</div>
        </SubsectionTitle>
        {additionalOrderServiceFields.length
          ? additionalOrderServiceFields.map(additionalServiceField => (
            <AdditionalService
              key={`${additionalServiceField.service.type.name}`}
              additionalServiceField={additionalServiceField}
              listing={listing}
              listView={listView}
            />
          ))
          : null}
      </Col>
      <Col
        xs={24}
        md={12}
        lg={8}
      >
        {totalForAccountFields.map((field, index) => (
          <FieldListView
            key={field.name}
            field={field}
            index={index}
            listView={listView}
            fieldSet={totalForAccountFields}
          />
        ))}
      </Col>
    </Row>
  )
}

export default Services
