/* eslint-disable no-alert */
import { Row } from 'antd'
import React, { FunctionComponent } from 'react'

import * as T from 'types'

import GroupedFieldsSection from './GroupedFieldsSection'

interface IProps {
  groupedFields: T.IMlsField[][]
  name: string
  listView: boolean
}

const Description: FunctionComponent<IProps> = ({ groupedFields, name, listView }) => (
  <Row gutter={[15, 15]}>
    <GroupedFieldsSection
      groupedFields={groupedFields}
      name={name}
      listView={listView}
      colMd={12}
      colLg={12}
    />
  </Row>
)

export default Description
