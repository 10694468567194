import { Alert, AlertProps, Button, Col, Modal, RadioChangeEvent, Row, Space } from 'antd'
import { CustomRadioGroup, Spacer } from 'components'
import dayjs from 'dayjs'
import moment, { Moment } from 'moment-timezone'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useListing } from 'services/swr'

import { hasOpenHouses } from 'utils/listingHelpers'
import { themeFontSize } from 'utils/styles'
import { applyDateWithTimezone, calculateTimeWithTimezone } from 'utils/timezone'

import * as T from 'types'

import { SubsectionTitle } from '../Styles'
import TableView from '../TableView'
import OpenHouseModal from './Modal'
import { StyledText } from './Shared'
import getColumns from './getColumns'
import { IDatesUpdating, IModalShowing, IShowing } from './types'

const AlertMessage = styled.div`
  padding-top: 2px; /* vertical font fix */
  ${themeFontSize}
`

interface IAlert {
  message: string
  type: AlertProps['type']
}

interface IGetMomentOrNull {
  (date: string | undefined, timezone: string): Moment | null
}

const getMomentOrNull: IGetMomentOrNull = (date, timezone) =>
  date ? moment(date).tz(timezone) : null

interface IConvertModalToShowing {
  (timezone: string, openHouseDate?: T.IOpenHouseDate): IModalShowing
}

const convertToModalShowing: IConvertModalToShowing = (timezone, openHouseDate) => ({
  date: getMomentOrNull(openHouseDate?.start, timezone),
  endTime: getMomentOrNull(openHouseDate?.end, timezone),
  startTime: getMomentOrNull(openHouseDate?.start, timezone),
  isCancelled: openHouseDate?.cancelled || false,
  isVirtual: openHouseDate?.virtual || false,
  virtualUrl: openHouseDate?.virtualUrl || '',
})

enum FilterStatuses {
  ShowAll,
  ShowIncomplete,
  ShowUpcoming,
}

interface IProps {
  listing: T.IListing
}

const OpenHouse: FunctionComponent<IProps> = ({ listing }) => {
  const { update } = useListing()

  const [datesUpdating, setDatesUpdating] = useState<IDatesUpdating>({})
  const [filterStatus, setFilterStatus] = useState(FilterStatuses.ShowIncomplete)
  const [showOpenHouseModal, setShowOpenHouseModal] = useState(false)
  const [openHouseDate, setOpenHouseDate] = useState<T.IOpenHouseDate>()

  const [modalShowing, setModalShowing] = useState(
    convertToModalShowing(listing.timeZone, openHouseDate),
  )

  const [modalErrorMessage, setModalErrorMessage] = useState('')
  const [alert, setAlert] = useState<IAlert>()
  const [confirmDeleteForShowing, setConfirmDeleteForShowing] = useState<IShowing>()

  const clearConfirmDeleteForShowing = () => setConfirmDeleteForShowing(undefined)
  const clearAlert = () => setAlert(undefined)

  const setOpenHouseDateAndModalState = (newOpenHouseDate: T.IOpenHouseDate | undefined) => {
    setOpenHouseDate(newOpenHouseDate)
    const showing = convertToModalShowing(listing.timeZone, newOpenHouseDate)
    setModalShowing(showing)
    setModalErrorMessage('')
  }

  const handleEditInDashboard = () => {
    if (typeof window === 'undefined' || !window || !window.getSelection()) return

    window.open(`${process.env.SITE_URL}/listing/${listing._id}/dashboard/showings`, '_blank')
  }

  const handleAddOpenHouse = () => {
    setOpenHouseDateAndModalState(undefined)
    setShowOpenHouseModal(true)
  }

  const handleModalCancel = () => setShowOpenHouseModal(false)

  const handleCheckboxOnChange = (id: string) => {
    const newDatesUpdating = { ...datesUpdating }
    newDatesUpdating[id] = true
    setDatesUpdating(newDatesUpdating)

    const updatedOpenHouseDates = [...listing.openHouseDates]

    const updatedOpenHouseDate = updatedOpenHouseDates.find(
      (date: T.IOpenHouseDate) => `${date._id}` === id,
    )
    if (updatedOpenHouseDate) updatedOpenHouseDate.mlsUpToDate = !updatedOpenHouseDate.mlsUpToDate

    update({ openHouseDates: updatedOpenHouseDates })
  }

  const handleEdit = (showing: IShowing) => {
    setOpenHouseDateAndModalState(listing.openHouseDates.find(el => el._id === showing._id))
    setShowOpenHouseModal(true)
  }

  const handleDelete = () => {
    const showing = confirmDeleteForShowing as IShowing
    const newOpenHouseDates = [...listing.openHouseDates]
    const idxToDelete = newOpenHouseDates.findIndex(({ _id }) => _id === showing._id)

    newOpenHouseDates.splice(idxToDelete, 1)
    update({ openHouseDates: newOpenHouseDates })
    clearConfirmDeleteForShowing()
    setAlert({ message: 'Showing Deleted', type: 'success' })
  }

  const handleAddOpenHouseDate = (newOpenHouseDate: T.IOpenHouseDate) => {
    update({ openHouseDates: listing.openHouseDates.concat([newOpenHouseDate]) })
    setShowOpenHouseModal(false)
  }

  const handleUpdateOpenHouseDate = (updatedOpenHouseDate: T.IOpenHouseDate) => {
    const newOpenHouseDates = [...listing.openHouseDates]
    const idxToReplace = newOpenHouseDates.findIndex(({ _id }) => _id === updatedOpenHouseDate._id)
    newOpenHouseDates[idxToReplace] = updatedOpenHouseDate

    update({ openHouseDates: newOpenHouseDates })
    setShowOpenHouseModal(false)
  }

  const columns = useMemo(
    () => getColumns(datesUpdating, handleCheckboxOnChange, handleEdit, setConfirmDeleteForShowing),
    [datesUpdating],
  )

  const handleRadioButtonOnChange = (event: RadioChangeEvent) => setFilterStatus(event.target.value)

  useEffect(() => {
    if (listing?.openHouseDates?.length) {
      const newDatesUpdating = listing.openHouseDates.reduce(
        (acc: { [key: string]: boolean }, date: T.IOpenHouseDate): { [key: string]: boolean } => {
          acc[date._id as string] = false
          return acc
        },
        {},
      )
      setDatesUpdating(newDatesUpdating)
    }
  }, [listing.openHouseDates])

  const filterDatesByStatus = (status: FilterStatuses) => {
    switch (status) {
      case FilterStatuses.ShowIncomplete:
        return listing.openHouseDates.filter(date => !date.mlsUpToDate)
      case FilterStatuses.ShowUpcoming:
        return listing.openHouseDates.filter(date => !dayjs(date.end).isBefore(dayjs(), 'hour'))
      case FilterStatuses.ShowAll:
        return listing.openHouseDates
      default:
        return listing.openHouseDates
    }
  }

  const getShowings = (): IShowing[] => {
    if (!listing.openHouseDates?.length) return []

    const filteredDates = filterDatesByStatus(filterStatus)
    return filteredDates
      .sort((left, right) => new Date(left.start).getTime() - new Date(right.start).getTime())
      .map(date => {
        const openHouseDateStr = applyDateWithTimezone(date.start, listing.timeZone, 'MM/DD/YY')
        const startTime = calculateTimeWithTimezone(date.start, listing.timeZone, 'h:mm A z')
        const endTime = calculateTimeWithTimezone(date.end, listing.timeZone, 'h:mm A z')
        const day = applyDateWithTimezone(date.start, listing.timeZone, 'dddd')

        return {
          '_id': date._id as string,
          'Day': day as string,
          'Date': openHouseDateStr as string,
          'Start Time': `${startTime}`,
          'End Time': `${endTime}`,
          'Virtual Url': date.virtualUrl,
          'Done': date.mlsUpToDate,
          'cancelled': date.cancelled,
        }
      })
  }

  const filterOptions = [
    { label: 'Needs Input', value: FilterStatuses.ShowIncomplete },
    { label: 'Show Upcoming', value: FilterStatuses.ShowUpcoming },
    { label: 'Show All', value: FilterStatuses.ShowAll },
  ]

  return (
    <>
      <Row>
        <Col span={24}>
          <SubsectionTitle>Scheduled Open Houses</SubsectionTitle>
          <Row>
            <CustomRadioGroup
              options={filterOptions}
              onChange={handleRadioButtonOnChange}
              defaultValue={filterStatus}
            />
          </Row>
          <Spacer size={10} />
          {!hasOpenHouses(listing) && (
            <StyledText>There are currently no open houses scheduled for this listing</StyledText>
          )}
          {TableView(getShowings(), columns)}
        </Col>
        <Spacer size={10} />
        <Col span={24}>
          <Space size={10}>
            <Button onClick={handleEditInDashboard}>
              <StyledText>Edit In Dashboard</StyledText>
            </Button>
            <Button onClick={handleAddOpenHouse}>
              <StyledText>Add Open House</StyledText>
            </Button>
            {alert && (
              <Alert
                style={{ height: '32px' }}
                message={<AlertMessage>{alert.message}</AlertMessage>}
                type={alert.type}
                showIcon
                closable
                onClose={clearAlert}
              />
            )}
          </Space>
        </Col>
      </Row>
      {confirmDeleteForShowing && (
        <Modal
          title="Delete Showing"
          okText="Confirm"
          onOk={handleDelete}
          onCancel={clearConfirmDeleteForShowing}
          visible
        >
          Are you sure you want to delete this showing?
        </Modal>
      )}
      <OpenHouseModal
        errorMessage={modalErrorMessage}
        onCancel={handleModalCancel}
        onAddOpenHouseDate={handleAddOpenHouseDate}
        onUpdateErrorMessage={setModalErrorMessage}
        onUpdateOpenHouseDate={handleUpdateOpenHouseDate}
        onUpdateShowing={setModalShowing}
        openHouseDate={openHouseDate}
        showing={modalShowing}
        timezone={listing.timeZone}
        visible={showOpenHouseModal}
      />
    </>
  )
}

export default OpenHouse
