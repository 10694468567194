import SVG from './SVG'
import * as T from './types'

export default (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 18 21"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.87496 0.561826V11.7983C7.87496 12.1086 8.1268 12.3602 8.43746 12.3602H9.56245C9.87311 12.3602 10.125 12.1086 10.125 11.7983V0.561826C10.125 0.251538 9.87311 0 9.56245 0H8.43746C8.1268 0 7.87496 0.251538 7.87496 0.561826ZM15.345 5.12386C15.4997 5.13254 15.645 5.20106 15.75 5.31488C18.941 8.92881 18.7114 14.4131 15.2296 17.7484C11.7478 21.0837 6.2522 21.0837 2.77038 17.7484C-0.711449 14.4131 -0.940965 8.92881 2.25003 5.31488C2.35778 5.19839 2.5076 5.12963 2.66628 5.12386C2.8222 5.12121 2.97248 5.18206 3.08253 5.29241L3.88127 6.0902C4.07608 6.30773 4.07608 6.63675 3.88127 6.85428C2.82456 8.06977 2.24499 9.62675 2.25003 11.2365C2.25003 14.96 5.2721 17.9784 9 17.9784C12.7279 17.9784 15.75 14.96 15.75 11.2365C15.7581 9.6282 15.1826 8.07139 14.13 6.85428C13.9352 6.63675 13.9352 6.30773 14.13 6.0902L14.9287 5.29241C15.0388 5.18206 15.1891 5.12121 15.345 5.12386Z"
  />
)
