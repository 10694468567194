import produce from 'immer'
import queryString from 'query-string'
import { mutate } from 'swr'

import { stringifyParams } from 'utils/stringFormatting'

import * as T from 'types'

import { api } from './api'
import { getDataFromResponse } from './swr/utils/dataHelpers'
import { getUpdateErrorMessage } from './swr/utils/errorMessageHelpers'

export const validateZip = async (zip: string) => {
  try {
    const response = await api.validateZip(zip)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    if (response.data.length) return true

    return false
  } catch (error) {
    // a 404 means the zip is invalid
    return false
  }
}

const mutateSearch = (searchQueryParamsKey: T.IGetQueryParams) => {
  if (searchQueryParamsKey) {
    let searchKey = '/zips/search'
    const queryParams = stringifyParams(['filter', 'populate'], searchQueryParamsKey)
    searchKey += `?${queryString.stringify(queryParams)}`
    mutate(searchKey)
  }
}

export const saveZips = async (
  zips: T.IZip[],
  searchQueryParamsKey?: T.IGetQueryParams,
): Promise<[boolean | undefined, T.IServerError | undefined]> => {
  const response = await api.saveZips(zips)
  const zipUpdateInfo = getDataFromResponse<[]>(response)

  const userReadableError = 'Failed to save zipcodes'
  const saveZipsError = getUpdateErrorMessage(response, { userReadableError })

  if (searchQueryParamsKey) mutateSearch(searchQueryParamsKey)

  if (zipUpdateInfo?.length) {
    // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the 2nd
    // argument of mutate is a function, it passes in the cached current value stored for the key
    // @FIXME: this does not appear to write server data to cache, but rather the argument zips
    await mutate(
      '/zips/',
      produce((draftZips: T.IZip[]) => {
        zips.forEach((zip: T.IZip) => {
          const foundZipIndex = draftZips?.findIndex((zipItem: T.IZip) => zipItem._id === zip._id)

          if (foundZipIndex >= 0) {
            draftZips[foundZipIndex] = zip
          }
        })
      }),
      false,
    )
  }

  return [zipUpdateInfo ? !!zipUpdateInfo.length : undefined, saveZipsError]
}
