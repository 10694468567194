import { EditOutlined } from '@ant-design/icons'
import { Typography, message } from 'antd'
import { EditableFieldsModal } from 'modules'
import { Fragment, FunctionComponent, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { copyToClipboard, formatJSONStringToObject, showFieldValue } from 'utils'

import Spacer from 'components/Spacer'

import { useListing } from 'services/swr'

import { themeFontSize } from 'utils/styles'

import * as T from 'types'
import * as E from 'types/enums'

import { SubsectionTitle } from '../Styles'
import { formatFieldArray, formatFieldToArray } from '../utils'
import ExpandableParagraphFieldValue from './ExpandableParagraphFieldValue'

const { Text } = Typography
interface IFieldContainerProps {
  backgroundColor: boolean
}

const FieldsListContainer = styled.div<IFieldContainerProps>`
  display: flex;
  width: 100%;
  padding: 2px 5px;
  ${props => props.backgroundColor && `background-color: ${props.theme.colors.grey};`}

  /* fix for long fields getting stuck between columns */
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
`

interface IListViewProp {
  listView?: boolean
}
interface IWidthProp {
  width?: string
}
interface IReadOnlyProp {
  readOnly?: boolean
}

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const FieldLabel = styled.span<IListViewProp & IWidthProp>`
  font-weight: 700;
  width: ${props => (props.listView ? '40%' : '100%')};
  ${props => props.width && `width: ${props.width};`}
  cursor: pointer;
  ${themeFontSize}
`

const FieldValue = styled.span<IListViewProp & IWidthProp & IReadOnlyProp>`
  ${themeFontSize}
  color: ${props => props.theme.textColor};
  font-weight: 400;
  width: ${props => (props.listView ? '60%' : '100%')};
  cursor: ${props => (props.readOnly ? 'auto' : 'pointer')};
  height: 100%;
`

interface IProps {
  title?: string
  listView: boolean
  field: T.IMlsField
  index: number
  removeTitle?: boolean
  fieldLabelWidth?: string
  fieldSet?: T.IMlsField[]
  ellipsis?: boolean
}

const FieldListView: FunctionComponent<IProps> = ({
  title,
  listView,
  field,
  index,
  removeTitle,
  fieldLabelWidth,
  fieldSet,
  ellipsis,
}) => {
  const { mutate } = useListing()
  const theme = useContext(ThemeContext)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const fieldType = field.fieldId?.type || field.type || ''

  const fieldValue: string[] = formatFieldToArray(fieldType, field)

  const highlightChecklistAnswers = (values: string[], answers: string[], color: string) =>
    values.map((value: string, idx: number) => {
      const highlightColor = answers.includes(value) ? color : 'inherit'
      return (
        <Fragment key={value.length + index}>
          <span style={{ color: highlightColor }}>{value}</span>
          {idx < values.length - 1 ? <span>, </span> : null}
        </Fragment>
      )
    })

  const getHighlightedField = (
    currentField: T.IMlsField,
    highlightAnswers: T.IHighlightAnswer[],
    highlightColor: string = 'red',
  ) => {
    const highlightAnswer = highlightAnswers.find(
      highlightItem => currentField.listingFlowStep === highlightItem.listingFlowStep,
    )

    if (!highlightAnswer) return null

    const label = highlightAnswer.highlightLabels.find(
      labelItem => labelItem.question === currentField.label,
    )

    if (!label) return null

    const value: string[] | string | undefined = showFieldValue(field)

    if (
      currentField.type === 'checklist'
      && label.answers.find((answer: string) => value?.includes(answer))
      && Array.isArray(value)
    ) {
      return highlightChecklistAnswers(value, label.answers, highlightColor)
    }

    if (
      currentField.type === 'switch'
      && typeof value === 'string'
      && label.answers.includes(value)
    ) {
      return <span style={{ color: highlightColor }}>{value}</span>
    }

    return null
  }

  const renderFieldValue = (highlightAnswers?: T.IHighlightAnswer[]) => {
    if (highlightAnswers) {
      const highlightedField = getHighlightedField(field, highlightAnswers)
      if (highlightedField) return highlightedField
    }

    return formatFieldArray(fieldType, fieldValue)
  }

  const highlightAnswers: T.IHighlightAnswer[] = [
    {
      listingFlowStep: 'Ownership Terms',
      highlightLabels: [
        {
          question: 'Are any of the owners of the property a currently Licensed Real Estate Agent?',
          answers: ['Yes'],
        },
        {
          question: 'Do any of the following conditions apply to the property?',
          answers: ['Foreclosure', 'Notice of Default', 'Short Sale', 'Probate'],
        },
        {
          question: 'Showing Response Time',
          answers: ['No'],
        },
      ],
    },
  ]

  const showEditModal = () => {
    if (field.readOnly) return

    setIsModalVisible(true)
  }

  const hideEditModal = (newListing?: T.IListing) => {
    if (newListing) mutate(newListing, false)

    setIsModalVisible(false)
  }

  const handleCopy
    = (dataToCopy: string | undefined, successMessage: string = 'Value Copied!') =>
      () => {
        message.success({
          style: { marginTop: '50px' },
          content: successMessage,
          duration: 5,
        })
        let filteredCopyData: string | object = dataToCopy !== '---' && dataToCopy ? dataToCopy : ''
        const filteredCopyDataObj = formatJSONStringToObject(filteredCopyData)

        if (filteredCopyDataObj && typeof filteredCopyDataObj === 'object') {
          filteredCopyData = Object.keys(filteredCopyDataObj).filter(key => filteredCopyDataObj[key])
        }

        copyToClipboard(filteredCopyData)
      }

  return (
    <>
      {index === 0 && !removeTitle && (
        <SubsectionTitle>
          {title || field.listingFlowSubStep || field.listingFlowStep}
        </SubsectionTitle>
      )}
      <FieldsListContainer backgroundColor={index % 2 === 0}>
        <FieldWrapper>
          <FieldLabel
            listView={listView}
            width={fieldLabelWidth}
          >
            {!listView && ellipsis
              ? (
                <ExpandableParagraphFieldValue
                  label={field.label}
                  onLabelClick={handleCopy(field?.value, `Copied ${field.label} value`)}
                  content={renderFieldValue()}
                  readOnly
                />
              )
              : (
                <>
                  <Text onClick={handleCopy(field?.value, `Copied ${field.label} value`)}>
                    {field.label}
                  </Text>
                  {!listView ? ': ' : ''}
                  {!listView && (
                    <FieldValue readOnly>
                      {field.listingFlowStep === E.ListingContentSection.OwnershipTerms
                        ? renderFieldValue(highlightAnswers)
                        : renderFieldValue()}
                    </FieldValue>
                  )}
                </>
              )}
          </FieldLabel>
          {listView && (
            <>
              <Spacer size={5} />
              <FieldValue
                listView={listView}
                readOnly
              >
                {fieldValue.map((item, idx) =>
                  fieldType === 'link'
                    ? (
                      <>
                        {idx !== 0 && <br />}
                        <Typography.Link
                          href={item}
                          target="_blank"
                          key={`${item}-link`}
                        >
                          {item}
                        </Typography.Link>
                      </>
                    )
                    : (
                      <>
                        {idx !== 0 && <br />}
                        {!ellipsis
                          ? (
                            <Typography.Text
                              style={{ color: `${theme.textColor}` }}
                              key={`${item}-text`}
                            >
                              {item}
                            </Typography.Text>
                          )
                          : (
                            <ExpandableParagraphFieldValue
                              content={item}
                              readOnly
                              key={`${item}-text`}
                            />
                          )}
                      </>
                    ),
                )}
              </FieldValue>
              <Spacer size={5} />
            </>
          )}
        </FieldWrapper>
        {!field.readOnly && <EditOutlined onClick={showEditModal} />}
      </FieldsListContainer>
      {isModalVisible && (
        <EditableFieldsModal
          isModalVisible={isModalVisible}
          fieldSet={fieldSet}
          field={field}
          closeModal={hideEditModal}
        />
      )}
    </>
  )
}

export default FieldListView
