import Cookies from 'js-cookie'
import { mutate } from 'swr'

import * as T from 'types'
import { AdminRoles } from 'types/enums'

import { api } from './api'
import { getUpdateErrorMessage } from './swr/utils/errorMessageHelpers'
import { ISignupParams } from './types'

export const createUser = async (
  createUserParams: ISignupParams,
): Promise<[T.IListing | undefined, Error | undefined]> => {
  try {
    const response = await api.createUser(createUserParams)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    Cookies.set('token', response.data.token, { expires: 14 })
    api.api.setHeader('Authorization', `Bearer ${response.data.token}`)

    // we prefill new listing with melissa and zillow data here:
    // (currently this step assumes you always want to create a listing with a user)
    const listingResponse = await api.getMyListings()

    // we don't want to error if this fails
    if (!listingResponse.ok || !listingResponse.data) return [undefined, undefined]

    const listing = listingResponse.data[0] // user will only have 1 listing to start
    const populatedResponse = await api.getPropertyDetails(listing._id)
    if (!populatedResponse.ok || !populatedResponse.data) return [undefined, undefined]

    return [populatedResponse.data, undefined]
  } catch (error) {
    return [undefined, error as Error]
  }
}

export const updateUser = async (
  userId: string,
  update: T.IUser | { [key: string]: number | undefined | string | string[] | boolean },
): Promise<[T.IUser | undefined, Error | undefined]> => {
  try {
    const response = await api.updateUser(userId, update)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    mutate('/users')
    mutate(`/users/admin/${userId}`, response.data, false)

    return [response.data, undefined]
  } catch (error) {
    return [undefined, error as Error]
  }
}

export const updateUserRoles = async (
  userId: string,
  roles: string[],
): Promise<[T.IUser | undefined, Error | undefined]> => {
  try {
    const response = await api.updateUserRoles(userId, roles)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    mutate('/users')
    mutate(`/users/admin/${userId}`, response.data, false)

    return [response.data, undefined]
  } catch (error) {
    return [undefined, error as Error]
  }
}

export const deleteUser = async (
  userId: string,
  { name = '', email = '', roles = [], resultsNo = 25, pageNo = 0 },
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    let key = `/users/search?resultsNo=${resultsNo}&pageNo=${pageNo}`

    if (name) key += `&name=${name}`

    if (email) key += `&email=${email}`

    if (roles) key += `&roles=${roles}`

    const response = await api.deleteUser(userId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    mutate('/users')
    mutate(key)

    return [true, undefined]
  } catch (error) {
    return [undefined, error as Error]
  }
}

// editor role will be defined under User collection in the future.
export const updateListingEditor = async (
  listingId: string,
  editor: string,
): Promise<[T.IListing | undefined, Error | undefined]> => {
  try {
    const response = await api.updateListingEditor(listingId, { editor })
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listingId}/ownerDetails`)
    return [response.data, undefined]
  } catch (error) {
    console.error(error)
    if (error instanceof Error) return [undefined, error]

    return [undefined, undefined]
  }
}

export const updateListingManager = async (
  listingId: string,
  managerName: string,
): Promise<[T.IListing | undefined, Error | undefined]> => {
  try {
    const response = await api.updateListingManager(listingId, { listingManagerName: managerName })
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    mutate(`/listings/${listingId}/ownerDetails`)
    return [response.data, undefined]
  } catch (error) {
    console.error(error)
    if (error instanceof Error) return [undefined, error]

    return [undefined, undefined]
  }
}

export const isAdmin = (user: T.IUser): boolean =>
  user.roles.length > 0 && user.roles.some(role => role in AdminRoles)
// verifies user has a role other than 'user'
export const hasAdminRole = (roles: string[]): boolean =>
  roles.length > 0 && roles.some(role => role in AdminRoles)

export const syncIntercomUser: T.ISyncIntercomUser = async userId => {
  const response = await api.syncIntercomUser(userId)

  const userReadableError = 'Failed to sync intercom user'
  const syncError = getUpdateErrorMessage(response, { userReadableError })

  return [true, syncError]
}
