import { Form, Input, Modal, message } from 'antd'
import MaskedInput from 'antd-mask-input'
import React from 'react'
import styled from 'styled-components'

import { IAdditionalContactInfo } from '../../../types'

const AddContactModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`
type NewAdditionalContactInfo = Omit<IAdditionalContactInfo, '_id'>
interface IAddContactModal {
  isOpen: boolean
  title: string
  confirmLoading: boolean
  onConfirm?: (contact: NewAdditionalContactInfo) => void
  onCancel?: () => void
}
interface IContactForm {
  name: string
  email: string
  phone: string
  notes: string
}

export const AddContactModal: React.FC<IAddContactModal> = ({
  isOpen,
  title,
  confirmLoading,
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm()
  const submitForm = () => form.submit()

  const eachFieldIsEmpty = (values: Record<string, any>) =>
    Object.keys(values).every(field => typeof values[field] === 'undefined' || values[field] === '')

  const handleSubmit = (values: IContactForm) => {
    if (eachFieldIsEmpty(values)) {
      message.error({
        style: { marginTop: '50px' },
        duration: 5,
        content: "Form shouldn't be empty",
      })
      return
    }

    const contactInfo: Omit<NewAdditionalContactInfo, '_id'> = {
      ...values,
      phone: values.phone ? parseInt(values.phone.replace(/[^\d]/g, ''), 10) : undefined,
    }
    onConfirm(contactInfo)
    form.resetFields()
  }

  const handleCancel = () => {
    onCancel()
    form.resetFields()
  }

  return (
    <Modal
      title={title}
      visible={isOpen}
      onOk={submitForm}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      centered
    >
      <AddContactModalContainer>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Name"
            name="name"
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
          >
            <MaskedInput
              mask="111-111-1111"
              style={{ width: '100%' }}
              placeholder="Phone"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Notes"
            name="notes"
          >
            <Input placeholder="Notes" />
          </Form.Item>
        </Form>
      </AddContactModalContainer>
    </Modal>
  )
}
