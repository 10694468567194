import { Checkbox, Popover, Typography, message } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Spacer } from 'components'
import { FC, Fragment, useEffect, useState } from 'react'

import { InfoContainer, SubsectionTitle } from 'modules/ListingContentSection/Styles'

import { validateURI } from 'utils/stringValidation'

import * as T from 'types'

const { Text } = Typography

interface IProps {
  title: string
  checklistListingItemsToDisplay: T.IChecklistListingItem[]
  allChecklistListingItems: T.IChecklistListingItem[]
  updateChecklistListingItem: T.IUpdateChecklistListingItem
}

const ChecklistCard: FC<IProps> = ({
  title,
  checklistListingItemsToDisplay,
  allChecklistListingItems,
  updateChecklistListingItem,
}) => {
  const [checklistListingItemDisableStatusMap, setChecklistListingItemDisableStatusMap] = useState<{
    [key: number]: boolean
  }>({})

  useEffect(() => {
    const newChecklistListingItemDisableStatusMap = allChecklistListingItems.reduce(
      (accum, item) => {
        if (!item.checked) {
          accum[item.checklistTemplateItemId] = false
        } else {
          accum[item.checklistTemplateItemId] = true
        }

        return accum
      },
      {} as { [key: number]: boolean },
    )

    setChecklistListingItemDisableStatusMap(newChecklistListingItemDisableStatusMap)
  }, [checklistListingItemsToDisplay])

  const handleChange = async (item: T.IChecklistListingItem, checked: boolean) => {
    const [data, error] = await updateChecklistListingItem({ ...item, checked })

    if (!data && error.message) {
      message.error({
        style: { marginTop: '50px' },
        content: error.message,
        duration: 3,
      })
    }
  }

  const addHttpProtocolToLink = (link: string) => {
    if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) return link

    return `https://${link}`
  }

  const renderTitle = (text: string) => {
    const words = text.split(' ')
    const modifiedWords = words.map((word, index) => ({ id: index, word }))
    return modifiedWords.map(modifiedWord =>
      validateURI(modifiedWord.word)
        ? (
          <Fragment key={modifiedWord.id}>
            {' '}
            <a
              href={addHttpProtocolToLink(modifiedWord.word)}
              target="_blank"
              rel="noreferrer"
            >
              {modifiedWord.word}
            </a>{' '}
          </Fragment>
        )
        : (
          <Fragment key={modifiedWord.id}> {modifiedWord.word} </Fragment>
        ),
    )
  }

  const isChecklistDisabled = (eventItems: T.IChecklistTemplateItem[]) =>
    !eventItems.every(item => !!checklistListingItemDisableStatusMap[item.id])

  return (
    <div>
      <SubsectionTitle>{title}</SubsectionTitle>
      {checklistListingItemsToDisplay.map((item: T.IChecklistListingItem, idx: number) => {
        const handleItemChange = (event: CheckboxChangeEvent) =>
          handleChange(item, event.target.checked)

        const disabled = isChecklistDisabled(item.checklistTemplateItem.checklistTemplateItemEvent)

        const eventItemNames = item.checklistTemplateItem.checklistTemplateItemEvent.map(
          eventItem => <li key={`${eventItem.title} - ${eventItem.type}`}>{eventItem.title}</li>,
        )

        const popoverContent = (
          <>
            <Text>Disabled until the following checklist items are checked:</Text>
            <ul style={{ margin: 0, padding: '10px' }}>{eventItemNames}</ul>
          </>
        )

        const infoContainer = (
          <InfoContainer
            key={item.id}
            backgroundColor={!!(idx % 2)}
          >
            <Checkbox
              disabled={disabled}
              checked={item.checked}
              onChange={handleItemChange}
            />
            <Spacer
              display="inline-block"
              size={10}
            />
            <Text disabled={disabled}>{renderTitle(item.checklistTemplateItem.title)}</Text>
          </InfoContainer>
        )
        return disabled
          ? (
            <Popover content={popoverContent}>{infoContainer}</Popover>
          )
          : (
            infoContainer
          )
      })}
    </div>
  )
}

export default ChecklistCard
