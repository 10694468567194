import { useState } from 'react'
import useSWR, { ConfigInterface, keyInterface, responseInterface } from 'swr'
import { fetcherFn } from 'swr/dist/types'

export interface IIsFetching {
  isFetching: boolean
}

interface IResult<Data, Error> extends responseInterface<Data, Error>, IIsFetching {}

// afaik in order to override the return type for the overloaded functions, we need to copy their
// definitions: https://github.com/vercel/swr/blob/0.4.2/src/use-swr.ts#L229-L243
interface IUseIsFetching {
  <Data = any, Error = any>(key: keyInterface): IResult<Data, Error>

  <Data = any, Error = any>(key: keyInterface, config?: ConfigInterface<Data, Error>): IResult<
    Data,
    Error
  >

  <Data = any, Error = any>(
    key: keyInterface,
    fn?: fetcherFn<Data>,
    config?: ConfigInterface<Data, Error>,
  ): IResult<Data, Error>
}

/**
 * this swr wrapper allows us to keep the old stale data and instead provide an 'isFetching'
 * property.  This keeps the UI from moving around.
 *
 * for instance, we use this when searching for listings so the listing table doesn't shift when
 * filtering or sorting the data
 *
 * note: the 'any[]' type is best practice as far as I know due to useSWR being an
 * overloaded function.
 */
const useIsFetching: IUseIsFetching = <Data, Error>(key: keyInterface, ...rest: any[]) => {
  const [previousResult, setPreviousResult] = useState<responseInterface<Data, Error>>()

  const result = useSWR<Data, Error>(key, ...rest)

  const isFetching = result.data === undefined && result.error === undefined

  if (!isFetching && result !== previousResult) {
    setPreviousResult(result)
  }

  if (!isFetching || previousResult === undefined) {
    return { ...result, isFetching }
  }

  return { ...previousResult, isFetching }
}

export default useIsFetching
