import { Popover } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { isString } from 'utils'

import { useMlsList } from 'services/swr'

import * as T from 'types'

const Paragraph = styled.p`
  margin: 0;
`

const ContentDl = styled.dl`
  display: grid;
  grid-template-columns: max-content auto;
  margin-bottom: 0;

  dt {
    grid-column-start: 1;
    margin-right: 20px;

    &:after {
      content: ':';
    }
  }

  dd {
    grid-column-start: 2;
    margin-bottom: 0;
  }
`

function getContent(mls: T.IMls | undefined) {
  const infoNotFound = <Paragraph>info not found</Paragraph>

  if (!mls) return infoNotFound

  const hasMaxCount = Number.isInteger(mls.photos?.maxCount)
  const hasMinCount = Number.isInteger(mls.photos?.minCount)
  const hasDescriptionLength = Number.isInteger(mls.descriptionLength)
  const hasStatusesAllowed = isString(mls.statusesAllowed)
  const hasDaysToResetDom = Number.isInteger(mls.daysToResetDom)
  const hasTempHoldAllowed = isString(mls.temporaryHoldAllowed)
  const hasShowings = !!mls.showings?.length

  const hasContent
    = hasMaxCount
    || hasMinCount
    || hasDescriptionLength
    || hasStatusesAllowed
    || hasDaysToResetDom
    || hasTempHoldAllowed
    || hasShowings

  return !hasContent
    ? (
      infoNotFound
    )
    : (
      <>
        <ContentDl>
          {hasMaxCount && (
            <>
              <dt>Max Photos</dt>
              <dd>{mls.photos.maxCount}</dd>
            </>
          )}
          {hasMinCount && (
            <>
              <dt>Min Photos</dt>
              <dd>{mls.photos.minCount}</dd>
            </>
          )}
          {hasDescriptionLength && (
            <>
              <dt>Max Description</dt>
              <dd>{mls.descriptionLength}</dd>
            </>
          )}
          {hasStatusesAllowed && (
            <>
              <dt>Statuses Allowed</dt>
              <dd>{mls.statusesAllowed}</dd>
            </>
          )}
          {hasDaysToResetDom && (
            <>
              <dt>Days to Reset DOM</dt>
              <dd>{mls.daysToResetDom}</dd>
            </>
          )}
          {hasShowings && (
            <>
              <dt>Showings</dt>
              {mls.showings?.map(showing => (
                <dd key={showing}>{showing}</dd>
              ))}
            </>
          )}
          {hasTempHoldAllowed && (
            <>
              <dt>Temp Hold Allowed</dt>
              <dd>{mls.temporaryHoldAllowed}</dd>
            </>
          )}
        </ContentDl>
      </>
    )
}

interface IBaseProps {
  fallbackName?: string
  placement?: TooltipPlacement
}

interface ILiveIn {
  liveIn: string
}

interface IMls {
  mls: T.IMls
}

type IProps = IBaseProps & T.Either<ILiveIn, IMls>

/**
 * renders the mls name with popover info
 *
 * because the consumer might only have the liveIn name, we accept that as input
 * rather than mls.  We ensure both props aren't passed via types because the
 * use-case should only ever be one or the other.
 */
const MLSName: FunctionComponent<IProps> = props => {
  const { children, fallbackName = '', liveIn, mls, placement = 'top' } = props
  const { mlsList } = useMlsList()

  const mlsInst = liveIn ? mlsList.find(el => el.name === liveIn) : mls

  return (
    <Popover
      content={getContent(mlsInst)}
      placement={placement}
    >
      {children || liveIn || mlsInst?.name || fallbackName}
    </Popover>
  )
}

export default MLSName
