import { yellow } from '@ant-design/colors'
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  RollbackOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Button,
  ButtonProps,
  Carousel,
  Col,
  Collapse,
  Image,
  Modal,
  Row,
  Spin,
  Typography,
  message,
} from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import { CustomButton, Spacer } from 'components'
import { Fragment, FunctionComponent, Ref, useMemo, useRef, useState } from 'react'
import { downloadZipLinks } from 'services'
import styled from 'styled-components'

import { useFieldsByGroupNames } from 'services/hooks/useFields'
import { ImageTypeEnum, getImageUrl } from 'services/images'
import { updateListingPhotos } from 'services/listings'
import { useListingPhotos } from 'services/swr'

import { themeFontSize } from 'utils/styles'

import * as T from 'types'

import FieldListView from './FieldListView'

const { Panel } = Collapse

const Text = styled.div`
  ${themeFontSize}
`

const Thumbnail = styled.div`
  /*
    unfortunately antd's Image component doesn't take the className property, so we need to style
    it like this
  */

  & > .ant-image {
    vertical-align: top;
  }

  .ant-image-img {
    object-fit: cover;
  }
`

const PhotoButtonsContainer = styled.div`
  display: flex;
`

interface IStyledCollapseProps {
  areDeleted?: boolean
}

const StyledCollapse = styled(Collapse)<IStyledCollapseProps>`
  margin-top: 20px;

  ${props =>
    props.areDeleted
    && `
    background-color: ${props.theme.colors.grey};
  `}
`

const FileSizeContainer = styled(Text)`
  padding-top: 2px; /* font centering fix */
`

interface IconButtonProps extends ButtonProps {
  top?: string
}
const IconButton = styled(Button)<IconButtonProps>`
  border: none;
  padding: 0;
  ${themeFontSize}
  ${props =>
    props.top
    && `
    top: ${props.top}px; /* vertical centering fix */
  `}
`

const PhotoList = styled.div`
  align-items: center;
  column-gap: 40px;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  row-gap: 5px;
`

interface IArrowColor {
  arrowColor?: string
}
const CarouselContainer = styled.div<IArrowColor>`
  .slick-dots {
    background-color: ${props => props.theme.colors.transparentBlackAlpha20};
    padding: 15px 0;
    margin: 0;
    bottom: 0;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: ${props => (props.arrowColor ? props.arrowColor : 'inherit')};

    .ant-carousel .slick-prev,
    .ant-carousel .slick-prev:hover {
      color: inherit;
    }

    .ant-carousel .slick-next,
    .ant-carousel .slick-next:hover {
      color: inherit;
    }
  }
`

interface IExpand {
  expand?: boolean
}
const CarouselSlide = styled.div<IExpand>`
  height: ${props => (props.expand ? '70vh' : '450px')};
  background-color: white;
  max-height: 900px;

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    ${props => (props.expand ? 'height: 80vh' : '')};
  }
`

interface IClickable {
  clickable: boolean
}
const CarouselImage = styled.img<IClickable>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  :hover {
    ${props => (props.clickable ? 'cursor: pointer' : '')};
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledModal = styled(Modal)`
  background-color: transparent;
  .ant-modal-body,
  .ant-modal-content {
    background-color: transparent;
    top: 50px; // header
    padding: 0;
    border: none;
    box-shadow: none;
  }
`

// size is already in MB
const formatPhotoSize = (size: number | undefined) => {
  if (size === undefined) return '<unknown size>'

  const trailingZeroesAndDecimalRe = /\.?0*$/

  return size < 1
    ? `${Math.ceil(size * 1024)}KB`
    : `${size.toFixed(1).replace(trailingZeroesAndDecimalRe, '')}MB`
}

interface ISrcByPhotoId {
  [key: string]: {
    listPreview: string
  }
}

interface IProps {
  listing: T.IListing
  listView: boolean
}

const PhotosAndVideos: FunctionComponent<IProps> = ({ listing, listView }) => {
  const groupedFields = useFieldsByGroupNames(['Photos'])

  const [loading, setLoading] = useState(false)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const { error: photosError, photos } = useListingPhotos(listing._id)

  const previewCarouselRef = useRef<CarouselRef>()
  const photosCarouselRef = useRef<CarouselRef>()

  const srcByPhotoId = useMemo(
    () =>
      photos?.reduce(
        (result, photo) =>
          Object.assign(result, {
            [photo._id]: {
              listPreview: getImageUrl(photo, ImageTypeEnum.ListPreview),
            },
          }),
        {} as ISrcByPhotoId,
      ),
    [photos],
  )

  const deletedPhotos = useMemo(() => photos?.filter(photo => photo.isSoftDeleted), photos)
  const nonDeletedPhotos = useMemo(() => photos?.filter(photo => !photo.isSoftDeleted), photos)

  if (photosError) {
    return (
      <Alert
        message="There was an error loading the listing's photos"
        type="error"
        closable
      />
    )
  }

  // only !photos is necessary here since the rest will have values once photos is truthy.  The rest are to
  // make typescript happy
  if (!photos || !srcByPhotoId || !deletedPhotos || !nonDeletedPhotos) {
    return <Spin tip="Loading Photos" />
  }

  const handleDownloadPhotos = async () => {
    setLoading(true)
    const [data, error] = await downloadZipLinks(nonDeletedPhotos, listing)

    if (error && !data) {
      message.error({
        style: { marginTop: '50px' },
        duration: 3,
        content: 'There was an error downloading the photos',
      })
    }

    setLoading(false)
  }

  const downloadSinglePhoto = (photoId: string) => {
    if (typeof window === 'undefined' || !window || !window.getSelection()) return

    const photo = photos.find(({ _id }) => photoId === _id) as T.IPhoto

    if (photo) window.open(photo.url)
  }

  const softDeleteSinglePhoto = (photoId: string, isSoftDeleted: boolean) => {
    const updatedPhotos = [...photos]
    const idx = updatedPhotos.findIndex(({ _id }) => _id === photoId)
    updatedPhotos[idx] = { ...updatedPhotos[idx], isSoftDeleted }

    updateListingPhotos(listing._id, updatedPhotos)
  }

  const handleDownloadSinglePhoto = () =>
    downloadSinglePhoto(nonDeletedPhotos[currentSlideIndex]._id)

  const handleOpenModal = () => {
    if (!modalVisible) setModalVisible(true)

    if (previewCarouselRef?.current) previewCarouselRef.current.goTo(currentSlideIndex, true)
  }

  const handleCloseModal = () => {
    if (photosCarouselRef?.current) photosCarouselRef.current.goTo(currentSlideIndex, true)

    setModalVisible(false)
  }

  const renderCarousel = (carouselRef: Ref<CarouselRef>, arrowColor?: string, expand?: boolean) => (
    <Row
      justify="center"
      align="middle"
      wrap={false}
    >
      <Col>
        <Spacer size={10} />
      </Col>
      <Col span={22}>
        <CarouselContainer arrowColor={arrowColor}>
          <Carousel
            ref={carouselRef}
            afterChange={setCurrentSlideIndex}
            arrows
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            initialSlide={currentSlideIndex}
            lazyLoad="ondemand"
          >
            {nonDeletedPhotos.map(photo => {
              const src = srcByPhotoId[photo._id]
              return (
                <CarouselSlide
                  key={`${photo._id}-carousel-slide`}
                  expand={expand}
                >
                  <CarouselImage
                    src={src.listPreview}
                    alt={`${photo.caption}`}
                    clickable={!modalVisible}
                    onClick={handleOpenModal}
                  />
                </CarouselSlide>
              )
            })}
          </Carousel>
        </CarouselContainer>
      </Col>
      <Spacer size={10} />
    </Row>
  )

  const photosCarousel = renderCarousel(photosCarouselRef as Ref<CarouselRef>, 'inherit', false)
  const previewCarousel = renderCarousel(previewCarouselRef as Ref<CarouselRef>, 'white', true)

  const hasNonDeletedPhotos = !!nonDeletedPhotos.length

  const renderCarouselView = () => (
    <Col
      xs={24}
      md={12}
      lg={12}
    >
      <Row justify="center">
        {hasNonDeletedPhotos && (
          <CustomButton
            backgroundColor={yellow[4]}
            backgroundColorOnHover={yellow[5]}
            custom
            onClick={handleDownloadSinglePhoto}
          >
            Download This Photo
          </CustomButton>
        )}
      </Row>
      {photosCarousel}
    </Col>
  )

  const renderVideoLinks = (label: string, items: string[]) => (
    <Row>
      <LinkContainer>
        <Typography.Text style={{ fontWeight: 800 }}>{label}</Typography.Text>
        {items.length > 0
          ? items.map(item => (
            <Typography.Link
              href={item}
              target="_blank"
              key={`${item}-link`}
            >
                &nbsp;
              {item}
            </Typography.Link>
          ))
          : 'Not available'}
      </LinkContainer>
    </Row>
  )

  const renderPhotoList = (photoList: T.IPhoto[]) => {
    if (!photoList.length) return null

    const areDeleted = photoList === deletedPhotos
    let header = 'Photo List'
    if (areDeleted) header += ' (Deleted)'

    return (
      <StyledCollapse
        areDeleted={areDeleted}
        bordered={false}
      >
        <Panel
          key="photo-list"
          header={header}
        >
          <PhotoList>
            {photoList.map((photo, idx) => {
              const downloadPhoto = () => downloadSinglePhoto(photo._id)
              const deletePhoto = () => softDeleteSinglePhoto(photo._id, true)
              const restorePhoto = () => softDeleteSinglePhoto(photo._id, false)

              const sizeDisplay = formatPhotoSize(photo.size)

              const src = srcByPhotoId[photo._id]

              const filename = photo.filename || '<unknown file name>'

              return (
                <Fragment key={photo._id}>
                  <div>{idx + 1}.</div>
                  <Thumbnail>
                    <Image
                      height={50}
                      preview={{ src: src.listPreview }}
                      src={src.listPreview}
                      width={90}
                    />
                  </Thumbnail>
                  <Text>{filename}</Text>
                  <FileSizeContainer>{sizeDisplay}</FileSizeContainer>
                  <PhotoButtonsContainer>
                    <IconButton
                      onClick={downloadPhoto}
                      top="1"
                      title="download"
                      type="ghost"
                    >
                      <CloudDownloadOutlined />
                    </IconButton>
                    <Spacer
                      display="inline-block"
                      size={10}
                    />
                    {!areDeleted && (
                      <IconButton
                        onClick={deletePhoto}
                        title="delete"
                        type="ghost"
                      >
                        <DeleteOutlined />
                      </IconButton>
                    )}
                    {areDeleted && (
                      <IconButton
                        onClick={restorePhoto}
                        title="restore"
                        type="ghost"
                      >
                        <RollbackOutlined />
                      </IconButton>
                    )}
                  </PhotoButtonsContainer>
                </Fragment>
              )
            })}
          </PhotoList>
        </Panel>
      </StyledCollapse>
    )
  }

  return (
    <>
      <Row
        gutter={[15, 15]}
        align="middle"
      >
        {renderCarouselView()}
        <Col
          xs={24}
          md={12}
          lg={12}
        >
          {renderVideoLinks('Video Tour', listing.videos)}
          <Spacer />
          {renderVideoLinks('3D Virtual Tours', listing.videos3d)}
          <Spacer />
          {hasNonDeletedPhotos && (
            <CustomButton
              backgroundColor={yellow[4]}
              backgroundColorOnHover={yellow[5]}
              custom
              onClick={handleDownloadPhotos}
              loading={loading}
            >
              Download All Photos
            </CustomButton>
          )}
        </Col>
      </Row>
      {renderPhotoList(nonDeletedPhotos)}
      {renderPhotoList(deletedPhotos)}
      <StyledModal
        visible={modalVisible}
        maskClosable
        centered
        closable={false}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        footer={null}
        width={1200}
      >
        {previewCarousel}
      </StyledModal>
      <Spacer size={20} />
      <Col
        xs={24}
        md={12}
        lg={8}
      >
        {groupedFields[0].map((field, index) => (
          <FieldListView
            key={field.name}
            field={field}
            index={index}
            listView={listView}
            fieldSet={groupedFields[0]}
          />
        ))}
      </Col>
    </>
  )
}

export default PhotosAndVideos
