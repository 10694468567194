import { Avatar, Dropdown, Menu } from 'antd'
import { Spacer } from 'components'
import Link from 'next/link'
import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { useWindowSize } from 'services/hooks'

import { IUser } from 'types'

const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`

const LinkText = styled.span``

const ItemRow = styled.div`
  display: flex;
  align-items: center;
`

interface IProps {
  user: IUser
  logout(): void
}

const User: FunctionComponent<IProps> = ({ user, logout }) => {
  const theme = useContext(ThemeContext)
  const { width } = useWindowSize()

  const isMobile = width && width < theme.metrics.tablet
  const UserIcon = theme.icons.userCircleFilled
  const LogoutIcon = theme.icons.power

  const menu = (
    <Menu>
      <Spacer size={10} />
      <Menu.Item data-testid="Profile">
        <Link href={`/users/${user._id}`}>
          <ItemRow>
            <IconContainer>
              <UserIcon
                width={18}
                height={18}
                fill={theme.colors.purple}
              />
            </IconContainer>
            <LinkText>Profile</LinkText>
          </ItemRow>
        </Link>
      </Menu.Item>
      <Menu.Item data-testid="Logout">
        <ItemRow onClick={logout}>
          <IconContainer>
            <LogoutIcon
              width={18}
              height={21}
              fill={theme.colors.purple}
            />
          </IconContainer>
          <LinkText>Logout</LinkText>
        </ItemRow>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      overlayStyle={{ zIndex: isMobile ? 10002 : 10000 }}
    >
      <Avatar
        style={{
          backgroundColor: theme.colors.purple,
          verticalAlign: 'middle',
          cursor: 'pointer',
        }}
      >
        {user?.name
          .split(' ')
          .map(word => word[0])
          .join('')}
      </Avatar>
    </Dropdown>
  )
}

export default User
