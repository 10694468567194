import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from 'antd'
import MaskedInput from 'antd-mask-input'
import moment, { Moment } from 'moment-timezone'
import { FunctionComponent, useEffect, useState } from 'react'

import SecondaryButton from 'components/SecondaryButton'

import { useAgreement } from 'services/hooks'
import { patchListing } from 'services/listings'
import { useDocuments, useListing } from 'services/swr'
import { useContractDetails } from 'services/swr/useContractDetails'
import mutateCacheKeysByRegex from 'services/swr/utils/mutateCacheKeysByRegex'

import * as T from 'types'

import DimensionsInput from './DimensionsInput'
import { EditableField, IUpdateRoomDimension } from './types'
import {
  generateNewFieldUpdate,
  generateNewFieldUpdateForRoomDimensions,
  getValueForInput,
  parseChecklistData,
} from './utils'

const { Option } = Select

const TIMEPICKER_CONFIG = {
  format: 'hh:mm A',
  use12Hours: true,
  minuteStep: 15,
}

const mutateHistoryRoutes = () => mutateCacheKeysByRegex(/history/)

const renderFields = (fieldOptions: string[], fieldLabels: string[] = []) => (
  <>
    {fieldOptions.map((fieldOption: string, idx) => (
      <Option
        key={`${fieldOption}`}
        value={`${fieldOption}`}
      >
        {fieldLabels[idx] || fieldOption}
      </Option>
    ))}
  </>
)

interface IProps {
  field: T.IMlsField
  fieldSet?: T.IMlsField[]
  isModalVisible: boolean
  closeModal(updatedListing?: T.IListing): void
}

const EditableFieldsModal: FunctionComponent<IProps> = ({
  field,
  fieldSet,
  closeModal,
  isModalVisible,
}) => {
  const { listing, updateAdminGoLiveDate } = useListing()
  const { contractDetails, update: updateContractDetails } = useContractDetails(listing?._id)
  const { listingDocuments } = useDocuments(listing?._id)

  useEffect(() => {
    if (!listing) return

    moment.tz.setDefault(listing.timeZone)
  }, [listing])

  const [loading, setLoading] = useState(false)
  const [fieldUpdate, setFieldUpdate] = useState<T.IListing>({} as T.IListing)
  const [inputFields, setInputFields] = useState<JSX.Element[]>([])

  const [error, setError] = useState('')
  useEffect(() => {
    if (error && loading) setLoading(false)
  }, [error])

  const { agreement } = useAgreement(listing, listingDocuments)

  const modalTitle = `Edit fields for ${field.listingFlowSubStep || field.listingFlowStep} section`

  // update temp object with new value
  const updateValue = (fld: T.IMlsField, value: string) => {
    if (!listing) return

    const newFieldUpdate: T.IListing = generateNewFieldUpdate(fld, value, fieldUpdate, listing)
    setFieldUpdate(newFieldUpdate)
  }

  // update temp object with new room dimension value
  const updateRoomDimension: IUpdateRoomDimension = (roomName, dimensionType, value) => {
    if (!listing) return

    const newFieldUpdate: T.IListing = generateNewFieldUpdateForRoomDimensions(
      roomName,
      dimensionType,
      value,
      fieldUpdate,
      listing,
    )
    setFieldUpdate(newFieldUpdate)
  }

  if (!listing) return null

  // send request to the backend, wait for the update to complete, only then close the modal
  const save = async (options: T.IListingApiOptions) => {
    setLoading(true)

    if (field.isAgreementField && !agreement) {
      setLoading(false)
      return setError('No agreement exists yet')
    }

    // fields can be currently be on the mongo listing or in the contract details postgres table
    if (field.isContractDetailsField) {
      try {
        if (!contractDetails) throw new Error('No contract details found')

        const [, contractDetailsUpdateError] = await updateContractDetails({
          id: contractDetails.id,
          updates: fieldUpdate as unknown as Partial<T.IContractDetails>,
          options,
        })
        if (contractDetailsUpdateError.message) throw new Error(contractDetailsUpdateError.message)

        setLoading(false)
        closeModal(listing)
      } catch (err) {
        setLoading(false)
        setError((err as Error).message)
      }

      return
    }

    // @TEMP: edge case for go live date, which has a separate update endpoint
    if (field.fieldId?.name === 'goLiveDate' && fieldUpdate.goLiveDate) {
      const [, goLiveDateError] = await updateAdminGoLiveDate(fieldUpdate)
      if (goLiveDateError.message) return setError(goLiveDateError.message)

      closeModal()
      setLoading(false)
    }

    try {
      const patchedListing = await patchListing(listing?._id, fieldUpdate, options)
      // await mutate(updatedListing, false) // update the listing in the cache
      setLoading(false)
      closeModal(patchedListing)
    } catch (err) {
      setLoading(false)
      setError((err as Error).message)
    }
  }

  const saveWithStatusUpdates = () =>
    save({
      disableStatusUpdate: false,
      disableListingChangesRequired: false,
      mutateHistoryRoutes,
    })

  const saveWithDisabledStatusUpdate = () =>
    save({
      disableStatusUpdate: true,
      disableListingChangesRequired: true,
      mutateHistoryRoutes,
    })

  // creates input component based on type
  const generateInputComponent = (fld: T.IMlsField) => {
    const { label, fieldOptions, fieldLabels, isTimepicker, isContractDetailsField, min, max } = fld
    if (!listing) return

    const type = String(fld.fieldId?.type || fld.type)

    // gets the value from temp upd object or listing by default
    const fieldValue: EditableField
      = getValueForInput(fieldUpdate, fld, agreement, contractDetails)
      || getValueForInput(listing, fld, agreement, contractDetails)

    let InputComponent: JSX.Element | null = null

    switch (type) {
      case 'checklist': {
        const { selectedOptions, parsedValue } = parseChecklistData(fieldValue)

        const handleChange = (pickedValue: string) => {
          if (!parsedValue[pickedValue]) {
            parsedValue[pickedValue] = true
          } else {
            parsedValue[pickedValue] = false
          }

          const orderedParsedValue = Object.keys(parsedValue)
            .sort()
            .reduce((obj: T.IChecklistValue, key: string) => {
              obj[key] = parsedValue[key]
              return obj
            }, {})
          updateValue(fld, JSON.stringify(orderedParsedValue))
        }

        if (!fieldOptions) {
          InputComponent = null
          break
        }

        InputComponent = (
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            value={selectedOptions}
            onSelect={handleChange}
            onDeselect={handleChange}
            onClear={() => updateValue(fld, '{}')}
          >
            {renderFields(fieldOptions, fieldLabels)}
          </Select>
        )
        break
      }

      case 'select': {
        if (!fieldOptions) {
          InputComponent = null
          break
        }

        // Edge Case for select.
        // Normally if the fieldUpdate is a blank string we will return the listing's current value for that
        // field. However, due to the select input onClear and onDeselect behavior we have to allow blank
        // values. Unlike the multiselect input, we don't store empty objects so we can't parse it
        // into a blank field.
        const tempFieldUpdateValue = getValueForInput(fieldUpdate, fld, agreement, contractDetails)

        const updatedFieldValue: EditableField
          = tempFieldUpdateValue === '' ? tempFieldUpdateValue : fieldValue

        const value
          = updatedFieldValue === null || typeof updatedFieldValue === 'undefined'
            ? undefined
            : String(updatedFieldValue)

        const handleChange = (selectedValue: string) => updateValue(fld, String(selectedValue))
        InputComponent = (
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            value={value}
            onSelect={handleChange}
            onDeselect={() => updateValue(fld, '')}
            onClear={() => updateValue(fld, '')}
          >
            {renderFields(fieldOptions, fieldLabels)}
          </Select>
        )
        break
      }

      case 'date': {
        const handleChange = (dateValue: Moment | null) => {
          updateValue(fld, dateValue?.format('YYYY-MM-DDTHH:mm:ss.SSSZ') ?? 'undefined')
        }

        const getMomentFieldValue = () => {
          if (!fieldValue) return undefined

          if (!Date.parse(String(fieldValue))) return undefined // invalid date case

          return moment(String(fieldValue))
        }

        InputComponent = (
          <DatePicker
            showTime={isTimepicker ? TIMEPICKER_CONFIG : undefined}
            defaultValue={getMomentFieldValue()}
            onChange={handleChange}
            format={isTimepicker ? 'MM/DD/YY, hh:mm A' : 'MM/DD/YY'}
          />
        )
        break
      }

      case 'switch': {
        const handleChange = (checked: boolean) => updateValue(fld, checked ? 'Yes' : 'No')

        let defaultChecked = isContractDetailsField ? !!fieldValue : fieldValue === 'Yes'
        if (fld.isCoreFieldBoolean) defaultChecked = !!fieldValue

        InputComponent = (
          <Switch
            defaultChecked={defaultChecked}
            onChange={handleChange}
          />
        )
        break
      }

      case 'dimensions': {
        InputComponent = (
          <DimensionsInput
            field={fld}
            fieldUpdate={fieldUpdate}
            listing={listing}
            updateRoomDimension={updateRoomDimension}
          />
        )
        break
      }

      case 'phonenumber': {
        const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
          updateValue(fld, String(evt.target.value).replace(/[^\d]/g, ''))
        InputComponent = (
          <MaskedInput
            mask="111-111-1111"
            style={{ width: '100%' }}
            placeholder={fld.label}
            defaultValue={fieldValue === null ? undefined : String(fieldValue)}
            onChange={handleChange}
          />
        )
        break
      }

      case 'number':
      case 'currency': {
        const handleChange = (numberVal: number | null) => updateValue(fld, String(numberVal ?? ''))
        InputComponent = (
          <InputNumber
            style={{ width: '100%' }}
            placeholder={fld.label}
            defaultValue={fieldValue === null || fieldValue === '' ? undefined : Number(fieldValue)}
            min={min || 0}
            max={max || 9999999999.99}
            formatter={value =>
              type === 'currency' ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}`
            }
            onChange={handleChange}
          />
        )
        break
      }

      default: {
        const onChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
          updateValue(fld, evt.target.value)
        InputComponent = (
          <Input
            placeholder={fld.label}
            defaultValue={
              fieldValue === null || typeof fieldValue === 'undefined' ? '' : String(fieldValue)
            }
            onChange={onChange}
          />
        )
        break
      }
    }

    return (
      <Form.Item
        key={label}
        label={label}
      >
        {InputComponent}
      </Form.Item>
    )
  }

  useEffect(() => {
    // every time we update the temp upd object, we want to refresh the field set;
    if (fieldSet) {
      const newInputFields: JSX.Element[] = []
      fieldSet.forEach((fld: T.IMlsField) => {
        if (!fld.readOnly) {
          const component = generateInputComponent(fld)
          if (component) newInputFields.push(component)
        }
      })
      setInputFields(newInputFields)
    } else {
      const component = generateInputComponent(field)
      if (component) setInputFields([component])
    }

    return () => setInputFields([])
  }, [fieldSet, field, fieldUpdate, contractDetails])

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    closeModal()
  }

  return (
    <Modal
      title={modalTitle}
      visible={isModalVisible}
      maskClosable={false}
      destroyOnClose
      okText="Save"
      onOk={saveWithDisabledStatusUpdate}
      onCancel={handleCancel}
      footer={(
        <Row justify="space-between">
          <Button
            key="back"
            onClick={() => closeModal()}
          >
            Close
          </Button>
          <Row
            gutter={16}
            key="action-buttons"
          >
            <SecondaryButton
              key="submit"
              loading={loading}
              onClick={saveWithStatusUpdates}
            >
              Save &amp; Create Change Required
            </SecondaryButton>
            <Button
              key="save"
              type="primary"
              loading={loading}
              onClick={saveWithDisabledStatusUpdate}
            >
              Save
            </Button>
          </Row>
        </Row>
      )}
    >
      <Form layout="vertical">{inputFields}</Form>
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginTop: '10px' }}
        />
      )}
    </Modal>
  )
}

export default EditableFieldsModal
