import * as T from 'types'

enum ImageTypeEnum {
  ListPreview = 'ListPreview',
  Default = 'Default',
}

const getTransformations = (type: ImageTypeEnum) => {
  switch (type) {
    case ImageTypeEnum.ListPreview:
      return 'w_800,c_scale'
    default:
      return 'w_auto,c_scale'
  }
}

const getImageUrl = (photo: T.IPhoto | undefined, type: ImageTypeEnum) => {
  const s3Url = photo?.url ?? ''

  if (
    !s3Url
    || s3Url.includes(`${process.env.CLOUDFRONT_URL}`)
    || !type
    || !process.env.S3_BASE_URL
  ) {
    return s3Url
  }

  // Given that s3Url has different paths/variations. We can use the S3_BASE_URL which has a
  // consistent url to help remove the portions of the s3Url path we don't need in order to
  // build the Cloudinary url version instead.
  const s3EnvBaseUrl = process.env.S3_BASE_URL
  const s3UrlPrefix = s3EnvBaseUrl.substring(0, s3EnvBaseUrl.indexOf('.')).replace('https://', '')
  const s3BaseUrl = s3EnvBaseUrl.substring(s3EnvBaseUrl.indexOf('.') + 1, s3EnvBaseUrl.length)

  const photoPath = s3Url
    .replace('https://', '')
    .replace('http://', '')
    .replace(`${s3UrlPrefix}.`, '')
    .replace(`${s3UrlPrefix}/`, '')
    .replace(`${s3BaseUrl}/`, '')

  return `${process.env.CLOUDFRONT_URL}/${photoPath}`

  // Legacy code that used cloudinary:
  /*
  // There are certain characters that are fine for AWS urls but not for Cloudinary urls.
  // We prevent uploading new photos with these characters, but older photos may have them,
  // so we need to encode them here.
  // We're not encoding each one currently, so you may need to add one if you're seeing a blank photo
  // Regex for all of them: /[#%&<>\\$\\\\?]+/g
  const namesWithReservedCharEncodings
    = photo.name?.replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/ /g, '%20') ?? photo.name

  const transformations = getTransformations(type)
  return `${basePath}/${transformations}/amazon/${listingId}/${namesWithReservedCharEncodings}.${extension}`
  */
}

export { ImageTypeEnum, getImageUrl, getTransformations }
