import { Signin } from 'modules'
import { NextPage, NextPageContext } from 'next'
import Router from 'next/router'
import { useEffect } from 'react'
import { ApiCreator, useUser } from 'services'
import styled from 'styled-components'

import * as T from 'types'

const PageContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${props => props.theme.metrics.headerHeightPhone}px);
  display: flex;
  justify-content: center;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: calc(100vh - ${props => props.theme.metrics.headerHeight}px);
  }
`

interface IProps {
  initialData: T.IUser | undefined
}

const SigninPage: NextPage<IProps> = ({ initialData }) => {
  const { user } = useUser(initialData)
  useEffect(() => {
    if (initialData?._id) Router.replace('/listings')

    if (user) Router.replace('/listings')
  }, [user, initialData])

  if (!user && !initialData?._id) {
    return (
      <PageContainer>
        <Signin />
      </PageContainer>
    )
  }

  return <div />
}

SigninPage.getInitialProps = async (ctx: NextPageContext) => {
  try {
    const api = new ApiCreator(ctx)
    const response = await api.getMe()
    if (!response.ok) throw new Error(response.problem)

    return { initialData: response.data }
  } catch (err) {
    return { initialData: undefined }
  }
}

export default SigninPage
