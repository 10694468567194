import { message } from 'antd'
// npm package CSS overrides
import 'antd/dist/antd.css'
import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { updateLastLogin, useUser } from 'services'

import Layout from 'components/Layout'
import Providers from 'components/Providers'

import { page, ready } from 'services/analytics'

import '../modules/MLS/mls.css'

Router.events.on('routeChangeComplete', page)

const App = ({ Component, pageProps, err }: AppProps & { err: any }) => {
  const { user } = useUser()

  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    // hide Intercom window, we don't want to show it for admin, but still need Intercom for updating users
    ready(() => {
      if ((window as any).Intercom) {
        ;(window as any).Intercom('update', {
          hide_default_launcher: true,
        })
      }
    })
    // antd config for message component
    message.config({ top: 45 })
  }, [])

  useEffect(() => {
    if (!firstLoad) return

    const hasToken = !!Cookies.get('token')

    if (!user && hasToken) return // still waiting on user to load, effect will rerun when ready

    if (!user) return // we don't have a user

    updateLastLogin(user._id)
    setFirstLoad(false)
  }, [user])

  return (
    <Providers>
      <>
        <Head>
          <title>Homelister Admin</title>
        </Head>
        <Layout>
          <Component
            {...pageProps}
            err={err}
          />
        </Layout>
      </>
    </Providers>
  )
}

export default App
