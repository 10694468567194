import styled from 'styled-components'

import SearchListings from 'modules/SearchListings'

const PageContainer = styled.div`
  padding: 10px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 10px 25px 25px;
  }
`

const Listings = () => (
  <PageContainer>
    <SearchListings />
  </PageContainer>
)

export default Listings
