import { Button, Row, Typography } from 'antd'
import { Spacer } from 'components'
import { FC, useState } from 'react'
import styled from 'styled-components'

const { Text, Paragraph } = Typography

interface IDisplayProp {
  display: 'inline' | 'block' | 'inline-flex'
}
interface IReadOnlyProp {
  readOnly: boolean
}

const StyledRow = styled(Row)<IDisplayProp>`
  display: ${props => props.display};
  .ant-typography {
    margin: 0;
  }
`

const StyledParagraph = styled(Paragraph)<IDisplayProp & IReadOnlyProp>`
  height: 100%;
  display: ${props => props.display};
  color: ${props => props.theme.textColor};
  cursor: ${props => (props.readOnly ? 'auto' : 'pointer')};
  white-space: normal;
`

const StyledButton = styled(Button)`
  font-size: inherit;
  font-weight: inherit;

  // antd paragraph expand button styling
  color: #1890ff;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
`

interface IProps {
  content: string | JSX.Element | JSX.Element[]
  readOnly: boolean
  label?: string
  onLabelClick?: () => void
}
const ExpandableParagraphFieldValue: FC<IProps> = ({ label, content, onLabelClick, readOnly }) => {
  const [expand, setExpand] = useState(false)
  const [counter, setCounter] = useState(0)

  const handleExpand = () => {
    setExpand(true)
    setCounter(!expand ? counter : counter + 1)
  }

  const handleClose = () => {
    setExpand(false)
    setCounter(!expand ? counter : counter + 1)
  }

  return (
    <StyledRow
      key={counter}
      display={expand ? 'inline' : 'inline-flex'}
      wrap={false}
    >
      {label && (
        <Text
          onClick={onLabelClick}
          style={{ paddingRight: '4px' }}
        >
          {label && `${label}:`}
        </Text>
      )}
      <StyledParagraph
        display={expand ? 'inline' : 'block'}
        readOnly={readOnly}
        ellipsis={{
          rows: 1,
          expandable: true,
          onExpand: handleExpand,
        }}
      >
        {content}
      </StyledParagraph>
      {expand
        ? (
          <div>
            <StyledButton
              type="link"
              onClick={handleClose}
            >
            Collapse
            </StyledButton>
          </div>
        )
        : (
          <Spacer size={5} />
        )}
    </StyledRow>
  )
}

export default ExpandableParagraphFieldValue
