import { Tabs } from 'antd'
import { FC } from 'react'

import * as T from 'types'

import AllNotes from './AllNotes'
import Notes from './Notes'
import SellerNotes from './SellerNotes'

const { TabPane } = Tabs

interface IProps extends T.IFontSize {
  listing: T.IListing
}

const ListingNotes: FC<IProps> = props => (
  <Tabs defaultActiveKey="all-notes">
    <TabPane
      tab="All Notes"
      key="all-notes"
    >
      <AllNotes {...props} />
    </TabPane>
    <TabPane
      tab="Agent Notes"
      key="agent-notes"
    >
      <Notes
        {...props}
        noteType="agentNotes"
      />
    </TabPane>
    <TabPane
      tab="Seller Notes"
      key="seller-notes"
    >
      <SellerNotes {...props} />
    </TabPane>
    <TabPane
      tab="Escrow Notes"
      key="escrow-notes"
    >
      <Notes
        {...props}
        noteType="escrowNotes"
      />
    </TabPane>
  </Tabs>
)

export default ListingNotes
