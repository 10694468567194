import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload/interface'
import Cookies from 'js-cookie'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { addAgreementToS3 } from 'services/documents'

import * as T from 'types'

const Container = styled.div`
  display: flex;
  align-items: center;
`

// the official types are here
// https://github.com/react-component/upload/blob/v4.3.1/src/interface.tsx#L56-L67
//
// but aren't exposed in antd.  Copying them here doesn't help us much either since we'd have to narrow the
// types to our use-case anyway, making the code harder to read than it's worth.
interface ICustomRequestOptions {
  onSuccess: (body: string) => void
  file: RcFile
}

interface IValue {
  filename: string
  url: string
}

interface IError {
  error: string
}

export type IOnChangeValue = IValue | IError | undefined

type IOnChange = (value: IOnChangeValue) => void

// onChange and value are required by antd to be used as a custom form control
// https://ant.design/components/form/#components-form-demo-customized-form-controls
//
// they need to be optional since otherwise we'd have to pass them explicitly in EditOwnerMOdal
interface IProps {
  listingId: string
  onChange?: IOnChange
  owner: T.IOwner
  value?: IValue
}

const OwnerAgreementUpload: FC<IProps> = props => {
  const { listingId, owner, value } = props
  const onChange = props.onChange as IOnChange // this will always be passed in via antd
  const token = Cookies.get('token')

  const fileList = useMemo(() => {
    if (!value) return []

    const { filename: name } = value

    const uploadFile: UploadFile = {
      name,
      status: 'done',
      uid: '1',
    }

    if (name === owner?.agreement?.filename) {
      uploadFile.url = `${
        process.env.API_URL
      }/amazons/listingDocument/${listingId}/owners.agreement/${owner.order}/${encodeURIComponent(
        name,
      )}?access_token=${token}`
    }

    return [uploadFile]
  }, [listingId, value?.filename, owner.order, token])

  const handleFileUpload = async (options: any) => {
    const { onSuccess, file } = options as ICustomRequestOptions

    if (file.name === owner?.agreement?.filename) {
      // this gets around an edge case where the user uploads a file with the same name but different content,
      // then cancels.  In that case the file is still uploaded - and since files are keyed by name the
      // contents are updated even though the user cancelled the action.
      return onChange({ error: 'Cannot upload a file with the same name as the previous file' })
    }

    const uploadedDoc = await addAgreementToS3([file], listingId, true, false)
    const { name, url } = uploadedDoc[0]
    if (!name || !url) return onChange({ error: 'Failed uploading the agreement' })

    onChange({ filename: name, url })

    return onSuccess('Successfully uploaded the agreement')
  }

  const handleFileRemove = () => onChange(undefined)

  return (
    <Container>
      <Upload
        customRequest={handleFileUpload}
        fileList={fileList}
        onRemove={handleFileRemove}
      >
        <Button
          icon={<UploadOutlined />}
          disabled={!!value?.filename}
        >
          Upload
        </Button>
      </Upload>
    </Container>
  )
}

export default OwnerAgreementUpload
