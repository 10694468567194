import Cookies from 'js-cookie'

import { api } from './api'

export const signinUser = async (email: string, password: string) => {
  try {
    const response = await api.login(email, password)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    Cookies.set('token', response.data.token, { expires: 14 })
    api.api.setHeader('Authorization', `Bearer ${response.data.token}`)

    return response
  } catch (error) {
    return { error, ok: false }
  }
}

export const changePassword = async (
  userId: string,
  oldPassword: string,
  newPassword: string,
): Promise<number | undefined> => {
  try {
    const response = await api.changePassword(userId, oldPassword, newPassword)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.status
  } catch (err) {
    console.error(err)
  }
}

export const updateLastLogin = async (userId: string) => {
  try {
    const response = await api.updateLastLogin(userId)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    return response
  } catch (error) {
    return { error, ok: false }
  }
}
