import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Alert, Button, Input, Typography } from 'antd'
import { Spacer } from 'components'
import { useRouter } from 'next/router'
import { FunctionComponent, MouseEvent, useEffect, useState } from 'react'
import { signinUser, useUser } from 'services'
import styled from 'styled-components'
import { mutate } from 'swr'

const { Password } = Input
const { Title } = Typography

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 0 15px;
  }
`

const FormContainer = styled.div`
  flex-basis: 365px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

interface ISigninCredentials {
  email: string
  password: string
  [key: string]: string
}
const initialCredentials: ISigninCredentials = {
  email: '',
  password: '',
}

const Signin: FunctionComponent = () => {
  const router = useRouter()

  const { user } = useUser()

  const [error, setError] = useState('')
  const resetError = () => setError('')

  const [isSigninLoading, setIsSigninLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [credentials, setCredentials] = useState<ISigninCredentials>(initialCredentials)

  const handleCredentialsChange = (name: string, value: string) => {
    if (error) resetError()

    const newCredentials = { ...credentials }
    newCredentials[name] = value
    setCredentials(newCredentials)
    if (submitted) setSubmitted(false)
  }

  const handleSubmit = async () => {
    setSubmitted(true)
    const { email, password } = credentials
    if (!email && !password) return setError('Email and password are required.')

    if (!email) return setError('Email is required.')

    if (!password) return setError('Password is required.')

    setIsSigninLoading(true)
    const response = await signinUser(email, password)

    if (!response.ok) {
      setIsSigninLoading(false)
      setError('Your email or password was incorrect. Please try again.')
    }

    // fetch the user, which will trigger a route chan
    mutate('/users/me')
  }

  const handleSigninSubmitClick = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    await handleSubmit()
  }

  useEffect(() => {
    if (user) router.replace('/listings')
  }, [user])

  return (
    <Container>
      <FormContainer>
        <Title>Sign In</Title>
        <StyledForm>
          <Input
            placeholder="Email"
            value={credentials.email}
            onChange={e => handleCredentialsChange('email', e.target.value)}
            onPressEnter={handleSubmit}
            data-testid="Email"
          />
          <Spacer size={10} />
          <Password
            value={credentials.password}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={e => handleCredentialsChange('password', e.target.value)}
            onPressEnter={handleSubmit}
            data-testid="Password"
          />
          <Spacer size={10} />
          <Button
            type="primary"
            onClick={handleSigninSubmitClick}
            block
            data-testid="SigninButton"
          >
            {isSigninLoading ? 'Signing in...' : 'Sign in'}
          </Button>
          <Spacer size={20} />
          {error && (
            <Alert
              message="Couldn't sign in"
              description={error}
              type="error"
              showIcon
            />
          )}
        </StyledForm>
      </FormContainer>
    </Container>
  )
}

export default Signin
