import { CSSProperties, FunctionComponent } from 'react'

import * as GT from 'types'

import * as T from './types'

// to use a rotation, you must provide explicit numerical width and height so that the SVG
// can center the rotation in the middle of the icon
const getRotations = (transform: T.Rotations, width: number, height: number): string => {
  // percent, etc values aren't supported and will not pass these conditions
  if (Number.isNaN(width)) return ''

  if (Number.isNaN(height)) return ''

  switch (transform) {
    case T.Rotations.right:
      return `rotate(90 ${width / 2} ${width / 2})`
    case T.Rotations.down:
      return `rotate(180 ${width / 2} ${height / 2})`
    case T.Rotations.left:
      return `rotate(270 ${height / 2} ${height / 2})`
    case T.Rotations.up:
    case T.Rotations.none:
    default:
      return ''
  }
}

// transform only supports rotation at this time, but could be expanded as needed
interface IProps {
  fill?: string
  stroke?: string
  width?: string | number
  height?: string | number
  className?: string
  viewBox?: string
  style?: CSSProperties
  transform?: T.Rotations
  [key: string]: GT.ValueOf<IProps>
}

const SVG: FunctionComponent<IProps> = ({
  fill = '#000',
  stroke = 'none',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 40 26',
  style = {},
  transform = T.Rotations.none,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      stroke={stroke}
      transform={transform ? getRotations(transform, Number(width), Number(height)) : ''}
      {...rest}
    />
  </svg>
)

export default SVG
