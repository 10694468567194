import { TableProps } from 'antd'
import { SorterResult } from 'antd/lib/table/interface'
import cloneDeep from 'lodash.clonedeep'
import { useEffect, useState } from 'react'

import { useUser } from 'services/swr'

import { getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage'

import * as T from 'types'
import * as E from 'types/enums'

/**
 * The purpose of this hook is to initialize and keep track of the user-initiated sorts for an antd
 * table and keep those sorted UI columns synchronized with our sorted backend fetch data. This is
 * not a use case antd seems to have considered in building Table, so we have to do a little hackery
 * to get it to work nicely for us.
 *
 * Regarding different types of sort variables: antd uses a concept called a `sorter` which is an
 * array of objects with a column and an order. We add a tag-along property `sortBy` to the column
 * data, which helps us figure out how to pass a `sort` query to our backend search API calls.
 *
 * NB: This hook is designed to return a `sort` that can be sent to *any* backend search API call
 * in `homelister-api` that implements `server/sharedMiddleware/makeSearchHandler.js`.
 */

// Helper functions

// for each element
const getSortOrder = (column: T.IColumn, sorterArray: SorterResult<T.IListing>[]) => {
  const sorter = sorterArray.find(sorterElement => sorterElement.column?.title === column.title)
  return sorter?.order
}

const getSort = (sorterArray: SorterResult<T.IListing>[]) => {
  const updatedSort = sorterArray.reduce(
    (result: string, sorterResult: SorterResult<T.IListing>) => {
      const column = sorterResult.column as T.IColumn | undefined
      if (!column?.sortBy) throw new Error('sortBy must exist on columns which have been sorted')

      const sortOrderPrefix = sorterResult.order === 'ascend' ? '' : '-'

      const sortStr = column.sortBy.map(key => sortOrderPrefix + key).join(' ')

      return result ? `${result} ${sortStr}` : sortStr
    },
    '',
  )

  return updatedSort
}

interface IModeInitialized {
  [key: string]: boolean
}

const defaultModeInitialized = Object.values(E.ListingStatusGroupings).reduce(
  (acc: IModeInitialized, curr) => {
    acc[curr] = false
    return acc
  },
  {},
)

type UseTableMultiSort = (argumentsObject: {
  key: string
  columns: T.IColumn[]
  // some tables are overloaded to display different columns based on mode.
  // others have only one set of solumns
  getColumnsForDisplay?: (key: any) => T.IColumn[]
}) => {
  displayColumnsWithSort: T.IColumn[]
  sort: string
  handleChange: TableProps<any>['onChange']
  handleClear: () => void
}

const useTableMultiSort: UseTableMultiSort = ({ key, columns, getColumnsForDisplay }) => {
  const { user: adminUser } = useUser()

  // sorter - antd Table's internal sort state data structure
  const [sorterArray, setSorterArray] = useState<SorterResult<T.IListing>[]>([])

  const handleChange: TableProps<T.IListing>['onChange'] = (
    _pagination,
    _filters,
    sorter,
    { action },
  ) => {
    if (action === 'sort') {
      const updatedSorterArray = Array.isArray(sorter) ? sorter : [sorter]
      setSorterArray(updatedSorterArray.filter(sorterElement => sorterElement.column))
    }
  }

  const handleClear = () => setSorterArray([])

  // columns we display for a given mode, with their synced sort data
  const [displayColumnsWithSort, setDisplayColumnsWithSort] = useState<T.IColumn[]>([])

  useEffect(() => {
    const columnsCopy = cloneDeep(getColumnsForDisplay ? getColumnsForDisplay(key) : columns)
    Object.values(columnsCopy).forEach(column => {
      // we sync the column state with what antd's onTableChange is giving us via its concept of a
      // "sorter". without this the sorting icons will be out of sync with what the data shows.
      column.sortOrder = getSortOrder(column, sorterArray)
    })
    setDisplayColumnsWithSort(columnsCopy)
  }, [key, adminUser?._id, sorterArray])

  // sort
  const [sort, setSort] = useState<string>('')
  const [modeInitialized, setModeInitialized] = useState<IModeInitialized>(defaultModeInitialized)

  // get our initial sort from local storage if it exists there
  // fetch it from local storage every time user changes the tab
  useEffect(() => {
    if (!key) return

    if (!adminUser?._id) return

    const updatedSorterArray
      = getFromLocalStorage<SorterResult<T.IListing>[]>(key, adminUser._id) || []

    const updatedSort = getSort(updatedSorterArray)

    setSort(updatedSort)
    setSorterArray(updatedSorterArray)
    setModeInitialized({ ...modeInitialized, [key]: true })
  }, [key, adminUser?._id])

  // update our sort whenever the sorter array or mode changes, after we've initialized
  useEffect(() => {
    if (!key || !modeInitialized[key]) return

    if (!adminUser?._id) return

    saveToLocalStorage(key, sorterArray, adminUser._id)

    const updatedSort = getSort(sorterArray)
    setSort(updatedSort)
  }, [key, sorterArray, adminUser?._id])

  return { sort, displayColumnsWithSort, handleChange, handleClear }
}

export default useTableMultiSort
