import { useState } from 'react'

import { getId, toggleAck } from 'services/changesRequiringAck'

import { ChangeRequiringAck } from '../../../types'

export function useAck() {
  const [updatedChanges, setUpdatedChanges] = useState<string[]>([])
  const [updatingChanges, setUpdatingChanges] = useState<string[]>([])

  const toggleChangeAck = async (change: ChangeRequiringAck) => {
    const id = getId(change)
    setUpdatingChanges(changes => {
      const updatingChangesSet = new Set(changes)
      return Array.from(updatingChangesSet.add(id))
    })
    setUpdatedChanges(changes => {
      const updatedChangesSet = new Set(changes)
      return Array.from(updatedChangesSet.add(id))
    })

    await toggleAck(change)

    setUpdatingChanges(changes => {
      const updatingChangesSet = new Set(changes)
      updatingChangesSet.delete(id)
      return Array.from(updatingChangesSet)
    })
  }

  const isChangeUpdating = (change: ChangeRequiringAck) => updatingChanges.includes(getId(change))

  const getUpdatedChanges = (changes: ChangeRequiringAck[]): ChangeRequiringAck[] =>
    changes.filter(c => updatedChanges.includes(getId(c)))

  return {
    getUpdatedChanges,
    toggleChangeAck,
    isChangeUpdating,
  }
}
