/**
 * Creates an object composed of the picked object properties.
 * @param obj The source object
 * @param keys The property paths to pick
 * @returns Returns the new object
 */
const pick = <T extends object, K extends keyof T>(obj: T, keys: K[]) => {
  const setOfKeys = new Set(keys)

  return Object.entries(obj).reduce((res, entry) => {
    const [key, val] = entry as [K, T[K]]
    if (setOfKeys.has(key)) res[key] = val

    return res
  }, {} as Partial<T>) as Pick<T, K>
}

/**
 * The opposite of _.pick; this method creates an object composed of the own and inherited enumerable property
 * paths of object that are not omitted
 * @param obj The source object
 * @param keys The property paths to omit
 * @returns Returns the new object
 */
const omit = <T extends object, K extends keyof T>(obj: T, keys: K[]) => {
  const setOfKeys = new Set(keys)

  return Object.entries(obj).reduce((res, entry) => {
    const [key, val] = entry as [K, T[K]]
    if (!setOfKeys.has(key)) res[key] = val

    return res
  }, {} as Partial<T>) as Omit<T, K>
}

export { omit, pick }
