/* eslint-disable import/prefer-default-export */
import produce from 'immer'
import { mutate } from 'swr'

import { isProduction } from 'utils/nodeEnv'

import * as T from 'types'

import { ListingStatus } from '../types/enums'
import { isErrorApiResponse, isSignatureRequestResponse } from '../types/typeNarrowing'
import { api } from './api'
import { getUpdateErrorMessage } from './swr/utils/errorMessageHelpers'

const ENDPOINT = '/listings/user'

export const createListing = async (
  data: T.ICreateListingData,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.createListing(data)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    // pre-fill melissa data and zillow data
    const populatedResponse = await api.getPropertyDetails(response.data._id)

    return populatedResponse.data
  } catch (error) {
    console.error({ error })
  }
}

// use the `update` function in `swr/useListing` when you need optimistic update/rollback
export const updateListing = async (
  listingId: string,
  update: { [key: string]: T.ValueOf<T.IListing> | null | undefined },
  options?: T.IListingApiOptions,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.updateListing(listingId, update, options)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    return response.data
  } catch (error) {
    if (!isProduction()) console.error({ error })
  }
}

export const patchListing = async (
  listingId: string,
  patch: { [key: string]: T.ValueOf<T.IListing> | null | undefined },
  options?: T.IListingApiOptions,
): Promise<T.IListing | undefined> => {
  try {
    const response = await api.patchListing(listingId, patch, options)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    /** @FIXME - the EditableFieldsModal was built incorrectly such that it calls this function
     *  directly, instead of the `update` function of the `useListing` hook. This means that we
     *  need to call the mutations for related listings here, instead of in the `useListing` hook.
     *
     *  Unfortunately, fixing this is a bit involved because if you swap out `update` into the
     *  EditableFieldsModal, it will cause UI bugs where the modal closes when it shouldn't.
     */
    mutate(`/listingChangeTask/listing/${listingId}`)
    if (options?.mutateHistoryRoutes) options.mutateHistoryRoutes()

    const { discountToAmountDueAtClosing, additionalAmountDueAtClosing }
      = (patch?.additionalPricing as T.IAdditionalPricing) ?? {}

    if (additionalAmountDueAtClosing || discountToAmountDueAtClosing) {
      mutate(`/orders/pricingDetails/${listingId}`)
    }

    return response.data
  } catch (error) {
    if (!isProduction()) console.error({ error })
  }
}

export const updateListingPhotos = async (
  listingId: string,
  photos: T.IPhoto[],
  category?: string, // @TODO: not sure what this is for but the api takes it
) => {
  try {
    const update: T.IListingPhotosUpdate = { update: { photos } }
    if (category) update.update.category = category

    const response = await api.updateListingPhotos(listingId, update)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    mutate(
      `/listings/${listingId}/photos?includeDeleted=true&returnAllPhotosForSoldListings=true`,
      response.data,
      false,
    )

    return response
  } catch (error) {
    return error
  }
}

export const findListingById = (draftListings: T.IListing[], id: string) =>
  draftListings.findIndex((listing: T.IListing) => listing._id === id)

export const saveRoom = async (room: T.IRoom, listing: T.IListing) => {
  try {
    if (!listing) throw new Error('Listing required')

    const response = await api.updateRoom(listing._id, room)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(
      `/listings/${listing._id}/ownerDetails`,
      response.data,
      true, // @FIXME: we're refetching the listing here, but not sure if we need to
    )
  } catch (err) {
    console.error(err)
  }
}

export const updateListingDocuments = async (
  pageListing: T.IListing,
  documents: T.IListingMlsDocument[],
) => {
  if (!pageListing) return

  // This is a curried producer: https://immerjs.github.io/immer/curried-produce. When the second
  // argument of mutate is a function, it passes in the cached current value stored for the key
  await mutate(
    ENDPOINT,
    produce((draftListings: T.IListing[]) => {
      const listingIndex = draftListings.findIndex(listing => listing._id === pageListing._id)
      if (listingIndex >= 0) draftListings[listingIndex].mlsList[0].documents = documents
    }),
    false,
  )
}

export const resetAgreement: T.IResetAgreement = async listingId => {
  const response = await api.resetAgreement(listingId)

  const userReadableError = 'Failed to reset agreement'
  const resetAgreementError = getUpdateErrorMessage(response, { userReadableError })

  await mutate(`/listings/${listingId}/ownerDetails`) // refetch listing data
  mutate(`/documents/listing/${listingId}`) // refetch documents
  return [response.data, resetAgreementError]
}

export const offerWasReceived = async (
  listingId: string,
): Promise<[T.IListing | undefined, Error | undefined]> => {
  try {
    const response = await api.offerWasReceived(listingId)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listingId}/ownerDetails`, response.data, false)
    return [response.data, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('Unknown error occured')
    return [undefined, err]
  }
}

export const removeListing = async (
  listingId: string,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.deleteListing(listingId)
    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    const key
      = '/listings/broker-search?statusIds=all&states=all&services=all&resultsNo=25&pageNo=0&tab=search&showFutureTasks=false'
    await mutate(key)

    return [true, undefined]
  } catch (error) {
    if (error instanceof Error) return [undefined, error]

    console.error('unknown error', error)
    return [undefined, undefined]
  }
}

export const cancel = async (
  listing: T.IListing,
  statuses: T.IListingStatus[],
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    if (listing.listingStatus.name === ListingStatus.draft) {
      const cancelledStatusId = statuses?.find(s => s.name === ListingStatus.cancelled)?._id
      if (!cancelledStatusId) throw new Error("Listing canceled status id wasn't found")

      const { data, ok } = await api.updateListingStatus(listing._id, {
        listingStatus: cancelledStatusId,
      })

      if (!ok) throw new Error("Listing wasn't cancelled because of an error")

      await mutate(`/listings/${listing._id}/ownerDetails`, data, false)
    } else {
      const { data, ok } = await api.signatureRequest(listing._id, {
        isCancelRequest: true,
        isAmendment: true,
      })

      if (!data) throw new Error("Listing wasn't cancelled because of an error")

      if (!ok && isErrorApiResponse(data)) throw new Error(data.message)

      if (isSignatureRequestResponse(data)) {
        await mutate(`/listings/${listing._id}/ownerDetails`, data.listing, false)
      }
    }

    return [true, undefined]
  } catch (error) {
    if (error instanceof Error) return [undefined, error]

    return [undefined, undefined]
  }
}
