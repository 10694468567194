/* global window */

declare const Blob: {
  prototype: Blob
  new (): Blob
  new (request: any, mime: { type: string }): Blob
}
export const downloadData = (
  data: any,
  filename = 'attachment.pdf',
  openInNewWindow = false,
  mimeType = 'application/pdf',
) => {
  if (!data) return

  const file = new Blob([data], { type: mimeType })

  if (openInNewWindow) {
    const fileUrl = window.URL.createObjectURL(file)
    window.open(fileUrl, '_blank')
    return
  }

  try {
    if (typeof window === 'undefined') return

    const a = window.document.createElement('a')
    const url = window.URL.createObjectURL(file)
    a.href = url
    a.download = filename
    a.setAttribute('download', filename)
    window.document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (err) {
    console.error('An error occurred while attempting to save file', err)
  }
}
