import { FC } from 'react'

import { useListingHistory } from 'services/swr'

import { orderBy } from 'utils/array'

import * as T from 'types'
import * as E from 'types/enums'

import HistoryPopover, { IGetHistoryValue } from './HistoryPopover'

type ListingHistory = T.IHistory<T.IChange | T.ITaskChange>[] | undefined

type GetHistory = (fieldHistory: ListingHistory) => ListingHistory

const getHistory: GetHistory = fieldHistory => {
  if (!fieldHistory) return

  return orderBy(
    fieldHistory,
    historyEntry => new Date(historyEntry.createdAt).getTime(),
    'desc',
  ).filter((entry, idx, history) => {
    if (idx === 0) return true

    const previousEntry = history[idx - 1]
    const currentChange = entry.changes[0] as T.IChange
    const previousChange = previousEntry.changes[0] as T.IChange

    // since we're including history items for when a listing is first published, and because a listing can be
    // "first published" multiple times, it's possible for there to be duplicate entries that we should
    // filter out
    return `${currentChange.newVal}` !== `${previousChange.newVal}`
  })
}

interface IProps {
  listing: T.IListing
  changeName: 'price' | 'commission'
  fieldValue: T.IChangeValue
  formatValue: (value: T.IChangeValue) => string
  customFilterByCategory?: E.HistoryCategory[]
}

const ListingFieldWithHistory: FC<IProps> = ({
  listing,
  changeName,
  fieldValue,
  formatValue,
  customFilterByCategory = [E.HistoryCategory.update],
}) => {
  const categoryFilter
    = customFilterByCategory.length > 1
      ? { $or: customFilterByCategory.map(category => ({ category })) }
      : { category: customFilterByCategory[0] }

  const { error: fieldHistoryError, history: fieldHistory } = useListingHistory(listing._id, {
    filter: { ...categoryFilter, 'changes.name': changeName },
    select: 'changes createdAt',
    sort: '-createdAt',
  })

  const history = getHistory(fieldHistory)

  const getHistoryValue: IGetHistoryValue = value => (value ? formatValue(value) : '---')

  const displayValue = fieldValue ? formatValue(fieldValue) : '---'

  return (
    <HistoryPopover
      getHistoryValue={getHistoryValue}
      history={history}
      swrError={fieldHistoryError}
    >
      {displayValue}
    </HistoryPopover>
  )
}

export default ListingFieldWithHistory
