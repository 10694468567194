import { useMemo } from 'react'

import { useStatuses } from 'services/swr'

import {
  // subsets
  activeListingStatuses,
  brokerReviewListingStatuses,
  closedListingStatuses,
  contractListingStatuses,
  expiringListingStatuses,
  newListingStatuses,
  openTaskListingStatuses, // all, sorted
  orderedListingStatuses,
} from 'data/listingStatusGroupings'

import * as T from 'types'
import * as E from 'types/enums'

const getStatuses = (statuses: T.IListingStatus[], statusGrouping: E.ListingStatus[]) => {
  if (!statuses?.length) return []

  const listingStatuses = [...statuses].filter(status => status.type === 'listing')
  return statusGrouping
    .map(name => listingStatuses.find(status => status.name === name))
    .filter(status => !!status) as T.IListingStatus[] // will always be status due to filter
}

const getStatusIds = (statuses: T.IListingStatus[]) => statuses?.map(status => status._id) ?? []

export const useOrderedListingStatuses = ({ includeDeleted = true } = {}) => {
  const statuses = useStatuses().statuses.filter(status =>
    includeDeleted ? true : !status.deleted,
  )

  const orderedStatuses = useMemo(() => getStatuses(statuses, orderedListingStatuses), [statuses])
  const orderedStatusIds = useMemo(() => getStatusIds(orderedStatuses), [orderedStatuses])
  return { orderedStatuses, orderedStatusIds }
}

export const useActiveListingStatuses = () => {
  const { statuses } = useStatuses()
  const activeStatuses = useMemo(() => getStatuses(statuses, activeListingStatuses), [statuses])
  const activeStatusIds = useMemo(() => getStatusIds(activeStatuses), [activeStatuses])
  return { activeStatuses, activeStatusIds }
}

export const useOpenTaskListingStatuses = () => {
  const { statuses } = useStatuses()
  const openTaskStatuses = useMemo(() => getStatuses(statuses, openTaskListingStatuses), [statuses])
  const openTaskStatusIds = useMemo(() => getStatusIds(openTaskStatuses), [openTaskStatuses])
  return { openTaskStatuses, openTaskStatusIds }
}

export const useNewListingStatuses = () => {
  const { statuses } = useStatuses()
  const newStatuses = useMemo(() => getStatuses(statuses, newListingStatuses), [statuses])
  const newStatusIds = useMemo(() => getStatusIds(newStatuses), [newStatuses])
  return { newStatuses, newStatusIds }
}

export const useBrokerReviewListingStatuses = () => {
  const { statuses } = useStatuses()

  const brokerReviewStatuses = useMemo(
    () => getStatuses(statuses, brokerReviewListingStatuses),
    [statuses],
  )

  const brokerReviewStatusIds = useMemo(
    () => getStatusIds(brokerReviewStatuses),
    [brokerReviewStatuses],
  )
  return { brokerReviewStatuses, brokerReviewStatusIds }
}

export const useContractListingStatuses = () => {
  const { statuses } = useStatuses()
  const contractStatuses = useMemo(() => getStatuses(statuses, contractListingStatuses), [statuses])
  const contractStatusIds = useMemo(() => getStatusIds(contractStatuses), [contractStatuses])
  return { contractStatuses, contractStatusIds }
}

export const useClosedListingStatuses = () => {
  const { statuses } = useStatuses()
  const closedStatuses = useMemo(() => getStatuses(statuses, closedListingStatuses), [statuses])
  const closedStatusIds = useMemo(() => getStatusIds(closedStatuses), [closedStatuses])
  return { closedStatuses, closedStatusIds }
}

export const useExpiringListingStatuses = () => {
  const { statuses } = useStatuses()
  const expiringStatuses = useMemo(() => getStatuses(statuses, expiringListingStatuses), [statuses])
  const expiringStatusIds = useMemo(() => getStatusIds(expiringStatuses), [expiringStatuses])
  return { expiringStatuses, expiringStatusIds }
}

export interface IStatusByKey {
  [key: string]: T.IListingStatus
}

export const useStatusById = (): IStatusByKey => {
  const { statuses } = useStatuses()

  return useMemo(
    () => statuses.reduce((result, status) => Object.assign(result, { [status._id]: status }), {}),
    [statuses],
  )
}

interface IStatusByNameResult {
  isInitialized: boolean
  statusByName: IStatusByKey
}

export const useStatusByName = (): IStatusByNameResult => {
  const { statuses } = useStatuses()

  return useMemo(
    () => ({
      isInitialized: !!statuses.length,
      statusByName: statuses.reduce(
        (result, status) => Object.assign(result, { [status.name]: status }),
        {},
      ),
    }),
    [statuses],
  )
}

const useGroupedListingStatuses = () => {
  const { orderedStatuses, orderedStatusIds } = useOrderedListingStatuses()
  const { activeStatuses, activeStatusIds } = useActiveListingStatuses()
  const { openTaskStatuses, openTaskStatusIds } = useOpenTaskListingStatuses()
  const { newStatuses, newStatusIds } = useNewListingStatuses()
  const { brokerReviewStatuses, brokerReviewStatusIds } = useBrokerReviewListingStatuses()
  const { contractStatuses, contractStatusIds } = useContractListingStatuses()
  const { closedStatuses, closedStatusIds } = useClosedListingStatuses()
  const { expiringStatuses, expiringStatusIds } = useExpiringListingStatuses()

  // useStatuses has resolved and all statuses have been grouped
  const statusesInitialized = useMemo(
    () =>
      !!orderedStatuses
      && !!activeStatusIds.length
      && !!openTaskStatusIds.length
      && !!newStatusIds.length
      && !!brokerReviewStatusIds.length
      && !!contractStatusIds.length
      && !!closedStatusIds.length
      && !!expiringStatusIds.length,
    [
      orderedStatuses,
      activeStatuses,
      openTaskStatuses,
      newStatuses,
      brokerReviewStatuses,
      contractStatuses,
      closedStatuses,
      expiringStatuses,
    ],
  )

  return {
    orderedStatuses,
    orderedStatusIds,
    activeStatuses,
    activeStatusIds,
    openTaskStatuses,
    openTaskStatusIds,
    newStatuses,
    newStatusIds,
    brokerReviewStatuses,
    brokerReviewStatusIds,
    contractStatuses,
    contractStatusIds,
    closedStatuses,
    closedStatusIds,
    expiringStatuses,
    expiringStatusIds,
    statusesInitialized,
  }
}

export default useGroupedListingStatuses
