import { cache, mutate } from 'swr'

const mutateCacheKeysByRegex = (matcher: RegExp, ...args: any) => {
  const keys: string[] = []

  const cacheKeyArr = Array.from(cache.keys())
  cacheKeyArr.forEach(key => {
    if (matcher.test(key)) keys.push(key)
  })

  const mutations = keys.map(key => mutate(key, ...args))
  return Promise.all(mutations)
}

export default mutateCacheKeysByRegex
