/* eslint-disable react/jsx-newline */
import { green, red } from '@ant-design/colors'
import { Col, Row, Typography } from 'antd'
import { Spacer } from 'components'
import dayjs from 'dayjs'
import { FunctionComponent, useEffect, useState } from 'react'
import { calculateTimeWithTimezone } from 'utils'
import { v4 as uuid } from 'uuid'

import { formatListingHistory, isTaskChange } from 'services/history'
import { useListingHistoryWithCreator, useListingStatus } from 'services/swr'

import * as T from 'types'

import HistoryLogChangeView from './HistoryLogChangeView'
import { SubsectionTitle } from './Styles'
import TableView from './TableView'

const { Text } = Typography

interface IChangeWithID extends T.IChange {
  _id?: string
}
interface ITaskChangeWithID extends T.ITaskChange {
  _id?: string
}
interface IHistoryLog {
  category: string
  date: string
  user: string
  userType: string | JSX.Element
  changes: (T.IChange | T.ITaskChange)[]
}

interface IProps {
  listing: T.IListing
}

const HistoryLog: FunctionComponent<IProps> = ({ listing }) => {
  const { history } = useListingHistoryWithCreator(listing._id)
  const { statuses } = useListingStatus()

  const [listingHistory, setListingHistory] = useState<T.IListingHistory | null>(null)
  const [historyLog, setHistoryLog] = useState<IHistoryLog[]>([])

  useEffect(() => {
    if (!history || !statuses) return

    setListingHistory({ ...formatListingHistory(history, statuses) })
  }, [history, statuses])

  useEffect(() => {
    if (!listingHistory) return

    // Format HistoryLog
    const newHistoryLog: IHistoryLog[] = []
    let prevHistoryLogEntry: IHistoryLog | undefined
    listingHistory.systemChanges.forEach((historyEntry: T.IHistory) => {
      let historyEntryCategory = historyEntry.category
      let historyLogEntryChanges: (T.IChange | T.ITaskChange)[] = []

      // Format history changes
      if (historyEntry.changes.length) {
        historyEntryCategory = historyEntry.changes[0].name

        historyLogEntryChanges = historyEntry.changes.map(change => ({
          ...change,
          entry: {
            ...historyEntry,
            changes: [],
          },
        }))

        historyLogEntryChanges[0].entry = { ...historyEntry }

        historyLogEntryChanges[0].entry.changes.forEach(change => {
          ;(change as IChangeWithID | ITaskChangeWithID)._id = uuid()
        })
      }

      // Append changes to history log if the prev and current history categories are the same.
      if (prevHistoryLogEntry?.category === historyEntryCategory) {
        prevHistoryLogEntry.changes.push(...historyLogEntryChanges)
      }

      // Create new history log if the previous one is of the same category
      if (
        !prevHistoryLogEntry?.category
        || !prevHistoryLogEntry.changes.length
        || prevHistoryLogEntry.category !== historyEntryCategory
      ) {
        const historyLogEntry = {
          category: historyEntryCategory,
          date: dayjs(historyEntry.createdAt).format('MM/DD/YY, h:mm A'),
          user: historyEntry?.creator?.name ?? 'System',
          // eslint-disable-next-line no-nested-ternary
          userType: historyEntry?.creator?.roles
            ? (
              <Text italic>{historyEntry.creator.roles.includes('admin') ? 'Admin' : 'Seller'}</Text>
            )
            : (
              ''
            ),
          changes: historyLogEntryChanges,
        }

        prevHistoryLogEntry = historyLogEntry // copied by reference
        newHistoryLog.push(historyLogEntry)
      }
    })

    setHistoryLog(newHistoryLog)
  }, [listingHistory])

  const columns = [
    {
      title: 'Type of change',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
  ]

  const isHistoryTransactionManagement = (entry: T.IHistory) =>
    [
      'Listing Agreement Completed',
      'Listing Amendment Completed',
      'Listing Amendment Signed',
    ].includes(entry.category)

  const renderInlineChanges = (entry: T.IHistory) => {
    const creatorName = entry.creator?.name ?? 'System'
    const createdAtShortDate = dayjs(entry.createdAt).format('MM/DD/YY, h:mm A') ?? ''

    return (
      <>
        {isHistoryTransactionManagement(entry) && <Text strong>AGREEMENT MODIFIED</Text>}
        <Text italic>{`${entry.category} at ${createdAtShortDate} by ${creatorName}`}</Text>
        {entry.category === 'Updated Listing Status' && !isTaskChange(entry.changes[0]) && (
          <>
            &nbsp;
            <Text strong>from</Text>
            &nbsp;
            <Text style={{ backgroundColor: red[2], padding: '2px' }}>
              {entry.changes[0].oldVal}
            </Text>
            &nbsp;
            <Text strong>to</Text>
            &nbsp;
            <Text style={{ backgroundColor: green[2], padding: '2px' }}>
              {entry.changes[0].newVal}
            </Text>
          </>
        )}
        {entry.category === 'Change request updated' && !isTaskChange(entry.changes[0]) && (
          <>
            &nbsp;
            <Text strong>{`: ${entry.changes[0].name}`}</Text>
            &nbsp;
            <Text>marked as</Text>
            &nbsp;
            <Text strong>{entry.changes[0].newVal ? 'complete' : 'incomplete'}</Text>
          </>
        )}
        {entry.category === 'Future Task Created' && isTaskChange(entry.changes[0]) && (
          <>
            <Text>:</Text>
            &nbsp;
            <Text strong>{entry.changes[0].name}</Text>
            <Text>,</Text>
            &nbsp;
            <Text strong>{entry.changes[0].description}</Text>
            <Text>Due on</Text>
            &nbsp;
            <Text strong>{dayjs(entry.changes[0].dueDate).format('MM/DD/YY, h:mm A')}</Text>
          </>
        )}
      </>
    )
  }

  const showChanges = (historyEntry: T.IHistory) =>
    ![
      'Added Photo',
      'Edited Photos',
      'Photos was modified',
      'Updated Listing Status',
      'Change request updated',
      'Future task created',
    ].includes(historyEntry.category)

  const renderHistoryLogs = (changes: (T.IChange | T.ITaskChange)[]) =>
    changes.map(change => {
      const entry = change.entry

      return (
        !!entry.changes.length && (
          <div
            key={entry._id}
            style={{ borderBottom: '1px solid rgba(1, 1, 1, 1)' }}
          >
            <div style={{ whiteSpace: 'normal' }}>{renderInlineChanges(entry)}</div>
            {showChanges(entry)
              && entry.changes.map(entryChange => (
                <div
                  key={(entryChange as IChangeWithID | ITaskChangeWithID)._id}
                  style={{ whiteSpace: 'normal', padding: '5px' }}
                >
                  <Text strong>{entryChange.name.toUpperCase()}</Text>
                  <Spacer size={10} />
                  <HistoryLogChangeView change={entryChange} />
                </div>
              ))}
          </div>
        )
      )
    })

  const expandable = {
    expandedRowRender: (record: IHistoryLog) => renderHistoryLogs(record.changes),
    rowExpandable: (record: IHistoryLog) => !!record.changes.length,
  }

  return listingHistory?.systemChanges.length
    ? (
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <SubsectionTitle>History Log</SubsectionTitle>
          <Row>
            <Col span={12}>
              <Text>
                <strong>Created Date: </strong>
                {calculateTimeWithTimezone(
                  new Date(listing.createdAt),
                  'America/New_York',
                  'MM/DD/YY, h:mm A z',
                )}
              </Text>
            </Col>
            <Col span={12}>
              <Text>
                <strong>Last Updated: </strong>
                {calculateTimeWithTimezone(
                  new Date(listing.updatedAt),
                  'America/New_York',
                  'MM/DD/YY, h:mm A z',
                )}
              </Text>
            </Col>
          </Row>
          <Spacer />
          {!!historyLog.length && TableView(historyLog, columns, expandable)}
        </Col>
      </Row>
    )
    : (
      <Text>There is currently no history for this listing.</Text>
    )
}

export default HistoryLog
