/* eslint-disable no-alert */
import { Radio, RadioChangeEvent } from 'antd'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import CustomRadioGroup from 'components/CustomRadioGroup'
import Spacer from 'components/Spacer'

import * as T from 'types'

import FieldListView from './FieldListView'
import { filterMlsFieldsValues, groupListingFieldsAlphabetically } from './utils'

const ELLIPSIS_FIELD_VALUE_THRESHOLD = 200

const CustomColumns = styled.div`
  column-count: 1;
  column-gap: 15px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    column-count: 2;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    column-count: 3;
  }
`

enum FilterStatuses {
  AllFields,
  FieldWithValues,
  FieldsWithoutValues,
}

interface IProps {
  fields: T.IMlsField[]
  listView: boolean
}

const MlsQuestions: FunctionComponent<IProps> = ({ fields: mlsFields, listView }) => {
  const { fieldsWithValues, fieldsWithoutValues } = useMemo(
    () => filterMlsFieldsValues(mlsFields),
    [mlsFields],
  )

  const groupedAllFields = useMemo(() => groupListingFieldsAlphabetically(mlsFields), [mlsFields])

  const groupedFieldsWithValues = useMemo(
    () => groupListingFieldsAlphabetically(fieldsWithValues),
    [mlsFields],
  )

  const groupedFieldsWithoutValues = useMemo(
    () => groupListingFieldsAlphabetically(fieldsWithoutValues),
    [mlsFields],
  )

  const [currentFields, setCurrentFields] = useState<T.IMlsField[] | undefined>()
  const [filterStatus, setFilterStatus] = useState(FilterStatuses.FieldWithValues)

  const filterByStatus = (status: FilterStatuses) => {
    switch (status) {
      case FilterStatuses.FieldWithValues:
        setFilterStatus(FilterStatuses.FieldWithValues)
        setCurrentFields(groupedFieldsWithValues)
        return
      case FilterStatuses.FieldsWithoutValues:
        setFilterStatus(FilterStatuses.FieldsWithoutValues)
        setCurrentFields(groupedFieldsWithoutValues)
        return
      case FilterStatuses.AllFields:
        setFilterStatus(FilterStatuses.AllFields)
        setCurrentFields(groupedAllFields)
        return
      default:
        setCurrentFields(undefined)
    }
  }

  const handleChange = (evt: RadioChangeEvent) => filterByStatus(evt.target.value)

  useEffect(() => {
    filterByStatus(filterStatus)
  }, [mlsFields])

  return (
    <>
      <CustomRadioGroup
        onChange={handleChange}
        size="small"
        value={filterStatus}
      >
        <Radio.Button value={FilterStatuses.FieldWithValues}>Fields With Values</Radio.Button>
        <Radio.Button value={FilterStatuses.FieldsWithoutValues}>
          Fields Without Values
        </Radio.Button>
        <Radio.Button value={FilterStatuses.AllFields}>Show All</Radio.Button>
      </CustomRadioGroup>
      <Spacer size={10} />
      <CustomColumns>
        {currentFields
          && currentFields.map((field, index) => (
            <FieldListView
              key={field._id}
              field={field}
              index={index}
              listView={listView}
              ellipsis={!!field.value && field.value.length > ELLIPSIS_FIELD_VALUE_THRESHOLD}
              removeTitle
            />
          ))}
      </CustomColumns>
    </>
  )
}

export default MlsQuestions
