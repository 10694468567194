import cookie from 'cookie'
import { ParsedUrlQuery } from 'querystring'

// dependency of next/router
import { getPackageLabel } from 'utils/listingHelpers'

import * as T from 'types'
import * as E from 'types/enums'

interface IProperty {
  [key: string]: any
}

interface IContext {
  cid?: string
  ua?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  gclid?: string
}

interface IParsedContext {
  cid?: string
  ua?: string
  gclid?: string
  campaign?: {
    name?: string
    medium?: string
    source?: string
  }
}

declare global {
  interface Window {
    analytics: any
    dotq: any
    ga: any
  }
}

/** helpers */

const isProductionCheck = () => {
  if (
    typeof window === 'undefined'
    || !window.analytics
    || !window.analytics.initialized
    || cookie.parse(document.cookie).disableAnalyticsTracking
  ) {
    return false
  }

  return true
}

/** Segment/Google Analytics */

export const page = () => {
  if (isProductionCheck()) {
    window.analytics.page()
  }
}

export const ready = (callback: () => void) => {
  window.analytics.ready(() => callback())
}

export const identify = (userId: string, traits: IProperty) => {
  const newTraits = {
    ...traits,
    siteVersion: 'react admin',
  }

  if (isProductionCheck()) {
    window.analytics.identify(userId, newTraits)
  }
}

export const reset = () => {
  if (isProductionCheck()) {
    window.analytics.reset()
  }
}

export const alias = (aid: string) => {
  if (isProductionCheck()) {
    window.analytics.alias(aid)
  }
}

export const track = (event: string, properties: IProperty = {}, context: IContext = {}) => {
  if (isProductionCheck()) {
    const parsedContext: IParsedContext = {
      cid: context.cid,
      ua: context.ua,
      gclid: context.gclid,
    }

    if (context.utmCampaign || context.utmMedium || context.utmSource) {
      parsedContext.campaign = {
        name: context.utmCampaign,
        medium: context.utmMedium,
        source: context.utmSource,
      }
    }

    window.analytics.track(
      event,
      {
        ...properties,
        appV2: true,
        siteVersion: 'react admin',
      },
      parsedContext,
    )
  }
}

export const trackBasicPackagePurchase = (
  listing: T.IListing,
  query: ParsedUrlQuery | T.IRefererParams = {},
) => {
  const price
    = Number(listing.pricingPackage.payNow || 0) + Number(listing.pricingPackage.payAtClose || 0)

  // setting the global variable for GTM to read the dynamic conversion value from it
  if (typeof window !== 'undefined' && window) {
    ;(window as any).globalPackageConversionValue = price
  }

  track(
    'goal.purchase_service_package.basic.fe',
    {
      listingId: listing._id,
      label: E.PackageType.basic,
      value: price,
      pricingPackageName: E.PackageType.basic,
      pricingPackagePayNow: listing.pricingPackage.payNow,
      pricingPackagePayAtClose: listing.pricingPackage.payAtClose,
      pricingPackageLabel: getPackageLabel(listing),
      type: 'Manual Agreement',
    },
    query,
  )
  if (window && window.ga) {
    window.ga(
      'gtm1.send',
      'event',
      '(FE) Basic service package purchased',
      'goal.purchase_service_package.basic.fe.test',
      E.PackageType.basic,
    )
  }
}
