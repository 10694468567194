import { Alert, Collapse, Row } from 'antd'
import { LoadingSpinner } from 'components'
import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { useCancelledContractDetails } from 'services/swr/useContractDetails'

import * as T from 'types'

import EscrowHistoryDocuments from './EscrowHistoryDocuments'

const CustomCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 14px;
    font-weight: 800;
    padding: 5px 20px;
  }

  &.ant-collapse-borderless > .ant-collapse-item {
    border-top: 1px solid ${props => props.theme.colors.grey5};
    border-bottom: none;
  }
`

const CustomCollapsePanel = styled(Collapse.Panel)`
  border: none;
  &.ant-collapse-item > .ant-collapse-content {
    background-color: white;
    .ant-collapse-content-box {
      padding: 5px 20px 10px 20px;
    }
  }
`

interface IProps {
  listing: T.IListing
}

const EscrowHistory: FunctionComponent<IProps> = ({ listing }) => {
  const { contractDetailsArr, error } = useCancelledContractDetails(listing._id)

  if (error) {
    return (
      <Row>
        <Alert
          message="Couldn't load previous escrows"
          // description="You must select at least one status to see tabular listing data"
          type="error"
          showIcon
        />
      </Row>
    )
  }

  if (!contractDetailsArr) return <LoadingSpinner />

  if (!contractDetailsArr.length) return <div>There are no cancelled escrows for this listing</div>

  return (
    <div>
      {contractDetailsArr.map(contractDetails => (
        <CustomCollapse
          key={`contractDetails-${contractDetails.id}-collapse`}
          bordered={false}
          ghost
        >
          <CustomCollapsePanel
            key={`contractDetails-${contractDetails.id}-collapse`}
            header={
              contractDetails.contractDate
                ? `Contract Date: ${dayjs(contractDetails.contractDate).format('MM/DD/YYYY')}`
                : `Creation Date (Contract Date Missing): ${dayjs(contractDetails.createdAt).format(
                  'MM/DD/YYYY',
                )}`
            }
          >
            <EscrowHistoryDocuments
              contractDetails={contractDetails}
              listingId={listing._id}
            />
          </CustomCollapsePanel>
        </CustomCollapse>
      ))}
    </div>
  )
}

export default EscrowHistory
