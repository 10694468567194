import { mutate } from 'swr'

import * as T from 'types'

import { api } from './api'

export const toggleFulfilled = async (
  orderId: string,
  serviceId: string,
  listingId: string,
  fulfilled: boolean,
): Promise<[T.IOrder | undefined, Error | undefined]> => {
  try {
    const response = await api.toggleFulfilled(orderId, serviceId, listingId, fulfilled)
    if (!response.data || !response.ok) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/orders/listing/${listingId}`)
    await mutate(`/orders/${orderId}`, response.data, false)

    return [response.data, undefined]
  } catch (error) {
    if (error instanceof Error) return [undefined, error]

    console.error('unknown error:', error)
    return [undefined, undefined]
  }
}

export const removeOrder = async (
  orderId: string,
  listingId?: string,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.removeOrder(orderId)

    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/orders/listing/${listingId}?includeDeleted=true`)
    await mutate(`/orders/${orderId}`)

    return [true, undefined]
  } catch (error) {
    console.error(error)
    if (error instanceof Error) return [undefined, error]

    return [undefined, undefined]
  }
}

export const removeOrderService = async (
  orderId: string,
  service: T.IService,
  listing: T.IListing,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.removeOrderService(orderId, service._id, listing._id)

    if (!response.ok) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/orders/listing/${listing._id}?includeDeleted=true`)
    await mutate(`/orders/listing/${listing._id}`)
    await mutate(`/orders/${orderId}`)

    return [true, undefined]
  } catch (error) {
    console.error(error)
    if (error instanceof Error) return [undefined, error]

    return [undefined, undefined]
  }
}
