import { ThemeInterface } from 'themes'

// components might end up used in a non-listing page.  In that case fontSize shouldn't be set and be
// inherited like normal.
const themeFontSize = (props: { theme: ThemeInterface }) =>
  props.theme.fontSize ? `font-size: ${props.theme.fontSize}px;` : ''

const defaultFontSize = (fontSize?: number) => (props: { theme: ThemeInterface }) => {
  const value = fontSize || props.theme.fonts.defaultFontSize
  return `font-size: ${value}px;`
}

export { themeFontSize, defaultFontSize }
