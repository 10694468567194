import { message } from 'antd'
import cloneDeep from 'lodash.clonedeep'

import { getFieldValueFromListing, getPrimaryMls } from 'utils/listingHelpers'

import * as T from 'types'
import * as E from 'types/enums'

import { EditableField } from './types'

const isNestedObjectProperty = (name: string) => name.split('.').length > 1

const parseNestedObjectByPropName = (name: string, listing: T.IListing) => {
  const objectName = name.split('.')[0]
  const currentSubObject = (listing[objectName] || {}) as Partial<T.IListing>
  const propName = name.split('.')[1]
  return { objectName, propName, currentSubObject }
}

// gets value from the temporary upd object or listing
export const getValueForInput = (
  listingObj: T.IListing,
  fld: T.IMlsField,
  agreement?: T.IListingMlsDocument | T.IListingDocument,
  contractDetails?: T.IContractDetails,
) => {
  if (!listingObj) return

  const { isCoreField, isAgreementField, isContractDetailsField } = fld
  const _id = fld.fieldId?._id
  const name = String(fld.fieldId?.name)

  if (isContractDetailsField) {
    // if we don't have an update yet, return the value from the latest contract details
    // else return the update value that hasn't been saved to DB yet
    if (!Object.keys(listingObj).length) return contractDetails?.[name]

    return listingObj?.[name]
  }

  let fieldValue: EditableField = null

  if (isCoreField) {
    if (isNestedObjectProperty(name)) {
      const { propName, currentSubObject } = parseNestedObjectByPropName(name, listingObj)
      fieldValue
        = typeof currentSubObject === 'object'
        && currentSubObject
        && `${propName}` in currentSubObject
          ? currentSubObject[propName]
          : null
    } else {
      fieldValue = listingObj[String(name)]
    }
  } else if (isAgreementField) {
    fieldValue = (agreement as any)?.[name] ?? null
  } else {
    fieldValue = getFieldValueFromListing(listingObj, name, _id)
  }

  return fieldValue
}

export const generateNewFieldUpdate = (
  fld: T.IMlsField,
  value: string,
  fieldUpdate: T.IListing,
  listing: T.IListing,
) => {
  const {
    fieldId,
    isAgreementField,
    isCoreField,
    isCoreFieldBoolean,
    isContractDetailsField,
    castTo,
  } = fld

  if (!fieldId) {
    message.error('fieldId is required to save the value')
    return fieldUpdate
  }

  const name = String(fieldId?.name)
  let newVal: string | number | boolean | null

  if (value && fieldId?.type === 'switch' && (isCoreField || isContractDetailsField)) {
    newVal = value // value is string Yes/No, undefined, or true/false
    // listing core fields are stored as string Yes/No sometimes and booleans others
    if (typeof value === 'boolean') {
      newVal = value ? 'Yes' : 'No'
    }

    if (isCoreFieldBoolean) newVal = value === 'Yes' // convert to boolean

    if (isContractDetailsField) newVal = value === 'Yes' // should be boolean type
  } else if (value === 'undefined' && fieldId?.type === 'date' && isCoreField) {
    newVal = null
  } else {
    newVal = castTo === 'number' ? Number(value) : String(value)
  }

  // for agreement fields updates we need to either update agreementOverride or MLS List
  if (isAgreementField) {
    // handling regular agreement in MLS List
    const mlsList = listing.mlsList
    const { primaryMlsIndex } = getPrimaryMls(listing)

    if (mlsList && primaryMlsIndex >= 0) {
      const docs = mlsList[primaryMlsIndex].documents || []
      const agreementIndex = docs.findIndex((doc: T.IListingMlsDocument) => doc.listingAgreement)
      docs[agreementIndex] = {
        ...docs[agreementIndex],
        [name]: value,
      }
      mlsList[primaryMlsIndex].documents = docs
      return {
        ...fieldUpdate,
        mlsList,
      }
    }

    return fieldUpdate
  }

  // processing core fields
  if (isCoreField || isContractDetailsField) {
    // for nested objects values, e.g. photographer.phoneNumber
    if (isNestedObjectProperty(name)) {
      const { objectName, propName, currentSubObject } = parseNestedObjectByPropName(
        name,
        fieldUpdate,
      )

      const newUpd = {
        ...fieldUpdate,
        [objectName]: {
          ...currentSubObject,
          [propName]: newVal,
        },
      } as T.IListing
      return newUpd
    }

    return {
      ...fieldUpdate,
      [name]: newVal,
    }
  }

  // processing regular MLS Fields
  const flds: T.IField[] = cloneDeep(fieldUpdate.fields || listing.fields)

  const fieldIndex: number = flds.findIndex(
    (clonedField: T.IField) => clonedField.fieldId?._id === fieldId?._id,
  )

  if (fieldIndex >= 0 && flds[fieldIndex]) {
    flds[fieldIndex].value = String(value)
  } else if (fld.fieldId) {
    flds.push({
      fieldId: fld.fieldId,
      value: String(value),
    })
  }

  return {
    ...fieldUpdate,
    fields: flds,
  }
}

export const parseChecklistData = (fieldValue: EditableField) => {
  let parsedValue: T.IChecklistValue = {}

  try {
    parsedValue = fieldValue ? JSON.parse(String(fieldValue)) : {}
  } catch (e) {
    parsedValue = {}
  }

  const selectedOptions = Object.keys(parsedValue).filter((key: string) => !!parsedValue[key])
  return { parsedValue, selectedOptions }
}

export const generateNewFieldUpdateForRoomDimensions = (
  name: string,
  updateType: keyof T.IRoomProps,
  value: number | E.RoomLocation | null,
  fieldUpdate: T.IListing,
  listing: T.IListing,
) => {
  // processing regular MLS Fields
  const rooms: T.IRoom[] = cloneDeep(fieldUpdate.rooms || listing.rooms || [])
  const roomIndex: number = rooms.findIndex((clonedRoom: T.IRoom) => clonedRoom.name === name)

  if (roomIndex >= 0 && rooms[roomIndex]) {
    const room = rooms[roomIndex]
    if (updateType === 'depth' || updateType === 'width') room[updateType] = value as number
    else room[updateType] = value as E.RoomLocation
  } else {
    rooms.push({
      name,
      [updateType]: value,
    })
  }

  return {
    ...fieldUpdate,
    rooms,
  }
}
