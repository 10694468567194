import groupBy from 'lodash.groupby'
import { mutate } from 'swr'

import * as T from 'types'
import { IHistoryRequiringAck } from 'types'

import { api } from './api'

export const updateHistory = async (
  listing: T.IListing,
  data: T.IUpdateHistory,
): Promise<[T.IHistory[] | undefined, Error | undefined]> => {
  try {
    const response = await api.updateHistory(listing, data)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listing._id}/history`, response.data)

    return [response.data, undefined]
  } catch (error) {
    if (error instanceof Error) return [undefined, error]

    console.error('unknown error:', error)
    return [undefined, undefined]
  }
}

const isListingChange = (change: T.IChange | T.ITaskChange): change is T.IChange =>
  change.name !== 'task'

const formatUpdateListingStatus = (
  historyEntry: T.IHistory,
  statuses: T.IListingStatus[],
): (T.IChange | T.ITaskChange)[] =>
  historyEntry.changes.map((change: T.IChange | T.ITaskChange) => {
    if (isListingChange(change)) {
      statuses.forEach((status: T.IListingStatus) => {
        if (status._id === change.oldVal) change.oldVal = status.name
        else if (status._id === change.newVal) change.newVal = status.name
      })
    }

    return change
  })

const getHistoryUpdates = (history: T.IHistory[]) => {
  enum changelogCategories {
    update = 'Update',
    addedPhoto = 'Added Photo',
    removedPhoto = 'Removed Photo',
    modifiedPhotosOrder = 'Modified Photos Order',
    futureTaskCreated = 'Future task created',
  }

  enum ignoreChangelogNames {
    openHouseDates = 'openHouseDates',
    agentNotes = 'agentNotes',
    escrowNotes = 'escrowNotes',
    agreementOverrideDocument = 'agreementOverrideDocument',
    openHouseVisitors = 'visitors',
    sellerNotes = 'agentSellerNotes',
    analyticsTrackWasCalled = 'analyticsTrackWasCalled',

    // Service fulfillment changes
    photographer = 'photographer',
    sign = 'sign',
    openHouseSignsOrdered = 'openHouseSignsOrdered',
    flyerProvided = 'flyerProvided',
    openHouseSignsTrackingInformation = 'openHouseSignsTrackingInformation',
    lockboxTrackingInformation = 'lockboxTrackingInformation',
    lockboxOrdered = 'lockboxOrdered',
    measurementAppraisalCompany = 'measurementAppraisalCompany',
    mlsRulesAgreed = 'mlsRulesAgreed',
  }

  return history
    .filter((historyEntry: T.IHistory) =>
      Object.values<string>(changelogCategories).includes(historyEntry.category),
    )
    .map((entry: T.IHistory) =>
      entry.changes
        .filter(
          (changeEntry: T.IChange | T.ITaskChange) =>
            !Object.values<string>(ignoreChangelogNames).includes(changeEntry.name),
        )
        .map((changeEntry: T.IChange | T.ITaskChange) => ({
          ...changeEntry,
          entry,
          visible: false,
        })),
    )
}

const getSystemChanges = (history: T.IHistory[]) =>
  history.map((val: T.IHistory) => ({ ...val, changes: val.changes }))

export const formatListingHistory = (
  history: T.IHistory[] = [],
  statuses: T.IListingStatus[] = [],
) => {
  const modifiedHistoryObject = [...history]
  modifiedHistoryObject.forEach((entry: T.IHistory) => {
    if (entry.category === 'Updated Listing Status') {
      entry.changes = formatUpdateListingStatus(entry, statuses)
    }
  })

  modifiedHistoryObject.sort((a: T.IHistory, b: T.IHistory) => {
    const dateB = new Date(b.createdAt)
    const dateA = new Date(a.createdAt)
    return dateB.getTime() - dateA.getTime()
  })

  const updates = getHistoryUpdates(modifiedHistoryObject)

  const listingChanges: T.IListingChanges = groupBy(
    updates.flat(),
    (change: T.IChange | T.ITaskChange) => (change as T.IChange).subcategory || change.name,
  )

  const systemChanges = getSystemChanges(modifiedHistoryObject)
  return { listingChanges, systemChanges }
}

export const isTaskChange = (
  changeEntry: T.IChange | T.ITaskChange,
): changeEntry is T.ITaskChange => changeEntry.name === 'task'

export const getNumberOfIncompleteChanges = (
  history: T.IHistory[],
  statuses: T.IListingStatus[],
) => {
  const { listingChanges } = formatListingHistory(history, statuses)
  return Object.keys(listingChanges).filter(changeCategory => {
    const changes = listingChanges[changeCategory]
    return changes.some(change => !change?.entry?.mlsUpToDate)
  }).length
}

export const toggleHistoryAck = async (
  historyRequiringAck: IHistoryRequiringAck,
): Promise<[T.IHistoryRequiringAck | undefined, undefined | Error]> => {
  try {
    const response = await api.toggleHistoryRequiringAck(historyRequiringAck)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${historyRequiringAck.listingId}/historyRequiringAck`)
    return [response.data, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('Unknown error occurred')
    return [undefined, err]
  }
}
