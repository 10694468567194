import * as E from 'types/enums'

const activeListingStatuses = [
  E.ListingStatus.listingDetailsUpdated,
  E.ListingStatus.brokerReview,
  E.ListingStatus.readyForSubmission,
  E.ListingStatus.published,
  E.ListingStatus.needsRevision,
  E.ListingStatus.inProgress,
  E.ListingStatus.contractUpdated,
  E.ListingStatus.remove,
  E.ListingStatus.amendmentNeeded,
  E.ListingStatus.amendmentSent,
  E.ListingStatus.offerAcceptedNYIC,
  E.ListingStatus.attorneyReview,
  E.ListingStatus.contractUpdated,
  E.ListingStatus.sellerInfoRequested,
  E.ListingStatus.inContract,
  E.ListingStatus.needsInput,
  E.ListingStatus.readyForPublish,
  E.ListingStatus.newEscrow,
]

const openTaskListingStatuses = [
  E.ListingStatus.amendmentNeeded,
  E.ListingStatus.amendmentSent,
  E.ListingStatus.cancellationSigned,
  E.ListingStatus.contractUpdated,
  E.ListingStatus.escrowClosed,
  E.ListingStatus.listingDetailsUpdated,
  E.ListingStatus.needsRevision,
  E.ListingStatus.newEscrow,
  E.ListingStatus.remove,
]

const newListingStatuses = [
  E.ListingStatus.amendmentNeeded,
  E.ListingStatus.draft,
  E.ListingStatus.initialReviewFailed,
  E.ListingStatus.inProgress,
  E.ListingStatus.listingDetailsUpdated,
  E.ListingStatus.needsInput,
  E.ListingStatus.readyForPublish,
  E.ListingStatus.readyForSubmission,
  E.ListingStatus.sellerInfoRequested,
]

const brokerReviewListingStatuses = [
  E.ListingStatus.brokerReview,
  E.ListingStatus.attorneyReview,
  E.ListingStatus.contractUpdated,
]

const contractListingStatuses = [
  E.ListingStatus.offerAcceptedNYIC,
  E.ListingStatus.inContract,
  E.ListingStatus.attorneyReview,
  E.ListingStatus.contractUpdated,
  E.ListingStatus.newEscrow,
  E.ListingStatus.escrowClosed,
]

const closedListingStatuses = [E.ListingStatus.closedNotPaid, E.ListingStatus.escrowClosed]

const expiringListingStatuses = [
  E.ListingStatus.published,
  E.ListingStatus.inProgress,
  E.ListingStatus.listingDetailsUpdated,
  E.ListingStatus.contractUpdated,
  E.ListingStatus.amendmentNeeded,
  E.ListingStatus.amendmentSent,
  E.ListingStatus.offerAcceptedNYIC,
  E.ListingStatus.attorneyReview,
  E.ListingStatus.inContract,
  E.ListingStatus.newEscrow,
  E.ListingStatus.brokerReview,
  E.ListingStatus.needsRevision,
  E.ListingStatus.tempHold,
]

// all statuses, in the order in which users typically move through them
const orderedListingStatuses = [
  E.ListingStatus.draft,
  E.ListingStatus.readyForSubmission,
  E.ListingStatus.initialReviewFailed,
  E.ListingStatus.sellerInfoRequested,
  E.ListingStatus.needsInput,
  E.ListingStatus.readyForPublish,
  E.ListingStatus.inProgress,
  E.ListingStatus.published,
  E.ListingStatus.listingDetailsUpdated,
  E.ListingStatus.contractUpdated,
  E.ListingStatus.amendmentNeeded,
  E.ListingStatus.amendmentSent,
  E.ListingStatus.offerAcceptedNYIC,
  E.ListingStatus.attorneyReview,
  E.ListingStatus.inContract,
  E.ListingStatus.newEscrow,
  E.ListingStatus.escrowClosed,
  E.ListingStatus.closedNotPaid,
  E.ListingStatus.sold,
  E.ListingStatus.remove,
  E.ListingStatus.cancellationSigned,
  E.ListingStatus.cancelled,
  E.ListingStatus.leased,
  E.ListingStatus.expired,
  E.ListingStatus.referred,
  E.ListingStatus.brokerReview,
  E.ListingStatus.needsRevision,
  E.ListingStatus.tempHold,
]

export {
  activeListingStatuses,
  openTaskListingStatuses,
  newListingStatuses,
  brokerReviewListingStatuses,
  contractListingStatuses,
  closedListingStatuses,
  expiringListingStatuses,
  orderedListingStatuses,
}
