/* eslint-disable no-alert */
import { cyan, grey, yellow } from '@ant-design/colors'
import { FileTextOutlined, UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Row, Upload } from 'antd'
import dayjs from 'dayjs'
import { FunctionComponent, useMemo, useState } from 'react'
import styled from 'styled-components'
import { getAmendmentsFromListing } from 'utils'

import CustomButton from 'components/CustomButton'
import LoadingSpinner from 'components/LoadingSpinner'
import Spacer from 'components/Spacer'

import { addDocumentToListing } from 'services/documents'
import { previewAmendment, sendSignatureReminderEmail, signatureRequest } from 'services/hellosign'
import { useUser } from 'services/swr'
import { useDocuments } from 'services/swr/useDocuments'
import { useAgreement } from 'services/hooks'

import * as T from 'types'
import * as E from 'types/enums'

import { SubsectionTitle } from './Styles'
import TableView from './TableView'

const { Dragger } = Upload

const DocumentNameWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    white-space: normal;
  }
`

const UploadContainer = styled.div`
  width: 100%;
`

const UploadDragAndDrop = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    height: unset;
  }
`

interface IProps {
  listing: T.IListing
}

const Amendment: FunctionComponent<IProps> = ({ listing }) => {
  // NB - this is the admin user, not sure you want to be passing this admin to previewAmendment
  const { user } = useUser()
  const { listingDocuments } = useDocuments(listing._id)
  const amendments = getAmendmentsFromListing(listing, listingDocuments)

  const [loading, setLoading] = useState('')
  const [alertMessage, setAlertMessage] = useState<T.IAlert | null>(null)
  const [updating, setUpdating] = useState(false)

  const { agreement } = useAgreement(listing, listingDocuments)

  const openAmendment = (doc: T.IListingMlsDocument | T.IListingDocument) => {
    if (doc.signatureRequestId) {
      window.open(`${process.env.API_URL}/hellosigns/preview/${doc.signatureRequestId}`, '_blank')
    } else {
      window.open(`${process.env.API_URL}/amazons/viewDocument/${doc._id}`, '_blank')
    }
  }

  const amendmentsWithSigners = amendments.filter(
    amendment => !!(amendment as T.IListingMlsDocument).signers,
  )

  const canSendReminder = !!amendmentsWithSigners.find(amendment =>
    (amendment as T.IListingMlsDocument).signers.some(signer => !signer.signed),
  )

  const amendmentColumns = useMemo(
    () => [
      {
        title: 'Name',
        width: '40%',
        render: (doc: T.IListingMlsDocument | T.IListingDocument) => (
          <DocumentNameWrapper>
            <FileTextOutlined
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() => openAmendment(doc)}
            />
            <span title={doc.name}>{doc.name}</span>
          </DocumentNameWrapper>
        ),
      },
      {
        title: 'Signed?',
        render: (doc: T.IListingMlsDocument | T.IListingDocument) =>
          (doc as T.IListingMlsDocument).signers?.map((signer: T.ISigner) => (
            <div key={signer.email}>{`${signer.name}: ${signer.signed ? '✔' : '✖'}`}</div>
          )),
      },
      {
        title: 'Signed At',
        render: (doc: T.IListingMlsDocument | T.IListingDocument) =>
          (doc as T.IListingMlsDocument).signers?.map((signer: T.ISigner) => {
            const signTime
              = signer && signer.signedAt ? dayjs(signer.signedAt).format('MM/DD/YY, h:mm A') : '---'
            return <div key={signer.email}>{signTime}</div>
          }),
      },
      {
        title: 'Signer Email',
        render: (doc: T.IListingMlsDocument | T.IListingDocument) =>
          (doc as T.IListingMlsDocument).signers?.map((signer: T.ISigner) => {
            const signerEmail = signer && signer.email ? `${signer.email}` : '---'
            return <div key={signer.email}>{signerEmail}</div>
          }),
      },
      {
        title: 'Signer Phone',
        render: (doc: T.IListingMlsDocument | T.IListingDocument) =>
          (doc as T.IListingMlsDocument).signers?.map((signer: T.ISigner) => {
            const owner = listing.owners.find((ownr: T.IOwner) => ownr.email === signer?.email)

            const signerEmail = signer && owner && owner.phone ? `${owner.phone}` : '---'
            return <div key={signer.email}>{signerEmail}</div>
          }),
      },
    ],
    [],
  )

  const handleFileUpload = async (
    options: any /* Antd's RcFile typings seem to be incorrect */,
  ) => {
    const { file, onSuccess } = options

    setUpdating(true)
    const uploadedDoc: T.IListingDocument[] = await addDocumentToListing(
      [file],
      listing._id,
      true,
      'Manual Amendment',
    )

    if (!uploadedDoc || !uploadedDoc.length) {
      return setAlertMessage({
        message: 'Failed uploading the document.',
        type: 'error',
      })
    }

    setUpdating(false)
    onSuccess('OK')
  }

  const guardAgreementGeneration = () => {
    if (!agreement?.signedDate) {
      window.alert('Agreement signed date is missing, please Update Agreement Fields')
      return false
    }

    const missingData = []
    if (!listing.owners) missingData.push('owners')

    if (!listing.street) missingData.push('street')

    if (!listing.city) missingData.push('city')

    if (!listing.state) missingData.push('state')

    if (!listing.zip) missingData.push('zip')

    if (!listing.price) missingData.push('price')

    if (!listing.commission) missingData.push('commission')
    // if (!listing.ownerType) missingData.push('ownerType')

    if (missingData.length) {
      window.alert(
        `Listing data required to create an amendement is missing: ${missingData.join(', ')}`,
      )
      return false
    }

    return true
  }

  const handlePreviewAmendment = async () => {
    if (!agreement || !user) return

    if (!guardAgreementGeneration()) return

    setLoading(E.AgreementSetting.PreviewAmendment)
    try {
      await previewAmendment(listing, agreement, user)
    } catch (error) {
      if (error instanceof Error) {
        setAlertMessage({
          message: error.message,
          type: 'error',
        })
      }
    }

    setLoading('')
  }

  const handleSendExtension = async () => {
    if (!guardAgreementGeneration()) return

    let message: T.IAlert | null = null
    setLoading(E.AgreementSetting.SendExtension)
    try {
      const result = await signatureRequest(listing._id, {
        isAmendment: true,
        setting: E.AgreementSetting.SendExtension,
      })
      if (result) message = { message: 'Listing extension amendment created!', type: 'success' }
    } catch (error) {
      message = { message: 'There was an error creating listing extension.', type: 'error' }
    }

    setAlertMessage(message)
    setLoading('')
  }

  const handleSendAmendment = async () => {
    if (!guardAgreementGeneration()) return

    let message: T.IAlert | null = null
    setLoading(E.AgreementSetting.SendAmendment)
    try {
      const result = await signatureRequest(listing._id, { isAmendment: true })
      if (result) message = { message: 'Listing amendment is sent!', type: 'success' }
    } catch (error) {
      message = { message: 'There was an error sending listing amendment.', type: 'error' }
    }

    setAlertMessage(message)
    setLoading('')
  }

  const handleSendReminder = async () => {
    try {
      await sendSignatureReminderEmail(listing._id, { isAgreement: false })
      setAlertMessage({ type: 'success', message: 'Signature reminder sent!' })
      setTimeout(() => setAlertMessage(null), 3000)
    } catch (err) {
      console.error(err)
      setAlertMessage({
        type: 'error',
        message: 'An error occurred attempting to send the reminder email',
      })
    }
  }

  const cleanMessage = () => setAlertMessage(null)

  return (
    <>
      <Row>
        <Col span={24}>
          <SubsectionTitle>
            <div>Amendment History</div>
          </SubsectionTitle>
          {TableView(amendments, amendmentColumns)}
        </Col>
      </Row>
      <Spacer size={15} />
      <Row align="middle">
        <CustomButton
          backgroundColor={cyan[2]}
          backgroundColorOnHover={cyan[3]}
          custom
          disabled={!agreement}
          onClick={handlePreviewAmendment}
          loading={loading === E.AgreementSetting.PreviewAmendment}
        >
          Preview Amendment
        </CustomButton>
        <Spacer size={10} />
        <CustomButton
          backgroundColor={yellow[4]}
          backgroundColorOnHover={yellow[5]}
          custom
          disabled={!agreement}
          onClick={handleSendAmendment}
          loading={loading === E.AgreementSetting.SendAmendment}
        >
          Send Amendment
        </CustomButton>
        <Spacer size={10} />
        <CustomButton
          backgroundColor={yellow[4]}
          backgroundColorOnHover={yellow[5]}
          custom
          disabled={!agreement}
          onClick={handleSendExtension}
          loading={loading === E.AgreementSetting.SendExtension}
        >
          Send Extension
        </CustomButton>
        <Spacer size={10} />
        <CustomButton
          backgroundColor={canSendReminder ? yellow[4] : grey[1]}
          backgroundColorOnHover={canSendReminder ? yellow[5] : grey[4]}
          custom
          onClick={handleSendReminder}
          disabled={!canSendReminder}
        >
          Send Reminder
        </CustomButton>
        {alertMessage && (
          <>
            <Spacer size={15} />
            <Alert
              style={{ height: 32 }}
              message={alertMessage.message}
              type={alertMessage.type}
              showIcon
              closable
              onClose={cleanMessage}
            />
          </>
        )}
      </Row>
      <Row>
        <Spacer />
        <UploadContainer>
          <UploadDragAndDrop
            name="file"
            customRequest={handleFileUpload}
            disabled={updating}
            multiple
          >
            {updating
              ? (
                <LoadingSpinner />
              )
              : (
                <Button
                  icon={<UploadOutlined />}
                  disabled={updating}
                >
                Upload Manual Amendment
                </Button>
              )}
          </UploadDragAndDrop>
        </UploadContainer>
      </Row>
    </>
  )
}

export default Amendment
