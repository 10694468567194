import { Button } from 'antd'
import styled from 'styled-components'

/*
  withConfig shouldForwardProp fixes the issue of sending unrecognized props to the DOM elements.
  It possibly happened due to conflicting prop handling of styled-component props and antd button props
  Reference: https://styled-components.com/docs/api#shouldforwardprop
*/
const SecondaryButton = styled(Button).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})`
  background: #e6f7ff;
  border-color: #40a9ff;
`

export default SecondaryButton
