import { useMemo } from 'react'
import { getAgreementFromListing } from 'utils'

import { getAgreementOverride } from 'utils/listingHelpers'

import * as T from 'types'

const useAgreement = (listing?: T.IListing, listingDocuments?: T.IListingDocument[]) =>
  useMemo(() => {
    if (!listingDocuments || !listing) return {}

    const agreementOverrideDocument = getAgreementOverride(listingDocuments)

    if (agreementOverrideDocument?.url && agreementOverrideDocument?.brokerSigned) {
      return {
        agreement: agreementOverrideDocument,
        hasOverride: true,
      }
    }

    return {
      agreement: getAgreementFromListing(listing),
      hasOverride: false,
    }
  }, [listing, listingDocuments])

export default useAgreement
