import { FC, ReactElement } from 'react'
import { ThemeProvider } from 'styled-components'
// import {
//   EventHandlerProvider,
// } from 'services/contexts/Context.Example'
import * as theme from 'themes'

interface IProps {
  children: ReactElement<any, any> | null
}

const Providers: FC<IProps> = ({ children }) => (
  <ThemeProvider theme={theme}>
    {/* NB: If we add new contexts, put their Providers here */}
    {/* <EventHandlerProvider> */}
    {children}
    {/* </EventHandlerProvider> */}
  </ThemeProvider>
)

export default Providers
