import { Col, Row } from 'antd'
import { LoadingSpinner } from 'components'
import { FC, useEffect, useState } from 'react'

import ChecklistCard from 'modules/Checklists/ChecklistCard'

import {
  refreshChecklistListingItems,
  useChecklistListingItems,
} from 'services/swr/useChecklistListingItems'

import * as T from 'types'

interface IProps {
  listing: T.IListing
  contractDetails: T.IContractDetails
}

const Checklist: FC<IProps> = ({ listing, contractDetails }) => {
  const { listingChecklistItems, update } = useChecklistListingItems(listing.id)

  const [listingChecklistItemByHeading, setListingChecklistItemsByHeading] = useState<{
    [key: string]: T.IChecklistListingItem[]
  }>()

  const organizeChecklistItemsByHeading = (checklistItems: T.IChecklistListingItem[]) =>
    checklistItems.reduce((res, checklistItem) => {
      if (res[checklistItem.heading]) {
        res[checklistItem.heading] = [...res[checklistItem.heading], checklistItem]
      } else {
        res[checklistItem.heading] = [checklistItem]
      }

      return res
    }, {} as { [key: string]: T.IChecklistListingItem[] })

  useEffect(() => {
    if (!listingChecklistItems) return

    const checklistItemsByHeading = organizeChecklistItemsByHeading(listingChecklistItems)
    setListingChecklistItemsByHeading(checklistItemsByHeading)
  }, [listingChecklistItems?.length, listingChecklistItems])

  useEffect(() => {
    if (!listing) return

    refreshChecklistListingItems(listing.id)
  }, [listing.listingStatus, listing.escrowStatus, contractDetails.buyerFinancingType])

  if (!listingChecklistItems) return <LoadingSpinner />

  if (!listingChecklistItemByHeading || !Object.keys(listingChecklistItemByHeading).length) {
    return <div>There are no checklist items</div>
  }

  return (
    <Row gutter={[20, 20]}>
      {Object.keys(listingChecklistItemByHeading).map(key => (
        <Col
          key={key}
          xs={24}
          md={12}
          lg={8}
        >
          <ChecklistCard
            title={key}
            allChecklistListingItems={listingChecklistItems}
            checklistListingItemsToDisplay={listingChecklistItemByHeading[key]}
            updateChecklistListingItem={update}
          />
        </Col>
      ))}
    </Row>
  )
}

export default Checklist
