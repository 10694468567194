import { SelectOutlined } from '@ant-design/icons'
import { Button, Modal, Popover } from 'antd'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import * as T from 'types'

const { warning } = Modal

interface IHighlight {
  highlight: boolean
}
const RowNumberContainer = styled.div<IHighlight>`
  font-size: 12px;
  text-align: center;
  border-radius: 2px;
  margin: 2px;
  padding-top: 2px; /* font correction */
  ${props => props.highlight && `background-color: ${props.theme.colors.transparentBlueAlpha20}`};
  :hover {
    cursor: pointer;
  }
`

interface IProps {
  record: T.IListing
  index: number
  selectedRowIds: string[]
  updateSelectedRowIds: (update: string[]) => void
}

const RowNumber: FunctionComponent<IProps> = ({
  record,
  index,
  selectedRowIds,
  updateSelectedRowIds,
}) => {
  const handleRowSelect = (id: string) => {
    const selectedRowIdsCopy = [...selectedRowIds]
    const removeIndex = selectedRowIdsCopy.findIndex(selectedRowId => selectedRowId === id)

    if (removeIndex >= 0) {
      selectedRowIdsCopy.splice(removeIndex, 1)
    } else {
      selectedRowIdsCopy.push(id)
    }

    updateSelectedRowIds(selectedRowIdsCopy)
  }

  const handleOpenListings = () => {
    let windowHandler: Window | null = null
    const baseUrl = window.location.origin
    selectedRowIds.forEach(id => {
      if (typeof window !== 'undefined') windowHandler = window.open(`/listing/${id}`, '_blank')
    })

    if (!windowHandler) {
      warning({
        title: 'Failed to open multiple listings',
        content: `The action of opening multiple listing in new tabs was blocked by your browser. Please allow ${baseUrl} to show pop-up windows.`,
      })
    }

    updateSelectedRowIds([])
  }

  const handleNumberHighlight = () => handleRowSelect(record._id)

  const popOverContent = (
    <Button
      style={{ border: 'none' }}
      size="small"
      icon={<SelectOutlined />}
      onClick={handleOpenListings}
      disabled={!selectedRowIds.length || !selectedRowIds.includes(record._id)}
    />
  )

  // Pop over inner has a child element that controls the padding and is unreachable.
  // The only way to overwrite the child element padding is through using width and height
  // and then use display flex to center child element
  const popOverInnerStyle = {
    width: '38px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return (
    <Popover
      content={popOverContent}
      overlayInnerStyle={popOverInnerStyle}
      placement="right"
    >
      <RowNumberContainer
        onClick={handleNumberHighlight}
        highlight={selectedRowIds.includes(record._id)}
      >
        {index}
      </RowNumberContainer>
    </Popover>
  )
}

export default RowNumber
