import { FunctionComponent } from 'react'
import styled from 'styled-components'

interface IContainerProps {
  noMargin?: boolean
}
const Container = styled.div<IContainerProps>`
  margin-bottom: ${props => (props.noMargin ? 0 : 10)}px;
`

interface IHeaderRowProps {
  width?: string
}
const HeaderRow = styled.div<IHeaderRowProps>`
  display: flex;
  align-items: center;
  ${props => props.width && `width: ${props.width};`}
`

interface IHeaderProps {
  extraBold?: boolean
  keepCase?: boolean
  large?: boolean
  looseSpacing?: boolean
  thin?: boolean
}
let Header = styled.div<IHeaderProps>`
  display: inline;
  font-style: normal;
  font-weight: ${props => (props.thin ? 500 : 800)};
  ${props => props.extraBold && 'font-weight: 900;'}
  font-size: ${props => (props.large ? 24 : 16)}px;
  line-height: ${props => (props.large ? 33 : 22)}px;
  ${props => props.looseSpacing && 'letter-spacing: 1.28571px;'}
  ${props => !props.keepCase && 'text-transform: capitalize;'}
`

interface IAlignRightProps {
  alignRight?: boolean
}
interface IAsteriskProps {
  asterisk?: boolean
}
const InfoText = styled.div<IAlignRightProps & IAsteriskProps>`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.theme.colors.black};
  margin-top: 4px;
  text-transform: initial;
  ${props =>
    props.alignRight
    && `
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  `}
  ${props =>
    props.asterisk
    && `
    font-style: normal;
    align-self: flex-start;
    margin-top: -5px;
  `}
`

const RequiredText = styled(InfoText)`
  color: ${props => props.theme.colors.red};
  font-style: italic;
`

const RequiredAsterisk = styled.span<IAlignRightProps>`
  color: ${props => props.theme.colors.red};
  font-style: normal;
  align-self: flex-start;
  margin-top: -5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  text-transform: initial;
  ${props =>
    props.alignRight
    && `
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  `}
`

const StyledH2 = styled(Header).attrs({ as: 'h2' })`
  margin: 0;
`

const StyledH3 = styled(Header).attrs({ as: 'h3' })`
  margin: 0;
`

interface IProps {
  extraBold?: boolean
  headerText: string
  infoText?: string
  keepCase?: boolean
  large?: boolean
  looseSpacing?: boolean
  noMargin?: boolean
  required?: boolean
  requiredRightAlign?: boolean
  requiredText?: string
  thin?: boolean
  width?: string
  h2?: boolean
  h3?: boolean
}

const SectionHeader: FunctionComponent<IProps> = ({
  extraBold,
  headerText,
  infoText,
  keepCase,
  large,
  looseSpacing,
  required,
  requiredRightAlign,
  requiredText,
  noMargin,
  thin,
  width,
  h2,
  h3,
}) => {
  // Header can be H2, or H3 for SEO reasons.
  if (h2) Header = StyledH2

  if (h3) Header = StyledH3

  return (
    <Container noMargin={noMargin}>
      <HeaderRow width={width}>
        <Header
          extraBold={extraBold}
          large={large}
          looseSpacing={looseSpacing}
          thin={thin}
          keepCase={keepCase}
        >
          {headerText}
          {required && <RequiredAsterisk alignRight={requiredRightAlign}>*</RequiredAsterisk>}
        </Header>
      </HeaderRow>
      {infoText && <InfoText>{infoText}</InfoText>}
      {requiredText && <RequiredText>{requiredText}</RequiredText>}
    </Container>
  )
}

export default SectionHeader
