import { green } from '@ant-design/colors'
import { Alert, Checkbox, Col, Row } from 'antd'
import { CustomButton, Spacer } from 'components'
import dayjs from 'dayjs'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

import * as T from 'types'
import { ChangeRequiringAck } from 'types'

import { isListingChangeTask } from '../../services/changesRequiringAck'
import AddTask from './ListingChangeTasks'
import { ListingChangesRequiredModal } from './ListingChangesRequiredModal'
import { SubsectionTitle } from './Styles'
import TableView from './TableView'
import { useChangesRequiringAck } from './hooks/useChangesRequiringAck'

interface IBackgroundColor {
  backgroundColor?: string
}
const StyledTableWrapper = styled.div<IBackgroundColor>`
  .ant-table-row {
    background-color: ${props => props.backgroundColor ?? 'auto'};
  }
`

const StyledClickableCell = styled.div`
  :hover {
    color: #0645ad;
    cursor: pointer;
  }
`

interface IProps {
  listing: T.IListing
}

const ListingChangesRequired: FunctionComponent<IProps> = ({ listing }) => {
  const {
    allChanges,
    openChanges,
    recentlyUpdatedChanges,
    tasks,
    historyRequiringAck,
    toggleChangeAck,
    isChangeUpdating,
    errors,
  } = useChangesRequiringAck(listing)

  const [showOpenChanges, setShowOpenChanges] = useState(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedRecord, setSelectedRecord] = useState<ChangeRequiringAck | null>(null)

  const handleCheckboxOnChange = (change: ChangeRequiringAck) => toggleChangeAck(change)
  const handleToggleListingChanges = () => setShowOpenChanges(!showOpenChanges)

  const onCellClicked = (change: ChangeRequiringAck) => ({
    onClick: () => {
      setSelectedRecord(change)
      setShowModal(true)
    },
  })

  const renderChangeTypeCol = (_value: any, change: ChangeRequiringAck) => {
    const renderVal = isListingChangeTask(change) ? change.task : change.name
    return <StyledClickableCell>{renderVal}</StyledClickableCell>
  }

  const renderLastUpdateCol = (_value: any, change: ChangeRequiringAck) => {
    const lastUpdatedAtDate = isListingChangeTask(change) ? change.createdAt : change.lastUpdatedAt
    return (
      <StyledClickableCell>
        {dayjs(lastUpdatedAtDate).format('MM/DD/YY, h:mm A')}
      </StyledClickableCell>
    )
  }

  const renderCheckboxCol = (_value: boolean, change: ChangeRequiringAck) => {
    const checked = isListingChangeTask(change) ? change.completed : change.ack
    return (
      <Checkbox
        onChange={() => handleCheckboxOnChange(change)}
        defaultChecked={checked}
        checked={checked}
        indeterminate={isChangeUpdating(change)}
        disabled={isChangeUpdating(change)}
      />
    )
  }

  const renderCreatedByCol = (_value: any, change: ChangeRequiringAck) => (
    <>
      {isListingChangeTask(change)
        ? (
          <StyledClickableCell>{change.createdBy?.name}</StyledClickableCell>
        )
        : null}
    </>
  )

  const renderCompletedByCol = (_value: any, change: ChangeRequiringAck) => (
    <>
      {isListingChangeTask(change)
        ? (
          <StyledClickableCell>{change.completedBy?.name}</StyledClickableCell>
        )
        : null}
    </>
  )

  const columns = {
    done: {
      title: 'Done',
      align: 'center',
      width: 50,
      render: renderCheckboxCol,
    },
    changeType: {
      title: 'Change Type',
      align: 'start',
      width: 200,
      onCell: onCellClicked,
      render: renderChangeTypeCol,
    },
    createdBy: {
      title: 'Created By',
      align: 'start',
      width: 200,
      onCell: onCellClicked,
      render: renderCreatedByCol,
    },
    completedBy: {
      title: 'Completed By',
      align: 'start',
      width: 200,
      onCell: onCellClicked,
      render: renderCompletedByCol,
    },
    lastChangedAt: {
      title: 'Last Changed At',
      align: 'start',
      width: 300,
      onCell: onCellClicked,
      render: renderLastUpdateCol,
    },
  }

  const { done, changeType, createdBy, completedBy, lastChangedAt } = columns

  const listingChangesRequiredColumns = [done, changeType, createdBy, completedBy, lastChangedAt]

  const recentlyUpdatedColumns = [changeType, createdBy, completedBy, lastChangedAt]

  return (
    <>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <SubsectionTitle>{!showOpenChanges ? 'All Changes' : 'Open Changes'}</SubsectionTitle>
          <Spacer size={2} />
          {!tasks.length && errors.listingChangeTasksError && (
            <Alert
              message="An error occurred when loading tasks"
              type="error"
              showIcon
            />
          )}
          {!historyRequiringAck.length && errors.historyRequiringAckError && (
            <Alert
              message="An error occurred when loading listing changes"
              type="error"
              showIcon
            />
          )}
          <Spacer size={10} />
          <CustomButton onClick={handleToggleListingChanges}>
            {showOpenChanges ? 'Show All Changes' : 'Show Open Changes'}
          </CustomButton>
          <AddTask listingId={listing._id} />
          <Spacer size={10} />
          <StyledTableWrapper>
            {TableView(showOpenChanges ? openChanges : allChanges, listingChangesRequiredColumns)}
          </StyledTableWrapper>
          {!!recentlyUpdatedChanges.length && (
            <>
              <SubsectionTitle>Recently Updated</SubsectionTitle>
              <StyledTableWrapper backgroundColor={green[2]}>
                {TableView(recentlyUpdatedChanges, recentlyUpdatedColumns)}
              </StyledTableWrapper>
            </>
          )}
        </Col>
      </Row>
      {selectedRecord && (
        <ListingChangesRequiredModal
          show={showModal}
          change={selectedRecord}
          close={() => setShowModal(false)}
          afterClose={() => setSelectedRecord(null)}
          toggleChangeAck={toggleChangeAck}
          isChangeUpdating={isChangeUpdating}
        />
      )}
    </>
  )
}

export default ListingChangesRequired
