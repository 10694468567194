import * as T from 'types'

import { filterOpenChanges, sortByDateProduced } from '../../../services/changesRequiringAck'
import { useHistoryRequiringAck, useListingChangeTask } from '../../../services/swr'
import { ChangeRequiringAck, IListing } from '../../../types'
import { useAck } from './useAck'

export function useChangesRequiringAck(listing: IListing): {
  allChanges: ChangeRequiringAck[]
  openChanges: ChangeRequiringAck[]
  recentlyUpdatedChanges: ChangeRequiringAck[]
  tasks: ChangeRequiringAck[]
  historyRequiringAck: ChangeRequiringAck[]
  toggleChangeAck: (change: ChangeRequiringAck) => void
  isChangeUpdating: (change: ChangeRequiringAck) => boolean
  errors: {
    listingChangeTasksError: T.IServerError
    historyRequiringAckError: T.IServerError
  }
} {
  const { listingChangeTasks: tasks = [], error: listingChangeTasksError }
    = useListingChangeTask(listing)

  const { historyRequiringAck = [], error: historyRequiringAckError }
    = useHistoryRequiringAck(listing)

  const { getUpdatedChanges, toggleChangeAck, isChangeUpdating } = useAck()

  const allChanges = [...tasks, ...historyRequiringAck].sort(sortByDateProduced)
  const openChanges = allChanges.filter(filterOpenChanges)
  const recentlyUpdatedChanges = getUpdatedChanges(allChanges)

  return {
    allChanges,
    openChanges,
    recentlyUpdatedChanges,
    tasks,
    historyRequiringAck,
    toggleChangeAck,
    isChangeUpdating,
    errors: {
      listingChangeTasksError,
      historyRequiringAckError,
    },
  }
}
