import { Col, Row } from 'antd'
import { FunctionComponent, useMemo } from 'react'

import * as T from 'types'
import * as E from 'types/enums'

import Agreement from './Agreement'
import AllMlsQuestions from './AllMlsQuestions'
import Amendment from './Amendment'
import Checklist from './Checklist'
import Contacts from './Contacts'
import Description from './Description'
import Documents from './Documents'
import Escrow from './Escrow'
import EscrowHistory from './EscrowHistory'
import GroupedFieldsSection from './GroupedFieldsSection'
import HistoryLog from './HistoryLog'
import ListingChangesRequired from './ListingChangesRequired'
import ListingNotes from './ListingNotes'
import MLSInfo from './MlsInfo'
import OpenHouse from './OpenHouse'
import Orders from './Orders'
import PhotosAndVideos from './PhotosAndVideos'
import ServiceFulfillment from './ServiceFulfillment'
import Services from './Services'
import { groupListingFieldsByFlowSubstep } from './utils'

interface IProps {
  listing: T.IListing
  contractDetails: T.IContractDetails
  name: string
  listingContentSection: T.IListingContentSection
  fontSize: number
  listView: boolean
  mlsAlphabeticalView: boolean
  update: T.IUpdateListing
}

const ListingContentSection: FunctionComponent<IProps> = ({
  listing,
  contractDetails,
  name,
  listingContentSection,
  fontSize,
  listView,
  mlsAlphabeticalView,
  update,
}) => {
  // group fields by listingFlowSubstep names
  let groupedFields = useMemo(
    () => groupListingFieldsByFlowSubstep(listingContentSection.fields),
    [listingContentSection.fields],
  )

  if (listingContentSection.name === E.ListingContentSection.Checklists) {
    return (
      <Checklist
        listing={listing}
        contractDetails={contractDetails}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.Contacts) {
    return <Contacts />
  }

  if (listingContentSection.name === E.ListingContentSection.ListingNotes) {
    return (
      <ListingNotes
        fontSize={fontSize}
        listing={listing}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.PhotosAndVideos) {
    return (
      <PhotosAndVideos
        listing={listing}
        listView={listView}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.ListingAgreement) {
    return (
      <Agreement
        listing={listing}
        listView={listView}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.Amendments) {
    return <Amendment listing={listing} />
  }

  if (listingContentSection.name === E.ListingContentSection.Documents) {
    return (
      <Documents
        listing={listing}
        update={update}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.MlsInfo) {
    return <MLSInfo listing={listing} />
  }

  if (listingContentSection.name === E.ListingContentSection.ListingChangesAndOpenHouses) {
    return (
      <Row
        gutter={[15, 15]}
        align="top"
        justify="center"
      >
        <Col
          xs={24}
          md={12}
          lg={12}
        >
          <ListingChangesRequired listing={listing} />
        </Col>
        <Col
          xs={24}
          md={12}
          lg={12}
        >
          <OpenHouse listing={listing} />
        </Col>
      </Row>
    )
  }

  if (listingContentSection.name === E.ListingContentSection.Description) {
    return (
      <Description
        groupedFields={groupedFields}
        name={name}
        listView={listView}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.Services) {
    return (
      <Services
        listing={listing}
        contractDetails={contractDetails}
        listView={listView}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.Escrow) {
    return (
      <Escrow
        listing={listing}
        contractDetails={contractDetails}
        listView={listView}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.EscrowHistory) {
    return <EscrowHistory listing={listing} />
  }

  if (listingContentSection.name === E.ListingContentSection.ServiceFulfillment) {
    return (
      <ServiceFulfillment
        listing={listing}
        listView={listView}
      />
    )
  }

  if (listingContentSection.name === E.ListingContentSection.HistoryLog) {
    return <HistoryLog listing={listing} />
  }

  if (listingContentSection.name === E.ListingContentSection.Orders) {
    return <Orders listing={listing} />
  }

  // re-arrange substeps orders for (ownership) terms section
  if (listingContentSection.name === E.ListingContentSection.Terms) {
    const sortedFields: T.IMlsField[][] = []
    let listPrice: T.IMlsField[] = []
    let offerTerms: T.IMlsField[] = []
    let commission: T.IMlsField[] = []
    groupedFields.forEach(group => {
      if (group[0].listingFlowSubStep === 'List Price') listPrice = group
      else if (group[0].listingFlowSubStep === 'Offer Terms') offerTerms = group
      else if (group[0].listingFlowSubStep === 'Commission') commission = group
      else sortedFields.push(group)
    })
    groupedFields = [listPrice, offerTerms, commission, ...sortedFields]
  }

  const colMd = listingContentSection.tabletColumnWidth || 12
  const colLg = listingContentSection.desktopColumnWidth || 8

  /*
    All Mls Fields from
     - Basic Home Details
     - Lot Details
     - Interior Details
     - Structure & Exterior
     - Special Features and Designations
     - Community Details
     - Utilities & Disclosures
     - Description questions
     - Price & Sale Terms
  */
  if (
    mlsAlphabeticalView
    && listingContentSection.name === E.ListingContentSection.AllMlsQuestions
  ) {
    // Alphabetical view of all mls fields use different grouping method
    return (
      <AllMlsQuestions
        fields={listingContentSection.fields}
        listView={listView}
      />
    )
  }

  return (
    <Row gutter={[15, 15]}>
      <GroupedFieldsSection
        groupedFields={groupedFields}
        name={name}
        listView={listView}
        colMd={colMd}
        colLg={colLg}
      />
    </Row>
  )
}

export default ListingContentSection
