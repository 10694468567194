import { Col } from 'antd'
import { FunctionComponent } from 'react'

import * as T from 'types'

import FieldListView from './FieldListView'

interface IProps {
  groupedFields: T.IMlsField[][]
  name: string
  listView: boolean
  sortAlphabetically?: boolean
  colMd?: number
  colLg?: number
}

const GroupedFieldsSection: FunctionComponent<IProps> = ({
  groupedFields,
  name,
  listView,
  sortAlphabetically,
  colMd = 12,
  colLg = 8,
}) => {
  const renderFlowSubsteps = (fields: T.IMlsField[]) => {
    if (sortAlphabetically) {
      fields.sort((a, b) => {
        if (a.label && b.label && a.label < b.label) return -1

        if (a.label && b.label && a.label > b.label) return 1

        return 0
      })
    }

    return fields.map((field, index) => (
      <FieldListView
        key={`${field._id ?? field.label}`}
        listView={listView}
        field={field}
        index={index}
      />
    ))
  }

  return (
    <>
      {groupedFields.map(
        (fields: T.IMlsField[]) =>
          fields.length > 0 && (
            <Col
              xs={24}
              md={colMd}
              lg={colLg}
              key={`${name}-${fields[0]?._id ?? fields[0].name}`}
            >
              {renderFlowSubsteps(fields)}
            </Col>
          ),
      )}
    </>
  )
}

export default GroupedFieldsSection
