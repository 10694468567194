import { Button, Checkbox, Collapse, Modal, Typography } from 'antd'
import { Spacer } from 'components'
import dayjs from 'dayjs'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

import { ChangeRequiringAck, IHistoryRequiringAck, IListingChangeTask } from 'types'

import { isListingChangeTask } from '../../services/changesRequiringAck'
import ListingChangeView from './ListingChangeView'

const { Text } = Typography
const { Panel } = Collapse

const CustomModal = styled(Modal)`
  .ant-modal-header {
    font-weight: bold;
    background-color: rgb(3, 169, 244);
  }
  .ant-modal-body {
    padding: 8px;
  }
`

const StyledContainer = styled.div`
  white-space: normal;
  border-bottom: 1px solid ${props => props.theme.colors.transparentBlackAlpha25};
`

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  max-height: 600px;

  @media (min-width: ${props => props.theme.metrics.phone}px) {
    overflow-y: auto;
  }
`

const { Title } = Typography

interface IProps {
  show: boolean
  change: ChangeRequiringAck
  close: () => void
  afterClose: () => void
  toggleChangeAck: (change: ChangeRequiringAck) => void
  isChangeUpdating: (change: ChangeRequiringAck) => boolean
}

const renderListingChanges = (change: IHistoryRequiringAck) => (
  <>
    <Spacer size={8} />
    <Collapse
      defaultActiveKey={change.history[0]?.updatedAt}
      expandIconPosition="right"
    >
      {change.history.map(historyItem => (
        <Panel
          key={historyItem.updatedAt}
          header={(
            <Text italic>{`Updated on ${dayjs(historyItem.updatedAt).format(
              'MM/DD/YY, h:mm A',
            )} by ${historyItem.changedBy?.name ?? ''}`}</Text>
          )}
        >
          <ListingChangeView change={historyItem} />
        </Panel>
      ))}
    </Collapse>
    <Spacer size={8} />
  </>
)

const renderListingChangeTask = (task: IListingChangeTask) => (
  <>
    <Spacer size={8} />
    <StyledContainer key={task.createdAt}>
      {task?.createdBy && (
        <>
          <Text italic>{`Created by: ${task.createdBy.name}`}</Text>
          <Spacer size={16} />
        </>
      )}
      {task?.completedBy && (
        <>
          <Text italic>{`Completed by: ${task.completedBy.name}`}</Text>
          <Spacer size={16} />
        </>
      )}
      {task.startDate && (
        <Text italic>{`Start date: ${dayjs(task.startDate).format('MM/DD/YY')}`}</Text>
      )}
      <Spacer size={16} />
      <Text italic>{task.details}</Text>
      <Spacer size={8} />
    </StyledContainer>
    <Spacer size={8} />
  </>
)

export const ListingChangesRequiredModal: FunctionComponent<IProps> = ({
  show,
  change,
  close,
  afterClose,
  toggleChangeAck,
  isChangeUpdating,
}) => {
  const title = isListingChangeTask(change)
    ? change.task ?? 'Task'
    : change.name ?? 'Listing Change'

  const [checked, setChecked] = useState(
    isListingChangeTask(change) ? change.completed : change.ack,
  )

  const onHandleChecked = () => {
    toggleChangeAck(change)
    if (isListingChangeTask(change)) {
      change.completed = !checked
    } else {
      change.ack = !checked
    }

    setChecked(!checked)
  }

  return (
    <CustomModal
      title={<Title level={4}>{title}</Title>}
      width={460}
      visible={show}
      onCancel={close}
      afterClose={afterClose}
      footer={[
        <Button
          key="back"
          onClick={close}
          type="primary"
        >
          OK
        </Button>,
      ]}
      closable={false}
    >
      <ModalContentContainer>
        <Spacer size={8} />
        <Title level={5}>
          Mark Done{' '}
          <Checkbox
            onChange={onHandleChecked}
            defaultChecked={checked}
            checked={checked}
            indeterminate={isChangeUpdating(change)}
            disabled={isChangeUpdating(change)}
          />
        </Title>
        {isListingChangeTask(change)
          ? renderListingChangeTask(change)
          : renderListingChanges(change)}
      </ModalContentContainer>
    </CustomModal>
  )
}
