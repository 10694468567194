import { Select, SelectProps } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

const ScrollableSelect: FC<SelectProps<any>> = styled(Select)`
  div.ant-select-selector {
    overflow-y: scroll;
    max-height: 100px;
  }

  .ant-select-arrow {
    margin-right: 10px;
  }

  .ant-select-clear {
    margin-right: 10px;
  }
`

export default ScrollableSelect
