import queryString from 'query-string'
import { mutate } from 'swr'

import { stringifyParams } from 'utils/stringFormatting'

import * as T from '../types'
import { api } from './api'

const mutateHistoryWithCreator = (listingId: string) => {
  let historyMutateKey = `/listings/${listingId}/history`
  const searchQueryParams: T.IGetQueryParams = {}
  searchQueryParams.populate = { path: 'creator', select: 'name email roles' }
  const queryParams = stringifyParams(['filter', 'populate'], searchQueryParams)
  historyMutateKey += `?${queryString.stringify(queryParams)}`
  mutate(historyMutateKey)
}

export const toggleListingChangeTaskAck = async (
  ListingChangeTask: T.IListingChangeTask,
): Promise<[T.IListingChangeTask | undefined, undefined | Error]> => {
  try {
    const response = await api.toggleListingChangeTaskAck(ListingChangeTask._id)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listingChangeTask/listing/${ListingChangeTask.listingId}`)
    mutateHistoryWithCreator(ListingChangeTask.listingId)

    return [response.data, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('Unknown error occurred')
    return [undefined, err]
  }
}

export const addTask = async (
  task: T.INewTask,
  listingId: string,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.addListingChangeTask(task, listingId)
    await mutate(`/listingChangeTask/listing/${listingId}`)
    mutateHistoryWithCreator(listingId)

    if (!response.ok || !response.data) throw new Error('Unable to create task due to an error')

    return [true, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('There was unknown error')
    return [false, err]
  }
}
