import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Checkbox, Space } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import { IconButton } from 'components'
import styled from 'styled-components'

import { themeFontSize } from 'utils/styles'

import { StyledText } from './Shared'
import { IDatesUpdating, IShowing } from './types'

interface IStyledLinkProps {
  lineThrough?: boolean
}

const StyledLink = styled.a<IStyledLinkProps>`
  ${props => props.lineThrough && 'text-decoration: line-through;'}
  ${themeFontSize}
`

interface IGetColumns {
  (
    datesUpdating: IDatesUpdating,
    handleCheckboxOnChange: (id: string) => void,
    handleEdit: (showing: IShowing) => void,
    setConfirmDeleteForShowing: (showing: IShowing) => void,
  ): ColumnsType<IShowing>
}

const getColumns: IGetColumns = (
  datesUpdating,
  handleCheckboxOnChange,
  handleEdit,
  setConfirmDeleteForShowing,
) => {
  const renderText = (text: string, record: IShowing) => ({
    children: (
      <StyledText
        lineThrough={record.cancelled}
        textColor={record.Done ? 'black' : 'red'}
      >
        {text}
      </StyledText>
    ),
  })

  const renderURL = (link: string, record: IShowing) => ({
    children: link && (
      <StyledLink
        href={link}
        target="_blank"
        rel="noreferrer"
        lineThrough={record.cancelled}
      >
        {link}
      </StyledLink>
    ),
  })

  const renderCheckbox = (value: boolean, record: IShowing) => {
    const handleOnChange = () => handleCheckboxOnChange(record._id)

    return {
      children: (
        <Checkbox
          onChange={handleOnChange}
          defaultChecked={value}
          checked={value}
          indeterminate={datesUpdating[record._id]}
          disabled={datesUpdating[record._id]}
        />
      ),
    }
  }

  const renderActions = (_: unknown, record: IShowing) => {
    const handleEditShowing = () => handleEdit(record)
    const confirmDelete = () => setConfirmDeleteForShowing(record)

    return {
      children: (
        <Space size="middle">
          <IconButton
            Icon={EditOutlined}
            onClick={handleEditShowing}
          />
          <IconButton
            Icon={DeleteOutlined}
            onClick={confirmDelete}
          />
        </Space>
      ),
    }
  }

  return [
    {
      title: 'Day',
      dataIndex: 'Day',
      key: 'Day',
      align: 'center',
      width: 60,
      render: renderText,
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      align: 'center',
      width: 60,
      render: renderText,
    },
    {
      title: 'Start Time',
      dataIndex: 'Start Time',
      key: 'Start Time',
      align: 'center',
      width: 100,
      render: renderText,
    },
    {
      title: 'End Time',
      dataIndex: 'End Time',
      key: 'End Time',
      align: 'center',
      width: 100,
      render: renderText,
    },
    {
      title: 'Virtual Url',
      dataIndex: 'Virtual Url',
      key: 'Virtual Url',
      align: 'center',
      width: 80,
      render: renderURL,
    },
    {
      title: 'Done',
      dataIndex: 'Done',
      key: 'Done',
      align: 'center',
      width: 60,
      render: renderCheckbox,
    },
    {
      title: 'Actions',
      key: 'Actions',
      align: 'center',
      width: 60,
      render: renderActions,
    },
  ]
}

export default getColumns
