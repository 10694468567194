import { Moment } from 'moment'

import * as T from 'types'

import { applyDateWithTimezone } from './timezone'

/* eslint-disable import/prefer-default-export */
export const addCommas = (value: string | number) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const removeCommas = (value: string) => value.replace(/,/g, '')

export const removeDollarSign = (value: string) => value.replace(/\$/g, '')

export const capitalize = (value: string) => {
  if (typeof value !== 'string') return ''

  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const removeCurrencyFormatting = (value: string) => removeDollarSign(removeCommas(value))

export const renderValueOrNothing = (
  value: string | number | null | undefined,
  defaultValue: string = '---',
) => {
  if (!value && value !== 0) {
    return defaultValue
  }

  return String(value)
}

export const renderCurrency = (value: string | number | undefined | null, defaultValue?: string) =>
  renderValueOrNothing(
    typeof value === 'undefined' || value === null ? '' : `$${addCommas(value.toString())}`,
    defaultValue,
  )

// @FIXME: timezone conversions are hard, this is hacky hacky hack
// when user submits a date, we just need to show the values we have in the DB
export const parseTzDate = (
  date: string | Date | undefined | null,
  timezone?: string,
  withTime?: boolean,
) => {
  if (!date) return ''

  if (!Date.parse(String(date))) return '' // invalid date case

  const dateWithTimezone = applyDateWithTimezone(String(date), timezone, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
  const [dateString, timeString] = String(dateWithTimezone).split('T') // e.g. ['2018-02-28', 'T00:22:39.516+00:00']
  const [year, month, day] = dateString.split('-')
  const [hours, timeStringWithoutHours] = timeString.split(':')
  const [minutes] = timeStringWithoutHours.split(':')
  let hoursAmPm: number = Number(hours)
  let amPm: string = 'AM'

  if (hoursAmPm >= 12) {
    if (hoursAmPm !== 12) {
      hoursAmPm -= 12
    }

    amPm = 'PM'
  }

  const shortYear = year.substring(2)
  return withTime
    ? `${month}/${day}/${shortYear} ${hoursAmPm}:${minutes} ${amPm}`
    : `${month}/${day}/${shortYear}`
}

export const parseBooleanValue = (value: boolean | undefined | null) => {
  if (typeof value !== 'boolean') return '---'

  return value ? 'Yes' : 'No'
}

export const addPercent = (value: string | number | undefined | null) => {
  if (typeof value === 'undefined' || value == null) return '---'

  return `${value}%`
}

export const formatCamelCaseToSentence = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

// @FIXME - why do we have two of these
export const renderPhoneNumber = (value?: string | number | null) => {
  const stringValue = renderValueOrNothing(value)
  if (stringValue === '---') return stringValue

  return `${stringValue.substring(0, 3)}-${stringValue.substring(3, 6)}-${stringValue.substring(6)}`
}

export const formatPhoneNumber = (input?: string | number) => {
  if (!input) return ''

  if (typeof input === 'number') input = String(input)

  const number = input.replace(/\D/g, '')
  return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 15)}`
}

export const formatDateWhenTruthy = (date: Moment | null) =>
  date ? date.format('MM/DD/YY') : undefined

export const renderDigitsOnly = (value?: string | number) => String(value).replace(/\D/g, '')

export const formatDollarAmount = (dollarAmount: string) => `$${addCommas(dollarAmount)}`

/**
 * This function exists since query-string doesn't support nested objects. They
 * recommend stringifying them
 */
export const stringifyParams: T.IStringifyParams = (names, params) =>
  names.reduce((result, aName) => {
    if (result[aName]) result[aName] = JSON.stringify(result[aName])

    return result
  }, params || {})

export const formatJSONStringToObject = (str: string) => {
  try {
    return JSON.parse(str)
  } catch (error) {
    return undefined
  }
}

export const formatToSnakeCase = (str: string) => str.replace(' ', '_').toLowerCase()
