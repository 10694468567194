export const saveToLocalStorage = <T>(key: string, value: T, userId: string) => {
  if (typeof window === 'undefined') return

  let data: { [key: string]: T } = {}
  const json = window.localStorage.getItem(key)

  if (json) {
    const parsed: { [key: string]: T } = JSON.parse(json)
    data = parsed
  }

  data[userId] = value
  localStorage.setItem(key, JSON.stringify(data))
}

export const getFromLocalStorage = <T = string>(key: string, userId: string) => {
  if (typeof window === 'undefined') return

  const json = window.localStorage.getItem(key)

  if (json) {
    const parsedData: { [key: string]: T } = JSON.parse(json)
    return parsedData[userId]
  }
}
