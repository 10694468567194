import { getRoomObject } from 'utils/listingHelpers'

import * as T from 'types'

import { parseBooleanValue, renderCurrency } from './stringFormatting'

export const parseFieldValue = (value: string | undefined) => {
  if (!value) return '---'

  // try/catch bc if string isn't json for some reason, we don't want JSON.parse to crash app
  try {
    const parsedValue = JSON.parse(value)
    const keys = Object.keys(parsedValue).filter((key: string) => !!parsedValue[key])
    if (!keys.length) return '---'

    return keys
  } catch (err) {
    return value
  }
}

export const showFieldValue = (field: T.IMlsField | T.IAgreementCoreField) => {
  if (field.fieldId?.type === 'checklist' || field.type === 'checklist') {
    return parseFieldValue(field.value)
  }

  if (field.type === 'currency') return renderCurrency(field.value)

  if (typeof field.value === 'boolean') return parseBooleanValue(field.value)

  if (field.value === 'undefined') return '---'

  return field.value || '---'
}

export const showFieldsWithValues = (pageListing?: T.IListing, schemaFields?: T.IMlsField[]) => {
  if (!pageListing || !schemaFields) return []

  return schemaFields.map((schemaField: T.IMlsField) => {
    if (schemaField.fieldId?.type === 'dimensions') {
      const roomName = String(schemaField.fieldId?.name)
      const room = getRoomObject(roomName, pageListing)
      let value: string = ''

      if (room && (room.width !== null || room.depth !== null || room.location !== null)) {
        const width = room.width ?? '{width}'
        const depth = room.depth ?? '{depth}'
        value = `${width} x ${depth}`
        const { location } = room
        if (location) value += ` : ${location}`
      }

      return {
        ...schemaField,
        value,
      }
    }

    if (schemaField.isCoreField) {
      const key = schemaField.fieldId?.name
      let value: T.ValueOf<T.IListing> = ''
      if (key) value = pageListing[key]

      if (!value && !Number.isInteger(value)) value = '' // avoids stringifying null/undefined into 'null'/'undefined'

      return {
        ...schemaField,
        value: String(value),
      }
    }

    const field = pageListing.fields.find(fld => fld.fieldId?._id === schemaField.fieldId?._id)
    return {
      ...schemaField,
      value: field?.value ?? '',
    }
  })
}
