import { blue } from '@ant-design/colors'
import { Table, TableProps } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import dayjs from 'dayjs'
import styled from 'styled-components'

import * as T from 'types'

interface ICustomTableProps<IEntry extends object> extends TableProps<IEntry>, T.IFontSize {}

/**
 * getting generic types to work with styled components is apparently difficult.  This solution was
 * pulled from here:
 *
 * https://github.com/styled-components/styled-components/issues/1803#issuecomment-975678538
 */
const makeNotesTableView = <IEntry extends object = any>(K: typeof Table) => styled(
  (props: ICustomTableProps<IEntry>) => <K {...props} />,
)`
  .ant-table table {
    font-size: ${props => props.fontSize}px;
  }
  .ant-table-thead {
    th.ant-table-cell {
      padding: 2px;
    }
  }
  .ant-table-tbody {
    td.ant-table-cell {
      padding: 2px;
    }
    .ant-table-cell,
    .ant-table-cell:hover {
      background-color: ${prop => prop.theme.colors.white};
    }
    .ant-table-row:nth-child(odd) td {
      background-color: ${prop => prop.theme.colors.white};
    }
    .ant-table-row:nth-child(even) td {
      background-color: ${prop => prop.theme.colors.grey};
    }
  }
  .seller-note {
    color: ${blue[7]};
  }
`

const NotesTableView = makeNotesTableView<T.INote>(Table)

const Text = styled.div<T.IFontSize>`
  font-size: ${props => props.fontSize}px;
`

const UserText = styled(Text)`
  width: 100px;
  white-space: 'nowrap';
  overflow: 'hidden';
`

interface IGetNoteTableColumns {
  (fontSize: number, nameTitle: string): ColumnsType<T.IAgentNote>
}

const getNoteTableColumns: IGetNoteTableColumns = (fontSize, nameTitle) => [
  {
    title: 'Notes',
    dataIndex: 'notes',
    render: (_, { note }) => <Text fontSize={fontSize}>{note}</Text>,
  },
  {
    title: nameTitle,
    dataIndex: 'name',
    width: 110,
    render: (_, { user }) => <UserText fontSize={fontSize}>{user}</UserText>,
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    width: 130,
    render: (_, { date }) => (
      <Text fontSize={fontSize}>{dayjs(date).format('MM/DD/YY, h:mm A')}</Text>
    ),
    sorter: (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
    defaultSortOrder: 'descend',
  },
]

export { getNoteTableColumns, NotesTableView, Text }
