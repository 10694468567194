/* eslint-disable no-alert */
import { yellow } from '@ant-design/colors'
import { Alert } from 'antd'
import { FunctionComponent, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import CustomButton from 'components/CustomButton'
import Spacer from 'components/Spacer'

import { toggleFulfilled } from 'services/orders'

import { renderCurrency, renderValueOrNothing } from 'utils/stringFormatting'

import * as T from 'types'

import FieldListView from './FieldListView'
import { ButtonsContainer } from './Shared'

interface IAlert {
  type?: 'success' | 'error' | 'info' | 'warning'
  message: string
}

interface IProps {
  additionalServiceField: T.IAdditionalOrderService
  listing: T.IListing
  listView: boolean
}

const Services: FunctionComponent<IProps> = ({ additionalServiceField, listing, listView }) => {
  const [alertMessage, setAlertMessage] = useState<IAlert | undefined>()
  const [updating, setUpdating] = useState(false)

  const { service, orderId, paid } = additionalServiceField

  const additionalServicesFields: T.IMlsField[] = useMemo(() => {
    const fields = [
      {
        _id: uuid(),
        name: 'Service Name',
        label: 'Service Name',
        value: renderValueOrNothing(service.type.name),
        listingFlowStep: 'Services',
        listingFlowSubStep: service.type.name,
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Paid',
        label: 'Paid',
        value: paid ? renderCurrency(service.type.price) : '$0',
        listingFlowStep: 'Services',
        listingFlowSubStep: service.type.name,
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Due At Closing',
        label: 'Due At Closing',
        value: paid ? '$0' : renderCurrency(service.type.price),
        listingFlowStep: 'Services',
        listingFlowSubStep: service.type.name,
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        name: 'Status',
        label: 'Status',
        value: service.fulfilled ? 'Fulfilled' : 'Unfulfilled',
        listingFlowStep: 'Services',
        listingFlowSubStep: service.type.name,
        defaultValue: '',
        readOnly: true,
      },
    ]

    if (service.type.discount && service.type.discountedPrice && paid) {
      fields[1].value = renderCurrency(service.type.discountedPrice)
      fields.splice(
        2,
        0,
        {
          _id: uuid(),
          name: 'Price',
          label: 'Price',
          value: renderCurrency(service.type.price),
          listingFlowStep: 'Services',
          listingFlowSubStep: service.type.name,
          defaultValue: '',
          readOnly: true,
        },
        {
          _id: uuid(),
          name: 'Discount',
          label: 'Discount',
          value: renderValueOrNothing(service.type.discount),
          listingFlowStep: 'Services',
          listingFlowSubStep: service.type.name,
          defaultValue: '',
          readOnly: true,
        },
      )
    }

    return fields
  }, [service])

  const handleUpdateFulfilled = async (fulfilled: boolean) => {
    setUpdating(true)
    const [data, error] = await toggleFulfilled(orderId, service.type._id, listing._id, fulfilled)

    if (data && !error) {
      setAlertMessage({ type: 'success', message: 'Service fulfillment updated!' })
    }

    if (error) {
      setAlertMessage({ type: 'error', message: 'There was an issue with service fulfillment' })
    }

    setUpdating(false)
  }

  return (
    <>
      {additionalServicesFields.map((field, index) => (
        <FieldListView
          key={`${field.name}`}
          field={field}
          index={index}
          listView={listView}
          removeTitle
        />
      ))}
      <ButtonsContainer>
        <CustomButton
          backgroundColor={yellow[4]}
          backgroundColorOnHover={yellow[5]}
          loading={updating}
          onClick={() => handleUpdateFulfilled(!service.fulfilled)}
          custom
        >
          {service.fulfilled ? 'Mark Unfulfilled' : 'Mark Fulfilled'}
        </CustomButton>
      </ButtonsContainer>
      {alertMessage && (
        <>
          <Spacer size={5} />
          <Alert
            type={alertMessage.type}
            message={alertMessage.message}
            showIcon
          />
          <Spacer size={5} />
        </>
      )}
    </>
  )
}

export default Services
