import { blue } from '@ant-design/colors'
import { Button, Popover } from 'antd'
import { LoadingSpinner } from 'components'
import dayjs from 'dayjs'
import { FC, useMemo, useState } from 'react'
import styled from 'styled-components'

import * as T from 'types'

const INITIAL_CHANGES_SHOWN = 10
const ADDITIONAL_CHANGES_SHOWN = 10

const LinkButton = styled(Button)`
  border: 0;
  color: ${blue[4]};
  height: auto;
  padding: 8px 0 0;
`

const Paragraph = styled.p`
  margin: 0;
`

const HistoryOl = styled.ol`
  margin-bottom: 0;
  padding-left: 0;

  li {
    display: flex;

    span:first-child {
      flex-grow: 1;
      margin-right: 20px;
    }
  }
`

interface IHistoryWithValue {
  _id: string
  createdAt: string
  value: string
}

interface IGetContent {
  (
    history: IHistoryWithValue[] | undefined,
    numberItemsShown: number,
    showMore: () => void,
    swrError: Error | undefined,
  ): JSX.Element
}

const getContent: IGetContent = (history, numberItemsShown, showMore, swrError) => {
  if (swrError) return <Paragraph>Error fetching history</Paragraph>

  if (!history) return <LoadingSpinner />

  if (!history.length) return <Paragraph>No Changes</Paragraph>

  const HistoryListItems = history.slice(0, numberItemsShown).map(({ _id, createdAt, value }) => (
    <li key={_id}>
      <span>{value}</span>
      <span>{createdAt}</span>
    </li>
  ))

  const hasMoreItems = history.length > numberItemsShown

  const showMoreBtn = hasMoreItems && (
    <li>
      <LinkButton onClick={showMore}>Show More</LinkButton>
    </li>
  )

  return (
    <HistoryOl>
      {HistoryListItems}
      {showMoreBtn}
    </HistoryOl>
  )
}

export interface IGetHistoryValue {
  (newVal: T.IChange['newVal']): string
}

export interface IProps {
  getHistoryValue: IGetHistoryValue
  history: T.IHistory[] | undefined
  swrError: Error | undefined
}

const HistoryPopover: FC<IProps> = props => {
  const { children, getHistoryValue, history = [], swrError } = props

  const [numberItemsShown, setNumberItemsShown] = useState(INITIAL_CHANGES_SHOWN)
  const showMore = () => setNumberItemsShown(numberItemsShown + ADDITIONAL_CHANGES_SHOWN)

  const historyWithValue = useMemo(
    () =>
      history
        ?.filter(({ changes }) => changes.length)
        .map(({ _id, changes, createdAt: createdAtISO }) => {
          const createdAt = dayjs(createdAtISO).format('MM/DD/YY, h:mm A')
          const value = getHistoryValue((changes[0] as T.IChange)?.newVal)

          return { _id, createdAt, value }
        }),
    [history],
  )

  const content = getContent(historyWithValue, numberItemsShown, showMore, swrError)

  const overlayInnerStyle = {
    maxHeight: '60vh',
    overflow: 'auto',
    overscrollBehavior: 'contain',
  }

  return (
    <Popover
      content={content}
      placement="bottom"
      overlayInnerStyle={overlayInnerStyle}
    >
      {children}
    </Popover>
  )
}

export default HistoryPopover
