import * as T from 'types'

type IHaveOverrideProps = T.IListingDocument | (T.IListingMlsDocument & T.IListingDocument)

export type ITruthyAgreementFromListing = T.IListingMlsDocument | T.IListingDocument

type IAgreementFromListing = ITruthyAgreementFromListing | undefined

type IGetAgreementFromListing = (listing?: T.IListing) => IAgreementFromListing

export const getAgreementFromListing: IGetAgreementFromListing = listing => {
  if (!listing || !listing.mlsList) return

  const agreement = listing.mlsList
    .map(mls => mls.documents.filter(doc => doc.listingAgreement))
    .flat()[0]
  return agreement
}

export const isListingMlsDocument = (
  agreement: IAgreementFromListing,
): agreement is T.IListingMlsDocument => !!(agreement && !agreement.type)

export const hasOverrideProps = (
  agreement: IAgreementFromListing,
): agreement is IHaveOverrideProps => !!(agreement && 'url' in agreement)

export const getAmendmentsFromListing = (
  listing?: T.IListing,
  listingDocuments?: T.IListingDocument[],
): (T.IListingMlsDocument | T.IListingDocument)[] => {
  if (!listing) return []

  const amendments = listing.mlsList
    .map(mls => mls.documents.filter(doc => !doc.listingAgreement))
    .flat()

  const manualAmendments: T.IListingDocument[]
    = listingDocuments
      ?.filter(doc => doc.type === 'Manual Amendment')
      .map(doc => ({
        ...doc,
        name: `${doc.name} (Manual Amendment)`,
      })) ?? []
  return [...amendments, ...manualAmendments]
}
