import SVG from './SVG'
import * as T from './types'

const verticalViewBox = '0 0 12 7'
const horizontalViewBox = '0 0 7 12'

const Arrow = (props: T.ISVGProps) => (
  <SVG
    d="M5.27997 0.219835C5.42049 0.0791426 5.61113 1.00805e-05 5.80997 -0.000164916L6.18997 -0.000164912C6.38839 0.00213917 6.57825 0.0809513 6.71997 0.219835L11.85 5.35984C11.9446 5.45372 11.9979 5.58152 11.9979 5.71484C11.9979 5.84815 11.9446 5.97595 11.85 6.06984L11.14 6.77984C11.0478 6.8739 10.9217 6.92691 10.79 6.92691C10.6583 6.92691 10.5321 6.8739 10.44 6.77984L5.99997 2.32984L1.55997 6.77984C1.46609 6.87449 1.33829 6.92773 1.20497 6.92773C1.07166 6.92773 0.943858 6.87449 0.849974 6.77984L0.149974 6.06984C0.055318 5.97595 0.00207521 5.84815 0.00207521 5.71484C0.00207521 5.58152 0.0553181 5.45372 0.149974 5.35984L5.27997 0.219835Z"
    {...props}
    viewBox="0 0 12 7"
  />
)

const UpArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.up}
    {...props}
    viewBox={verticalViewBox}
  />
)

const RightArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.right}
    {...props}
    viewBox={horizontalViewBox}
  />
)

// @FIXME -- these transforms do not work as expected, not sure why
// as a temp fix, place in div and use `transform: rotate(`n`deg);` on div to apply rotations
const DownArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.down}
    {...props}
    viewBox={verticalViewBox}
  />
)

const LeftArrow = (props: T.ISVGProps) => (
  <Arrow
    transform={T.Rotations.left}
    {...props}
    viewBox={horizontalViewBox}
  />
)

export { UpArrow, RightArrow, DownArrow, LeftArrow }
