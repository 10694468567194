export enum ContentColumnWidth {
  full = 24,
  half = 12,
}

export enum ListingContentSection {
  Contacts = 'Contacts',
  ListingAgreement = 'Listing Agreement',
  Amendments = 'Amendments',
  AgentNotes = 'Agent Notes',
  AgentSellerNotes = 'Notes To Seller',
  EscrowNotes = 'Escrow Notes',
  ListingNotes = 'Notes',
  Documents = 'Documents',
  Services = 'Services',
  ServiceFulfillment = 'Service Fulfillment',
  Escrow = 'Contract & Escrow',
  EscrowHistory = 'Escrow History',
  MlsInfo = 'MLS Info',
  AllMlsQuestions = 'All MLS Questions',
  BasicHomeDetails = 'Basic Home Details',
  RoomDimensions = 'Room Dimensions',
  InteriorDetails = 'Interior Details',
  StructureAndExterior = 'Structure & Exterior',
  LotDetails = 'Lot Details',
  SpecialFeaturesAndDesignation = 'Special Features & Designations',
  CommunityDetails = 'Community Details',
  UtilitiesAndDisclosures = 'Utilities & Disclosures',
  Description = 'Description',
  PhotosAndVideos = 'Photos & Videos',
  ShowingInstructions = 'Showing Instructions',
  PriceAndSaleTerms = 'Price & Sale Terms',
  OwnershipTerms = 'Ownership Terms',
  Terms = 'Price & Sale Terms', // groups Price & Sale Terms and Ownership Terms and titled as Ownership Terms
  PublishDate = 'Publish Date',
  ListingChangesAndOpenHouses = 'Listing Changes & Open Houses',
  OpenHouse = 'Open House',
  Orders = 'Orders',
  HistoryLog = 'History Log',
  Checklists = 'Checklists',
}

export enum AgreementSetting {
  ViewAgreement = 'View Agreement',
  PreviewAmendment = 'Preview Amendment',
  SendAmendment = 'Send Amendment',
  OverrideAgreement = 'Override Agreement',
  SendExtension = 'Send Extension',
  TransactionManagement = 'Transaction Management',
  ResetAgreement = 'Reset Agreement',
}

export enum HomeTypeEnum {
  condo = 'Condo',
  coop = 'Coop',
  detached = 'House',
  duplex = 'Duplex',
  land = 'Land',
  townhouse = 'Townhouse',
  other = 'Other',
}

export enum ButtonType {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

// Breadcrumb name type
export enum BreadcrumbEnum {
  Home = 'Home',
  Dashboard = 'My Dashboard',
  HomesForSale = 'Homes For Sale',
  RecentSales = 'Recent Sales',
  HowItWorks = 'How it Works',
  Pricing = 'Pricing',
  FAQ = 'FAQ',
  ViewListing = 'View Listing',
}

export enum LocalStorage {
  guidedTourMode = 'guidedTourMode',
}

export enum OwnerType {
  Company = 'Company',
  Individuals = 'Individuals',
  Trust = 'Trust',
}

export enum PackageType {
  basic = 'Basic',
  premium = 'Premium',
  platinum = 'Platinum',
}

export enum ServiceType {
  Public = 'public',
  YardSign = 'yardSign',
  Photography = 'photography',
  TransactionManagement = 'transactionManagement',
  PayUpFront = 'payUpFront',
}

// specifies required static fields (non-dynamic fields) on each substeps and create its field ids.
export enum StaticFieldId {
  // homeDetails/basic-home-details
  PropertyType = 'propertyType',
  // photos
  PhotosUploaded = 'photosUploaded',
  CanUsePhotos = 'canUsePhotos',
  // showings
  PrimaryContactName = 'contactInfo.name',
  PrimaryContactPhone = 'contactInfo.phone',
  PrimaryContactEmail = 'contactInfo.email',
  // offer-pricing
  HomeListPrice = 'homeListPrice',
  ListPriceIncludes = 'listPriceIncludes',
  ListPriceExcludes = 'listPriceExcludes',
  // mls-rules
  MlsRulesAgreed = 'mlsRulesAgreed',
}

export enum SidebarPosition {
  top = 'top',
  bottom = 'bottom',
}

export enum ContractStatus {
  optionPeriod = 'Option Period',
  offerAccepted = 'Offer Accepted, Contract Not Yet Signed',
  attorneyReview = 'Attorney Review',
  underContract = 'Under Contract (Has contingencies)',
  pending = 'Pending (No contingencies)',
}

export type ContractStatusEnumKey = keyof typeof ContractStatus

export enum ContractDetails {
  salePrice = 'Sale Price',
  contractDate = 'Contract Date',
  closingDate = 'Closing Date',
  depositAmount = 'Deposit Amount',
  escrowDetails = 'Escrow Details',
  escrowNumber = 'Escrow Number',
  buyerName = 'Buyer Name',
  buyerPhone = 'Buyer Phone',
  buyerEmail = 'Buyer Email',
  buyerAgentName = 'Buyer Agent Name',
  buyerAgentPhone = 'Buyer Agent Phone',
  buyerAgentEmail = 'Buyer Agent Email',
  buyerAgentBrokerage = 'Buyer Agent Brokerage',
  buyerAgentCommission = 'Buyer Agent Commission',
  titleCompany = 'Title Company',
  escrowAgentName = 'Escrow Agent Name',
  escrowAgentPhone = 'Escrow Agent Phone',
  escrowAgentEmail = 'Escrow Agent Email',
  closingAgentName = 'Closing Agent Name',
  closingAgentPhone = 'Closing Agent Phone',
  closingAgentEmail = 'Closing Agent Email',
  showBackupOffers = 'Show BackupOffers',
  notes = 'Notes',
  fullyPaid = 'Fully Paid',
  contractStatus = 'Contract Status',
  earnestMoneyDepositAmount = 'EMD Amount',
  earnestMoneyDepositReceived = 'EMD Received',
  optionPaymentAmount = 'Option Payment Amount',
  optionExpirationDate = 'Option Expiration Date',
}

export enum ListingStatus {
  draft = 'Draft',
  published = 'Published',
  needsRevision = 'Needs Revision',
  listingDetailsUpdated = 'Listing Details Updated',
  remove = 'Remove',
  inContract = 'In Contract',
  sold = 'Sold',
  inProgress = 'In Progress',
  readyForSubmission = 'Ready For Submission',
  cancelled = 'Cancelled',
  referred = 'Referred',
  brokerReview = 'Broker Review',
  closedNotPaid = 'Closed Not Paid',
  amendmentSent = 'Amendment Sent',
  amendmentNeeded = 'Amendment Needed',
  cancellationSigned = 'Cancellation Signed',
  leased = 'Leased',
  expired = 'Expired',
  offerAcceptedNYIC = 'Offer Accepted NYIC',
  tempHold = 'Temp Hold',
  needsInput = 'Needs Input',
  readyForPublish = 'Ready For Publish',
  attorneyReview = 'Attorney Review',
  contractUpdated = 'Contract Updated',
  sellerInfoRequested = 'Seller Info Requested',
  escrowClosed = 'Escrow Closed',
  newEscrow = 'New Escrow',
  initialReviewFailed = 'Initial Review Failed',
}

export enum MlsStatus {
  publishedOnMls = 'Published on MLS',
  // @FIXME: add the rest
}

export enum ListingStatusGroupings {
  all = 'search-all-listings',
  active = 'search-active-listings',
  openTasks = 'search-open-tasks-listings',
  newListings = 'search-new-listings',
  brokerReview = 'search-broker-review-listings',
  inContract = 'search-in-contract-listings',
  closed = 'search-closed-listings',
  expiringSoon = 'search-expiring-listings',
  collections = 'search-in-collections-listings',
  offersOutstanding = 'search-offers-outstanding-listings',
  escrow = 'search-escrow-listings',
}

export enum RoomLocation {
  Basement = 'Basement',
  FirstFloor = '1st Floor',
  SecondFloor = '2nd Floor',
  ThirdFloor = '3rd Floor',
}

export enum UserRoles {
  user = 'user',
}

export enum AdminRoles {
  admin = 'admin',
  agent = 'agent',
  customerServiceAgent = 'customerServiceAgent',
  listingCoordinator = 'listingCoordinator',
  listingManager = 'listingManager',
  operationsCoordinator = 'operationsCoordinator',
  system = 'system',
  titleCoordinator = 'titleCoordinator',
  transactionAgent = 'transactionAgent',
  transactionCoordinator = 'transactionCoordinator',
}

export const Roles = { ...UserRoles, ...AdminRoles }

export enum TwilioWorkerActivity {
  offline = 'Offline',
  idle = 'Idle',
  busy = 'Busy',
  reserved = 'Reserved',
}

export enum BrokermintTransactionStatus {
  active = 'ACTIVE',
  cancelled = 'CANCELLED',
}

export enum MLSPageTabs {
  settings = 'mls-settings',
  questions = 'mls-questions',
  zipcodes = 'mls-zipcodes',
}

export enum EscrowStatus {
  brokerReview = 'Broker Review',
  escrowClosed = 'Escrow Closed',
  escrowUpdated = 'Escrow Updated',
  attorneyReview = 'Attorney Review',
  inEscrow = 'In Escrow',
  newEscrow = 'New Escrow',
  notInEscrow = 'Not In Escrow',
  titleNotPaid = 'Escrow Closed, Title Not Paid',
}

export enum ChecklistType {
  goLive = 'GO_LIVE',
  listingReview = 'LISTING_REVIEW',
  // escrow = 'ESCROW',
  // property = 'PROPERTY'
}

// temp till I figure out how to get nice labels
export enum BuyerFinancingType {
  conventional = 'Conventional',
  fha = 'FHA',
  va = 'VA',
  usda = 'USDA',
  cash = 'Cash',
  OWNER = 'Owner Financing',
  OTHER = 'Other',
}

export type BuyerFinancingTypeKey = keyof typeof BuyerFinancingType

export enum LockboxType {
  Combination = 'Combination lockbox with a single code',
  Digital = 'Bluetooth digital lockbox with individual codes'
}

export enum HistoryCategory {
  update = 'Update',
  priceWhenFirstPublished = 'Price When First Published',
}
