import * as E from 'types/enums'

const orderedEscrowStatuses = [
  E.EscrowStatus.notInEscrow,
  E.EscrowStatus.newEscrow,
  E.EscrowStatus.escrowUpdated,
  E.EscrowStatus.attorneyReview,
  E.EscrowStatus.inEscrow,
  E.EscrowStatus.brokerReview,
  E.EscrowStatus.escrowClosed,
  E.EscrowStatus.titleNotPaid,
]

export { orderedEscrowStatuses }
