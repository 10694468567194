import { blue, green } from '@ant-design/colors'
import { CloseOutlined } from '@ant-design/icons'
import { Affix, Row, Slider } from 'antd'
import { Spacer } from 'components'
import React, { FunctionComponent, useState } from 'react'
import { ColorResult, SwatchesPicker } from 'react-color'
import styled from 'styled-components'

import * as T from 'types'

const MenuContainer = styled.div`
  margin-right: 10px;
  height: 100%;
  border-left: 1px solid ${props => props.theme.colors.grey};
`

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: auto;
  /* hide the scroll bar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* Safari and Chrome */
    display: none;
  }
`

const MenuItem = styled.li`
  list-style: none;
  font-size: 12px;
`

interface IMenuLink {
  collapsed: boolean
  active?: boolean
}

const MenuLink = styled.div<IMenuLink>`
  display: block;
  padding-left: 10px;
  overflow: hidden;
  color: ${props =>
    props.collapsed
      ? props.theme.colors.transparentBlackAlpha50
      : props.theme.colors.transparentBlackAlpha85};
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 1px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: ${blue[5]};
  }

  ${props =>
    props.active
    && `
    color: ${green[7]};
    border-left: 1px solid ${blue[5]};
  `}
`

const StyleCtrlMenuContainer = styled.div`
  padding-left: 10px;
`

const Text = styled.div`
  font-size: 12px;
  font-weight: 800;
  height: 16px;
`

const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  top: 10px;
  padding: 5px 0;
  cursor: pointer;
`

const FontColorContainer = styled.div`
  display: flex;
`

const SwatchContainer = styled.div`
  position: fixed;
  right: 150px;
  bottom: 10px;
`

interface IColorPickerButtonProps {
  color: string
}

const ColorPickerButton = styled.div<IColorPickerButtonProps>`
  background-color: ${props => props.color};
  border-radius: 4px;
  height: 15px;
  width: 15px;
  cursor: pointer;
`
interface IControlButton {
  active?: boolean
  color?: string
  width?: string
}

const ControlButton = styled.span<IControlButton>`
  font-size: 12px;
  text-align: left;
  text-decoration: underline;
  padding: 2px 4px;
  height: 22px;
  background: none;
  border: none;
  cursor: pointer;

  ${props =>
    props.active
    && `
    font-weight: 900;
    text-decoration: none;
  `}

  &:hover {
    color: ${blue.primary};
    text-decoration: underline;
  }
`
interface IProps {
  menuList: T.IMenuList[]
  listView: boolean
  textColor: string
  fontSize: number
  offsetTop: number
  activePanelKeys: string[]
  listingFlowSubstepKeys: string[]
  mlsAlphabeticalView: boolean
  handleMlsAlphabeticalView: (alphaView: boolean) => () => void
  changeLayout: (layout: string) => () => void
  changeFontSize: (value: any) => void
  changeTextColor: (value: any) => void
  handleActivePanelKeys: (activeKeys: string[]) => void
  toggleSideMenu: () => void
}

const AffixMenu: FunctionComponent<IProps> = ({
  menuList,
  listView,
  textColor,
  fontSize,
  offsetTop,
  activePanelKeys,
  listingFlowSubstepKeys,
  mlsAlphabeticalView: alphabeticalView,
  handleMlsAlphabeticalView,
  changeLayout,
  changeFontSize,
  changeTextColor,
  handleActivePanelKeys,
  toggleSideMenu,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const [colorPickerEditMode, setColorPickerEditMode] = useState(false)

  const handleClick = (item: T.IMenuList, index: number) => () => {
    // scroll to the panel location
    if (!window || !document) return

    // collapse panel container has flowstep name as an id
    const scrollDiv = document.getElementById(item.name)?.offsetTop
    const statusHeaderHeight = 72

    if (typeof scrollDiv === 'number') {
      window.scroll({ top: scrollDiv + statusHeaderHeight, behavior: 'smooth' })
    }

    // open the panel if selected panel is collapsed
    if (!activePanelKeys.includes(item.key)) handleActivePanelKeys([...activePanelKeys, item.key])

    if (index !== activeIndex) {
      // highlight last selected menu item
      setActiveIndex(index)
    }
  }

  const toggleColorPickerEditMode = () => setColorPickerEditMode(currentMode => !currentMode)

  const handleSwatchColorChange = (color: ColorResult) => {
    changeTextColor(color.hex)
    setColorPickerEditMode(false)
  }

  return (
    <Affix offsetTop={offsetTop}>
      <MenuContainer>
        <CloseButton onClick={toggleSideMenu}>
          <div data-testid="sidemenuCloseButton">close</div>
          <div>
            <CloseOutlined
              width={15}
              height={15}
            />
          </div>
        </CloseButton>
        <Wrapper>
          <Spacer size={10} />
          <div>
            {menuList.map((item, index) => (
              <MenuItem
                key={item.key}
                onClick={handleClick(item, index)}
                data-testid={item.key}
              >
                <MenuLink
                  active={index === activeIndex}
                  collapsed={!activePanelKeys.includes(item.key)}
                >
                  {item.name}
                </MenuLink>
              </MenuItem>
            ))}
          </div>
          <Spacer />
          <StyleCtrlMenuContainer>
            <Text>Views:</Text>
            <Spacer size={5} />
            <ControlWrapper>
              <Row>
                <ControlButton
                  active={alphabeticalView}
                  onClick={handleMlsAlphabeticalView(true)}
                  data-testid="alphabetical"
                >
                  Alphabetical
                </ControlButton>
                /
                <ControlButton
                  active={!alphabeticalView}
                  onClick={handleMlsAlphabeticalView(false)}
                  data-testid="group"
                >
                  Group
                </ControlButton>
              </Row>
              <Row>
                <ControlButton
                  active={!listView}
                  onClick={changeLayout('paragraph')}
                  data-testid="paragraph"
                >
                  Paragraph
                </ControlButton>
                /
                <ControlButton
                  active={listView}
                  onClick={changeLayout('list')}
                  data-testid="list"
                >
                  List
                </ControlButton>
              </Row>
              <Row>
                <ControlButton
                  active={activePanelKeys.length === listingFlowSubstepKeys.length}
                  onClick={() => handleActivePanelKeys(listingFlowSubstepKeys)}
                  data-testid="expand"
                >
                  Expand
                </ControlButton>
                /
                <ControlButton
                  active={activePanelKeys.length === 0}
                  onClick={() => handleActivePanelKeys([])}
                  data-testid="collapse"
                >
                  Collapse
                </ControlButton>
              </Row>
            </ControlWrapper>
            <Spacer size={15} />
            <Text>
              Font Size: <strong>{fontSize}</strong>
              px
            </Text>
            <Spacer size={5} />
            <Slider
              defaultValue={fontSize}
              min={10}
              max={16}
              onAfterChange={changeFontSize}
              style={{ margin: '0 10px 0 5px' }}
            />
            <Spacer size={15} />
            <FontColorContainer>
              <Text>Text Color:</Text>
              <Spacer size={5} />
              <ColorPickerButton
                color={textColor}
                onClick={toggleColorPickerEditMode}
                data-testid="colorPickerButton"
              />
            </FontColorContainer>
            {colorPickerEditMode && (
              <SwatchContainer>
                <SwatchesPicker
                  color={textColor}
                  onChangeComplete={handleSwatchColorChange}
                />
              </SwatchContainer>
            )}
          </StyleCtrlMenuContainer>
          <Spacer size={200} />
        </Wrapper>
      </MenuContainer>
    </Affix>
  )
}

export default AffixMenu
