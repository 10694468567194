import { ChangeRequiringAck, IListingChangeTask } from '../types'
import { toggleHistoryAck } from './history'
import { toggleListingChangeTaskAck } from './listingChangeTask'

export const isListingChangeTask = (task: ChangeRequiringAck): task is IListingChangeTask =>
  typeof (task as IListingChangeTask)?.task !== 'undefined'

type ChangeFilter = (change: ChangeRequiringAck) => boolean

// this should be removed and refactored to fit a broader solution implemented by hom-2920
const filterExceptions: ChangeFilter = change => {
  const isException
    = !isListingChangeTask(change)
    && ((change.name === 'owners' && change.history[0].changedBy.role === 'admin')
      || change.name === 'hasOutstandingOffers')

  return !isException
}

const filterIsNotDone: ChangeFilter = change =>
  isListingChangeTask(change) ? !change.completed : !change.ack

const filterActualTasks: ChangeFilter = change => {
  if (!isListingChangeTask(change)) return true

  if (isListingChangeTask(change) && !change.startDate) return true

  if (isListingChangeTask(change) && change.startDate && new Date() > new Date(change.startDate)) {
    return true
  }

  return false
}

export const filterOpenChanges: ChangeFilter = change =>
  filterExceptions(change) && filterIsNotDone(change) && filterActualTasks(change)

export const sortByDateProduced = (change1: ChangeRequiringAck, change2: ChangeRequiringAck) => {
  const date1 = isListingChangeTask(change1) ? change1.createdAt : change1.lastUpdatedAt

  const date2 = isListingChangeTask(change2) ? change2.createdAt : change2.lastUpdatedAt
  return Date.parse(date2) - Date.parse(date1)
}

export const toggleAck = (
  change: ChangeRequiringAck,
): ReturnType<typeof toggleListingChangeTaskAck> | ReturnType<typeof toggleHistoryAck> => {
  if (isListingChangeTask(change)) return toggleListingChangeTaskAck(change)

  return toggleHistoryAck(change)
}

export const getId = (change: ChangeRequiringAck) =>
  isListingChangeTask(change) ? change._id : change.name
