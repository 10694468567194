import { green, yellow } from '@ant-design/colors'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Modal, Row, message } from 'antd'
import { CustomButton, Spacer } from 'components'
import dayjs from 'dayjs'
import Link from 'next/link'
import { Fragment, MouseEvent } from 'react'
import { removeListing, useListing } from 'services'
import styled from 'styled-components'
import { formatAddress, formatDollarAmount, formatPhoneNumber } from 'utils'

import { getPackageName } from 'utils/listingHelpers'

import * as T from 'types'
import * as E from 'types/enums'

export type IColumnName =
  | 'address'
  | 'agentNotes'
  | 'closingDate'
  | 'commission'
  | 'contactInfo'
  | 'contractDate'
  | 'contractPrice'
  | 'createdAt'
  | 'deleteListing'
  | 'expiration'
  | 'goLiveDate'
  | 'listingManager'
  | 'listingStatus'
  | 'mls'
  | 'price'
  | 'seller'
  | 'serviceLevel'
  | 'totalDueAtClosing'
  | 'updatedAt'
  | 'escrowStatus'
  | 'claimedBy'

export type IListingWithColumnData = T.IListing & {
  searchMutateKey: string | undefined
  adminUser: T.IUser | undefined
}

const { confirm } = Modal

/**
 * per antd, `multiple: #` defines the column priority in the case of multiple columns being sorted
 * https://ant.design/components/table/#components-table-demo-multiple-sorter
 *
 * so we set all columns to have the same priority since we want the priority to be based off the order the
 * columns are sorted, rather than an implicit priority.
 */
const sorter = { multiple: 1 }

// as weird as it looks this is actually well supported
// https://css-tricks.com/line-clampin/#aa-the-standardized-way
const TruncatedNote = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const CenteredParagraph = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
`

const CellButton = styled(Button)`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  box-shadow: none;

  :hover,
  :active,
  :visited,
  :focus {
    color: inherit;
    background-color: transparent;
  }
`

const StyledAddressContainer = styled.div`
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const claimListing = (
  listing: IListingWithColumnData,
  updateListingClaim: T.IUpdateListingClaim,
  userHasClaim: boolean,
) => {
  const update = { listing }
  if (userHasClaim) return updateListingClaim(update)

  updateListingClaim({ ...update, userId: listing.adminUser?._id })

  if (typeof window === 'undefined' || !window || !window.open) return

  if (!userHasClaim) window.open(`${window.location.origin}/listing/${listing._id}`, '_blank')
}

const showDeleteListingModal = (listing: IListingWithColumnData) => {
  const handleDeleteListing = async () => {
    const [data, error] = await removeListing(listing._id)

    if (error && !data) {
      message.error({
        style: { marginTop: '50px' },
        content: 'There was an error deleting the listing',
        duration: 5,
      })
    } else {
      message.success({
        style: { marginTop: '50px' },
        content: 'The listing has been deleted',
        duration: 5,
      })
    }
  }

  confirm({
    title: `Are you sure you want to delete this listing: ${formatAddress(listing)?.fullAddress}?`,
    icon: <ExclamationCircleOutlined />,
    okType: 'danger',
    maskClosable: true,
    onOk: handleDeleteListing,
  })
}

const renderContactInfo = (contactInfo?: T.IContactInfo) => {
  if (!contactInfo || (!contactInfo.email && !contactInfo.name && !contactInfo.phone)) return null

  const handleCopy = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.closest('a')) return

    const textToCopy = `${contactInfo.name}\n${contactInfo.email}\n${formatPhoneNumber(
      contactInfo.phone,
    )}`
    navigator.clipboard.writeText(textToCopy)

    message.success({
      style: { marginTop: '50px' },
      duration: 2,
      content: 'Copied',
    })
  }

  return (
    <Col>
      <CellButton onClick={handleCopy}>
        <div>
          {contactInfo.name && <CenteredParagraph>{contactInfo.name}</CenteredParagraph>}
          {contactInfo.email && (
            <CenteredParagraph>
              <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
            </CenteredParagraph>
          )}
          {contactInfo.phone && (
            <CenteredParagraph>
              <a href={`tel:${contactInfo.phone}`}>{formatPhoneNumber(contactInfo.phone)}</a>
            </CenteredParagraph>
          )}
        </div>
      </CellButton>
    </Col>
  )
}

const columns: { [key in IColumnName]: T.IColumn } = {
  address: {
    title: 'Address',
    dataIndex: 'address',
    width: '300px',
    render: (_: string, record: IListingWithColumnData) => (
      <StyledAddressContainer>
        <Link href={`/listing/${record._id}`}>
          {/* jsx-a11y recommends disabling this due to the incompatibility with nextjs' Link */}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{formatAddress(record)?.fullAddress ?? '---'}</a>
        </Link>
      </StyledAddressContainer>
    ),
  },
  price: {
    title: 'Price',
    dataIndex: 'price',
    width: '90px',
    render: (price: string) => (price ? formatDollarAmount(price) : '---'),
  },
  seller: {
    title: 'Seller Name',
    dataIndex: 'userId',
    width: '150px',
    render: (userId: { name: string }) => userId?.name ?? '---',
  },
  contactInfo: {
    title: 'Showing Contact Info',
    dataIndex: 'contactInfo',
    width: '200px',
    render: (contactInfo: T.IContactInfo | undefined) => renderContactInfo(contactInfo),
  },
  listingStatus: {
    title: 'Listing Status',
    dataIndex: 'listingStatus',
    sorter,
    sortBy: ['listingStatusName'],
    render: (listingStatus: T.IListingStatus) => listingStatus?.name ?? '---',
  },
  mls: {
    title: 'MLS',
    dataIndex: 'sortableMls',
    width: '150px',
    sorter,
    render: (_: string, record: IListingWithColumnData) => {
      if (!record.mlsList.length) return 'Missing MLS Error'

      const mlsLiveIns = record.mlsList.map(({ mlsLiveIn }) => mlsLiveIn).filter(Boolean)
      const firstMlsName = record.mlsList[0].mls?.name

      let mlsDisplay: string | JSX.Element | JSX.Element[] = ''

      if (mlsLiveIns.length) {
        mlsDisplay = mlsLiveIns.map((mlsLiveIn, idx) => (
          // there is no other unique value
          // eslint-disable-next-line react/no-array-index-key
          <CenteredParagraph key={idx}>{mlsLiveIn}</CenteredParagraph>
        ))
      } else if (firstMlsName) {
        mlsDisplay = <CenteredParagraph>{firstMlsName}</CenteredParagraph>
      }

      return <Fragment key={record._id}>{mlsDisplay}</Fragment>
    },
  },
  serviceLevel: {
    title: 'Service Level',
    dataIndex: 'pricingPackage',
    sorter,
    sortBy: ['pricingPackageName', 'hasTransactionManagement'],
    render: (_: string, record: IListingWithColumnData) => getPackageName(record),
  },
  commission: {
    title: 'Commission',
    dataIndex: 'commission',
    render: (commission: string) => (commission ? `${commission}%` : '---'),
  },
  contractPrice: {
    title: 'Contract Price',
    dataIndex: ['contractDetails', 'salePrice'],
    render: (salePrice: string) => (salePrice ? formatDollarAmount(salePrice) : '---'),
  },
  contractDate: {
    title: 'Contract Date',
    dataIndex: ['contractDetails', 'contractDate'],
    sorter,
    render: (contractDate: string) =>
      contractDate ? dayjs(contractDate).format('MM/DD/YY') : '---',
  },
  closingDate: {
    title: 'Closing Date',
    dataIndex: ['contractDetails', 'closingDate'],
    sorter,
    render: (closingDate: string) => (closingDate ? dayjs(closingDate).format('MM/DD/YY') : '---'),
  },
  escrowStatus: {
    title: 'Escrow Status',
    dataIndex: ['escrowStatus'],
    sorter: true,
    render: (escrowStatus: string) => escrowStatus ?? '',
  },
  agentNotes: {
    title: 'Agent Notes',
    dataIndex: 'agentNotes',
    key: 'agentNotes',
    width: '200px',
    render: (notes: T.IAgentNote[]) => {
      const latestNote = notes[notes.length - 1]?.note ?? ''
      return <TruncatedNote>{latestNote}</TruncatedNote>
    },
  },
  listingManager: {
    title: 'Listing Manager',
    dataIndex: 'listingManagerName',
    sorter,
    sortBy: ['listingManagerName'],
    render: (listingManagerName: string) => listingManagerName ?? '',
  },
  goLiveDate: {
    title: 'Listing Live Date',
    dataIndex: 'goLiveDate',
    sorter,
    render: (goLiveDate: string, record: IListingWithColumnData) => {
      const formattedGoLiveDate = dayjs(goLiveDate).format('MM/DD/YY')

      const formattedGoLiveDateWithBadge = (
        <Row
          wrap={false}
          align="middle"
        >
          <div>{formattedGoLiveDate}</div>
          <Spacer size={3} />
          <div>
            <Badge status="error" />
          </div>
        </Row>
      )

      const goLiveDateRender
        = record.goLiveLater
        && !record.firstPublishedAt
        && !!record?.goLiveDate
        && new Date(record.goLiveDate).getTime() >= new Date().getTime()
          ? formattedGoLiveDateWithBadge
          : formattedGoLiveDate

      return goLiveDate ? goLiveDateRender : '---'
    },
  },
  expiration: {
    title: 'Listing Exp.',
    dataIndex: 'dateOfExpiration',
    sorter,
    sortBy: ['dateOfExpiration'],
    render: (dateOfExpiration: string) =>
      dateOfExpiration ? dayjs(dateOfExpiration).format('MM/DD/YY') : '---',
  },
  createdAt: {
    title: 'Created',
    dataIndex: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('MM/DD/YY'),
  },
  updatedAt: {
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    render: (updatedAt: string) => (
      <>
        <div>{dayjs(updatedAt).format('MM/DD/YY')}</div>
        <div>{dayjs(updatedAt).format('h:mm a')}</div>
      </>
    ),
  },
  deleteListing: {
    title: 'Delete',
    dataIndex: '_id',
    key: '_id',
    width: '70px',
    render: (_: string, record: IListingWithColumnData) => {
      const handleShowDeleteListingModal = () => showDeleteListingModal(record)
      return (
        <Button
          shape="circle"
          size="middle"
          icon={<CloseOutlined />}
          onClick={handleShowDeleteListingModal}
          danger
        />
      )
    },
  },
  totalDueAtClosing: {
    title: 'Due At Closing',
    dataIndex: ['totalDueAtClosing'],
    render: (totalDueAtClosing: string) =>
      totalDueAtClosing ? formatDollarAmount(totalDueAtClosing) : '---',
  },
  claimedBy: {
    title: 'Claimed By',
    dataIndex: ['claimedByName'],
    sorter,
    sortBy: ['claimedByName'],
    render: (claimedByName: string | undefined, record: IListingWithColumnData) => {
      const { updateListingClaim } = useListing()
      const userHasClaim = record.adminUser?._id === record.claimedBy?._id
      const handleClickClaimListing = () => claimListing(record, updateListingClaim, userHasClaim)
      return (
        <FlexColumn>
          {claimedByName && <div>{claimedByName}</div>}
          {(userHasClaim || !record.claimedBy) && (
            <CustomButton
              backgroundColor={userHasClaim ? green[5] : yellow[4]}
              backgroundColorOnHover={userHasClaim ? green[6] : yellow[5]}
              style={{ width: 75 }}
              custom
              onClick={handleClickClaimListing}
            >
              {userHasClaim ? 'Release' : 'Claim'}
            </CustomButton>
          )}
        </FlexColumn>
      )
    },
  },
}

/**
 * For columns marked 'sorter = true', set the property 'sortBy' if it doesn't already have one
 *
 * the default is the dataIndex, since that is the property grabbed for display
 *
 * 'sortBy' tells the server how to sort the listings
 */
function setSortByDefault() {
  Object.values(columns).forEach(col => {
    if (!col.sorter || col.sortBy) return

    const { dataIndex } = col

    col.sortBy = Array.isArray(dataIndex) ? [dataIndex.join('.')] : [dataIndex]
  })
}

setSortByDefault()

const allColumns = Object.values(columns)

export { columns, allColumns }

const {
  address,
  price,
  seller,
  contactInfo,
  listingStatus,
  mls,
  serviceLevel,
  commission,
  closingDate,
  contractDate,
  contractPrice,
  agentNotes,
  listingManager,
  goLiveDate,
  expiration,
  createdAt,
  updatedAt,
  totalDueAtClosing,
  escrowStatus,
  claimedBy,
  // deleteListing,
} = columns

const defaultColumns = [
  address,
  price,
  seller,
  contactInfo,
  listingStatus,
  serviceLevel,
  closingDate,
  agentNotes,
  claimedBy,
  listingManager,
  createdAt,
  updatedAt,
  // deleteListing,
]

const activeColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  mls,
  price,
  commission,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
  expiration,
  updatedAt,
]

const openTasksColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  goLiveDate,
  mls,
  price,
  commission,
  serviceLevel,
  closingDate,
  agentNotes,
  claimedBy,
  listingManager,
  updatedAt,
]

const newListingsColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  goLiveDate,
  mls,
  price,
  commission,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
  updatedAt,
]

const brokerReviewColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  goLiveDate,
  mls,
  price,
  commission,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
  expiration,
]

const contractColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  mls,
  contractPrice,
  contractDate,
  closingDate,
  totalDueAtClosing,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
]

const closedColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  goLiveDate,
  mls,
  contractPrice,
  contractDate,
  closingDate,
  totalDueAtClosing,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
]

const expiringColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  mls,
  price,
  commission,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
  expiration,
]

const collectionsColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  contractPrice,
  contractDate,
  closingDate,
  totalDueAtClosing,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
]

const offersOutstandingColumns = [
  address,
  seller,
  contactInfo,
  listingStatus,
  contractPrice,
  contractDate,
  closingDate,
  totalDueAtClosing,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
]

const escrowColumns = [
  address,
  seller,
  contactInfo,
  escrowStatus,
  mls,
  contractPrice,
  contractDate,
  closingDate,
  totalDueAtClosing,
  serviceLevel,
  agentNotes,
  claimedBy,
  listingManager,
]

export const currentTabToQueryTab: Partial<Record<E.ListingStatusGroupings, string>> = {
  [E.ListingStatusGroupings.active]: 'activeListings',
  [E.ListingStatusGroupings.expiringSoon]: 'expiringListings',
}

export const getColumns = (mode?: E.ListingStatusGroupings | null) => {
  switch (mode) {
    case E.ListingStatusGroupings.active:
      return activeColumns
    case E.ListingStatusGroupings.openTasks:
      return openTasksColumns
    case E.ListingStatusGroupings.newListings:
      return newListingsColumns
    case E.ListingStatusGroupings.brokerReview:
      return brokerReviewColumns
    case E.ListingStatusGroupings.inContract:
      return contractColumns
    case E.ListingStatusGroupings.closed:
      return closedColumns
    case E.ListingStatusGroupings.expiringSoon:
      return expiringColumns
    case E.ListingStatusGroupings.escrow:
      return escrowColumns
    default:
      return defaultColumns
  }
}

const columnsByStatus: Partial<Record<E.ListingStatusGroupings, T.IColumn[]>> & {
  default: T.IColumn[]
} = {
  [E.ListingStatusGroupings.active]: activeColumns,
  [E.ListingStatusGroupings.openTasks]: openTasksColumns,
  [E.ListingStatusGroupings.newListings]: newListingsColumns,
  [E.ListingStatusGroupings.brokerReview]: brokerReviewColumns,
  [E.ListingStatusGroupings.inContract]: contractColumns,
  [E.ListingStatusGroupings.closed]: closedColumns,
  [E.ListingStatusGroupings.expiringSoon]: expiringColumns,
  [E.ListingStatusGroupings.collections]: collectionsColumns,
  [E.ListingStatusGroupings.offersOutstanding]: offersOutstandingColumns,
  [E.ListingStatusGroupings.escrow]: escrowColumns,
  default: defaultColumns,
}

export default columnsByStatus
