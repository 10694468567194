import {
  Alert,
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Switch,
  Table,
  TableProps,
  Typography,
} from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import { LoadingSpinner, PageError, PageLoader, ScrollableSelect, Spacer } from 'components'
import { Moment } from 'moment'
import { useRouter } from 'next/router'
import { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { showTotal } from 'utils'

import { useGroupedListingStatuses } from 'services/hooks'
import useTableMultiSort from 'services/hooks/useTableMultiSort'
import {
  useListingManagers,
  useListingSearchResults,
  useMlsList,
  useMlsStates,
  useUser,
  useUserById,
} from 'services/swr'
import usePricingPackages from 'services/swr/usePricingPackages'

import { formatDateWhenTruthy } from 'utils/stringFormatting'

import { orderedEscrowStatuses } from 'data/escrowStatuses'

import * as T from 'types'
import * as E from 'types/enums'

import RowNumber from './RowNumber'
import { IListingWithColumnData, allColumns, currentTabToQueryTab, getColumns } from './columns'
import { useAndQueryExpression } from './utils'

type ISetDate = (date: Moment | null) => void

interface IMakeOnDateRangeChange {
  (setFromDate: ISetDate, setToDate: ISetDate): RangePickerProps['onCalendarChange']
}

const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

// unknown type because this helper will work for any array types
const isAllSelected = (dataArr: unknown[], selectedArr: unknown[]) =>
  selectedArr.length && dataArr.length === selectedArr.length

const formatArrayQueryString = (dataArr: unknown[], selectedArr: unknown[]) => {
  if (!selectedArr.length) return ''

  if (isAllSelected(dataArr, selectedArr)) return 'all'

  return selectedArr.join(',')
}

const FilterSection = styled.div`
  padding: 15px;
  background: ${props => props.theme.colors.grey2};
  border: 1px solid ${props => props.theme.colors.grey4};
  border-radius: 3px;
`

const StripedTable: FunctionComponent<TableProps<any>> = styled(Table)`
  .ant-table-tbody tr:nth-child(odd) td {
    background-color: ${props => props.theme.colors.grey2};
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${props => props.theme.colors.grey};
  }
  .ant-table-cell:nth-child(1) {
    padding: 2px 0px;
  }

  .ant-table-tbody > tr.active > td,
  .ant-table-tbody > tr.active:hover > td {
    background-color: ${props => props.theme.colors.transparentBlueAlpha10};
  }
`

const EmailWrapper = styled(Button)`
  white-space: normal;
  height: auto;
  word-break: break-all;
`

const SearchListings = () => {
  // theme
  const theme = useContext(ThemeContext)

  const { user: adminUser } = useUser()

  // router and listing status filter mode (mode depends on the router query)
  const router = useRouter()
  const [currentMode, setCurrentMode] = useState(E.ListingStatusGroupings.all)

  // server data
  const {
    orderedStatuses: listingStatuses,
    orderedStatusIds: listingStatusIds,
    activeStatusIds,
    brokerReviewStatusIds,
    contractStatusIds,
    closedStatusIds,
    expiringStatusIds,
    newStatusIds,
    openTaskStatusIds,
    statusesInitialized,
  } = useGroupedListingStatuses()

  const { mlsStateCodes, mlsStatesInitialized: mlsStateCodesInitialized } = useMlsStates()
  const { pricingPackageNames, pricingPackagesInitialized } = usePricingPackages()
  const { managers } = useListingManagers()
  const { mlsList } = useMlsList()

  // filter search data
  const [mlsId, setMlsId] = useState('')
  const [street, setStreet] = useState('')
  const [escrow, setEscrow] = useState('')
  const [phone, setPhone] = useState('')
  const [seller, setSeller] = useState('')
  const [managerName, setManagerName] = useState<string>('')
  const [closingDateFrom, setClosingDateFrom] = useState<Moment | null>(null)
  const [closingDateTo, setClosingDateTo] = useState<Moment | null>(null)
  const [goLiveDateFrom, setGoLiveDateFrom] = useState<Moment | null>(null)
  const [goLiveDateTo, setGoLiveDateTo] = useState<Moment | null>(null)
  const [sellerUsingHomeListerTitleService, setSellerUsingHomeListerTitleService] = useState(false)

  // text search input handlers
  const searchAddress = (addressValue: string) => setStreet(addressValue)
  const searchMlsId = (mlsIdValue: string) => setMlsId(mlsIdValue)
  const searchSeller = (sellerValue: string) => setSeller(sellerValue)
  const searchPhone = (value: string) => setPhone(value.replace(/[^a-zA-Z0-9]/g, ''))
  const searchEscrow = (value: string) => setEscrow(value)
  const searchManager = (value: string) => setManagerName(value)

  // date range inputs
  const resetConditionalFilters = () => {
    setClosingDateFrom(null)
    setClosingDateTo(null)
    setGoLiveDateFrom(null)
    setGoLiveDateTo(null)
  }

  // switch inputs
  const toggleSellerUsingHomeListerTitleService = () =>
    setSellerUsingHomeListerTitleService(prev => !prev)

  const makeOnDateRangeChange: IMakeOnDateRangeChange = (setDateFrom, setDateTo) => {
    const handleDateRangeChange: RangePickerProps['onCalendarChange'] = (
      values,
      _strDates,
      { range },
    ) => {
      const [dateFrom, dateTo] = values || [null, null]
      const datesWereCleared = !dateFrom && !dateTo
      if (datesWereCleared) {
        setDateFrom(dateFrom)
        setDateTo(dateTo)
      } else if (range === 'start') setDateFrom(dateFrom)
      else setDateTo(dateTo)
    }

    return handleDateRangeChange
  }

  const handleClosingDateChange = makeOnDateRangeChange(setClosingDateFrom, setClosingDateTo)
  const handleGoLiveDateChange = makeOnDateRangeChange(setGoLiveDateFrom, setGoLiveDateTo)

  useEffect(() => {
    if (!router.isReady) return

    const newMode = router.query.mode as E.ListingStatusGroupings

    // when coming to the homepage directly, set the mode to All as the default
    if (!newMode) {
      const paramDivider = Object.keys(router.query).length > 0 ? '&' : '?'
      router.replace(`${router.asPath}${paramDivider}mode=${E.ListingStatusGroupings.all}`)
      return
    }

    // default malformed modes to default All mode
    if (!Object.values(E.ListingStatusGroupings).includes(newMode)) {
      router.replace(`/listings?mode=${E.ListingStatusGroupings.all}`)
      return
    }

    // otherwise set the mode to the one specified in the query
    if (newMode !== currentMode) {
      resetConditionalFilters()
      setCurrentMode(newMode)
    }
  }, [router])

  // set initial query search params into state then clear the url so they don't keep setting the
  // state every time the router changes
  useEffect(() => {
    if (!router.isReady) return

    const { address: qAddress, mlsId: qMlsId, seller: qSeller } = router.query

    if (qAddress) setStreet(qAddress as string)

    if (qMlsId) setMlsId(qMlsId as string)

    if (qSeller) setSeller(qSeller as string)

    if (qAddress || qMlsId || qSeller) {
      router.replace('/listings')
    }
  }, [router])

  // query parameters used in the listing search API fetch
  //  1. status ids
  const [statusIds, setStatusIds] = useState<string[]>([])

  const handleClickAllListingStatusIds = () => setStatusIds(listingStatusIds)
  const handleClickNoneListingStatusIds = () => setStatusIds([])

  const toggleListingStatusIdSelected = (status: T.IListingStatus) => {
    if (!statusIds.includes(status._id)) return setStatusIds([...statusIds, status._id])

    setStatusIds([...statusIds].filter(statusId => statusId !== status._id))
  }

  const [escrowStatuses, setEscrowStatuses] = useState<E.EscrowStatus[]>([])
  const handleClickAllEscrowStatuses = () => setEscrowStatuses(orderedEscrowStatuses)
  const handleClickNoneEscrowStatuses = () => setEscrowStatuses([])

  const toggleEscrowStatusSelected = (status: E.EscrowStatus) => {
    if (!escrowStatuses.includes(status)) return setEscrowStatuses([...escrowStatuses, status])

    setEscrowStatuses(escrowStatuses.filter(s => s !== status))
  }

  useEffect(() => {
    if (!statusesInitialized && !router.query.mode) return

    setEscrowStatuses(orderedEscrowStatuses)
    switch (router.query.mode) {
      case E.ListingStatusGroupings.active:
        return setStatusIds(activeStatusIds)
      case E.ListingStatusGroupings.brokerReview:
        return setStatusIds(brokerReviewStatusIds)
      case E.ListingStatusGroupings.inContract:
        return setStatusIds(contractStatusIds)
      case E.ListingStatusGroupings.closed:
        return setStatusIds(closedStatusIds)
      case E.ListingStatusGroupings.expiringSoon:
        return setStatusIds(expiringStatusIds)
      case E.ListingStatusGroupings.newListings:
        return setStatusIds(newStatusIds)
      case E.ListingStatusGroupings.openTasks:
        return setStatusIds(openTaskStatusIds)
      default:
        return setStatusIds(listingStatusIds)
    }
  }, [router.query.mode, statusesInitialized])

  //  2. states
  const [statePostalCodes, setStatePostalCodes] = useState<string[]>([])
  const handleClickAllStatePostalCodes = () => setStatePostalCodes(mlsStateCodes)
  const handleClickNoneStatePostalCodes = () => setStatePostalCodes([])

  const toggleStatePostalCodeSelected = (stateCode: string) => {
    if (!statePostalCodes.includes(stateCode)) {
      return setStatePostalCodes([...statePostalCodes, stateCode])
    }

    setStatePostalCodes(
      [...statePostalCodes].filter(selectedStateCode => selectedStateCode !== stateCode),
    )
  }

  useEffect(() => {
    if (mlsStateCodesInitialized) setStatePostalCodes(mlsStateCodes)
  }, [mlsStateCodesInitialized])

  // 3. services
  const [serviceNames, setServiceNames] = useState<string[]>([])

  // basic + tm is not a package but we're exposing it as one until we have separate service and
  // package filters - which will require some underlying structures to be refactored.
  const [includeBasicTm, setIncludeBasicTm] = useState<boolean>(false)

  const toggleIncludeBasicTm = () => setIncludeBasicTm(!includeBasicTm)

  const handleClickAllServiceNames = () => {
    setServiceNames(pricingPackageNames.map(pkgName => pkgName))
    setIncludeBasicTm(true)
  }

  const handleClickNoneServiceNames = () => {
    setServiceNames([])
    setIncludeBasicTm(false)
  }

  const toggleServiceNameSelected = (serviceName: string) => {
    if (!serviceNames.includes(serviceName)) return setServiceNames([...serviceNames, serviceName])

    setServiceNames([...serviceNames].filter(sName => sName !== serviceName))
  }

  useEffect(() => {
    if (pricingPackagesInitialized) {
      setServiceNames(pricingPackageNames.map(pkgName => pkgName))
      setIncludeBasicTm(true)
    }
  }, [pricingPackagesInitialized])

  //  4. resultsNo - defines the number of results we limit our db read to on BE/show per page on FE
  // const [resultsNo, setResultsNo] = useState(25)
  const resultsNo = 100 // should be user-modifiable val in the future

  //  5. page number - multiplied by resultsNo, sets the skip value when reading from the db
  const [pageNo, setPageNo] = useState(1)

  const handlePageChange = (page: number) => {
    setPageNo(page)
    // 2nd arg pageSize is always NaN for some reason, seems like antd bug
    // if (pageSize && pageSize !== resultsNo) setResultsNo(pageSize)
  }

  const getServices = () =>
    serviceNames.length ? formatArrayQueryString(pricingPackageNames, serviceNames) : ''

  const hasRequiredData
    = statusesInitialized && mlsStateCodesInitialized && pricingPackagesInitialized

  const sellerId = useMemo(
    () => (router.query.sellerId ? String(router.query.sellerId) : undefined),
    [router.query.sellerId],
  )

  const { user: sellerInQuery } = useUserById(sellerId)

  // 6. MLS Live In
  /**
   * NB: We sometimes accept user input for one MLS but for various reasons (mistakenly assigned,
   * user requested a different one), we end up actually listing it on a different MLS. This
   * concept is represented by the `mlsLiveIn` field, which is equal to the `mls.name` of the MLS
   * that is serving as the alias for the MLS that the user inputted. It's often useful for
   * brokers to search by this value instead of the underlying MLS.
   */
  const [mlsLiveInSelected, setMlsLiveInSelected] = useState<string[]>([])
  const handleMLSSelect = (value: string) => setMlsLiveInSelected([...mlsLiveInSelected, value])

  const handleMLSDeselect = (value: string) =>
    setMlsLiveInSelected([...mlsLiveInSelected].filter(name => name !== value))

  const handleClearAll = () => setMlsLiveInSelected([])

  const handleShowMyMLSes = () => {
    if (!adminUser?.mlses?.length) return

    const userMlsesObj: { [key: string]: string } = adminUser?.mlses.reduce(
      (prev, curr) => ({ ...prev, [curr]: curr }),
      {},
    )

    const mlsLiveIn = mlsList.filter(mls => !!userMlsesObj[mls._id])
    const newMlsLiveInSelection = mlsLiveIn.map(mls => mls.name)
    setMlsLiveInSelected(newMlsLiveInSelection)
  }

  useEffect(() => {
    if (!router.isReady || !mlsList) return

    const mlsLiveIn = (router.query.mlsLiveIn as string) || ''

    // mlsLiveIn value is derived from the MLS Name property
    const isAnMLSLiveInOption = mlsList.some(({ name }) => name === mlsLiveIn)
    const isSelected = mlsLiveInSelected.includes(mlsLiveIn)

    if (mlsLiveIn && !isSelected && isAnMLSLiveInOption) {
      setMlsLiveInSelected([...mlsLiveInSelected, mlsLiveIn])
    }
  }, [router, mlsList])

  const encodedMlsLiveInSelected = useMemo(
    () => mlsLiveInSelected.map(mlsLiveIn => encodeURIComponent(mlsLiveIn)),
    [mlsLiveInSelected],
  )

  useEffect(() => {
    if (!router.isReady || !mlsList) return

    const mlsMongoId = (router.query.mlsMongoId as string) || ''

    const mls = mlsList.find(mlsEntry => mlsEntry._id === mlsMongoId)
    if (mls?.name) setMlsLiveInSelected([...mlsLiveInSelected, mls.name])
  }, [router, mlsList])

  // 7. `sort` param to send in search API call; UI config for multiply-sorted columns
  // NB: selections are persisted in/hydratted from local storage
  const {
    sort,
    displayColumnsWithSort,
    handleChange: handleTableChange,
    handleClear: handleClearSort,
  } = useTableMultiSort({
    key: currentMode as string,
    columns: allColumns,
    getColumnsForDisplay: getColumns,
  })

  useEffect(() => {
    // whenever sort or any of the filters change, we should start back at page 1
    setPageNo(1)
  }, [
    sort,
    mlsId,
    seller,
    street,
    escrow,
    phone,
    managerName,
    closingDateFrom,
    closingDateTo,
    mlsLiveInSelected,
    sellerUsingHomeListerTitleService,
    statusIds,
    statePostalCodes,
    serviceNames,
  ])

  // helper function to determine display and search query parameters
  const isColumnDisplayed = (name: string) =>
    displayColumnsWithSort.map(({ title }) => title).includes(name)

  // 8. AND query expression
  // newListings and openTasks are special cases where we need a sub-query to handle the
  // "listing details updated" case. This value will be passed as `$and` in the backend db query
  const andQueryExpression = useAndQueryExpression({
    currentMode,
    selectedServiceNames: serviceNames,
    selectedStatusIds: statusIds,
  })

  const {
    error: listingSearchResultsError,
    isFetching,
    listingSearchResults,
    listingSearchResultsCount,
    listingSearchResultsKey,
  } = useListingSearchResults({
    shouldFetch: hasRequiredData, // conditionally wait to fetch data
    statusIds: formatArrayQueryString(listingStatuses, statusIds),
    states: formatArrayQueryString(mlsStateCodes, statePostalCodes),
    services: getServices(),
    resultsNo,
    // Mongo pagination uses zero-based index
    // antd uses one-based index for it's pagination.
    pageNo: pageNo - 1,
    // @FIXME: sending the backend information about what kind of view we are displaying
    //         is a big code smell. The API should be revised.
    tab: currentTabToQueryTab[currentMode] || 'search',
    // this is the correct way to do the above, uses an explicit query parameter to the API
    showFutureTasks: currentMode === E.ListingStatusGroupings.openTasks,
    // search params
    street,
    mlsId,
    mlsLiveIn: encodedMlsLiveInSelected,
    phone,
    escrow,
    seller,
    sellerId,
    listingManagerName: managerName,
    includeBasicTm,
    sellerUsingHomeListerTitleService,
    closingDateFrom: formatDateWhenTruthy(closingDateFrom),
    closingDateTo: formatDateWhenTruthy(closingDateTo),
    goLiveDateFrom: formatDateWhenTruthy(goLiveDateFrom),
    goLiveDateTo: formatDateWhenTruthy(goLiveDateTo),
    sort,
    collections: currentMode === E.ListingStatusGroupings.collections || undefined,
    includeTotalDueAtClosing: isColumnDisplayed('Due At Closing'),
    andQueryExpression,
    // behavior differs based on the false, true, or undefined value
    hasOutstandingOffers: currentMode === E.ListingStatusGroupings.offersOutstanding || undefined,
    escrowStatuses: formatArrayQueryString(orderedEscrowStatuses, escrowStatuses),
  })

  // add additional data we'll need to pass to button functions inside column render functions
  const appendUpdateListingClaim = (listing: T.IListing): IListingWithColumnData => {
    listing.searchMutateKey = listingSearchResultsKey
    listing.adminUser = adminUser
    return listing as IListingWithColumnData
  }

  const listingSearchResultsTableDataSource: IListingWithColumnData[] = useMemo(
    () =>
      !listingSearchResults
        ? listingSearchResults
        : [...listingSearchResults]?.map(appendUpdateListingClaim),
    [listingSearchResults, listingSearchResultsKey],
  )

  const hasRequiredSelections = !!(
    statusIds.length
    && escrowStatuses.length
    && statePostalCodes.length
    && (serviceNames.length || includeBasicTm)
  )

  // a popover allows listing detail pages for all selected rows to be opened in new tabs
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  useEffect(() => {
    setSelectedRowIds([])
  }, [currentMode])

  if (listingSearchResultsError) {
    return (
      <PageError error="There was a problem loading search listings. Please refresh and try your selection again." />
    )
  }

  if (!hasRequiredData) return <PageLoader />

  const renderIncludesBasicTmColumn = () => (
    <Col>
      <Button
        onClick={toggleIncludeBasicTm}
        type={includeBasicTm ? 'primary' : 'default'}
        size="small"
        data-testid="basicTm"
      >
        Basic + TM
      </Button>
    </Col>
  )

  const renderFilterSection = () => (
    <FilterSection>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Input.Search
                placeholder="MLS ID Search"
                defaultValue={mlsId}
                onSearch={searchMlsId}
                data-testid="MLS ID Search"
                allowClear
              />
            </Col>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Input.Search
                placeholder="Seller Name Search"
                defaultValue={seller}
                onSearch={searchSeller}
                data-testid="Seller Name Search"
                allowClear
              />
            </Col>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Input.Search
                placeholder="Address Search"
                defaultValue={street}
                onSearch={searchAddress}
                data-testid="Address Search"
                allowClear
              />
            </Col>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Input.Search
                placeholder="Escrow Search"
                onSearch={searchEscrow}
                data-testid="Escrow Search"
                allowClear
              />
            </Col>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Input.Search
                placeholder="Search by Phone"
                onSearch={searchPhone}
                data-testid="Search by Phone"
                allowClear
              />
            </Col>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Search by Manager"
                onSelect={searchManager}
                onClear={() => setManagerName('')}
                data-testid="Search By Manager"
                showSearch
                allowClear
              >
                {managers.concat([{ name: 'Unassigned', _id: '' }]).map(mgr => (
                  <Option
                    key={mgr._id}
                    value={mgr.name}
                    data-testid={mgr.name}
                  >
                    {mgr.name}
                  </Option>
                ))}
              </Select>
            </Col>
            {isColumnDisplayed('Closing Date') && (
              <Col
                xs={24}
                md={12}
                lg={8}
              >
                <RangePicker
                  onCalendarChange={handleClosingDateChange}
                  placeholder={['Closing Date From', 'Closing Date To']}
                  style={{ width: '100%' }}
                  data-testid="Closing Date"
                  value={[closingDateFrom, closingDateTo]}
                />
              </Col>
            )}
            {isColumnDisplayed('Go Live Date') && (
              <Col
                xs={24}
                md={12}
                lg={8}
              >
                <RangePicker
                  onCalendarChange={handleGoLiveDateChange}
                  placeholder={['Live Date From', 'Live Date To']}
                  style={{ width: '100%' }}
                  data-testid="Live Date"
                  value={[goLiveDateFrom, goLiveDateTo]}
                />
              </Col>
            )}
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Row gutter={[10, 10]}>
                <Col flex="4">
                  <ScrollableSelect
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select MLS"
                    value={mlsLiveInSelected}
                    onSelect={handleMLSSelect}
                    onDeselect={handleMLSDeselect}
                    onClear={handleClearAll}
                    data-testid="Select MLS"
                    showSearch
                    showArrow
                    allowClear
                  >
                    {mlsList.map(mls => (
                      <Option
                        key={mls._id}
                        value={mls.name}
                        data-testid={mls.name}
                      >
                        {mls.name}
                      </Option>
                    ))}
                  </ScrollableSelect>
                </Col>
                {!!adminUser?.mlses?.length && (
                  <Col flex="1">
                    <Button
                      onClick={handleShowMyMLSes}
                      disabled={!adminUser}
                    >
                      Show Only My MLSes
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
            <Col
              xs={24}
              md={12}
              lg={8}
            >
              <Row
                style={{ height: '100%' }}
                align="middle"
                gutter={[10, 10]}
              >
                <Col>
                  <Text>HL Title Requested</Text>
                </Col>
                <Col>
                  <Switch
                    checked={sellerUsingHomeListerTitleService}
                    onChange={toggleSellerUsingHomeListerTitleService}
                    data-testid="sellerUsingHomeListerTitleService"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Spacer />
      {currentMode !== E.ListingStatusGroupings.escrow && (
        <Row gutter={[15, 15]}>
          <Col
            xs={24}
            sm={4}
            lg={2}
          >
            <Title
              level={5}
              style={{ margin: 0 }}
            >
              Statuses
            </Title>
          </Col>
          <Col lg={3}>
            <Row
              gutter={[5, 5]}
              wrap={false}
            >
              <Col>
                <Button
                  type={statusIds.length === listingStatuses.length ? 'primary' : 'dashed'}
                  onClick={handleClickAllListingStatusIds}
                  shape="round"
                  size="small"
                  data-testid="StatusesAll"
                >
                  All
                </Button>
              </Col>
              <Col>
                <Button
                  type={statusIds.length === 0 ? 'primary' : 'dashed'}
                  onClick={handleClickNoneListingStatusIds}
                  shape="round"
                  size="small"
                  data-testid="StatusesNone"
                >
                  None
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            lg={19}
          >
            <Row gutter={[5, 5]}>
              {listingStatuses
                .filter(status => !!status)
                .map(status => {
                  const handleClickListingStatus = () => toggleListingStatusIdSelected(status)
                  return (
                    <Col key={status._id}>
                      <Button
                        onClick={handleClickListingStatus}
                        type={statusIds.includes(status._id) ? 'primary' : 'default'}
                        size="small"
                        data-testid={status.name}
                      >
                        {status.name}
                      </Button>
                    </Col>
                  )
                })}
            </Row>
          </Col>
        </Row>
      )}
      {currentMode === E.ListingStatusGroupings.escrow && (
        <Row gutter={[15, 15]}>
          <Col
            xs={24}
            sm={4}
            lg={2}
          >
            <Title
              level={5}
              style={{ margin: 0 }}
            >
              Escrow Statuses
            </Title>
          </Col>
          <Col lg={3}>
            <Row
              gutter={[5, 5]}
              wrap={false}
            >
              <Col>
                <Button
                  type={
                    escrowStatuses.length === orderedEscrowStatuses.length ? 'primary' : 'dashed'
                  }
                  onClick={handleClickAllEscrowStatuses}
                  shape="round"
                  size="small"
                  data-testid="EscrowEstatusAll"
                >
                  All
                </Button>
              </Col>
              <Col>
                <Button
                  type={escrowStatuses.length === 0 ? 'primary' : 'dashed'}
                  onClick={handleClickNoneEscrowStatuses}
                  shape="round"
                  size="small"
                  data-testid="EscrowEstatusNone"
                >
                  None
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            lg={19}
          >
            <Row gutter={[5, 5]}>
              {orderedEscrowStatuses
                .filter(status => !!status)
                .map(status => {
                  const handleClickEscrowStatus = () => toggleEscrowStatusSelected(status)
                  return (
                    <Col key={status}>
                      <Button
                        onClick={handleClickEscrowStatus}
                        type={escrowStatuses.includes(status) ? 'primary' : 'default'}
                        size="small"
                        data-testid={status}
                      >
                        {status}
                      </Button>
                    </Col>
                  )
                })}
            </Row>
          </Col>
        </Row>
      )}
      <Spacer />
      <Row gutter={[15, 15]}>
        <Col
          xs={24}
          sm={4}
          lg={2}
        >
          <Title
            level={5}
            style={{ margin: 0 }}
          >
            States
          </Title>
        </Col>
        <Col lg={3}>
          <Row
            gutter={[5, 5]}
            wrap={false}
          >
            <Col>
              <Button
                type={statePostalCodes.length === mlsStateCodes.length ? 'primary' : 'dashed'}
                onClick={handleClickAllStatePostalCodes}
                shape="round"
                size="small"
                data-testid="StatesAll"
              >
                All
              </Button>
            </Col>
            <Col>
              <Button
                type={statePostalCodes.length === 0 ? 'primary' : 'dashed'}
                onClick={handleClickNoneStatePostalCodes}
                shape="round"
                size="small"
                data-testid="StatesNone"
              >
                None
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          lg={19}
        >
          <Row gutter={[5, 5]}>
            {mlsStateCodes
              .filter(statePostalCode => !!statePostalCode)
              .map(statePostalCode => {
                const handleClickStatePostalCode = () =>
                  toggleStatePostalCodeSelected(statePostalCode)
                return (
                  <Col key={statePostalCode}>
                    <Button
                      onClick={handleClickStatePostalCode}
                      type={statePostalCodes.includes(statePostalCode) ? 'primary' : 'default'}
                      size="small"
                      data-testid={statePostalCode}
                    >
                      {statePostalCode}
                    </Button>
                  </Col>
                )
              })}
          </Row>
        </Col>
      </Row>
      <Spacer />
      <Row gutter={[15, 15]}>
        <Col
          xs={24}
          sm={4}
          lg={2}
        >
          <Title
            level={5}
            style={{ margin: 0 }}
          >
            Service Level
          </Title>
        </Col>
        <Col lg={3}>
          <Row
            gutter={[5, 5]}
            wrap={false}
          >
            <Col>
              <Button
                type={
                  serviceNames.length === pricingPackageNames.length && includeBasicTm
                    ? 'primary'
                    : 'dashed'
                }
                onClick={handleClickAllServiceNames}
                shape="round"
                size="small"
                data-testid="ServiceLevelAll"
              >
                All
              </Button>
            </Col>
            <Col>
              <Button
                type={serviceNames.length === 0 && !includeBasicTm ? 'primary' : 'dashed'}
                onClick={handleClickNoneServiceNames}
                shape="round"
                size="small"
                data-testid="ServiceLevelNone"
              >
                None
              </Button>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          lg={17}
        >
          <Row gutter={[5, 5]}>
            {pricingPackageNames
              .filter(pkgName => !!pkgName)
              .map(pkgName => {
                const handleClickService = () => toggleServiceNameSelected(pkgName)

                const packageColumn = (
                  <Col key={pkgName}>
                    <Button
                      onClick={handleClickService}
                      type={serviceNames.includes(pkgName) ? 'primary' : 'default'}
                      size="small"
                      data-testid={pkgName}
                    >
                      {pkgName}
                    </Button>
                  </Col>
                )

                if (pkgName === 'Basic') {
                  return (
                    <Fragment key={pkgName}>
                      {packageColumn}
                      {renderIncludesBasicTmColumn()}
                    </Fragment>
                  )
                }

                return packageColumn
              })}
          </Row>
        </Col>
        <Col
          xs={24}
          lg={2}
        >
          <Button
            size="small"
            type="primary"
            data-testid="ClearSort"
            onClick={handleClearSort}
          >
            Clear Sort
          </Button>
        </Col>
      </Row>
    </FilterSection>
  )

  const renderHeaderWithSeller = (sellerUser: T.IUser) => (
    <FilterSection>
      <Row
        align="middle"
        justify="space-between"
      >
        <Col>
          <Title
            level={5}
            style={{ margin: 0 }}
          >
            {sellerUser.name}
          </Title>
        </Col>
        <Col>
          <EmailWrapper
            type="primary"
            shape="round"
            href={`mailto:${sellerUser.email}`}
          >
            {sellerUser.email}
          </EmailWrapper>
        </Col>
      </Row>
    </FilterSection>
  )

  const renderRowNumberColumn = (_: any, record: T.IListing, index: number) => {
    const rowStartNumber = (pageNo || 1) * resultsNo - resultsNo
    const rowIndex = rowStartNumber + (index + 1)
    const updateSelectedRowIds = (update: string[]) => setSelectedRowIds(update)
    return (
      <RowNumber
        record={record}
        index={rowIndex}
        selectedRowIds={selectedRowIds}
        updateSelectedRowIds={updateSelectedRowIds}
      />
    )
  }

  return (
    <>
      {sellerInQuery ? renderHeaderWithSeller(sellerInQuery) : renderFilterSection()}
      <Spacer />
      {hasRequiredData && hasRequiredSelections && (
        <StripedTable
          columns={displayColumnsWithSort}
          dataSource={listingSearchResultsTableDataSource}
          onChange={handleTableChange}
          pagination={{
            current: pageNo,
            showSizeChanger: false,
            pageSize: resultsNo,
            total: listingSearchResultsCount,
            onChange: handlePageChange,
            showTotal,
          }}
          rowClassName={(record: T.IListing) =>
            selectedRowIds.includes(record._id) ? 'active' : ''
          }
          rowSelection={{
            renderCell: renderRowNumberColumn,
            columnWidth: '20px',
            hideSelectAll: true,
          }}
          rowKey="_id"
          scroll={{ x: theme.metrics.phone }}
          loading={{
            spinning: isFetching,
            indicator: <LoadingSpinner />,
          }}
        />
      )}
      {!hasRequiredSelections && (
        <Row gutter={[15, 15]}>
          {!(statusIds.length && escrowStatuses.length) && (
            <Col span={24}>
              <Row justify="center">
                <Col
                  xs={24}
                  md={12}
                >
                  <Alert
                    message="No statuses selected"
                    description="You must select at least one status to see tabular listing data"
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!statePostalCodes.length && (
            <Col span={24}>
              <Row justify="center">
                <Col
                  xs={24}
                  md={12}
                >
                  <Alert
                    message="No states selected"
                    description="You must select at least one state to see tabular listing data"
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!serviceNames.length && !includeBasicTm && (
            <Col span={24}>
              <Row justify="center">
                <Col
                  xs={24}
                  md={12}
                >
                  <Alert
                    message="No service levels selected"
                    description="You must select at least one service level to see tabular listing data"
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      )}
    </>
  )
}

export default SearchListings
