import { Button, ButtonProps } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

import { themeFontSize } from 'utils/styles'

const StyledText = styled.span`
  ${themeFontSize}
`

const FontSizeButton: FC<ButtonProps> = ({ children, ...buttonProps }) => (
  <Button {...buttonProps}>
    <StyledText>{children}</StyledText>
  </Button>
)

export default FontSizeButton
