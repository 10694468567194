import { Alert, Space, Typography } from 'antd'
import { Document, LoadingSpinner } from 'components'
import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useContractDetailsDocuments } from 'services/swr/useDocuments'

import { prepareListingDocuments } from 'utils/listingHelpers'

import * as T from 'types'

const { Text } = Typography

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface IProps {
  contractDetails: T.IContractDetails
  listingId: string
}

const EscrowHistoryDocuments: FunctionComponent<IProps> = ({ contractDetails, listingId }) => {
  const { documents: contractDetailsDocuments = [], error: documentsError }
    = useContractDetailsDocuments(contractDetails.id)

  // @FIXME - the urls are incorrect straight from the BE for some reason. This code mirrors
  // ListingContentSection/Documents.tsx and should be refactored
  const [documents, setDocuments] = useState<T.IListingDocument[]>([
    ...prepareListingDocuments(contractDetailsDocuments),
  ])

  useEffect(() => {
    setDocuments([...prepareListingDocuments(contractDetailsDocuments)].filter(Boolean))
  }, [contractDetailsDocuments])

  if (documentsError) {
    return (
      <Alert
        type="error"
        message="Couldn't load documents"
      />
    )
  }

  if (!documents) return <LoadingSpinner />

  if (!documents.length) return <Text>No documents were uploaded for this escrow</Text>

  return (
    <DocumentsContainer>
      <Space
        direction="vertical"
        size={10}
      >
        {documents.map(document => (
          <Document
            document={document}
            listingId={listingId}
            deletable={false}
            editable={false}
          />
        ))}
      </Space>
    </DocumentsContainer>
  )
}

export default EscrowHistoryDocuments
