import SVG from './SVG'
import * as T from './types'

export default (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 18 18"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0ZM9 2.7C10.4912 2.7 11.7 3.90883 11.7 5.4C11.7 6.89117 10.4912 8.1 9 8.1C7.50883 8.1 6.3 6.89117 6.3 5.4C6.3 3.90883 7.50883 2.7 9 2.7ZM8.99982 15.2881C10.9934 15.2881 12.8693 14.3445 14.0578 12.744C14.242 12.4748 14.2661 12.127 14.1208 11.835L13.9138 11.412C13.461 10.4878 12.522 9.90137 11.4928 9.9H6.50682C5.46293 9.90112 4.51322 10.5039 4.06782 11.448L3.87882 11.844C3.73708 12.1336 3.76112 12.4769 3.94182 12.744C5.13033 14.3445 7.00626 15.2881 8.99982 15.2881Z"
  />
)
