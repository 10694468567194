import { cache, mutate } from 'swr'

import { api } from './api'

const filterBlockedPhonesCacheKeys = (key: string) => /\/blockedCaller\/phone?/.test(key)

export const blockPhoneNumber = async (
  phoneNumber: string,
  userId?: string,
): Promise<[any, Error | undefined]> => {
  try {
    const { data, status } = await api.blockCaller(String(phoneNumber.replace(/\D/g, '')), userId)
    if (status !== 200) throw new Error('Incorrect response code for the block request')

    cache
      .keys()
      .filter(filterBlockedPhonesCacheKeys)
      .forEach((key: string) => mutate(key))
    return [data, undefined]
  } catch (e) {
    return [undefined, new Error("Can't block the caller because of server error")]
  }
}

export const unBlockPhoneNumberById = async (id: string): Promise<[any, Error | undefined]> => {
  try {
    const { data, status } = await api.unBlockCaller(id)
    if (status !== 200) throw new Error('Incorrect response code for the block request')

    cache
      .keys()
      .filter(filterBlockedPhonesCacheKeys)
      .forEach((key: string) => mutate(key))
    return [data, undefined]
  } catch (e) {
    return [undefined, new Error("Can't unblock the caller because of server error")]
  }
}
