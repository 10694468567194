// a wrapper to antd's `message` component since we end up using the same params a lot of the time
import { MessageArgsProps, message } from 'antd'

type IDisplayMessage = (
  type: 'error' | 'success',
  content: string,
  opts?: Partial<Omit<MessageArgsProps, 'content'>>, // content should come from the positional arg
) => void

const displayMessage: IDisplayMessage = (type, content, opts = {}) => {
  const optsWithDefaults = Object.assign(
    {
      style: { marginTop: '50px' },
      content,
      duration: 5,
    } as Partial<MessageArgsProps>,
    opts,
  ) as MessageArgsProps

  message[type](optsWithDefaults)
}

export default displayMessage
