import { FunctionComponent } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
`

const StyledSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.black};
  white-space: nowrap;
`

interface IProps {
  handleClick(): void
}

const SignIn: FunctionComponent<IProps> = ({ handleClick }) => (
  <Container onClick={handleClick}>
    {/* <UserIconContainer>
        <UserIcon
          width={22.21}
          height={26.81}
          fill={colors.purple}
        />
      </UserIconContainer> */}
    <StyledSpan data-testid="NavSigninButton">Sign In</StyledSpan>
  </Container>
)

export default SignIn
