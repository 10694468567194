import produce from 'immer'
import { useState } from 'react'
import useSWR, { cache, mutate } from 'swr'

import { api } from 'services/api'
import { fetchWithToken } from 'services/fetchers'

import * as T from 'types'

import { getDataFromResponse } from './utils/dataHelpers'
import { getFetchErrorMessage, getUpdateErrorMessage } from './utils/errorMessageHelpers'

const getSwrKey = (id: string) => `/mlss/${id}`

const useMls = (id?: string) => {
  const key = id ? getSwrKey(id) : null

  const response = useSWR<T.IMls>(key, fetchWithToken)

  const [errors, setErrors] = useState<{ [key: string]: T.IServerError }>({})
  const fetchError = getFetchErrorMessage(response, 'MLS')

  const createMls = async (
    mls: T.IMls,
  ): Promise<[T.IMls | undefined, T.IServerError | undefined]> => {
    const mlsResponse = await api.createMls(mls)
    const newMls = getDataFromResponse<T.IMls>(mlsResponse)

    const userReadableError = 'Failed to create mls'
    const createMlsError = getUpdateErrorMessage(mlsResponse, { userReadableError })
    if (createMlsError) setErrors({ ...errors, createMlsError })

    if (newMls) {
      mutate(
        '/mlss',
        produce((cachedMlsList: T.IMls[]) => {
          if (!cachedMlsList) return

          cachedMlsList.push(newMls)
        }),
        false,
      )
    }

    return [newMls, createMlsError]
  }

  const mutateMlsSearch = () => {
    const filterMlsSearchCacheKeys = (swrKey: string) => /^\/mlss\/search?/.test(swrKey)

    cache
      .keys()
      .filter(filterMlsSearchCacheKeys)
      .forEach((mlsSearchSwrKey: string) => mutate(mlsSearchSwrKey))
  }

  const updateMls = async (
    update: Partial<T.IMls>,
  ): Promise<[T.IMls | undefined, T.IServerError]> => {
    if (!id) return [undefined, { message: 'Missing MLS id' }]

    const mlsResponse = await api.updateMls(id, update)

    const updatedMls = getDataFromResponse<T.IMls>(mlsResponse)

    const userReadableError = 'Failed to update mls'
    const updateMlsError = getUpdateErrorMessage(mlsResponse, { userReadableError })
    if (updateMlsError) setErrors({ ...errors, updateMlsError })

    if (updatedMls) {
      mutate(
        '/mlss',
        produce((draftMlsList: T.IMls[]) => {
          if (!draftMlsList) return

          const foundMlsIndex = draftMlsList.findIndex((mls: T.IMls) => mls._id === id)

          if (foundMlsIndex >= 0 && updatedMls) {
            draftMlsList[foundMlsIndex] = updatedMls
          }
        }),
        false,
      )
    }

    mutate(getSwrKey(id), updatedMls, false)
    mutateMlsSearch()

    return [updatedMls, updateMlsError]
  }

  return {
    ...response,
    mls: response.data,
    error: fetchError,
    errors,
    updateMls,
    createMls,
  }
}

const refreshMls = (id: string) => mutate(getSwrKey(id))

export { useMls, refreshMls }
