import SVG from './SVG'
import * as T from './types'

export default (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 3 8"
    d="M1.056 2.22406C0.768 2.22406 0.517333 2.12273 0.304 1.92006C0.101333 1.70673 0 1.45606 0 1.16806C0 0.880061 0.101333 0.634727 0.304 0.432061C0.517333 0.218727 0.768 0.112061 1.056 0.112061C1.344 0.112061 1.58933 0.218727 1.792 0.432061C2.00533 0.634727 2.112 0.880061 2.112 1.16806C2.112 1.45606 2.00533 1.70673 1.792 1.92006C1.58933 2.12273 1.344 2.22406 1.056 2.22406ZM0 6.83206C0 6.54406 0.101333 6.29873 0.304 6.09606C0.517333 5.88273 0.768 5.77606 1.056 5.77606C1.344 5.77606 1.58933 5.88273 1.792 6.09606C2.00533 6.29873 2.112 6.54406 2.112 6.83206C2.112 7.12006 2.00533 7.37073 1.792 7.58406C1.58933 7.78673 1.344 7.88806 1.056 7.88806C0.768 7.88806 0.517333 7.78673 0.304 7.58406C0.101333 7.37073 0 7.12006 0 6.83206Z"
  />
)
