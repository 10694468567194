import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Select, SelectProps, Space } from 'antd'
import { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react'

interface ISelectOption {
  label: string
  value: any
}

const SelectWithCreateSupport: FC<SelectProps<any>> = props => {
  const inputRef = useRef<Input>(null)
  const [items, setItems] = useState<ISelectOption[]>((props?.options as ISelectOption[]) ?? [])
  const [item, setItem] = useState<ISelectOption | null>(null)

  useEffect(() => {
    if (props.options && !items?.length) setItems(props.options as ISelectOption[])
  }, [props?.options])

  const onItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) setItem({ label: event.target.value, value: event.target.value })
    else setItem(null)
  }

  const addItem = (event: MouseEvent<HTMLElement>) => {
    if (!item) return

    event.preventDefault()
    const newItem = { label: item.label.trim(), value: item.value.trim() }
    setItems([...items, newItem])
    setItem(null)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  return (
    <Select
      {...props}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={item?.value}
              onChange={onItemChange}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={addItem}
            >
              Add item
            </Button>
          </Space>
        </>
      )}
      options={items}
    />
  )
}

export default SelectWithCreateSupport
