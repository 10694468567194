import { UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Row, Upload } from 'antd'
import { Document, LoadingSpinner, Spacer } from 'components'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'

import { addDocumentToListing } from 'services/documents'
import { useDocuments } from 'services/swr/useDocuments'
import { useListing } from 'services/swr/useListing'

import { prepareListingDocuments } from 'utils/listingHelpers'

import * as T from 'types'

const { Dragger } = Upload

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const UploadContainer = styled.div`
  width: 100%;
`

const UploadDragAndDrop = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    height: unset;
  }
`

interface IProps {
  listing: T.IListing
  update: T.IUpdateListing
}

// @FIXME: Some document arrays have (erroneously) been containing `null` values, thus all the
// `filter(Boolean)`s. These can be safely removed once the source of that error is found on API side.
const Documents: FunctionComponent<IProps> = ({ listing, update }) => {
  const { mutate } = useListing()
  const [error, setError] = useState('')

  const [updating, setUpdating] = useState(false)
  const { listingDocuments = [] } = useDocuments(listing._id)

  const [documents, setDocuments] = useState<T.IListingDocument[]>([
    ...prepareListingDocuments(listingDocuments),
  ])

  const handleFileUpload = async (
    options: any /* Antd's RcFile typings seem to be incorrect */,
  ) => {
    const { file, onSuccess } = options

    setUpdating(true)
    const uploadedDoc: T.IListingDocument[] = await addDocumentToListing([file], listing._id, true)
    if (!uploadedDoc || !uploadedDoc.length) return setError('Failed uploading the document.')

    mutate()
    setUpdating(false)
    onSuccess('OK')
  }

  useEffect(() => {
    setDocuments([...prepareListingDocuments(listingDocuments)].filter(Boolean))
  }, [update, listing, listingDocuments])

  return (
    <Row>
      <Spacer size={10} />
      <UploadContainer>
        <UploadDragAndDrop
          name="file"
          customRequest={handleFileUpload}
          disabled={updating}
          multiple
        >
          {updating
            ? (
              <LoadingSpinner />
            )
            : (
              <Button
                icon={<UploadOutlined />}
                disabled={updating}
              >
              Upload Documents
              </Button>
            )}
        </UploadDragAndDrop>
        {error && (
          <Alert
            message={error}
            type="error"
          />
        )}
      </UploadContainer>
      <Spacer size={10} />
      <DocumentsContainer>
        {documents.map((document: T.IListingDocument, index) => {
          const isAttachedDocument = !document.type || document.type === 'Contract'
          return (
            <Fragment key={`${document._id}-${document.name || 'unnamed'}-${document.uploadedAt}`}>
              {index > 0 && <Spacer size={10} />}
              <Document
                document={document}
                listingId={listing._id}
                deletable={isAttachedDocument}
                editable
              />
            </Fragment>
          )
        })}
      </DocumentsContainer>
    </Row>
  )
}

export default Documents
