import dayjs from 'dayjs'

import * as T from 'types'
import * as E from 'types/enums'

// used in admin
export const formatAddress = (listing?: T.IListing | T.ISparseListing) => {
  if (!listing) return

  const streetAddress = listing.aptNum ? `${listing.street} Unit ${listing.aptNum}` : listing.street

  const cityStateZip = `${listing.city}, ${listing.state} ${listing.zip}`
  const fullAddress = `${streetAddress}, ${cityStateZip}`
  return { streetAddress, cityStateZip, fullAddress }
}

interface IAddressObj {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  zip: string
}

export const formatAddressObj = ({ addressLine1, addressLine2, city, state, zip }: IAddressObj) => {
  if (!addressLine1 || !city || !state || !zip) return

  const streetAddress = addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1

  const cityStateZip = `${city}, ${state} ${zip}`
  return `${streetAddress}, ${cityStateZip}`
}

export const getAgreementOverride = (docs: T.IListingDocument[]) =>
  docs.find((doc: T.IListingDocument) => doc.type === 'Agreement Override' && !doc.archived)

const sortDocuments = (docs: T.IListingDocument[]) =>
  docs.sort((a: any, b: any) => {
    if (dayjs(a.uploadedAt || 0).isAfter(dayjs(b.uploadedAt || 0))) return -1

    if (dayjs(b.uploadedAt || 0).isAfter(dayjs(a.uploadedAt || 0))) return 1

    return 0
  })

// this function formats documents returned from /documents/ API to have correct download links to our API
// it also formats the displayed name and sorts documents
export const prepareListingDocuments = (docs: T.IListingDocument[], filterType?: string) => {
  const newDocuments: T.IListingDocument[] = docs.map((doc: T.IListingDocument) => ({
    ...doc,
    originalUrl: doc.url,
    url: `${process.env.API_URL}/amazons/viewDocumentById/${doc._id}`,
  }))

  if (filterType) return newDocuments.filter(document => document.type === filterType)

  return sortDocuments(newDocuments)
}

export const stateOptions = [
  'AZ',
  'CA',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'IL',
  'MA',
  'MD',
  'MI',
  'NJ',
  'NM',
  'NY',
  'NC',
  'OR',
  'PA',
  'TX',
  'UT',
  'WA',
  'WI',
].map((state: string) => ({ label: state, value: state }))

export const hasOpenHouses = (listing: T.IListing) =>
  listing && listing.openHouseDates && listing.openHouseDates.length

export const isPublished = (listing: T.IListing) => {
  switch (listing.listingStatus?.name ?? '') {
    case E.ListingStatus.amendmentNeeded:
    case E.ListingStatus.amendmentSent:
    case E.ListingStatus.attorneyReview:
    case E.ListingStatus.cancellationSigned:
    case E.ListingStatus.closedNotPaid:
    case E.ListingStatus.contractUpdated:
    case E.ListingStatus.escrowClosed:
    case E.ListingStatus.inContract:
    case E.ListingStatus.leased:
    case E.ListingStatus.listingDetailsUpdated:
    case E.ListingStatus.newEscrow:
    case E.ListingStatus.offerAcceptedNYIC:
    case E.ListingStatus.published:
    case E.ListingStatus.remove:
    case E.ListingStatus.sold:
      return true
    default:
      return false
  }
}

export const getFieldValueFromListing = (
  listing: T.IListing,
  fieldName: string,
  fieldId?: string,
  isCoreField?: boolean,
) => {
  if (!listing || !listing.fields) return ''

  if (isCoreField) {
    return listing[fieldName] || ''
  }

  const foundField = listing.fields.find((field: T.IField) => {
    if (fieldId) {
      return field.fieldId?._id === fieldId
    }

    return field.fieldId?.name === fieldName
  })
  return foundField ? foundField.value : ''
}

export const getPrimaryMls = (listing: T.IListing) => {
  const primaryMls = listing.mlsList?.find(mls => mls.primary) ?? listing.mlsList?.[0]

  const primaryIndex = listing.mlsList?.findIndex(mls => mls.primary)
  const selectedIndex = listing.mlsList?.findIndex(mls => mls.selected)

  let primaryMlsIndex: number = 0

  if (primaryIndex >= 0) {
    primaryMlsIndex = primaryIndex
  } else if (selectedIndex >= 0) {
    primaryMlsIndex = selectedIndex
  }

  return { primaryMls, primaryMlsIndex }
}

export const getRoomObject = (name: string, listingObj: T.IListing) =>
  listingObj?.rooms?.find((room: T.IRoom) => room?.name === name)

export const getPackageName = (listing?: T.IListing) => {
  if (listing?.pricingPackage?.name !== 'Basic') return listing?.pricingPackage?.name ?? ''

  if (!listing?.hasTransactionManagement) return 'Basic'

  return 'Basic +TM'
}

export const getPackageLabel = (listing?: T.IListing) => {
  if (!listing) return ''

  const stateCodes = listing.pricingPackage.stateCodes || []
  return stateCodes.length
    ? `${stateCodes.filter(state => state === listing.state).join(', ')} ${getPackageName(listing)}`
    : `Standard ${getPackageName(listing)}`
}

export const purchasedPackageType = (listing: T.IListing, packageType: E.PackageType) =>
  listing.pricingPackage.name === packageType

export const purchasedPhotographyService = (listing: T.IListing) => {
  if (!listing || !listing.services) return false

  return listing.services.some(service => service.type.type.includes(E.ServiceType.Photography))
}
