import { ColumnType } from 'antd/lib/table/interface'
import { paramCase } from 'change-case'
import { FC, useMemo } from 'react'

import * as T from 'types'

import { NotesTableView, Text, getNoteTableColumns } from './Shared'
import { IListingAndUpdate } from './types'

interface IProps extends T.IFontSize {
  listing: IListingAndUpdate['listing']
}

const AllNotes: FC<IProps> = ({ fontSize, listing }) => {
  const { agentNotes = [], agentSellerNotes = [], escrowNotes = [] } = listing

  const agentSellerNoteIds = useMemo(
    () => new Set(agentSellerNotes.map(({ _id }) => _id)),
    [agentSellerNotes],
  )

  const agentNoteIds = useMemo(() => new Set(agentNotes.map(({ _id }) => _id)), [agentNotes])

  const allNotes: T.IWithKey<T.INote>[] = useMemo(
    () =>
      agentNotes
        .concat(agentSellerNotes)
        .concat(escrowNotes)
        .map(note => ({ ...note, key: note._id as string })),
    [agentNotes, agentSellerNotes, escrowNotes],
  )

  const getNoteType = (id: string | undefined) => {
    if (agentSellerNoteIds.has(id)) {
      return 'Seller Note'
    }

    if (agentNoteIds.has(id)) {
      return 'Agent Note'
    }

    return 'Escrow Notes'
  }

  const columns = useMemo(() => {
    const typeColumn: ColumnType<T.INote> = {
      dataIndex: '_id',
      render: (_, { _id }) => {
        const noteType = getNoteType(_id)
        return <Text fontSize={fontSize}>{noteType}</Text>
      },
      title: 'Note Type',
      width: '110px',
    }

    const result = getNoteTableColumns(fontSize, 'Author')
    result.splice(1, 0, typeColumn)
    return result
  }, [agentSellerNoteIds, agentNoteIds, fontSize])

  const getRowClassName = ({ _id }: T.INote) => paramCase(getNoteType(_id))

  return (
    <NotesTableView
      columns={columns}
      dataSource={allNotes}
      fontSize={fontSize}
      pagination={false}
      rowClassName={getRowClassName}
    />
  )
}

export default AllNotes
