import { PhoneOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import styled from 'styled-components'

const { Text, Paragraph } = Typography

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
`

export const StyledParagraph = styled(Paragraph)`
  margin-bottom: 5px;
  font-size: 12px;
`

export const BoldText = styled(Text)`
  font-weight: 800;
`

export const NameColumn = {
  title: 'Name',
  dataIndex: 'Name',
  key: 'Name',
}

export const EmailColumn = {
  title: 'Email',
  dataIndex: 'Email',
  key: 'Email',
}

export const PhoneColumn = {
  title: 'Phone',
  dataIndex: 'Phone',
  key: 'Phone',
  render: (text: any) => (
    <div>
      {text && <PhoneOutlined />} <span>{text}</span>
    </div>
  ),
}
