/* eslint-disable no-alert */
import { yellow } from '@ant-design/colors'
import { CopyOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Alert, Col, Input, Modal, Popconfirm, Row, Select, message } from 'antd'
import { CustomButton, MlsName } from 'components'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { copyToClipboard } from 'utils'

import Spacer from 'components/Spacer'

import useAgreementSigningState from 'services/hooks/useAgreementSigningState'
import { addNewMls, deleteMls, updateMlsStatus } from 'services/mls'
import { useDocuments, useMlsList, useMlsListForZip, useStatuses } from 'services/swr'

import { getAgreementOverride } from 'utils/listingHelpers'

import * as T from 'types'
import * as E from 'types/enums'

import { SubsectionTitle } from './Styles'
import TableView from './TableView'

const StyledLink = styled.a`
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: anywhere;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const MlsInfoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MlsInfoActionContainer = styled.div`
  .anticon {
    margin: 0 5px;
  }
`

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledCustomButton = styled(CustomButton)`
  margin: 0;
  cursor: pointer;
  width: 10%;
  height: 100%;
  border: none;
  box-shadow: none;
  & > span {
    padding-top: 4px;
  }
`

interface IMLSTableRecord {
  mlsDataSet: JSX.Element
  mlsLiveIn: JSX.Element
  status: string
  number: string
  numberDigitsOnly: string
  link: string | null
  mlsIndex: number
}

interface IProps {
  listing: T.IListing
}

const MlsInfo: FunctionComponent<IProps> = ({ listing }) => {
  const { Option } = Select

  const { mlsList } = useMlsList()
  const { statuses } = useStatuses()
  const { listingDocuments } = useDocuments(listing._id)

  const mlsStatuses = statuses ? [...statuses]?.filter(status => status.type === 'mls') : []

  const [mlsIndex, setMlsIndex] = useState<number>(-1)
  const { mlsListForZip } = useMlsListForZip(listing.zip)
  const [deletionIndex, setDeletionIndex] = useState<number | undefined>()
  const [displayModal, setDisplayModal] = useState(false)
  const [displayWarningModal, setDisplayWarningModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [dataSetEditingEnabled, setDataSetEditingEnabled] = useState(false)

  const [agreementOverrideDocument, setAgreementOverrideDocument] = useState<
    T.IListingDocument | undefined
  >(undefined)

  useEffect(() => {
    if (!listingDocuments) return

    const newAgreementOverrideDocument = getAgreementOverride(listingDocuments)

    if (newAgreementOverrideDocument?.url) {
      setAgreementOverrideDocument(newAgreementOverrideDocument)
    }
  }, [listing, listingDocuments])

  // agreement
  const { agreementComplete } = useAgreementSigningState(listing, agreementOverrideDocument)

  // modal fields to edit existing mls or add a new mls
  const [mlsDataSet, setMlsDataSet] = useState<T.IMls | undefined>()
  const [mlsLiveIn, setMlsLiveIn] = useState('')
  const [mlsStatusId, setMlsStatusId] = useState('')
  const [mlsNumber, setMlsNumber] = useState('')
  const [mlsLink, setMlsLink] = useState('')

  // modal
  const editMls = (index: number) => () => {
    setMlsIndex(index)
    setDisplayModal(true)
  }

  const addMls = () => {
    setMlsIndex(-1)
    setDisplayModal(true)
  }

  const confirmDelete = async () => {
    setConfirmLoading(true)
    // only delete non-primary mlses
    if (deletionIndex && deletionIndex > 0) {
      try {
        await deleteMls(listing._id, listing.mlsList[deletionIndex]._id)
        // close the delete confirmation modal
        setDeletionIndex(undefined)
      } catch (error) {
        if (error instanceof Error) setErrorMessage(error.message)
      }
    }

    setConfirmLoading(false)
  }

  const needsShowSignerWarning = (statusId: string) => {
    if (!listing) return false

    const statusName = mlsStatuses.find(status => status._id === statusId)?.name ?? ''
    if (statusName === E.MlsStatus.publishedOnMls && !agreementComplete) return true

    return false
  }

  const getModalTitle = () => {
    const mls = mlsIndex >= 0 && listing.mlsList[mlsIndex]?.mls

    if (mls) {
      return <div>Edit MLS Info</div>
    }

    return 'Add New MLS Data'
  }

  const handleCopyToClipboard = (
    data: object | string,
    successMessage: string = 'Value Copied!',
  ) => {
    message.success({
      style: { marginTop: '50px' },
      content: successMessage,
      duration: 5,
    })
    copyToClipboard(data)
  }

  const handleConfirm = async () => {
    setConfirmLoading(true)
    const mlsData: any = mlsIndex >= 0 ? listing.mlsList[mlsIndex] : {}

    const canUpdateMlsStatus = mlsNumber && mlsLink && mlsStatusId

    if (!canUpdateMlsStatus && (mlsStatusId || mlsNumber || mlsLink)) {
      setConfirmLoading(false)
      return setErrorMessage('Status, number and link fields must be filled in together')
    }

    if (mlsDataSet?.name !== mlsData.name) mlsData.mls = mlsDataSet

    mlsData.number = mlsNumber
    mlsData.mlsLiveIn = mlsLiveIn
    mlsData.numberDigitsOnly = (mlsNumber || '').replace(/\D+/g, '')
    mlsData.statusId = mlsStatusId
    mlsData.status = mlsStatusId
    mlsData.link = mlsLink

    if (mlsIndex >= 0) {
      // update mls
      const [data, error] = await updateMlsStatus(listing._id, mlsData)

      if (!data && error?.message) {
        setErrorMessage(error.message)
      } else {
        setDisplayModal(false)
      }
    } else {
      // new mls
      try {
        mlsData.mls = listing.mlsList[0].mls._id
        await addNewMls(listing._id, mlsData)
        setDisplayModal(false)
      } catch (error) {
        if (error instanceof Error) setErrorMessage(error.message)
      }
    }

    setConfirmLoading(false)
  }

  const handleCancel = () => setDisplayModal(false)

  const mlsInfoTableColumns = [
    {
      title: 'MLS Data Set',
      dataIndex: 'mlsDataSet',
    },
    {
      title: 'MLS Live In',
      dataIndex: 'mlsLiveIn',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Number',
      dataIndex: 'number',
    },
    {
      title: 'Number (digits only)',
      dataIndex: 'numberDigitsOnly',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      render: (link: string) =>
        link
          ? (
            <StyledLink
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {link}
            </StyledLink>
          )
          : (
            '---'
          ),
    },
    {
      title: '',
      dataIndex: 'mlsIndex',
      render: (index: number, record: IMLSTableRecord) => {
        const handleDeletion = () => setDeletionIndex(index)
        const handleCopy = () => handleCopyToClipboard(record.link ?? '', 'Copied MLS Link')
        return (
          <MlsInfoActionContainer>
            <FormOutlined onClick={editMls(index)} />
            {index > 0 && <DeleteOutlined onClick={handleDeletion} />}
            <CopyOutlined onClick={handleCopy} />
          </MlsInfoActionContainer>
        )
      },
    },
  ]

  const getMlsInfoTableDataSource = () =>
    listing.mlsList.map((mls, index) => ({
      mlsDataSet: <MlsName
        mls={mls.mls}
        fallbackName="---"
      />,
      mlsLiveIn: <MlsName
        liveIn={mls.mlsLiveIn}
        fallbackName="---"
      />,
      status: mls.status?.name || '---',
      number: mls.number || '---',
      numberDigitsOnly: mls.numberDigitsOnly || '---',
      link: mls.link,
      mlsIndex: index,
    }))

  useEffect(() => {
    setErrorMessage('')
    if (displayModal) {
      if (mlsIndex >= 0) {
        setMlsDataSet(listing.mlsList[mlsIndex].mls)
        setMlsLiveIn(listing.mlsList[mlsIndex].mlsLiveIn)
        setMlsStatusId(listing.mlsList[mlsIndex].status?._id || '')
        setMlsNumber(listing.mlsList[mlsIndex].number || '')
        setMlsLink(listing.mlsList[mlsIndex].link || '')
      } else {
        setMlsDataSet(undefined)
        setMlsLiveIn('')
        setMlsStatusId('')
        setMlsNumber('')
        setMlsLink('')
      }
    } else {
      setDataSetEditingEnabled(false)
    }
  }, [displayModal])

  // warn the broker if they're trying to publish a listing on the MLS without a fully signed
  // listing agreement
  const showSignerWarning = needsShowSignerWarning(mlsStatusId)

  if (!displayWarningModal && showSignerWarning) {
    const handleClosePublishWarning = () => {
      setMlsStatusId(listing.mlsList[mlsIndex]?.status?._id || '')
      setDisplayWarningModal(false)
    }

    setDisplayWarningModal(true)
    Modal.error({
      title: 'Status Change Error',
      content:
        'You are trying to set the MLS Status to Published but not all signers have signed yet.',
      onOk: handleClosePublishWarning,
      maskClosable: true,
      centered: true,
    })
  }

  const selectMlsDataSetValue = (value: string) => {
    const mlsValue = mlsList.find(mls => mls.name === value)
    setMlsDataSet(mlsValue)
  }

  const descriptionText = `
    Changing the MLS Data Set will modify the Listing Flow Questions for this Listing, and reduce the % complete.
  `

  const ConfirmDescription = () => (
    <>
      <h3>Enable MLS DataSet Editing?</h3>
      <p>
        <b>Warning:</b>
      </p>
      <p>{descriptionText}</p>
      <p>Seller will need to answer new questions before the Listing can be completed.</p>
    </>
  )
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <SubsectionTitle>MLS Info</SubsectionTitle>
          {TableView(getMlsInfoTableDataSource(), mlsInfoTableColumns)}
        </Col>
        <Col span={24}>
          <CustomButton
            backgroundColor={yellow[4]}
            backgroundColorOnHover={yellow[5]}
            custom
            onClick={addMls}
          >
            Add MLS
          </CustomButton>
        </Col>
      </Row>
      <Modal
        title={getModalTitle()}
        visible={displayModal}
        onOk={handleConfirm}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        centered
      >
        <MlsInfoModalContainer>
          <strong>Data Set</strong>
          <SelectContainer>
            <Select
              disabled={!dataSetEditingEnabled}
              showSearch
              value={mlsDataSet?.name}
              onSelect={selectMlsDataSetValue}
              style={{ width: '90%' }}
            >
              {mlsListForZip.map(mls => (
                <Option
                  key={mls._id}
                  value={mls.name}
                >
                  {mls.name}
                </Option>
              ))}
            </Select>
            <Popconfirm
              placement="bottom"
              title={ConfirmDescription}
              onConfirm={() => setDataSetEditingEnabled(true)}
              onCancel={() => setDataSetEditingEnabled(false)}
              okText="Yes"
              cancelText="No"
            >
              <StyledCustomButton>
                <FormOutlined style={{ fontSize: 16 }} />
              </StyledCustomButton>
            </Popconfirm>
          </SelectContainer>
          <Spacer size={12} />
          <strong>MLS Live In</strong>
          <Select
            showSearch
            value={mlsLiveIn}
            onSelect={(value: string) => setMlsLiveIn(value)}
          >
            {mlsList.map(mls => (
              <Option
                key={mls._id}
                value={mls.name}
              >
                {mls.name}
              </Option>
            ))}
          </Select>
          <Spacer size={12} />
          <strong>Status</strong>
          <Select
            value={mlsStatusId}
            onSelect={(value: string) => setMlsStatusId(value)}
          >
            {mlsStatuses.map(option => (
              <Option
                key={option._id}
                value={option._id}
              >
                {option.name}
              </Option>
            ))}
          </Select>
          <Spacer size={12} />
          <strong>Number</strong>
          <Input
            name="mlsNumber"
            value={mlsNumber}
            onChange={(evt: any) => setMlsNumber(evt.target.value)}
          />
          <Spacer size={12} />
          <strong>Link</strong>
          <Input
            value={mlsLink}
            onChange={(evt: any) => setMlsLink(evt.target.value)}
          />
        </MlsInfoModalContainer>
        {errorMessage && (
          <Alert
            style={{ marginTop: '20px' }}
            message={errorMessage}
            type="error"
          >
            {errorMessage}
          </Alert>
        )}
      </Modal>
      {deletionIndex && (
        <Modal
          title="Delete MLS"
          visible={!!deletionIndex}
          onOk={confirmDelete}
          onCancel={() => setDeletionIndex(undefined)}
          confirmLoading={confirmLoading}
          centered
        >
          <div>
            MLS Live In: &nbsp;
            <strong>{listing.mlsList[deletionIndex]?.mlsLiveIn}</strong>
          </div>
          <div>
            MLS Status: &nbsp;
            <strong>{listing.mlsList[deletionIndex]?.status?._id || ''}</strong>
          </div>
          <div>
            MLS Number: &nbsp;
            <strong>{listing.mlsList[deletionIndex]?.number || ''}</strong>
          </div>
          <div>
            MLS Link: &nbsp;
            <strong>{listing.mlsList[deletionIndex]?.link || ''}</strong>
          </div>
          {errorMessage && (
            <Alert
              style={{ marginTop: '20px' }}
              message={errorMessage}
              type="error"
            >
              {errorMessage}
            </Alert>
          )}
        </Modal>
      )}
    </>
  )
}

export default MlsInfo
