import { mutate } from 'swr'

import * as T from 'types'

import { api } from './api'

export const callSeller = async (
  listing: T.IListing,
  phone: string,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.callSeller(listing._id, phone)

    if (response.data?.error === 'admin-no-phone-number') {
      throw new Error('You need to update your phone number first in order to call the seller.')
    }

    if (!response.ok || !response.data?.success) {
      throw new Error('There was an error while making a call')
    }

    return [true, undefined]
  } catch (error) {
    const err = error instanceof Error ? error : new Error('There was unknown error')
    return [false, err]
  }
}

export const addNewAdditionalContactInfo = async (
  listing: T.IListing,
  contact: Omit<T.IAdditionalContactInfo, '_id'>,
) => {
  try {
    const contacts = [...listing.additionalContactInfo, contact]
    const response = await api.updateListing(listing._id, { additionalContactInfo: contacts })
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listing._id}/ownerDetails`)
    return response.data
  } catch (error) {
    throw new Error('There was an error adding a new contact')
  }
}

export const deleteAdditionalContactInfo = async (
  listing: T.IListing,
  additionalContactId: string,
) => {
  try {
    const contacts = listing.additionalContactInfo.filter(
      contact => contact._id !== additionalContactId,
    )

    const response = await api.updateListing(listing._id, { additionalContactInfo: contacts })
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await mutate(`/listings/${listing._id}/ownerDetails`)
    return response.data
  } catch (error) {
    throw new Error('There was an error deleting an additional contact info.')
  }
}
