import { Col, Form, Input, Modal, Row, Select } from 'antd'
import MaskedInput from 'antd-mask-input'
import { FC, useMemo, useState } from 'react'
import styled from 'styled-components'

import { renderDigitsOnly } from 'utils/stringFormatting'

import * as T from 'types'
import * as E from 'types/enums'

import OwnerAgreementUpload, { IOnChangeValue } from './OwnerAgreementUpload'

const AddContactModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ownerTypeOptions = Object.keys(E.OwnerType).map(type => ({ label: type, value: type }))

type IRenderOwnerType = (
  owner: T.IOwner,
  onOwnerTypeChange: (value: E.OwnerType) => void,
) => JSX.Element

const renderOwnerType: IRenderOwnerType = (owner, onOwnerTypeChange) => (
  <Col span={24}>
    <Form.Item
      initialValue={owner.ownerType}
      label="Title Holder Type"
      name="ownerType"
    >
      <Select
        options={ownerTypeOptions}
        onChange={onOwnerTypeChange}
        style={{ display: 'block' }}
      />
    </Form.Item>
  </Col>
)

type IRenderFormItems = (owner: T.IOwner) => JSX.Element
type IRenderCompanyOrTrustFormItems = (owner: T.IOwner, listingId: string) => JSX.Element

const noUploadError = (_: any, value: IOnChangeValue) =>
  value && 'error' in value ? Promise.reject(new Error(value.error)) : Promise.resolve()

const renderEmailAndPhoneFormItems: IRenderFormItems = owner => (
  <>
    <Col
      xs={24}
      sm={12}
    >
      <Form.Item
        initialValue={owner.email}
        label="Email"
        name="email"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      sm={12}
    >
      <Form.Item
        initialValue={owner.phone}
        label="Phone"
        name="phone"
        rules={[{ required: true }]}
        normalize={renderDigitsOnly}
      >
        <MaskedInput
          mask="111-111-1111"
          style={{ width: '100%' }}
        />
      </Form.Item>
    </Col>
  </>
)

const renderIndividualsFormItems: IRenderFormItems = owner => (
  <>
    <Col span={24}>
      <Form.Item
        initialValue={owner.name}
        label="Name"
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    {renderEmailAndPhoneFormItems(owner)}
  </>
)

const renderCompanyFormItems: IRenderCompanyOrTrustFormItems = (owner, listingId) => (
  <>
    <Col span={24}>
      <Form.Item
        initialValue={owner.companyName}
        label="Company Name"
        name="companyName"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      sm={12}
    >
      <Form.Item
        initialValue={owner.name}
        label="Authorized Signer's Name"
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      sm={12}
    >
      <Form.Item
        initialValue={owner.title}
        label="Title"
        name="title"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    {renderEmailAndPhoneFormItems(owner)}
    <Col span={24}>
      <Form.Item
        initialValue={owner.agreement}
        label="Operating Agreement Showing Authorized Signers"
        name="agreement"
        rules={[{ required: true }, { validator: noUploadError }]}
      >
        <OwnerAgreementUpload
          listingId={listingId}
          owner={owner}
        />
      </Form.Item>
    </Col>
  </>
)

const renderTrustFormItems: IRenderCompanyOrTrustFormItems = (owner, listingId) => (
  <>
    <Col span={24}>
      <Form.Item
        initialValue={owner.companyName}
        label="Trust Name"
        name="trustName"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      sm={12}
    >
      <Form.Item
        initialValue={owner.name}
        label="Name"
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      sm={12}
    >
      <Form.Item
        initialValue={owner.title}
        label="Title"
        name="title"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    {renderEmailAndPhoneFormItems(owner)}
    <Col span={24}>
      <Form.Item
        initialValue={owner.agreement}
        label="Trust Agreement Showing Trustees"
        name="agreement"
        rules={[{ required: true }, { validator: noUploadError }]}
      >
        <OwnerAgreementUpload
          listingId={listingId}
          owner={owner}
        />
      </Form.Item>
    </Col>
  </>
)

const renderFormItemsByType = {
  [E.OwnerType.Individuals]: renderIndividualsFormItems,
  [E.OwnerType.Company]: renderCompanyFormItems,
  [E.OwnerType.Trust]: renderTrustFormItems,
}

const unusedPropsByType = {
  [E.OwnerType.Individuals]: ['title', 'agreement', 'companyName', 'trustName'],
  [E.OwnerType.Company]: ['trustName'],
  [E.OwnerType.Trust]: ['companyName'],
}

interface IEditOwnerModal {
  close: () => void
  listingId: string
  owner: T.IOwner
  update: (owner: T.IOwner) => void
}

type IOwnerForm = Pick<
  T.IOwner,
  'name' | 'email' | 'phone' | 'title' | 'agreement' | 'ownerType' | 'companyName' | 'trustName'
>

export const EditOwnerModal: FC<IEditOwnerModal> = ({ close, listingId, owner, update }) => {
  const [form] = Form.useForm<IOwnerForm>()
  const submitForm = () => form.submit()
  const [ownerType, setOwnerType] = useState(owner.ownerType)

  const onOwnerTypeChange = (updatedOwnerType: E.OwnerType) => {
    setOwnerType(updatedOwnerType)
  }

  const handleSubmit = (values: IOwnerForm) => {
    const updatedOwner: T.IOwner = { ...(owner as T.IOwner), ...values }

    unusedPropsByType[ownerType].forEach(key => {
      updatedOwner[key] = null
    })

    update(updatedOwner)
    close()
  }

  const formItems = useMemo(
    () => (
      <Row gutter={[12, 12]}>
        {renderOwnerType(owner, onOwnerTypeChange)}
        {renderFormItemsByType[ownerType](owner, listingId)}
      </Row>
    ),
    [owner, ownerType],
  )

  return (
    <Modal
      title="Edit Title Holder"
      visible={!!owner}
      onOk={submitForm}
      onCancel={close}
      width={700}
    >
      <AddContactModalContainer>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
        >
          {formItems}
        </Form>
      </AddContactModalContainer>
    </Modal>
  )
}
