import Clock from './Clock'
import Colon from './Colon'
import DeleteX from './DeleteX'
import DeleteXCircle from './DeleteXCircle'
import Eye from './Eye'
import Power from './Power'
import UserCircleFilled from './UserCircleFilled'
import * as Arrows from './arrows'
import * as Compound from './compound'
import * as T from './types'

export interface iconsObject {
  [key: string]: (props: T.ISVGProps) => JSX.Element
}

const icons: iconsObject = {
  clock: Clock,
  colon: Colon,
  deleteXCircle: DeleteXCircle,
  deleteX: DeleteX,
  eye: Eye,
  power: Power,
  userCircleFilled: UserCircleFilled,
  upload: Compound.Upload,
  upArrow: Arrows.UpArrow,
  rightArrow: Arrows.RightArrow,
  downArrow: Arrows.DownArrow,
  leftArrow: Arrows.LeftArrow,
}

export default icons
