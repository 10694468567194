import { CloseOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Modal, Row, message } from 'antd'
import { CustomButton, Spacer } from 'components'
import dayjs from 'dayjs'
import Link from 'next/link'
import React, { FunctionComponent, useState } from 'react'
import { removeOrder, removeOrderService, useOrdersByListingId } from 'services'
import styled from 'styled-components'
import { addCommas } from 'utils'

import * as T from 'types'

import { SubsectionTitle } from './Styles'
import TableView from './TableView'

const { confirm } = Modal

const StyledTableWrapper = styled.div`
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    .ant-table-thead .ant-table-cell {
      white-space: nowrap;
    }
    .ant-table-cell {
      overflow: auto;
      white-space: normal;
      max-width: none;
    }
  }
`

const OrdersButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  button {
    width: auto;
    margin: 0 5px 5px 0;
  }
`
interface ILineThroughProp {
  lineThrough: boolean
}
const StyledSpan = styled.span<ILineThroughProp>`
  text-decoration: ${props => (props.lineThrough ? 'line-through' : 'auto')};
`

interface IProps {
  listing: T.IListing
}

const Orders: FunctionComponent<IProps> = ({ listing }) => {
  const { orders } = useOrdersByListingId(listing._id, true)

  const [showDeletedOrders, setShowDeletedOrders] = useState<boolean>(false)

  const showDeleteOrderModal = (id: string) => {
    confirm({
      title: `Are you sure you want to delete order: ${id}?`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      maskClosable: true,
      onOk: async () => {
        const [data, error] = await removeOrder(id, listing._id)

        if (error && !data) {
          message.error({
            style: { marginTop: '50px' },
            content: error.message,
            duration: 3,
          })
        } else {
          message.success({
            style: { marginTop: '50px' },
            content: `${id} has been deleted`,
            duration: 3,
          })
        }
      },
    })
  }

  const showDeleteOrderServiceModal = (id: string, service: T.IService) => {
    confirm({
      title: `Are you sure you want to delete service: ${service.name}?`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      maskClosable: true,
      onOk: async () => {
        const [data, error] = await removeOrderService(id, service, listing)

        if (error && !data) {
          message.error({
            style: { marginTop: '50px' },
            content: error.message,
            duration: 3,
          })
        } else {
          message.success({
            style: { marginTop: '50px' },
            content: `Service order ${service.name} has been deleted`,
            duration: 3,
          })
        }
      },
    })
  }

  const toggleShowDeletedOrders = () => setShowDeletedOrders(!showDeletedOrders)

  const filterOrders = (order: T.IOrder) => order.deleted === showDeletedOrders

  const ordersColumns = [
    {
      title: 'Order No.',
      dataIndex: '_id',
      key: '_id',
      render: (orderNum: string, order: T.IOrder) =>
        order.paid
          ? (
            <Link href={`../orders/${orderNum}`}>{orderNum}</Link>
          )
          : (
            <div>
              <strong>Currently in Cart</strong>
            </div>
          ),
    },
    {
      title: 'Shipping Address',
      dataIndex: 'shipping',
      key: 'shipping',
      render: (shipping?: T.IOrderShipping) => {
        if (!shipping) return null

        return (
          <div>
            {shipping?.fullName && (
              <>
                {`${shipping.fullName}`}
                <br />
              </>
            )}
            {`${shipping.address} ${shipping.apt || ''}`}
            <br />
            {`${shipping.city}, ${shipping.state} ${shipping.zip}`}
          </div>
        )
      },
    },
    {
      title: 'Order Items',
      dataIndex: 'items',
      key: 'items',
      render: (items: T.IOrderItem[], record: { _id: string }) => {
        const services: T.IOrderService[] = []
        items.forEach((item: T.IOrderItem) => services.push(...item.services))

        return (
          <>
            {services
              && services.map((service: T.IOrderService) => (
                <Row
                  key={service._id}
                  wrap={false}
                  align="middle"
                >
                  <Col>
                    <Row wrap={false}>
                      <StyledSpan lineThrough={!!service.deleted}>&bull;</StyledSpan>
                      <Spacer size={5} />
                      <StyledSpan lineThrough={!!service.deleted}>{service.type.name}</StyledSpan>
                      <Spacer size={5} />
                    </Row>
                  </Col>
                  <Col>
                    <Row wrap={false}>
                      {services.length > 1 && service.type.status !== 'PricingPackage' && (
                        <Button
                          disabled={service?.deleted}
                          shape="circle"
                          size="small"
                          style={{ minWidth: 0, width: '20px', height: '20px' }}
                          danger={!service?.deleted}
                          icon={<CloseOutlined style={{ fontSize: '10px' }} />}
                          onClick={() => showDeleteOrderServiceModal(record._id, service.type)}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              ))}
          </>
        )
      },
    },
    {
      title: 'Paid Date',
      dataIndex: 'paidDate',
      key: 'paidDate',
      render: (paidDate?: string) => (
        <div>{paidDate ? dayjs(paidDate).format('MM/DD/YY, h:mm A') : ''}</div>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      render: (total?: number) => (
        <div>{total !== undefined && total >= 0 ? `$${addCommas(total.toFixed(2))}` : '---'}</div>
      ),
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      render: (paid: boolean) => <div>{String(paid)}</div>,
    },
    {
      title: 'Fulfilled',
      dataIndex: 'fulfilled',
      key: 'fulfilled',
      render: (fulfilled: boolean) => <div>{String(fulfilled)}</div>,
    },
    {
      title: 'Remove',
      dataIndex: '_id',
      key: '_id',
      render: (_id: string, record: { deleted: boolean }) => {
        const handleShowDeleteOrderModal = () => showDeleteOrderModal(_id)
        return record.deleted
          ? (
            <Badge
              style={{ whiteSpace: 'nowrap' }}
              status="default"
              text="Deleted"
            />
          )
          : (
            <Button
              onClick={handleShowDeleteOrderModal}
              icon={<DeleteOutlined />}
            />
          )
      },
    },
  ]

  return (
    <>
      {orders?.length
        ? (
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <SubsectionTitle>Orders</SubsectionTitle>
              <OrdersButtonContainer>
                <Col
                  xs={24}
                  md={24}
                  lg={12}
                >
                  <CustomButton onClick={toggleShowDeletedOrders}>
                    {showDeletedOrders ? 'Show Open Orders' : 'Show Deleted Orders'}
                  </CustomButton>
                </Col>
                <Spacer size={15} />
              </OrdersButtonContainer>
              <StyledTableWrapper>
                {TableView(orders.filter(filterOrders) ?? [], ordersColumns)}
              </StyledTableWrapper>
            </Col>
          </Row>
        )
        : (
          <div>There are currently no orders for this listing</div>
        )}
    </>
  )
}

export default Orders
