import { Button, ButtonProps } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'

import { themeFontSize } from 'utils/styles'

const StyledButton = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 2px 0 0; /* 4px to fix vertical centering */
  ${themeFontSize}

  &:hover {
    background-color: transparent;
  }
`

interface IIconButtonProps extends ButtonProps {
  Icon: React.ComponentType
}

const IconButton: FC<IIconButtonProps> = ({ Icon, ...buttonProps }) => (
  <StyledButton {...buttonProps}>
    <Icon />
  </StyledButton>
)

export default IconButton
