import fetch from 'isomorphic-unfetch'
import Cookies from 'js-cookie'

const fetchWithToken = async (
  path: string,
  reqToken?: string | undefined,
  returnResponse = false,
) => {
  if (!path) throw new Error('Missing path')

  if (path[0] !== '/') throw new Error('Missing forward slash')

  // use request cookie in server context or grab from document if in browser context
  let token = reqToken
  if (!token && typeof window !== 'undefined') token = Cookies.get('token')

  if (!token) throw new Error('Missing token')

  const response = await fetch(`${process.env.API_URL}${path}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (!response.ok) throw new Error('An error occurred while fetching the data.')

  if (returnResponse) return response

  const data = response.json()
  return data
}

export default fetchWithToken
