import { MenuOutlined } from '@ant-design/icons'
import { Avatar, Divider, Dropdown, Menu } from 'antd'
import { Spacer } from 'components'
import Link from 'next/link'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { useWindowSize } from 'services/hooks'

const LISTINGS_PATH = '/listings'
const ZIPS_PATH = '/zips'
const USERS_PATH = '/users'
const MLS_PATH = '/mls'
const DOCUMENTS_PATH = '/documents'
const ORDERS_PATH = '/orders'
const SERVICES_PATH = '/services'
const UTILS_PATH = '/utils'
const ADMIN_MANAGEMENT_PATH = '/users/admin'
const VOICEMAILS_PATH = '/recordings'
const CHECKLIST_UTILITY_PATH = '/checklist'
const FAQ_PATH = '/faq'

const LISTINGS_LABEL = 'Listings'
const ZIPS_LABEL = 'Zips'
const USERS_LABEL = 'Users'
const MLS_LABEL = 'MLS'
const DOCUMENTS_LABEL = 'Documents'
const ORDERS_LABEL = 'Orders'
const SERVICES_LABEL = 'Services | Promotions'
const UTILS_LABEL = 'Utils'
const ADMIN_MANAGEMENT_LABEL = 'Admin Management'
const VOICEMAILS_LABEL = 'Voicemails | Calls'
const CHECKLIST_UTILITY_LABEL = 'Checklist Utility'
const FAQ_LABEL = 'FAQ'

const StyledLink = styled.a`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`

const CustomDropdown = styled(Dropdown)`
  :hover {
    cursor: pointer;
  }
`

const StyledDivider = styled(Divider)`
  margin: 0;
`

const menu = (
  <Menu>
    <Spacer size={10} />
    <Menu.Item
      key={LISTINGS_LABEL}
      data-testid={LISTINGS_LABEL}
    >
      <Link
        href={LISTINGS_PATH}
        passHref
      >
        <StyledLink>{LISTINGS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={ORDERS_LABEL}
      data-testid={ORDERS_LABEL}
    >
      <Link
        href={ORDERS_PATH}
        passHref
      >
        <StyledLink>{ORDERS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={VOICEMAILS_LABEL}
      data-testid={VOICEMAILS_LABEL}
    >
      <Link href={VOICEMAILS_PATH}>
        <StyledLink>{VOICEMAILS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={USERS_LABEL}
      data-testid={USERS_LABEL}
    >
      <Link
        href={USERS_PATH}
        passHref
      >
        <StyledLink>{USERS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <StyledDivider />
    <Menu.Item
      key={MLS_LABEL}
      data-testid={MLS_LABEL}
    >
      <Link
        href={MLS_PATH}
        passHref
      >
        <StyledLink>{MLS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={ZIPS_LABEL}
      data-testid={ZIPS_LABEL}
    >
      <Link
        href={ZIPS_PATH}
        passHref
      >
        <StyledLink>{ZIPS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={DOCUMENTS_LABEL}
      data-testid={DOCUMENTS_LABEL}
    >
      <Link
        href={DOCUMENTS_PATH}
        passHref
      >
        <StyledLink>{DOCUMENTS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={SERVICES_LABEL}
      data-testid={SERVICES_LABEL}
    >
      <Link
        href={SERVICES_PATH}
        passHref
      >
        <StyledLink>{SERVICES_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={ADMIN_MANAGEMENT_LABEL}
      data-testid={ADMIN_MANAGEMENT_LABEL}
    >
      <Link
        href={ADMIN_MANAGEMENT_PATH}
        passHref
      >
        <StyledLink>{ADMIN_MANAGEMENT_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={CHECKLIST_UTILITY_LABEL}
      data-testid={CHECKLIST_UTILITY_LABEL}
    >
      <Link
        href={CHECKLIST_UTILITY_PATH}
        passHref
      >
        <StyledLink>{CHECKLIST_UTILITY_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <Menu.Item
      key={FAQ_LABEL}
      data-testid={FAQ_LABEL}
    >
      <Link
        href={FAQ_PATH}
        passHref
      >
        <StyledLink>{FAQ_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
    <StyledDivider />
    <Menu.Item
      key={UTILS_LABEL}
      data-testid={UTILS_LABEL}
    >
      <Link
        href={UTILS_PATH}
        passHref
      >
        <StyledLink>{UTILS_LABEL}</StyledLink>
      </Link>
    </Menu.Item>
  </Menu>
)

const MoreViews = () => {
  const theme = useContext(ThemeContext)
  const { width } = useWindowSize()
  const isMobile = width && width < theme.metrics.tablet
  return (
    <CustomDropdown
      overlay={menu}
      overlayStyle={{ zIndex: isMobile ? 10002 : 10000 }}
    >
      <Avatar
        style={{ backgroundColor: theme.colors.grey, verticalAlign: 'middle' }}
        data-testid="dropDownMenu"
      >
        <MenuOutlined style={{ color: theme.colors.purple }} />
      </Avatar>
    </CustomDropdown>
  )
}

export default MoreViews
