// eslint-disable-next-line import/no-extraneous-dependencies
import { OptionData, OptionGroupData } from 'rc-select/lib/interface'

// Antd multiselect input is by default searched by value.
// Adding our own filter allows us to search by label instead.
export const searchSelectOptionsByLabel = (
  inputValue: string,
  option: OptionData | OptionGroupData | undefined,
) => {
  const re = new RegExp(inputValue, 'i')
  return !!String(option?.label).match(re)
}
