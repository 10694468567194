import styled from 'styled-components'

interface IProps {
  height?: string
}

const Anchor = styled.a.attrs({ rel: 'noopener noreferrer' })<IProps>`
  text-decoration: none;
  color: inherit;
  &:hover,
  :focus,
  :active {
    text-decoration: none;
    color: inherit;
  }
  ${props => (props.height ? `height: ${props.height};` : '')}
`

export default Anchor
