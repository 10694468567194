import * as T from 'types'

/* eslint-disable import/prefer-default-export */
interface IKeyValue {
  [key: string]: string
}

export interface ISVGProps {
  fill?: string
  width?: string
  height?: string
  viewBox: string
  style?: IKeyValue
  d?: string
  maskId?: string
  [key: string]: T.ValueOf<ISVGProps>
  masktype?: string
}

export enum Rotations {
  up = 'none',
  none = 'none',
  right = 'right',
  down = 'down',
  left = 'left',
}
