import { MlsName } from 'components'
import { FC } from 'react'

import * as T from 'types'

interface IProps {
  referenceList?: T.IMls[]
  ids: string[]
}

const MlsNameList: FC<IProps> = ({ referenceList, ids }) => {
  const mlses = ids
    .map((mlsId: string) => referenceList?.find((mls: T.IMls) => mls._id === mlsId))
    .map(mls => mls && (
      <MlsName
        key={mls._id}
        mls={mls}
      />
    ))
    .filter(Boolean)
    // there's nothing unique about these comma spans
    // eslint-disable-next-line react/no-array-index-key
    .map((mlsName, idx) => [mlsName, <span key={idx}>, </span>])
    .flat()
    .slice(0, -1)

  return <div>{mlses}</div>
}

export default MlsNameList
