import * as T from 'types'

import { api } from './api'
import { refreshChecklistListingItems } from './swr/useChecklistListingItems'
import { refreshListing } from './swr/useListing'
import { getDataFromResponse } from './swr/utils/dataHelpers'
import { getUpdateErrorMessage } from './swr/utils/errorMessageHelpers'

export const updateMlsStatus = async (
  listingId: string,
  mls: T.IListingMls,
): Promise<[T.IListing | undefined, T.IServerError | undefined]> => {
  const response = await api.updateMlsStatus(listingId, mls)
  const updatedListing = getDataFromResponse<T.IListing>(response)

  const userReadableError = 'Failed to update mls status'
  const updateMlsStatusError = getUpdateErrorMessage(response, { userReadableError })

  await refreshListing(listingId)
  await refreshChecklistListingItems(listingId)

  return [updatedListing, updateMlsStatusError]
}

export const addNewMls = async (listingId: string, mls: T.IListingMls) => {
  try {
    const response = await api.addNewMls(listingId, mls)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await refreshListing(listingId)
    await refreshChecklistListingItems(listingId)
    return response.data
  } catch (error) {
    throw new Error('There was an error adding a new mls list')
  }
}

export const deleteMls = async (listingId: string, mlsId: string) => {
  try {
    const response = await api.deleteMls(listingId, mlsId)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    await refreshListing(listingId)
    await refreshChecklistListingItems(listingId)
    return response.data
  } catch (error) {
    throw new Error('There was an error deleting a selected mls list.')
  }
}
