import { InputNumber, Select } from 'antd'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Spacer from 'components/Spacer'

import { getRoomObject } from 'utils/listingHelpers'

import * as T from 'types'
import * as E from 'types/enums'

import { IUpdateRoomDimension } from './types'

const StyledInputNumber = styled(InputNumber)`
  width: 150px;
`

const DimensionsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`

const XContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: 5px;
  text-transform: capitalize;
`

interface IProps {
  field: T.IMlsField
  fieldUpdate: T.IListing
  listing: T.IListing
  updateRoomDimension: IUpdateRoomDimension
}

const DimensionsInput: FunctionComponent<IProps> = ({
  field,
  fieldUpdate,
  listing,
  updateRoomDimension,
}) => {
  const name = String(field.fieldId?.name)

  const dimensionsValue: T.IRoom = getRoomObject(name, fieldUpdate)
    || getRoomObject(name, listing) || { name }

  const { width, depth, location } = dimensionsValue

  const locationOptions = Object.values(E.RoomLocation).map(val => ({ label: val, value: val }))

  // InputNumber onchange should always return a number in our case
  const updateWidth = (numberVal: number | string | null) => {
    const widthVal = typeof numberVal === 'number' ? Math.round(numberVal as number) : null
    updateRoomDimension(name, 'width', widthVal)
  }

  const updateDepth = (numberVal: number | string | null) => {
    const depthVal = typeof numberVal === 'number' ? Math.round(numberVal as number) : null
    updateRoomDimension(name, 'depth', depthVal)
  }

  const updateLocation = (val: E.RoomLocation) => updateRoomDimension(name, 'location', val)
  const onClearLocation = () => updateRoomDimension(name, 'location', null)

  return (
    <DimensionsRow>
      <StyledInputNumber
        placeholder="width"
        defaultValue={width === null ? undefined : Number(width)}
        onChange={updateWidth}
      />
      <Spacer size={20} />
      <XContainer>x</XContainer>
      <Spacer size={20} />
      <StyledInputNumber
        placeholder="depth"
        defaultValue={depth === null ? undefined : Number(depth)}
        onChange={updateDepth}
      />
      <Spacer size={20} />
      <Select
        allowClear
        value={location || undefined}
        options={locationOptions}
        onClear={onClearLocation}
        onSelect={updateLocation}
        placeholder="Level"
      />
    </DimensionsRow>
  )
}

export default DimensionsInput
