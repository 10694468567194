export interface metricsObject {
  [key: string]: number
}

// Used via Metrics.baseMargin
const metrics: metricsObject = {
  // ant design breakpoints
  phone: 576,
  tablet: 768,
  desktop: 992,
  desktopLarge: 1200,

  // etc
  contentMaxWidth: 1140,
  maxTextAreaCharacters: 1000,
  sidebarWidth: 340,
  headerHeight: 50,
  headerHeightPhone: 73, // header + extended header
  tallHeaderHeight: 100,
  tallHeaderHeightPhone: 180,
}

export default metrics
