import { red } from '@ant-design/colors'
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  TimePicker,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { Spacer } from 'components'
import dayjs from 'dayjs'
import { Moment } from 'moment-timezone'
import { FC, FocusEventHandler, useMemo } from 'react'
import styled from 'styled-components'

import 'utils/timezone'

// for dayjs timezone plugin side-effect
import * as T from 'types'

import { IModalShowing } from './types'

const StyledCheckbox = styled(Checkbox)`
  flex-direction: row-reverse;

  span:nth-child(2) {
    padding-left: 0;
    padding-right: 15px;
  }
`

const TimePickerLabel = styled.label`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 250px;
`

const StyledText = styled.p`
  color: ${red[5]};
  margin-bottom: 0;
`

const StyledDatePicker = styled(DatePicker)`
  width: 250px;
`

type IOnAddOpenHouseDate = (showing: T.IOpenHouseDate) => void
type IOnCancel = () => void
type IOnUpdateErrorMessage = (errorMessage: string) => void
type IOnUpdateOpenHouseDate = (showing: T.IOpenHouseDate) => void
type IOnUpdateShowing = (showing: IModalShowing) => void

interface IValidModalShowing {
  date: Moment
  endTime: Moment
  startTime: Moment
  isCancelled: boolean
  isVirtual: boolean
  virtualUrl: string
}

const getDateTime = (date: Moment, time: Moment, timezone: string): string =>
  dayjs()
    .tz(timezone)
    .year(date.year())
    .month(date.month())
    .date(date.date())
    .hour(time.hour())
    .minute(time.minute())
    .format()

interface IConvertToOpenHouseDate {
  (
    showing: IValidModalShowing,
    timezone: string,
    openHouseDate?: T.IOpenHouseDate,
  ): T.IOpenHouseDate
}

const convertToOpenHouseDate: IConvertToOpenHouseDate = (showing, timezone, openHouseDate) => ({
  ...(openHouseDate || {}),
  cancelled: showing.isCancelled,
  end: getDateTime(showing.date, showing.endTime, timezone),
  mlsUpToDate: openHouseDate?.mlsUpToDate || false,
  start: getDateTime(showing.date, showing.startTime, timezone),
  virtual: showing.isVirtual,
  virtualUrl: showing.virtualUrl,
})

const getErrorMessage = (showing: IModalShowing) => {
  const emptyFields = [
    showing.date ? '' : 'Date',
    showing.endTime ? '' : 'End Time',
    showing.startTime ? '' : 'Start Time',
    showing.isVirtual && !showing.virtualUrl ? 'Virtual Url' : '',
  ]
    .filter(Boolean)
    .join(', ')

  if (emptyFields) return `The following fields must be set: ${emptyFields}`

  if (!showing.startTime || !showing.endTime) {
    throw new Error('start and end times must exist at this point')
  }

  if (showing.startTime.isAfter(showing.endTime)) {
    return 'The start time must be before the end time'
  }
}

interface IOpenHouseModalProps {
  errorMessage: string
  onAddOpenHouseDate: IOnAddOpenHouseDate
  onCancel: IOnCancel
  onUpdateErrorMessage: IOnUpdateErrorMessage
  onUpdateOpenHouseDate: IOnUpdateOpenHouseDate
  onUpdateShowing: IOnUpdateShowing
  openHouseDate: T.IOpenHouseDate | undefined
  showing: IModalShowing
  timezone: string
  visible: boolean
}

const OpenHouseModal: FC<IOpenHouseModalProps> = props => {
  const {
    errorMessage,
    onAddOpenHouseDate,
    onCancel,
    onUpdateErrorMessage,
    onUpdateOpenHouseDate,
    onUpdateShowing,
    openHouseDate,
    showing,
    timezone,
    visible,
  } = props

  const title = openHouseDate ? 'Update Showing' : 'Add A Showing'

  const handleClickUpdate = () => {
    const msg = getErrorMessage(showing)

    if (msg) onUpdateErrorMessage(msg)
    else {
      const updatedOpenHouseDate = convertToOpenHouseDate(
        showing as IValidModalShowing,
        timezone,
        openHouseDate,
      )
      onUpdateOpenHouseDate(updatedOpenHouseDate as T.IOpenHouseDate)
    }
  }

  const handleClickAdd = () => {
    const msg = getErrorMessage(showing)
    if (msg) onUpdateErrorMessage(msg)
    else onAddOpenHouseDate(convertToOpenHouseDate(showing as IValidModalShowing, timezone))
  }

  const getFooter = () => {
    const cancel = (
      <Button
        key="cancel"
        onClick={onCancel}
      >
        Cancel
      </Button>
    )

    const confirm = openHouseDate?._id
      ? (
        <Button
          key="update"
          type="primary"
          onClick={handleClickUpdate}
        >
        Update
        </Button>
      )
      : (
        <Button
          key="add"
          type="primary"
          onClick={handleClickAdd}
        >
        Add
        </Button>
      )

    return [cancel, confirm]
  }

  const footer = useMemo(getFooter, [openHouseDate, showing])
  const timezoneAbbr = useMemo(() => dayjs().tz(timezone).format('z'), [timezone])

  const updateShowing = (updateProp: Partial<IModalShowing>) =>
    onUpdateShowing({
      ...showing,
      ...updateProp,
    })

  const handleDateChange = (date: Moment | null) => updateShowing({ date })
  const handleEndChange = (endTime: Moment | null) => updateShowing({ endTime })
  const handleStartChange = (startTime: Moment | null) => updateShowing({ startTime })

  const handleTypeChange = (event: RadioChangeEvent) =>
    updateShowing({
      isVirtual: event.target.value,
    })

  const handleVirtualUrlChange: FocusEventHandler<HTMLInputElement> = event =>
    updateShowing({
      virtualUrl: event.target.value,
    })

  const handleIsCancelledChanged = (event: CheckboxChangeEvent) =>
    updateShowing({
      isCancelled: event.target.checked,
    })

  return (
    <Modal
      footer={footer}
      onCancel={onCancel}
      title={title}
      visible={visible}
    >
      <Space
        direction="vertical"
        size={15}
      >
        <StyledDatePicker
          format="MM/DD/YY"
          onChange={handleDateChange}
          placeholder="Date"
          value={showing.date}
        />
        <TimePickerLabel>
          <div>Start Time ({timezoneAbbr})</div>
          <TimePicker
            format="h:mm a"
            minuteStep={15}
            onSelect={handleStartChange}
            value={showing.startTime}
          />
        </TimePickerLabel>
        <TimePickerLabel>
          <div>End Time ({timezoneAbbr})</div>
          <TimePicker
            format="h:mm a"
            minuteStep={15}
            onSelect={handleEndChange}
            value={showing.endTime}
          />
        </TimePickerLabel>
        <div>
          The Open House is
          <Spacer
            size={15}
            display="inline-block"
          />
          <Radio.Group
            onChange={handleTypeChange}
            value={showing.isVirtual}
          >
            <Radio value={false}>In Person</Radio>
            <Radio value>Virtual</Radio>
          </Radio.Group>
        </div>
        {showing.isVirtual && (
          <Input
            onChange={handleVirtualUrlChange}
            placeholder="Enter Virtual Tour Link"
            value={showing.virtualUrl}
          />
        )}
        <StyledCheckbox
          onChange={handleIsCancelledChanged}
          checked={showing.isCancelled}
        >
          Cancelled?
        </StyledCheckbox>
        {!!errorMessage && <StyledText>{errorMessage}</StyledText>}
      </Space>
    </Modal>
  )
}

export default OpenHouseModal
