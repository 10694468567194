import { Alert } from 'antd'
import { Spacer } from 'components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTwilioWorkerByEmail, useUser } from 'services'
import styled from 'styled-components'

import { formatCamelCaseToSentence } from 'utils/stringFormatting'

import ListingSearchNavLinks from './ListingSearchNavLinks'
import MoreViews from './MoreViews'
import Signin from './Signin'
import TwilioStatusButton from './TwilioStatusButton'
import User from './User'
import * as T from './types'

interface IHideDropShadowProp {
  hideDropShadow: boolean
}
const HeaderContainer = styled.div<T.IModalOpenProps & IHideDropShadowProp>`
  flex-shrink: 0;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => props.theme.metrics.headerHeightPhone}px;
  border-bottom: 1px solid ${props => props.theme.colors.grey3};

  background-color: ${props => props.theme.colors.white};

  z-index: 10002;

  ${props =>
    props.modalOpen
    && `
    box-shadow: none;
    height: 100vh;
    overflow: hidden;
  `}

  height: ${props => props.theme.metrics.headerHeight}px;
  overflow: visible;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  ${props => props.hideDropShadow && 'box-shadow: none;'}

  @media print {
    position: absolute;
    page-break-inside: avoid;
    top: -50px;
    ul {
      display: none;
    }
  }
`

const Div = styled.div``

const HeaderContentContainer = styled.div<T.IModalOpenProps>`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  ${props => props.modalOpen && 'margin: 11px 13px;'}
  height: 100%;
  margin: 0;
`

const MaxWidthContainer = styled.div`
  position: relative;
  max-width: 2880px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 0 25px;
  }
`

const HomeListerLogo = styled.img`
  display: block;
  height: 35px;
  cursor: pointer;
`

const HeaderCornerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
`

const CustomTwilioButtonContainer = styled.div`
  display: none;
  width: 125px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: block;
  }
`

const ExtendedHeaderContainer = styled.div`
  position: absolute;
  top: 49px; // under header
  padding: 2px;
  background-color: white;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: none;
  }
`

const CustomTwilioMobileButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 24px;
  background-color: white;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: none;
  }
`

const CustomAlert = styled(Alert)`
  position: relative;
  margin: 0;
  padding: 0 15px;
  height: 24px;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: none;
  }
`

const Header: FunctionComponent = () => {
  const homeListerLogoSrc = '/static/logos/homelister_logo.png'

  // routing
  const router = useRouter()
  const isOnListingPage = router.pathname === '/listing/[id]'
  const navToSignin = () => router.push('/signin')
  const mode = (router.query.mode || '') as string

  // user data
  const { user, isValidating, logout } = useUser()
  const { twilioWorker } = useTwilioWorkerByEmail(user?.email)

  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)

  useEffect(() => {
    if (initialPageLoad) return setInitialPageLoad(false)

    if (isValidating) return

    setLoadingUser(false)
  }, [user, isValidating])

  const handleLogout = () => {
    logout()
    router.push('/signin')
  }

  return (
    <>
      <HeaderContainer hideDropShadow={isOnListingPage}>
        <HeaderContentContainer>
          <MaxWidthContainer>
            <HeaderCornerContainer>
              <Link href="/">
                <HomeListerLogo
                  src={homeListerLogoSrc}
                  data-testid="homelisterLogo"
                />
              </Link>
              <Spacer size={40} />
            </HeaderCornerContainer>
            {!!user && <ListingSearchNavLinks />}
            <Spacer
              size={50}
              hideDesktop
            />
            <HeaderCornerContainer>
              {!user && !loadingUser && (
                <Signin
                  handleClick={navToSignin}
                  data-testid="signIn"
                />
              )}
              {!!user && (
                <>
                  {twilioWorker && (
                    <CustomTwilioButtonContainer>
                      <TwilioStatusButton twilioWorker={twilioWorker} />
                    </CustomTwilioButtonContainer>
                  )}
                  <MoreViews />
                  <Spacer size={10} />
                  <Div data-testid="user">
                    <User
                      user={user}
                      logout={handleLogout}
                    />
                  </Div>
                </>
              )}
            </HeaderCornerContainer>
          </MaxWidthContainer>
        </HeaderContentContainer>
        <ExtendedHeaderContainer>
          {twilioWorker && (
            <CustomTwilioMobileButtonContainer>
              <TwilioStatusButton twilioWorker={twilioWorker} />
            </CustomTwilioMobileButtonContainer>
          )}
          {mode && <CustomAlert message={`Viewing: ${formatCamelCaseToSentence(mode)}`} />}
        </ExtendedHeaderContainer>
      </HeaderContainer>
      {mode && (
        <Spacer
          size={24}
          hideDesktop
          hideTablet
        />
      )}
    </>
  )
}

export default Header
