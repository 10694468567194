import SVG from './SVG'
import * as T from './types'

export default (props: T.ISVGProps) => (
  <SVG
    {...props}
    viewBox="0 0 20 20"
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM13.2415 12.21C13.2415 12.0774 13.1867 11.9507 13.09 11.86L11.24 10L13.09 8.14C13.1867 8.04927 13.2415 7.92259 13.2415 7.79C13.2415 7.65741 13.1867 7.53073 13.09 7.44L12.56 6.91C12.4693 6.81332 12.3426 6.75848 12.21 6.75848C12.0774 6.75848 11.9507 6.81332 11.86 6.91L10 8.76L8.14 6.91C8.04927 6.81332 7.92259 6.75848 7.79 6.75848C7.65741 6.75848 7.53073 6.81332 7.44 6.91L6.91 7.44C6.81332 7.53073 6.75848 7.65741 6.75848 7.79C6.75848 7.92259 6.81332 8.04927 6.91 8.14L8.76 10L6.91 11.86C6.81332 11.9507 6.75848 12.0774 6.75848 12.21C6.75848 12.3426 6.81332 12.4693 6.91 12.56L7.44 13.09C7.53073 13.1867 7.65741 13.2415 7.79 13.2415C7.92259 13.2415 8.04927 13.1867 8.14 13.09L10 11.24L11.86 13.09C11.9507 13.1867 12.0774 13.2415 12.21 13.2415C12.3426 13.2415 12.4693 13.1867 12.56 13.09L13.09 12.56C13.1867 12.4693 13.2415 12.3426 13.2415 12.21Z"
  />
)
