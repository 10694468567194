import { blue, red, yellow } from '@ant-design/colors'
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  MailOutlined,
  PhoneOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Col, Modal, Row, Space, message } from 'antd'
import { Anchor, CopyCheckboxes, CustomButton, IconButton, Spacer } from 'components'
import Cookies from 'js-cookie'
import React, { CSSProperties, FunctionComponent, useState } from 'react'
import {
  addNewAdditionalContactInfo,
  blockPhoneNumber,
  callSeller,
  deleteAdditionalContactInfo,
  unBlockPhoneNumberById,
  useBlockedPhoneNumbers,
  useListing,
  useSellerListings,
  useUser,
} from 'services'
import styled from 'styled-components'
import { copyToClipboard, formatPhoneNumber } from 'utils'

import displayMessage from 'utils/displayMessage'
import { omit } from 'utils/objectHelpers'
import { themeFontSize } from 'utils/styles'

import * as T from 'types'

import { SubsectionTitle } from '../Styles'
import { AddContactModal } from './AddContactModal'
import { EditOwnerModal } from './EditOwnerModal'

const IconLink = styled(IconButton)`
  color: black;

  &:focus:not(:hover) {
    color: black;
  }
`

const StyledIconButton = styled(IconButton)`
  color: white;
  height: auto;
  line-height: 1;
`

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

const ContactContainer = styled.div`
  display: flex;
  width: 100%;
`

const InfoWrapper = styled.div`
  width: 100%;
`

interface ITextProps {
  backgroundColor?: boolean
  fontWeight?: number
}

const Text = styled.div<ITextProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${themeFontSize};
  ${props => props.backgroundColor && `background-color: ${props.theme.colors.grey};`}
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  height: 20px;
  padding: 0 4px;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const IconWrapper = styled.div`
  padding-left: 5px;
`

const Contacts: FunctionComponent = () => {
  // swr
  const { listing, update } = useListing()
  const { listings: sellerListings } = useSellerListings(listing)
  const { user } = useUser()

  const [displayAddContactModal, setDisplayAddContactModal] = useState(false)
  const [editableOwner, setEditableOwner] = useState<T.IOwner>()

  if (!listing) return null

  const renderSellerListingsCount = () => {
    if (sellerListings && sellerListings.length > 1) {
      return (
        <Anchor
          href={`/listings?sellerId=${listing?.userId._id}`}
          target="_blank"
        >
          {`(${sellerListings.length})`}
        </Anchor>
      )
    }

    return null
  }

  interface IUserData {
    Name: string
    Email: string
    Phone: string
  }

  const userData: IUserData[] = [
    {
      Name: listing.userId.name,
      Email: listing.userId.email,
      Phone: formatPhoneNumber(listing.userId.phoneHome),
    },
  ]

  const getContactInfo = (contactInfo: T.IContactInfo) => {
    let phoneAcceptsTexts

    switch (contactInfo.phoneAcceptsTexts) {
      case true:
        phoneAcceptsTexts = 'Yes'
        break
      case false:
        phoneAcceptsTexts = 'No'
        break
      default:
        phoneAcceptsTexts = ''
    }

    return {
      'Name': contactInfo.name,
      'Email': contactInfo.email,
      'Phone': formatPhoneNumber(contactInfo.phone),
      'Text Message Accepted?': phoneAcceptsTexts,
    }
  }

  interface IContact extends IUserData {
    'Text Message Accepted?': string
  }

  const getContacts = (): IContact[] => {
    const contacts = []
    if (listing.contactInfo) contacts.push(getContactInfo(listing.contactInfo))

    if (listing.secondaryContactInfo) {
      if (
        listing.secondaryContactInfo.name
        || listing.secondaryContactInfo.email
        || listing.secondaryContactInfo.phone
      ) {
        contacts.push(getContactInfo(listing.secondaryContactInfo))
      }
    }

    return contacts
  }

  interface IOwner extends IUserData {
    '_id': string
    'Type': string | undefined
    'Organization Name': string | undefined
    'Title': string | undefined
    'Document': undefined | (T.IOwnerDocument & { display?: string })
  }

  const getOwners = (): IOwner[] =>
    listing.owners.map(owner => ({
      '_id': owner._id,
      'Name': owner.name,
      'Email': owner.email,
      'Phone': formatPhoneNumber(owner.phone),
      'Type': owner.ownerType,
      'Organization Name': owner.companyName || owner.trustName,
      'Title': owner.title,
      'Document': owner.agreement && { ...owner.agreement, display: owner.agreement.filename },
    }))

  interface IAdditionalContact {
    _id: string
    Email: string | undefined
    Name: string | undefined
    notes: string | undefined
    Phone: string
  }

  const getAdditionalContacts = (): IAdditionalContact[] => {
    if (typeof listing.additionalContactInfo === 'undefined') return []

    return listing.additionalContactInfo.map(contact => ({
      _id: contact._id,
      Name: contact.name,
      Email: contact.email,
      Phone: formatPhoneNumber(contact.phone),
      notes: contact.notes,
    }))
  }

  const contactsData = getContacts()
  const ownersData = getOwners()
  const additionalContacts = getAdditionalContacts()

  const phoneNumbers = [...contactsData, ...ownersData, ...additionalContacts].reduce(
    (numbers, { Phone }) => (Phone ? [...numbers, Phone] : numbers),
    [] as string[],
  )

  const { blockedPhoneNumbers } = useBlockedPhoneNumbers(phoneNumbers)

  const isPhoneNumberBlocked = (phoneNumber: string) => {
    const noHyphenPhoneNumber = Number(phoneNumber.replace(/-/g, ''))
    return !!blockedPhoneNumbers[Number(noHyphenPhoneNumber)]?.blockActive
  }

  const handleCall = (phone: string) => async () => {
    const [data, error] = await callSeller(listing, phone)
    if (error && !data) displayMessage('error', error.message)

    if (!error && data) {
      displayMessage(
        'success',
        `Call has been successfully made, connecting: ${formatPhoneNumber(
          user?.phoneCell || user?.phoneHome,
        )}`,
      )
    }
  }

  const handleBlockPhoneNumber = (phoneNumber: string) => () => {
    const { confirm } = Modal

    if (isPhoneNumberBlocked(phoneNumber)) {
      confirm({
        title: `Are you sure you want to unblock ${phoneNumber}`,
        async onOk() {
          const noHyphenPhoneNumber = Number(phoneNumber.replace(/-/g, ''))

          const [data, error] = await unBlockPhoneNumberById(
            blockedPhoneNumbers[noHyphenPhoneNumber]._id,
          )
          if (error) displayMessage('error', error.message)

          if (!error && typeof data !== 'undefined') {
            displayMessage('success', 'The phone number has been successfully unblocked')
          }
        },
      })
    } else {
      confirm({
        title: `Are you sure you want to block ${phoneNumber}`,
        async onOk() {
          const [data, error] = await blockPhoneNumber(phoneNumber)
          if (error) displayMessage('error', error.message)

          if (!error && typeof data !== 'undefined') {
            displayMessage('success', 'The phone number has been successfully blocked')
          }
        },
      })
    }
  }

  const renderBlockIcon = (phoneNumber: string) => {
    const style: CSSProperties = { marginLeft: 5 }
    if (isPhoneNumberBlocked(phoneNumber)) style.color = red[6]

    return (
      <IconButton
        Icon={StopOutlined}
        onClick={handleBlockPhoneNumber(phoneNumber)}
        style={style}
      />
    )
  }

  interface IRenderContactInfoOpts {
    editableId?: string
    nonContactFields?: JSX.Element
    onDeleteConfirm?: () => void
  }

  interface IRenderContactInfo {
    (
      title: string,
      data: IUserData | IContact | Omit<IOwner, '_id'> | Omit<IAdditionalContact, '_id'>,
      opts?: IRenderContactInfoOpts,
    ): JSX.Element
  }

  const renderContactInfo: IRenderContactInfo = (title, data, opts = {}) => {
    const { editableId, nonContactFields, onDeleteConfirm } = opts

    const editOwner = () => {
      const owner = listing.owners.find(({ _id }) => _id === editableId)
      setEditableOwner(owner)
    }

    const onClickDelete = () =>
      Modal.confirm({
        title: 'Do you want to delete this information?',
        onOk: onDeleteConfirm,
      })

    const handleCopy
      = (tableData: object | string, successMessage: string = 'Value Copied!') =>
        () => {
          message.success({
            style: { marginTop: '50px' },
            content: successMessage,
            duration: 5,
          })
          copyToClipboard(tableData)
        }

    const contactToCopy = (data as IOwner)?.Document ? omit(data as IOwner, ['Document']) : data

    const onClickContactCopy = handleCopy(contactToCopy, `Copied ${title} values`)

    const getDocumentHref = ({ filename }: T.IOwnerDocument) => {
      const ownerIdx = ownersData.findIndex(({ _id }) => editableId === _id)
      return `${process.env.API_URL}/amazons/listingDocument/${
        listing._id
      }/owners.agreement/${ownerIdx}/${encodeURIComponent(
        filename,
      )}?inline=true&access_token=${Cookies.get('token')}`
    }

    return (
      <InfoWrapper key={`${data.Name} + ${data.Phone} + ${data.Email}`}>
        <SubsectionTitle>
          <div>{title}</div>
          <Space size={12}>
            {editableId && (
              <StyledIconButton
                Icon={EditOutlined}
                onClick={editOwner}
              />
            )}
            {onDeleteConfirm && (
              <StyledIconButton
                Icon={DeleteOutlined}
                onClick={onClickDelete}
              />
            )}
            <StyledIconButton
              Icon={CopyOutlined}
              onClick={onClickContactCopy}
            />
          </Space>
        </SubsectionTitle>
        {Object.entries(data).map(([key, value], index) => {
          const onClickEmailCopy = handleCopy(data.Email ?? '', `Copied ${title} email value`)
          return (
            <Text
              backgroundColor={index % 2 === 0}
              key={key}
            >
              <span>{value?.display || value?.url || value || ''}</span>
              <IconWrapper>
                {index === 0 && title === 'User Info' && (
                  <span style={{ color: blue.primary }}>{renderSellerListingsCount()}</span>
                )}
                {key === 'Email' && value && (
                  <IconButton
                    Icon={MailOutlined}
                    onClick={onClickEmailCopy}
                  />
                )}
                {key === 'Phone' && value && (
                  <>
                    <IconButton
                      Icon={PhoneOutlined}
                      onClick={handleCall(value)}
                    />
                    {renderBlockIcon(value)}
                  </>
                )}
                {key === 'Document' && value && (
                  <IconLink
                    href={getDocumentHref(value)}
                    Icon={FileTextOutlined}
                    type="link"
                    target="_blank"
                  />
                )}
              </IconWrapper>
            </Text>
          )
        })}
        {nonContactFields || null}
      </InfoWrapper>
    )
  }

  const openAddContactModal = () => setDisplayAddContactModal(true)
  const closeAddContactModal = () => setDisplayAddContactModal(false)
  const closeEditOwnerModal = () => setEditableOwner(undefined)

  const onDeleteConfirm = (contactId?: string) => () =>
    contactId
    && deleteAdditionalContactInfo(listing, contactId).catch(error => {
      displayMessage('error', error.message)
    })

  const addAdditionalContact = (contact: Omit<T.IAdditionalContactInfo, '_id'>) => {
    addNewAdditionalContactInfo(listing, contact).catch(error => {
      displayMessage('error', error.message)
    })
    closeAddContactModal()
  }

  const updateOwner = async (owner: T.IOwner) => {
    const owners = [...listing.owners]
    const idx = owners.findIndex(({ _id }) => _id === owner._id)
    owners[idx] = owner

    const errMsg = await update({ owners })

    if (errMsg) displayMessage('error', errMsg)
    else displayMessage('success', 'Title Holder Updated', { duration: 3 })
  }

  const allUserDataEmails = userData.map(data => data.Email).join(',')
  const allShowingContactEmails = contactsData.map(data => data.Email).join(',')
  const allTitleHolderEmails = ownersData.map(data => data.Email).join(',')
  const allAdditionalContactEmails = additionalContacts.map(data => data.Email).join(',')
  return (
    <>
      <SectionContainer>
        {userData.map((data, index) => {
          const title = `User Info ${index}`
          return (
            <ContactContainer key={title}>
              {renderContactInfo('User Info', data, {
                nonContactFields: (
                  <Text fontWeight={600}>
                    {`Role: ${listing.iAmNotATitleHolder ? 'Listing Manager' : 'Title Holder'}`}
                  </Text>
                ),
              })}
            </ContactContainer>
          )
        })}
        {contactsData.map((data, index) => {
          const title = `Showing Contact ${index + 1}`
          return <ContactContainer key={title}>{renderContactInfo(title, data)}</ContactContainer>
        })}
        {ownersData.map((data, index) => {
          const title = `Title Holder ${index + 1}`
          const { _id, ...owner } = data
          return (
            <ContactContainer key={data.Name}>
              {renderContactInfo(title, owner, { editableId: _id })}
            </ContactContainer>
          )
        })}
        {additionalContacts.map((data, index) => {
          const title = `Additional Contact ${index + 1}`
          const { _id: contactId, ...contact } = data
          return (
            <ContactContainer key={data.Name}>
              {renderContactInfo(title, contact, { onDeleteConfirm: onDeleteConfirm(contactId) })}
            </ContactContainer>
          )
        })}
      </SectionContainer>
      <Spacer size={10} />
      <Row
        gutter={[20, 10]}
        align="middle"
      >
        <Col>
          <CustomButton
            backgroundColor={yellow[4]}
            backgroundColorOnHover={yellow[5]}
            custom
            onClick={openAddContactModal}
          >
            Add Contact
          </CustomButton>
          <AddContactModal
            isOpen={displayAddContactModal}
            title="Create Additional contact"
            confirmLoading={false}
            onConfirm={addAdditionalContact}
            onCancel={closeAddContactModal}
          />
        </Col>
        <Col>
          {!!editableOwner && (
            <EditOwnerModal
              close={closeEditOwnerModal}
              listingId={listing._id}
              owner={editableOwner}
              update={updateOwner}
            />
          )}
        </Col>
        <Col>
          <CopyCheckboxes
            copyOptions={[
              { label: 'User Info', value: allUserDataEmails },
              { label: 'Showing Contacts', value: allShowingContactEmails },
              { label: 'Title Holders', value: allTitleHolderEmails },
              { label: 'Additional Contacts', value: allAdditionalContactEmails },
            ]}
            copyBtnText="Copy Emails"
            copyCompletedMsg="Copied Emails!"
          />
        </Col>
      </Row>
    </>
  )
}

export default Contacts
