import * as T from 'types'

import { api } from './api'

/* global window */

declare const Blob: {
  prototype: Blob
  new (): Blob
  new (request: any, mime: { type: string }): Blob
}

export const downloadZipLinks = async (
  nonDeletedPhotos: T.IPhoto[],
  listing: T.IListing,
): Promise<[boolean | undefined, Error | undefined]> => {
  try {
    const response = await api.downloadZipLinks(listing._id, nonDeletedPhotos, listing.street)
    if (!response.data || !response.ok) throw new Error(`bad response: ${response.problem}`)

    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    const folderName = `${listing.street}.zip`

    const a = window.document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = folderName
    a.setAttribute('download', folderName)
    window.document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)

    return [true, undefined]
  } catch (error) {
    console.error(error)
    if (error instanceof Error) return [undefined, error]

    return [undefined, undefined]
  }
}
