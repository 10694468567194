import { Alert, Col, Row } from 'antd'
import { FC } from 'react'

interface IProps {
  error: string
}
const PageError: FC<IProps> = ({ error: description }) => (
  <Row
    style={{ height: '95vh' }}
    align="middle"
    justify="center"
  >
    <Col
      xs={24}
      md={8}
    >
      <Alert
        message="Error"
        description={description}
        type="error"
        showIcon
      />
    </Col>
  </Row>
)

export default PageError
