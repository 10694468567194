import { Button, Checkbox, Col, Row, message } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { copyToClipboard } from 'utils'

const StyledRow = styled(Row)`
  border: ${props => `1px solid ${props.theme.colors.grey4}`};
  border-radius: 5px;
  padding: 10px;
`

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: 800;
  height: 24px;
  margin-top: 1px;
  border-radius: 32px;
`

interface ICheckboxOption {
  label: string
  value: string
  checked?: boolean
}

interface IProps {
  copyOptions: ICheckboxOption[]
  copyBtnText?: string
  copyCompletedMsg?: string
}

const CopyCheckboxes: FunctionComponent<IProps> = ({
  copyOptions,
  copyBtnText = 'Copy',
  copyCompletedMsg = 'Copied!',
}) => {
  const [checkboxOptions, setCheckboxOptions] = useState<ICheckboxOption[]>([])
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const [copyCompleted, setCopyCompleted] = useState(false)

  useEffect(() => {
    const newCheckboxOptions = copyOptions.map(option => ({
      label: String(option.label),
      value: String(option.value),
      checked: false,
    }))

    setCheckboxOptions(newCheckboxOptions)

    // Clear selection
    if (selectedValues.length) setSelectedValues([])
  }, [...copyOptions.map(option => option.value)])

  useEffect(() => {
    if (copyCompleted) {
      message.success({
        style: { marginTop: '50px' },
        content: copyCompletedMsg,
        duration: 5,
      })
    }
  }, [copyCompleted])

  const handleCopy = () => {
    const formattedSelectedValues = selectedValues.map(value => [...value.split(',')]).flat()
    const uniqueValues: string[] = []
    new Set([...formattedSelectedValues]).forEach(value => uniqueValues.push(value))
    copyToClipboard(uniqueValues.join(','))
    setCopyCompleted(true)
  }

  const handleOnChange = (checked: boolean, index: number) => {
    setCopyCompleted(false)

    if (checked && checkboxOptions[index].value && checkboxOptions[index].value !== '---') {
      setSelectedValues([...selectedValues, checkboxOptions[index].value])
    } else {
      const removeIndex = selectedValues.indexOf(checkboxOptions[index].value)

      if (removeIndex > -1) {
        const selectedValuesCopy = [...selectedValues]
        selectedValuesCopy.splice(removeIndex, 1)
        setSelectedValues(selectedValuesCopy)
      }
    }

    const updateCheckboxOptions = [...checkboxOptions]
    updateCheckboxOptions[index].checked = checked
    setCheckboxOptions(updateCheckboxOptions)
  }

  return (
    <Row>
      <StyledRow align="middle">
        <Col>
          {checkboxOptions.map((option, index) => (
            <Checkbox
              key={String(option.label)}
              style={{ margin: 0 }}
              checked={option.checked}
              onChange={event => handleOnChange(event.target.checked, index)}
              data-testid={option.label}
            >
              {option.label}
            </Checkbox>
          ))}
        </Col>
        <Col>
          <StyledButton
            onClick={handleCopy}
            data-testid={copyBtnText}
          >
            {copyBtnText}
          </StyledButton>
        </Col>
      </StyledRow>
    </Row>
  )
}

export default CopyCheckboxes
