import { Form, Input, Modal } from 'antd'
import React, { useState } from 'react'

import { CustomButton } from '../../../components'
import { addTask } from '../../../services/listingChangeTask'
import displayMessage from '../../../utils/displayMessage'

interface IListingChangeTasksProps {
  listingId: string
}

const ListingChangeTasks: React.FC<IListingChangeTasksProps> = ({ listingId }) => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleVisibility = () => {
    form.resetFields()
    setIsModalVisible(!isModalVisible)
  }

  const handleAddTask = async () => {
    const task = form.getFieldsValue()
    const [, err] = await addTask(task, listingId)

    if (err) {
      displayMessage('error', err.message)
    } else {
      displayMessage('success', 'Task added')
      toggleVisibility()
    }
  }

  return (
    <>
      <CustomButton onClick={toggleVisibility}>Add task</CustomButton>
      <Modal
        visible={isModalVisible}
        onOk={handleAddTask}
        onCancel={toggleVisibility}
      >
        <Form
          layout="vertical"
          form={form}
        >
          <Form.Item
            name="task"
            label="Task"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="details"
            label="Details"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Future date"
          >
            <Input type="date" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ListingChangeTasks
