/* eslint-disable @typescript-eslint/no-unused-vars */
import queryString from 'query-string'
import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import { stringifyParams } from 'utils/stringFormatting'

import * as T from 'types'

import { api } from '../api'
import { fetchWithToken } from '../fetchers'
import { getDataFromResponse } from './utils/dataHelpers'
import { getFetchErrorMessage, getUpdateErrorMessage } from './utils/errorMessageHelpers'

export const useRecording = (id: string = '') => {
  const apiEndpoint: string | null = id ? `/recordings/${id}` : null

  const response = useSWR<T.IRecording | T.IServerError>(apiEndpoint, fetchWithToken)

  let recording: T.IRecording | undefined

  if (response.data) recording = response.data as T.IRecording

  const [errors, setErrors] = useState<{ [key: string]: T.IServerError }>({})
  const error = getFetchErrorMessage(response, 'recording')

  const update = async (updates: { [key: string]: T.ValueOf<T.IRecording> }) => {
    if (!recording || !id) {
      return setErrors({ ...errors, update: { message: 'There is no recording' } })
    }

    // we POST the API and update the UI with the data that comes back on success
    const updateResponse = await api.updateRecording(recording._id, updates)
    const updatedRecording = getDataFromResponse<T.IRecording>(updateResponse)

    const userReadableError = 'failed to update'
    const updateError = getUpdateErrorMessage(updateResponse, { userReadableError })

    if (updateError.message) return setErrors({ ...errors, update: updateError })

    await mutate(apiEndpoint, updatedRecording, false)
  }

  const updateSearch = async (
    recordingId: string,
    updates: { [key: string]: T.ValueOf<T.IRecording> },
    searchQueryParamsKey: T.IGetQueryParams,
  ) => {
    const updateResponse = await api.updateRecording(recordingId, updates)
    const userReadableError = 'Failed to update'
    const updateError = getUpdateErrorMessage(updateResponse, { userReadableError })

    if (updateError.message) {
      return setErrors({ ...errors, updateSearchRecordings: updateError })
    }

    // Update paginated search recordings endpoint
    if (searchQueryParamsKey) {
      let searchKey = '/recordings/search'
      const queryParams = stringifyParams(['filter', 'populate'], searchQueryParamsKey)
      searchKey += `?${queryString.stringify(queryParams)}`
      await mutate(searchKey)
    }
  }

  return {
    ...response,
    recording,
    update,
    updateSearch,
    error,
    errors,
  }
}
