import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import Link from 'next/link'

export const renderBreadcrumbItems = (
  route: Route,
  params: any,
  routes: Array<Route>,
  paths: Array<string>,
) => {
  const first = routes.indexOf(route) === 0
  const last = routes.indexOf(route) === routes.length - 1
  if (first) return <Link href={`/${paths[0]}`}>{route.breadcrumbName}</Link>

  if (last) return <span>{route.breadcrumbName}</span>

  return <Link href={paths.join('/')}>{route.breadcrumbName}</Link>
}
