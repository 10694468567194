import { Alert } from 'antd'
import { Header } from 'modules'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { useUser } from 'services'
import styled, { createGlobalStyle } from 'styled-components'

import { isAdmin } from 'services/users'

import PageLoader from './PageLoader'

const PageLayout = styled.div`
  position: relative;
`

const MinHeightContainer = styled.div`
  /* for IE 11 */
  margin-top: ${props => props.theme.metrics.headerHeightPhone}px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    margin-top: ${props => props.theme.metrics.headerHeight}px;
  }
`

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body, input, textarea, button {
    margin: 0;
    padding: 0;
    font-family: "Avenir",Lato,Montserrat,Raleway,"Helvetica Neue",Helvetica,Arial,sans-serif;
    @media print {
      font-family: Lato,Montserrat,Raleway,"Helvetica Neue",Helvetica,Arial,sans-serif;
    }
  }
  body, html {
    box-sizing: border-box;
  }
  html {
    scroll-padding-top: 150px;
  }
   /* @font-face {
   font-family: "Avenir";
   font-weight: 300;
   src: url("/fonts/AvenirLTStd-Light");
  } */
  @font-face {
    font-family: "Avenir";
    font-weight: normal;
    src: url("/fonts/AvenirLTStd-Book.otf");
    font-display: block;
  }
  /* @font-face {
    font-family: "Avenir";
    font-weight: 500;
    src: url("/fonts/AvenirLTStd-Roman");
  } */
  @font-face {
    font-family: "Avenir";
    font-weight: 800;
    src: url("/fonts/AvenirLTStd-Medium.otf");
    font-display: block;
  }
  @font-face {
    font-family: "Avenir";
    font-weight: 900;
    src: url("/fonts/AvenirLTStd-Black.otf");
    font-display: block;
  }
`

const Layout = ({ children }: { children: ReactNode }) => {
  const router = useRouter()

  // user data
  const { user, isValidating } = useUser()
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)

  useEffect(() => {
    if (initialPageLoad) return setInitialPageLoad(false)

    if (isValidating) return

    setLoadingUser(false)
  }, [user, isValidating])

  const renderContentOrError = () => {
    const unauthorizedRoutes = ['', 'signin', 'resetPassword']
    const routeIsAllowed = unauthorizedRoutes.includes(String(router.asPath.split('/').pop()))
    if (routeIsAllowed) return children

    return (
      <>
        {loadingUser && <PageLoader />}
        {!loadingUser && (!user || !isAdmin(user)) && (
          <Alert
            message="You must be an admin to view this content"
            description="Click the Sign In button to sign in as an admin"
            type="error"
            showIcon
          />
        )}
        {user && isAdmin(user) && children}
      </>
    )
  }

  return (
    <PageLayout>
      <GlobalStyle />
      <Header />
      <MinHeightContainer>{renderContentOrError()}</MinHeightContainer>
    </PageLayout>
  )
}

export default Layout
