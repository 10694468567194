import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import * as T from 'types'

import { api } from '../api'
import { fetchWithToken } from '../fetchers'
import { getDataFromResponse } from './utils/dataHelpers'
import { getFetchErrorMessage, getUpdateErrorMessage } from './utils/errorMessageHelpers'
import mutateCacheKeysByRegex from './utils/mutateCacheKeysByRegex'

const mutateHistoryRoutes = () => mutateCacheKeysByRegex(/history/)

export const useContractDetails = (listingId?: string) => {
  const apiKey: string | null = listingId ? `/contract-details/listing/${listingId}/active` : null

  const response = useSWR<T.IContractDetails | T.IServerError>(apiKey, fetchWithToken)

  let contractDetails: T.IContractDetails | undefined
  if (response.data && !response.data.message) contractDetails = response.data as T.IContractDetails

  const error = getFetchErrorMessage(response, 'contract details')

  const [updateError, setUpdateError] = useState<T.IServerError>({})

  const update: T.IUpdateContractDetails = async ({ id, updates, options }) => {
    // In prisma/postgres, to unset a value we must explicitly set it to null
    const updatesWithNulls = Object.entries(updates).reduce((acc, [key, value]) => {
      if (!value && value !== 0 && typeof value !== 'boolean') acc[key] = null
      // Temp: some inputs incorrectly send a string 'undefined' if the user clears a field
      else if (value === 'undefined') acc[key] = null
      else acc[key] = value

      return acc
    }, {} as T.IContractDetails)

    const updateResponse = await api.patchContractDetails(id, updatesWithNulls, options)
    const updatedContractDetails = getDataFromResponse<T.IContractDetails>(updateResponse)

    const userReadableError = 'failed to update contract details'
    const updateContractDetailsError = getUpdateErrorMessage(updateResponse, { userReadableError })
    if (error) setUpdateError(updateContractDetailsError)

    if (updatedContractDetails) {
      mutate(apiKey, updatedContractDetails, false)
      mutateHistoryRoutes()
    }

    return [updatedContractDetails, updateContractDetailsError]
  }

  const resetEscrow: T.IResetEscrow = async ({ contractDetailsId, escrowInfoToPreserve }) => {
    const resetEscrowResponse = await api.resetEscrow(contractDetailsId, escrowInfoToPreserve)
    const newContractDetails = getDataFromResponse(resetEscrowResponse)

    const userReadableError = 'failed to cancel escrow'
    const resetEscrowError = getUpdateErrorMessage(resetEscrowResponse, { userReadableError })
    if (error) setUpdateError(resetEscrowError)

    if (newContractDetails) {
      await mutate(`/contract-details/listing/${listingId}/active`, newContractDetails, false)
      await mutate(`/contract-details/listing/${listingId}/cancelled`)
      await mutate(`/documents/listing/${listingId}`)
      await mutateHistoryRoutes()
    }

    return [resetEscrowResponse.data, { message: undefined }]
  }

  return {
    ...response,
    contractDetails,
    error, // swr fetch error
    update, // generic update function
    resetEscrow,
    updateError,
  }
}

export const useMultipleContractDetails = (listingIds?: string[]) => {
  let apiKey: string | null = null

  if (listingIds?.length) {
    const formattedQuery
      = 'mongoListingIds=6324c4d0abe654445335bb44&mongoListingIds=63221812abe654445335bab3'
    apiKey = `/contract-details/listings/?${formattedQuery}`
  }

  const response = useSWR<T.IContractDetails[] | T.IServerError>(apiKey, fetchWithToken)

  let contractDetails: T.IContractDetails[] | undefined

  if (response.data && Array.isArray(response.data)) {
    contractDetails = response.data as T.IContractDetails[]
  }

  const error = getFetchErrorMessage(response, 'contract details')

  return {
    ...response,
    contractDetails,
    error, // swr fetch error
  }
}

export const useCancelledContractDetails = (listingId?: string) => {
  const apiKey: string | null = listingId ? `/contract-details/listing/${listingId}/cancelled` : null
  const response = useSWR<T.IContractDetails | T.IServerError>(apiKey, fetchWithToken)

  let contractDetailsArr: T.IContractDetails[] | undefined
  if (response.data && !response.data.message) contractDetailsArr = (response.data as T.IContractDetails[])

  const error = getFetchErrorMessage(response, 'contract details')

  return {
    ...response,
    contractDetailsArr,
    error, // swr fetch error
  }
}
