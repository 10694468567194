import { useEffect, useState } from 'react'

import { getAgreementFromListing, isListingMlsDocument } from 'utils/agreementHelpers'

import * as T from 'types'
import * as E from 'types/enums'

const amendmentListingStatusStrings: string[] = [E.ListingStatus.amendmentNeeded]

const useAgreementSigningState = (
  listing?: T.IListing,
  agreementOverrideDocument?: T.IListingDocument,
) => {
  const agreement = getAgreementFromListing(listing)
  const agreementOverrideExists = !!agreementOverrideDocument?.signedDate

  const signedArray = isListingMlsDocument(agreement)
    ? agreement.signers.filter((signer: T.ISigner) => signer.signed)
    : []

  const signerCount = signedArray.length

  const agreementInProgress = isListingMlsDocument(agreement) && !agreement.signed
  const agreementNoSigners = agreementInProgress && !signerCount

  const agreementComplete
    = (isListingMlsDocument(agreement) && agreement.signed) || agreementOverrideExists

  // this is for the case where the user is managing the listing, so they're the first signer.
  // if `agreementInProgress` and the `listingUser` hasn't signed yet, we silently reset the
  // agreement for the user on the BE, so we don't show the Edit Buttons/Reset Agreement Modal
  // when `awaitingListingUserSigner` is true
  const managingUser = listing?.iAmNotATitleHolder
  const listingUserEmail = listing?.userId?.email
  const listingUserSigned = !!signerCount && signedArray[0].email === listingUserEmail
  const awaitingListingUserSigner = agreementInProgress && !managingUser && !listingUserSigned

  const [amendmentInProgress, setAmendmentInProgress] = useState(
    amendmentListingStatusStrings.includes(listing?.listingStatus.name ?? ''),
  )

  useEffect(() => {
    if (amendmentListingStatusStrings.includes(listing?.listingStatus.name ?? '')) {
      setAmendmentInProgress(true)
    }
  }, [listing?.listingStatus.name])

  return {
    agreement,
    agreementInProgress,
    agreementNoSigners,
    agreementComplete,
    awaitingListingUserSigner,
    setAmendmentInProgress,
    amendmentInProgress,
  }
}

export default useAgreementSigningState
