import { DeleteOutlined, ExclamationCircleOutlined, FileTextOutlined } from '@ant-design/icons'
import { Popconfirm, Typography, message } from 'antd'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { removeDocument, updateDocument } from 'services/documents'

import * as T from 'types'

import Spacer from './Spacer'

const { Text } = Typography

const DocumentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: ${props => props.theme.colors.grey};
  width: 100%;
`

const DocInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const DocumentNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-height: 32px;
  width: 100%;
`

const Suffix = styled(Text)`
  white-space: nowrap;
  margin-left: 5px;
  display: flex;
`

const DocumentName = styled(Text)`
  font-weight: 800;
  display: flex;

  &.ant-typography-edit-content {
    width: 80%;
    padding: 0;
    margin: 0;
    margin-left: 20px;
  }
`

interface IProps {
  document: T.IListingDocument
  editable: boolean
  deletable: boolean
  listingId: string
  onClick?: (document: T.IListingDocument) => void
  onClickEdit?: (newName: string) => void
  onClickDelete?: (document: T.IListingDocument) => void
}

const Document: FunctionComponent<IProps> = ({
  document,
  editable,
  deletable,
  listingId,
  onClick,
  onClickEdit,
  onClickDelete,
}) => {
  // default behaviors
  const openDocument = (file: T.IListingDocument) => {
    if (file.signatureRequestId) {
      window.open(`${process.env.API_URL}/hellosigns/preview/${file.signatureRequestId}`, '_blank')
    } else if (file.url) {
      window.open(`${file.url}?inline=true&access_token=${Cookies.get('token')}`, '_blank')
    } else {
      message.error({
        style: { marginTop: '50px' },
        content: 'Unsupported document type',
        duration: 5,
      })
    }
  }

  const handleChangeDocumentName = (name: string) => {
    if (!document._id || (document.type && document.type !== 'Contract')) return

    if (!document.originalUrl) return

    const originalFilenameArr = document.originalUrl.split('/')
    const originalFilename = originalFilenameArr[originalFilenameArr.length - 1]
    const extensionArr = originalFilename?.split('.')
    const extension = extensionArr[extensionArr.length - 1]

    let nameWithExtension: string = name

    if (!name.endsWith(`.${extension}`)) {
      nameWithExtension += `.${extension}`
    }

    const newDocument: T.IListingDocument = {
      ...document,
      name: nameWithExtension,
      url: document.originalUrl,
    }
    updateDocument(newDocument, listingId)
  }

  const handleFileDelete = async (file: T.IListingDocument) => {
    // allow to delete only user uploaded files and contracts
    if (file._id && (!file.type || file.type === 'Contract')) {
      await removeDocument(file._id, listingId)
    }
  }

  // click handlers
  const handleClick = () => {
    if (onClick) onClick(document)
    else openDocument(document)
  }

  const handleClickEdit = (newName: string) => {
    if (onClickEdit) onClickEdit(newName)
    else handleChangeDocumentName(newName)
  }

  const handleConfirmDelete = () => {
    if (onClickDelete) onClickDelete(document)
    else handleFileDelete(document)
  }

  // formatted data
  let suffix = ''
  if (document.type) suffix += ` (${document.type})`

  if (document.archived) suffix += ' (archived)'

  let uploader = 'Seller'
  if (document.uploadedBy?.role === 'admin') uploader = 'Admin'

  if (document.uploadedBy?.name) uploader = document.uploadedBy?.name

  const uploadedBy = `Uploaded by ${uploader}`

  const uploadedAt = dayjs(document.uploadedAt).format('MM/DD/YYYY')

  return (
    <DocumentContainer>
      <DocInfo>
        <FileTextOutlined onClick={handleClick} />
        <Spacer size={5} />
        <DocumentNameContainer>
          <DocumentName editable={editable ? { onChange: handleClickEdit } : false}>
            {document.name ?? 'unknown (name missing)'}
          </DocumentName>
          <Suffix>{suffix}</Suffix>
        </DocumentNameContainer>
      </DocInfo>
      <div>
        <span style={{ fontSize: '12px', paddingRight: '5px' }}>
          {`${uploadedBy} on ${uploadedAt}`}
        </span>
        {!document.archived && deletable && (
          <Popconfirm
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
            title="Are you sure you want to delete this document?"
            placement="left"
            onConfirm={handleConfirmDelete}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ alignSelf: 'flex-end' }} />
          </Popconfirm>
        )}
      </div>
    </DocumentContainer>
  )
}

export default Document
