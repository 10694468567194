import isoFetch from 'isomorphic-unfetch'

const fetch = async (path: string, returnResponse = false) => {
  if (!path) throw new Error('Missing path')

  if (path[0] !== '/') throw new Error('Missing forward slash')

  const response = await isoFetch(`${process.env.API_URL}${path}`, {
    method: 'GET',
  })
  if (returnResponse) return response

  const data = response.json()
  return data
}

export default fetch
