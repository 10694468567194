/* eslint-disable no-alert */
import { green, grey, red, yellow } from '@ant-design/colors'
import { FileTextFilled } from '@ant-design/icons'
import { Alert, Col, Row } from 'antd'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { FunctionComponent, useContext, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { v4 as uuid } from 'uuid'

import CustomButton from 'components/CustomButton'
import Spacer from 'components/Spacer'

import { api } from 'services/api'
import { sendSignatureReminderEmail, signatureRequest } from 'services/hellosign'
import { useAgreement } from 'services/hooks'
import { useDocuments } from 'services/swr/useDocuments'

import {
  getAmendmentsFromListing,
  hasOverrideProps,
  isListingMlsDocument,
} from 'utils/agreementHelpers'
import displayMessage from 'utils/displayMessage'
import { parseBooleanValue, parseTzDate, renderValueOrNothing } from 'utils/stringFormatting'

import * as T from 'types'
import * as E from 'types/enums'

import { OverrideAgreementModal, ResetAgreementModal } from './AgreementModal'
import FieldListView from './FieldListView'
import { SubsectionTitle } from './Styles'
import TableView from './TableView'

const AgreementName = styled.div`
  height: 100%;
  text-overflow: ellipsis;
`

interface IProps {
  listing: T.IListing
  listView: boolean
}

const Agreement: FunctionComponent<IProps> = ({ listing, listView }) => {
  const theme = useContext(ThemeContext)
  const { listingDocuments } = useDocuments(listing._id)
  const [modalType, setModalType] = useState<E.AgreementSetting | undefined>()
  const [alertMessage, setAlertMessage] = useState<T.IAlert | null>(null)
  const [sendingAgreement, setSendingAgreement] = useState(false)

  const { agreement, hasOverride } = useAgreement(listing, listingDocuments)
  const amendments = getAmendmentsFromListing(listing)

  const getGoLiveDate = () => {
    const dateString = renderValueOrNothing(parseTzDate(listing.goLiveDate, listing.timeZone))
    if (listing.goLiveLater) return dateString

    return `${dateString} or before`
  }

  const brokerSignedFieldValue = hasOverrideProps(agreement)
    ? agreement.brokerSigned
    : agreement?.brokerSigner?.signed

  const agreementExtension = amendments.find(
    amendment => (amendment as T.IListingMlsDocument).isListingExtension && !amendment.archived,
  )

  const agreementColumns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      width: '20%',
    },
    {
      title: 'Signed?',
      dataIndex: 'Signed?',
      width: '12.5%',
    },
    {
      title: 'Signed At',
      dataIndex: 'Signed At',
      width: '12.5%',
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      width: '25%',
    },
  ]

  const agreementCoreFields: T.IMlsField[] = useMemo(
    () => [
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'name',
          type: 'text',
        },
        isAgreementField: true,
        name: 'Agreement Name',
        label: 'Agreement Name',
        value: agreement?.name || 'No Listing Agreement has been started',
        readOnly: !agreement || hasOverride,
        type: 'date',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: '',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'brokerSigned',
          type: 'switch',
        },
        isCoreField: false,
        name: 'Signed by Broker',
        label: 'Signed by Broker',
        value: parseBooleanValue(brokerSignedFieldValue),
        type: '',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: 'Signed by Broker',
        defaultValue: '',
        readOnly: true,
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'extensionSentAt',
          type: 'date',
        },
        readOnly: true,
        name: 'Extension Sent On',
        label: 'Extension Sent On',
        value: renderValueOrNothing(parseTzDate(agreementExtension?.uploadedAt, listing.timeZone)),
        type: 'date',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: '',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'signedDate',
          type: 'date',
        },
        isAgreementField: true,
        readOnly: hasOverride,
        name: 'Completed On',
        label: 'Completed On',
        value: renderValueOrNothing(parseTzDate(agreement?.signedDate, listing.timeZone)),
        type: 'date',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: '',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'goLiveDate',
          type: 'date',
        },
        isCoreField: true,
        name: 'Go Live Date',
        label: 'Go Live Date',
        value: getGoLiveDate(),
        type: 'date',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: '',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'dateOfExpiration',
          type: 'date',
        },
        isAgreementField: true,
        readOnly: hasOverride,
        name: 'Expiration Date',
        label: 'Expiration Date',
        value: renderValueOrNothing(parseTzDate(agreement?.dateOfExpiration, listing.timeZone)),
        type: 'date',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: '',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'goLiveLater',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Hold till Specified Publish Date',
        label: 'Hold till Specified Publish Date',
        value: parseBooleanValue(listing.goLiveLater),
        type: '',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: 'Go Live Date',
        defaultValue: '',
      },
      {
        _id: uuid(),
        fieldId: {
          _id: uuid(),
          name: 'adminGoLiveDateOverride',
          type: 'switch',
        },
        isCoreField: true,
        isCoreFieldBoolean: true,
        name: 'Admin Go Live Date',
        label: 'Admin Go Live Date',
        value: parseBooleanValue(listing.adminGoLiveDateOverride),
        type: 'date',
        listingFlowStep: 'Listing Agreement',
        listingFlowSubStep: '',
        defaultValue: '',
      },
    ],
    [listing, listingDocuments, agreement],
  )

  const readyForBrokerSignature = !!(
    (
      listing.listingStatus.name !== 'Draft'
      && isListingMlsDocument(agreement)
      && agreement.signers.every(signer => signer.signed)
      && agreement.brokerSigner
      && !agreement.brokerSigner.signed // and not be signed
      && agreement.brokerSigner.signatureId
    ) // not incomplete agreements begun in old system
  )

  const canAttemptToSendAgreement
    = !agreement
    || (isListingMlsDocument(agreement) && agreement.signers.every(signer => !signer.signed))

  const canSendReminder
    = isListingMlsDocument(agreement) && agreement.signers.some(signer => !signer.signed)

  const getSigners = (mlsDoc: T.IListingMlsDocument) =>
    mlsDoc.signers.map(signer => ({
      'Name': signer.name,
      'Signed?': signer.signed ? 'Yes' : 'No',
      'Signed At': signer.signedAt ? dayjs(signer.signedAt).format('MM/DD/YY, h:mm A') : '',
      'Email': signer.email,
    }))

  const getTitleHolders = (overrideDoc: T.IListingDocument | undefined) =>
    listing.owners.map(owner => ({
      'Name': owner.name,
      'Signed?': overrideDoc?.type === 'Agreement Override' ? 'Manual Agreement' : 'No',
      'Signed At':
        overrideDoc?.type === 'Agreement Override'
          ? dayjs(overrideDoc.signedDate).format('MM/DD/YY, h:mm A')
          : '',
      'Email': owner.email,
    }))

  // Agreement button handlers
  const handleViewAgreement = () => {
    if (!agreement || typeof window === 'undefined') return

    const url = hasOverrideProps(agreement)
      ? `${process.env.API_URL}/amazons/agreementDocument/${
        listing._id
      }?inline=true&access_token=${Cookies.get('token')}`
      : `${process.env.API_URL}/hellosigns/preview/${agreement.signatureRequestId}`

    window.open(url)
  }

  const handleOverrideAgreement = () => setModalType(E.AgreementSetting.OverrideAgreement)

  const handleResetAgreement = () => setModalType(E.AgreementSetting.ResetAgreement)

  const handleSignAgreement = () => {
    const token = isListingMlsDocument(agreement) && agreement.brokerSigner?.token

    if (token && typeof window !== 'undefined') {
      window.open(`${process.env.SITE_URL}/document/sign/${token}`)
    }
  }

  const handleSendAgreement = async () => {
    try {
      if (agreement) {
        return setAlertMessage({
          type: 'warning',
          message: 'Please reset your current agreement before sending a new one',
        })
      }

      setSendingAgreement(true)
      const response = await api.canSendAgreement(listing._id)

      if (!response.ok) {
        throw new Error(`unexpected response from canSendAgreement: ${response.status}`)
      }

      const canSendAgreement = response.data

      if (!canSendAgreement) {
        return setAlertMessage({
          type: 'error',
          message: 'All required fields not completed, unable to send agreement',
        })
      }

      const { data } = await signatureRequest(listing._id)

      // eslint-disable-next-line camelcase
      if (!data?.signUrl?.sign_url) {
        throw new Error('unexpected response when sending a signature request')
      }

      setAlertMessage({
        type: 'success',
        message: 'Agreement Sent',
      })
    } catch (err) {
      console.error(err)
      displayMessage('error', 'An error occurred while attempting to send the agreement')
    } finally {
      setSendingAgreement(false)
    }
  }

  const handleSendReminder = async () => {
    try {
      await sendSignatureReminderEmail(listing._id, { isAgreement: true })
      setAlertMessage({ type: 'success', message: 'Signature reminder sent!' })
      setTimeout(() => setAlertMessage(null), 3000)
    } catch (err) {
      console.error(err)
      setAlertMessage({
        type: 'error',
        message: 'An error occurred attempting to send the reminder email',
      })
    }
  }

  const handleAlertMessage = (message: T.IAlert) => setAlertMessage(message)

  const cleanMessage = () => setAlertMessage(null)

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col
          xs={24}
          md={16}
          lg={8}
        >
          <SubsectionTitle>
            <AgreementName>Agreement Details</AgreementName>
            <FileTextFilled
              disabled={!!agreement}
              onClick={handleViewAgreement}
            />
          </SubsectionTitle>
          {agreementCoreFields.map((field, index) => (
            <FieldListView
              key={field._id}
              listView={listView}
              field={field}
              index={index}
              removeTitle
            />
          ))}
        </Col>
        <Col
          xs={24}
          md={24}
          lg={16}
        >
          <SubsectionTitle>Signers</SubsectionTitle>
          {TableView(
            isListingMlsDocument(agreement) ? getSigners(agreement) : getTitleHolders(agreement),
            agreementColumns,
          )}
        </Col>
      </Row>
      <Spacer size={15} />
      <Row
        align="middle"
        gutter={[15, 15]}
      >
        <Col>
          <CustomButton
            backgroundColor={yellow[4]}
            backgroundColorOnHover={yellow[5]}
            custom
            onClick={handleOverrideAgreement}
          >
            Override Agreement
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            color={theme.colors.white}
            backgroundColor={red[5]}
            backgroundColorOnHover={red[6]}
            custom
            onClick={handleResetAgreement}
            disabled={!agreement}
          >
            Reset Agreement
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            backgroundColor={readyForBrokerSignature ? green[5] : grey[1]}
            backgroundColorOnHover={readyForBrokerSignature ? green[6] : grey[4]}
            custom
            onClick={handleSignAgreement}
            disabled={!readyForBrokerSignature}
          >
            Sign Agreement
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            backgroundColor={canAttemptToSendAgreement ? green[5] : grey[1]}
            backgroundColorOnHover={canAttemptToSendAgreement ? green[6] : grey[4]}
            custom
            loading={sendingAgreement}
            onClick={handleSendAgreement}
            disabled={!canAttemptToSendAgreement}
          >
            Send Agreement
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            backgroundColor={canSendReminder ? yellow[4] : grey[1]}
            backgroundColorOnHover={canSendReminder ? yellow[5] : grey[4]}
            custom
            onClick={handleSendReminder}
            disabled={!canSendReminder}
          >
            Send Reminder
          </CustomButton>
        </Col>
        {alertMessage && (
          <>
            <Spacer size={15} />
            <Alert
              style={{ height: 32 }}
              message={alertMessage.message}
              type={alertMessage.type}
              showIcon
              closable
              onClose={cleanMessage}
            />
          </>
        )}
      </Row>
      {modalType === E.AgreementSetting.OverrideAgreement && (
        <OverrideAgreementModal
          listing={listing}
          setModalType={setModalType}
        />
      )}
      {modalType === E.AgreementSetting.ResetAgreement && (
        <ResetAgreementModal
          listing={listing}
          setModalType={setModalType}
          onCompletion={handleAlertMessage}
        />
      )}
    </>
  )
}

export default Agreement
