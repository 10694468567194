/* eslint-disable no-alert */
import { Col, Row } from 'antd'
import { FunctionComponent } from 'react'

import { useServiceFulfillmentFields } from 'services/hooks/listingDetails'

import * as T from 'types'

import FieldListView from './FieldListView'

interface IProps {
  listing: T.IListing
  listView: boolean
}

const ServiceFulfillment: FunctionComponent<IProps> = ({ listing, listView }) => {
  const {
    photographerFields,
    serviceSetupFields,
    yardSignFields,
    otherServicesFields,
    oldYardSignFields,
    measurementAppraisalCompany,
    mlsRulesFields,
  } = useServiceFulfillmentFields(listing)

  const renderFieldListViews = (fieldSets: T.IMlsField[][]) =>
    fieldSets.map(fieldSet => (
      <Col
        key={`${fieldSet[0]?.name}`}
        xs={24}
        md={12}
        lg={8}
      >
        {fieldSet.map((field: T.IMlsField, index: number) => (
          <FieldListView
            key={field.name}
            field={field}
            index={index}
            listView={listView}
            fieldSet={fieldSet}
          />
        ))}
      </Col>
    ))

  return (
    <Row gutter={[15, 15]}>
      {renderFieldListViews([
        serviceSetupFields,
        photographerFields,
        yardSignFields,
        otherServicesFields,
        measurementAppraisalCompany,
        mlsRulesFields,
        oldYardSignFields,
      ])}
    </Row>
  )
}

export default ServiceFulfillment
