import dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import moment from 'moment-timezone'

import * as T from 'types'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced) // adds 'Z' timezone formatting support

/*
 * All times should be in EST/EDT and in MM/DD/YY, h:mm A Timezone format
 * except for showing and availability times which are listing location specific
 */
export const calculateTimeWithTimezone = (
  time: Date | string,
  listingTimezone: string,
  format: string = 'h:mm A z',
) => {
  if (!time) return null

  const date = dayjs(time)
    .tz(listingTimezone || 'America/New_York')
    .format(format)
  return date === 'Invalid Date' ? 'Invalid Date' : date
}

// return EST, EDT, PST, PDT...
export const getTimeZoneAbbreviation = (listing: T.IListing, time: Date) =>
  dayjs.tz(time, listing.timeZone || 'America/New_York').format('z')

export const updateDateWithTimezone = (value: Date, listingTimezone: string): Date => {
  const timeZone: string = dayjs.tz.guess()
  const currentOffset: number = dayjs.tz(dayjs(), timeZone).utcOffset()
  const listingOffset: number = dayjs.tz(value, listingTimezone).utcOffset()
  const timeInHours: number = (listingOffset - currentOffset) / 60
  return dayjs(value).subtract(timeInHours, 'hours').toDate()
}

export const applyDateWithTimezone = (
  value: string,
  listingTimezone?: string,
  format: string = 'MM/DD/YY, h:mm A z',
) => {
  const date = dayjs(value)
  if (!date.isValid()) return

  const tz = listingTimezone || dayjs.tz.guess() || 'America/New_York'
  return date.tz(tz).format(format)
}

export const setDefaultMomentTimezone = (timeZone: string) => {
  moment.tz.setDefault(timeZone)
}
