import { mutate } from 'swr'

import * as T from 'types'

import { api } from './api'
import { getDataFromResponse } from './swr/utils/dataHelpers'
import { getUpdateErrorMessage } from './swr/utils/errorMessageHelpers'

export const createTwilioWorker = async (
  newWorker: T.ITwilioWorkerParams,
): Promise<[T.ITwilioWorker | undefined, T.IServerError]> => {
  const response = await api.createTwilioWorker(newWorker)
  const worker = getDataFromResponse<T.ITwilioWorker>(response)

  const userReadableError = 'Failed to create worker'
  const createWorkerError = getUpdateErrorMessage(response, { userReadableError })

  mutate('/users/me')
  mutate('/twilios/workers')
  mutate(`/twilios/worker/email/${newWorker.email}`, response.data, false)

  return [worker, createWorkerError]
}

export const updateTwilioWorker = async (
  email: string,
  changes: Partial<T.ITwilioWorkerParams>,
): Promise<[T.ITwilioWorker | undefined, T.IServerError]> => {
  const response = await api.updateTwilioWorker(email, changes)
  const updatedWorker = getDataFromResponse<T.ITwilioWorker>(response)

  const userReadableError = 'Failed to update worker status'
  const updateWorkerError = getUpdateErrorMessage(response, { userReadableError })

  mutate('/users/me')
  mutate('/twilios/workers')
  mutate(`/twilios/worker/email/${email}`, response.data, false)

  return [updatedWorker, updateWorkerError]
}

export const removeTwilioWorker = async (
  email: string,
): Promise<[boolean | undefined, T.IServerError]> => {
  const response = await api.removeTwilioWorker(email)

  const userReadableError = 'Failed to remove worker'
  const updateWorkerError = getUpdateErrorMessage(response, { userReadableError })

  mutate('/users/me')
  mutate('/twilios/workers')
  mutate(`/twilios/worker/email/${email}`, null, false)

  return [response.ok ?? undefined, updateWorkerError]
}

export const bringUserToIdle = async (
  email: string,
): Promise<[boolean | undefined, T.IServerError]> => {
  const response = await api.bringUserToIdle(email)

  const userReadableError = 'Failed to change worker activity to Idle'
  const updateWorkerError = getUpdateErrorMessage(response, { userReadableError })

  mutate('/users/me')
  mutate(`/twilios/worker/email/${email}`)
  mutate('/twilios/workers')

  return [response.ok ?? undefined, updateWorkerError]
}

export const setCellphoneAsPrimaryDevice = async (
  email: string,
): Promise<[boolean | undefined, T.IServerError]> => {
  const response = await api.setCellphoneAsPrimaryDevice(email)

  const userReadableError = 'Failed to set primary device'
  const updateDeviceError = getUpdateErrorMessage(response, { userReadableError })

  mutate(`/twilios/worker/${email}/device`)

  return [response.ok ?? undefined, updateDeviceError]
}

export const bringUserToOffline = async (
  email: string,
): Promise<[boolean | undefined, T.IServerError]> => {
  const response = await api.bringUserToOffline(email)

  const userReadableError = 'Failed to change worker activity to Offline'
  const updateWorkerError = getUpdateErrorMessage(response, { userReadableError })

  mutate('/users/me')
  mutate(`/twilios/worker/email/${email}`)
  mutate('/twilios/workers')

  return [response.ok ?? undefined, updateWorkerError]
}
