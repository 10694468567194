import { useFieldSchema, useListing } from 'services'

import { showFieldsWithValues } from 'utils/mlsFieldsHelpers'

import * as T from 'types'

export const useFieldsByGroupNames = (groupNames: string[]) => {
  const { listing } = useListing()
  const { fieldSchema } = useFieldSchema(listing?.mlsList[0]?.mls?._id)

  const fieldsInSchema: T.IMlsField[] = fieldSchema ? fieldSchema.fields : []

  const fields: T.IMlsField[] = showFieldsWithValues(listing, fieldsInSchema)

  return groupNames.map(groupName => fields.filter(field => field.listingFlowStep === groupName))
}
