import { LoadingOutlined } from '@ant-design/icons'
import { Row, Spin } from 'antd'
import { FunctionComponent } from 'react'

const LoadingIcon = (
  <LoadingOutlined
    style={{ fontSize: 24 }}
    spin
  />
)
interface IProps {
  pageLoader?: boolean
}

const LoadingSpinner: FunctionComponent<IProps> = ({ pageLoader }) =>
  pageLoader
    ? (
      <Row
        style={{ height: '95vh' }}
        align="middle"
        justify="center"
      >
        <Spin />
      </Row>
    )
    : (
      <Spin indicator={LoadingIcon} />
    )

export default LoadingSpinner
