import { Alert, Col, Input, Row, Space } from 'antd'
import { FontSizeButton } from 'components'
import { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useListing, useUser } from 'services/swr'
import mutateCacheKeysByRegex from 'services/swr/utils/mutateCacheKeysByRegex'

import * as T from 'types'

import { NotesTableView, getNoteTableColumns } from './Shared'

const { TextArea } = Input

const mutateHistoryRoutes = () => mutateCacheKeysByRegex(/history/)

const NoteInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;

  button {
    align-self: flex-end;
  }
`

interface IProps extends T.IFontSize {
  listing: T.IListing
  noteType: 'escrowNotes' | 'agentNotes'
}

const Notes: FC<IProps> = ({ fontSize, listing, noteType }) => {
  const { user } = useUser()
  const { update, updateError } = useListing()

  const { [noteType]: notes = [] } = listing

  const notesWithKey: T.IWithKey<T.INote>[] = useMemo(
    () => notes.map(note => ({ ...note, key: note._id as string })),
    [notes],
  )

  const [note, setNote] = useState('')
  const [error, setError] = useState('')

  const columns = useMemo(() => getNoteTableColumns(fontSize, 'Agent Name'), [fontSize])

  const addNote = (includeChanges = false) => {
    if (!user?.name) return setError('Admin user does not exist')

    if (!note) return setError('Note cannot be empty')

    const updatedNotes = notes.concat({
      date: new Date().toISOString(),
      note,
      user: user.name,
    })

    if (includeChanges) {
      update({ [noteType]: updatedNotes })
    } else {
      update(
        { [noteType]: updatedNotes },
        {
          disableStatusUpdate: true,
          disableListingChangesRequired: true,
          mutateHistoryRoutes,
        },
      )
    }

    setNote('')
  }

  const addNoteWithoutChanges = () => addNote()
  const addNoteWithChanges = () => addNote(true)

  const handleTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement> = evt =>
    setNote(evt.target.value)

  useEffect(() => {
    setError(updateError)
  }, [updateError])

  return (
    <Row gutter={[0, 25]}>
      <Col span={24}>
        <NoteInputContainer>
          <Row gutter={[5, 5]}>
            <Col
              xs={24}
              md={16}
            >
              <TextArea
                rows={3}
                value={note}
                onChange={handleTextAreaChange}
              />
            </Col>
            <Col>
              <Space
                direction="vertical"
                size={10}
              >
                <FontSizeButton
                  disabled={!note}
                  onClick={addNoteWithoutChanges}
                  type="primary"
                >
                  Add Note
                </FontSizeButton>
                <FontSizeButton
                  disabled={!note}
                  onClick={addNoteWithChanges}
                  type="primary"
                >
                  Add Note + Create Change Required
                </FontSizeButton>
              </Space>
            </Col>
            {error && (
              <Col span={24}>
                <Alert
                  style={{ height: '32px', width: '100%' }}
                  message={error}
                  type="error"
                  showIcon
                  closable
                />
              </Col>
            )}
          </Row>
        </NoteInputContainer>
      </Col>
      <Col span={24}>
        <NotesTableView
          columns={columns}
          dataSource={notesWithKey}
          fontSize={fontSize}
          pagination={false}
        />
      </Col>
    </Row>
  )
}

export default Notes
